/**
 * @generated SignedSource<<36241654bd482eab51274f29d3f3f7a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemTileCarousel_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ItemTileBase_itemSearch" | "ProductCarousel_itemSearch">;
  readonly " $fragmentType": "ItemTileCarousel_itemSearch";
};
export type ItemTileCarousel_itemSearch$key = {
  readonly " $data"?: ItemTileCarousel_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemTileCarousel_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemTileCarousel_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemTileBase_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductCarousel_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "555daed6462283d77e4dbf32ebf7576f";

export default node;

import { ReactElement, FC, memo } from 'react';
import { useIntl } from './IntlContext';

type Props = Intl.NumberFormatOptions & {
    value: number;
    children?: (result: string) => ReactElement;
};

const FormattedNumber: FC<Props> = memo(({ value, children, ...numberFormatOptions }) => {
    const intl = useIntl();
    const result = intl.formatNumber(value, numberFormatOptions);
    if (typeof children === 'function') {
        return children(result);
    }
    return <>{result}</>;
});

FormattedNumber.displayName = 'FormattedNumber';

export { FormattedNumber };

import { FC } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import Footer from '../Footer/Footer';
import footerRootQuery, {
    initialVariables,
    footerRootQueryType,
} from '../../queries/footerRootQuery';

const FooterRenderer: FC = () => {
    const data = useLazyLoadQuery<footerRootQueryType>(footerRootQuery, initialVariables, {
        fetchPolicy: 'store-only',
    });

    return <Footer viewer={data.viewer} />;
};

export default FooterRenderer;

export type Split<
    StringList extends string,
    Separator extends string = ' '
> = StringList extends `${infer T}${Separator}${infer Rest}`
    ? T extends ''
        ? [...Split<Rest>]
        : T extends string
        ? [T, ...Split<Rest>]
        : [...Split<Rest>]
    : [StringList];

export function split<T extends string | undefined = ''>(
    arg: T
): T extends string ? Split<T> : Split<''> {
    return (typeof arg === 'undefined' ? [] : arg.split(' ')) as T extends string
        ? Split<T>
        : Split<''>;
}

/**
 * @generated SignedSource<<b8d30d37668165daf3937c14c72f2691>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedCollectionSpotlightModule_componentModule$data = {
  readonly cmsDisplayTitle: string | null;
  readonly messages: {
    readonly collectionImage: {
      readonly imageHeight: string | null;
      readonly imageUrl: string | null;
      readonly imageWidth: string | null;
    } | null;
    readonly collectionPhoneImage: {
      readonly imageHeight: string | null;
      readonly imageUrl: string | null;
      readonly imageWidth: string | null;
    } | null;
    readonly cta: string | null;
    readonly description: string | null;
    readonly image: {
      readonly imageHeight: string | null;
      readonly imageUrl: string | null;
      readonly imageWidth: string | null;
    } | null;
    readonly isCollection: boolean | null;
    readonly items: ReadonlyArray<{
      readonly contemporaryTrackingString: string | null;
      readonly itemId: string | null;
      readonly linkData: {
        readonly path: string | null;
      };
      readonly title: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"HpSharedCollectionSpotlightItem_item">;
    } | null> | null;
    readonly linkData: {
      readonly path: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"SeoButton_linkData">;
    };
    readonly linkText: string | null;
    readonly profileImage: {
      readonly imageHeight: string | null;
      readonly imageUrl: string | null;
      readonly imageWidth: string | null;
    } | null;
    readonly title: string | null;
    readonly videoEmbedId: string | null;
  } | null;
  readonly " $fragmentType": "HpSharedCollectionSpotlightModule_componentModule";
};
export type HpSharedCollectionSpotlightModule_componentModule$key = {
  readonly " $data"?: HpSharedCollectionSpotlightModule_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedCollectionSpotlightModule_componentModule">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "imageUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "imageWidth",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "imageHeight",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "userType"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedCollectionSpotlightModule_componentModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cmsDisplayTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "userType",
          "variableName": "userType"
        }
      ],
      "concreteType": "CollectionSpotlightItemMessages",
      "kind": "LinkedField",
      "name": "messages",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCollection",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoEmbedId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cta",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionSpotlightImageType",
          "kind": "LinkedField",
          "name": "collectionImage",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionSpotlightImageType",
          "kind": "LinkedField",
          "name": "collectionPhoneImage",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionSpotlightImageType",
          "kind": "LinkedField",
          "name": "profileImage",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionSpotlightImageType",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LinkData",
          "kind": "LinkedField",
          "name": "linkData",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SeoButton_linkData"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionSpotlightItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "itemId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contemporaryTrackingString",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LinkData",
              "kind": "LinkedField",
              "name": "linkData",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HpSharedCollectionSpotlightItem_item"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionSpotlightModule",
  "abstractKey": null
};
})();

(node as any).hash = "13c69eb84ab34250941695ca1bc774f4";

export default node;

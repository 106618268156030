const SIZE_NONE = 'none';
const SIZE_DEFAULT = 'default';
const SIZE_TINY = 'tiny';
const SIZE_SMALL = 'small';
const SIZE_MEDIUM = 'medium';
const SIZE_LARGE = 'large';
const SIZE_HEADER_X_LARGE = 'header-x-large';
const SIZE_ANIMATED_PLACEHOLDER = 'animatedPlaceholder';

export const SIZES = {
    none: SIZE_NONE,
    def: SIZE_DEFAULT,
    tiny: SIZE_TINY,
    small: SIZE_SMALL,
    medium: SIZE_MEDIUM,
    large: SIZE_LARGE,
    headerXLarge: SIZE_HEADER_X_LARGE,
    animatedPlaceholder: SIZE_ANIMATED_PLACEHOLDER,
};

const DIRECTION_LEFT = 'left';
const DIRECTION_RIGHT = 'right';
const DIRECTION_DOWN = 'down';
const DIRECTION_UP = 'up';

export const DIRECTIONS = {
    left: DIRECTION_LEFT,
    right: DIRECTION_RIGHT,
    down: DIRECTION_DOWN,
    up: DIRECTION_UP,
};

const ALIGN_BOTTOM = 'bottom';
const ALIGN_CENTER = 'center';
const ALIGN_TOP = 'top';

export const ALIGN = {
    bottom: ALIGN_BOTTOM,
    center: ALIGN_CENTER,
    top: ALIGN_TOP,
};

import merge from 'deepmerge';
import { getGtmEventQueue } from './gtmEventQueue';
import { isEmpty, omit } from './utils';
import { trackingConstants } from './constants';

import { TrackingObject, EcomProduct } from './types';

/**
 * helper method used to parse a pdp url for an item's category level (L1/L2/L3), name and id
 * @param url
 * @returns {*}
 */
const ecomParseUrl = (
    url?: string | number | boolean | null // only added `number` to accommodate EcomProduct type
): { category?: string; name?: string; id?: string } => {
    if (typeof url !== 'string') {
        return {};
    }

    if (url.startsWith('https://')) {
        const noProto = url.split('https://')[1];
        url = noProto.slice(noProto.indexOf('/'));
    }

    // given a pdp url of the form -->
    //      /{L1}/{l2}/{L3}/{product name}/id-{item pk}/?{random params}
    // set pathArr to
    //      [path, {L1/L2/L3}, {L1}, {L2}, {L3}, {product name}, {item pk}]
    const pathArr =
        /^\/(?:([^\/]*)\/([^\/]*)(?:(?:\/([^\/]*)(?:\/([^\/]*)))|(?:\/([^\/]*))?))\/id-([^/]+)\/?$/i.exec(
            url
        );
    if (pathArr === null) {
        return {};
    }
    // grab the item id, the product name and the uri substring for the categories
    return {
        category: [pathArr[1], pathArr[2], pathArr[3]]
            .filter(function (elem) {
                return !!elem;
            })
            .join('/'), // {L1/L2/L3}
        name: (pathArr[4] || pathArr[5] || '').split('-').join(' '),
        id: pathArr[6],
    };
};

/**
 * helper method (used in ecommerceProduct) that takes the trackObj products array and returns a product array
 * formatted for the GTM tracking object.
 * @param productArray
 * @returns {Array}
 */
const ecomGetProducts = (productArray?: EcomProducts): EcomProducts => {
    const outArr: EcomProducts = [];
    if (Array.isArray(productArray) && productArray.length) {
        productArray.forEach(product => {
            const parsedEcomData = ecomParseUrl(product.url);
            let productData: EcomProduct = merge(parsedEcomData || {}, product);
            productData = omit(productData, ['url', 'additional']);
            outArr.push(productData);
        });
    }
    return outArr;
};

type EcomEventType = TrackingObject['event'];
type EcomProducts = TrackingObject['products'];
type EcomActionField = TrackingObject['actionField'];
type EcomPromotions = TrackingObject['promotions'];
type EcomType =
    | undefined
    | {
          // type: 'productImpression';
          impressions?: EcomProducts;
          // type: 'productClick';
          click?: {
              actionField: EcomActionField;
              products: EcomProducts;
          };
          // type: 'productDetail';
          detail?: { products: EcomProducts };
          // type: 'addToCart';
          add?: { products: EcomProducts };
          // type: 'checkout'
          checkout?: {
              actionField: EcomActionField;
              products: EcomProducts;
          };
          // type: 'promotionImpression':
          promoView?: { promotions: EcomPromotions };
          // type: 'promoClick':
          promoClick?: { promotions: EcomPromotions };
          // type: 'purchase'
          purchase?: {
              actionField: EcomActionField;
              products: EcomProducts;
          };
      };

/**
 * Ecommerce Tracking used in Google Tag Manager
 * @param {object} trackObj
 * @param {string} trackObj.type            - productImpression/productClick/productDetail
 * @param {Array}  trackObj.products        - an array of objects, each containing one product's info
 *                                              {   url     : product url,
 *                                                  brand   : dealer id,
 *                                                  price   : USD price     ... }
 * @param {object} [trackObj.actionField]   - an object containing additional information specific to each tracking type
 *                                          { list : list,
 *                                            step : 1      ... }
 */
export function trackEcommerce(trackObj: TrackingObject): void {
    let ecommerce: EcomType = {};
    let products: EcomProducts;

    if (!isEmpty(trackObj) && trackObj.type) {
        const gtmEventQueue = getGtmEventQueue();
        products = ecomGetProducts(trackObj.products);
        const { step_interaction_name } = trackObj;
        switch (trackObj.type) {
            case trackingConstants.ECOM_PRODUCT_IMPRESSION:
                if (Array.isArray(products) && products.length) {
                    ecommerce.impressions = products;
                } else {
                    ecommerce = undefined;
                }
                break;
            case trackingConstants.ECOM_PRODUCT_CLICK:
                if (trackObj.actionField && Array.isArray(products) && products.length) {
                    ecommerce.click = {
                        actionField: trackObj.actionField,
                        products,
                    };
                }
                break;
            case trackingConstants.ECOM_PRODUCT_DETAIL:
                if (Array.isArray(products) && products.length) {
                    ecommerce.detail = { products: products };
                } else {
                    ecommerce = undefined;
                }
                break;
            case trackingConstants.ECOM_PRODUCT_CART:
                if (Array.isArray(products) && products.length) {
                    ecommerce.add = { products: products };
                }
                break;
            case trackingConstants.ECOM_PRODUCT_CHECKOUT:
                if (
                    trackObj.actionField &&
                    trackObj.actionField.step &&
                    Array.isArray(products) &&
                    products.length
                ) {
                    ecommerce.checkout = {
                        actionField: { step: trackObj.actionField.step },
                        products: products,
                    };
                }
                break;
            case trackingConstants.ECOM_PRODUCT_PROMO_IMPRESSION:
                if (trackObj.promotions && trackObj.promotions.length) {
                    ecommerce.promoView = { promotions: trackObj.promotions };
                }
                break;
            case trackingConstants.ECOM_PRODUCT_PROMO_CLICK:
                if (trackObj.promotions && trackObj.promotions.length) {
                    ecommerce.promoClick = { promotions: trackObj.promotions };
                }
                break;
        }
        // FINDING-8606 allow ecommerce === undefined because when ecommerce
        // is undefined it removes the event from the data layer. Which stops it from
        // being tracked on every vpv
        if (!isEmpty(ecommerce) || ecommerce === undefined) {
            gtmEventQueue.push({
                event: ('ecommerce_' + trackObj.type) as EcomEventType,
                ecommerce,
                eventName: trackObj.eventName || undefined,
                trigger: trackObj.trigger || undefined,
                step_interaction_name,
            });
        }
    }
}
/**
 * Ecommerce tracking function (specifically for tracking transactions)
 * This need to be sent to Google before userAttributes, gtmPageInfo or pageview is fired.
 * @param {object} trackObj
 * @param {array} trackObj.products
 * @param {object} trackObj.actionField
 * @param {string} trackObj.eventName
 */
export function trackEcommercePurchase(trackObj: TrackingObject): void {
    const gtmEventQueue = getGtmEventQueue();
    const products = ecomGetProducts(trackObj.products);
    const ecommerce: EcomType = {};

    if (trackObj.actionField && Array.isArray(products) && products.length) {
        ecommerce.purchase = {
            actionField: trackObj.actionField,
            products: products,
        };
    }

    if (!isEmpty(ecommerce)) {
        gtmEventQueue.push({
            event: ('ecommerce_' + trackObj.type) as EcomEventType,
            eventName: trackObj.eventName || undefined,
            ecommerce,
        });
    }
}

import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { getMeasurementUnit } from 'dibs-intl/exports/units';
import { useIntl } from 'dibs-react-intl';

import { useItemDisplayDimensions_user$key } from './__generated__/useItemDisplayDimensions_user.graphql';
import { useItemDisplayDimensions_item$key } from './__generated__/useItemDisplayDimensions_item.graphql';

const userFragment = graphql`
    fragment useItemDisplayDimensions_user on User {
        preferences {
            measurementUnit
        }
    }
`;

const itemFragment = graphql`
    fragment useItemDisplayDimensions_item on Item {
        measurement {
            widthDimensions: display(addLabel: false, fields: [width]) {
                unit
                value
            }
            heightDimensions: display(addLabel: false, fields: [height]) {
                unit
                value
            }
        }
    }
`;

export const useItemDisplayDimensions = ({
    user: userRef,
    item: itemRef,
}: {
    user: useItemDisplayDimensions_user$key | null | undefined;
    item: useItemDisplayDimensions_item$key;
}): { displayWidth: string | null; displayHeight: string | null } => {
    const { locale } = useIntl();
    const user = useFragment(userFragment, userRef);
    const item = useFragment(itemFragment, itemRef);
    const { measurement } = item;

    const mainUnit = user?.preferences?.measurementUnit || getMeasurementUnit(locale);
    const displayWidth =
        measurement?.widthDimensions?.find(dim => dim?.unit === mainUnit)?.value || null;
    const displayHeight =
        measurement?.heightDimensions?.find(dim => dim?.unit === mainUnit)?.value || null;

    return useMemo(
        () => ({
            displayWidth,
            displayHeight,
        }),
        [displayWidth, displayHeight]
    );
};

import { connect } from 'react-redux';
import { bool, func, object, string } from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import classnames from 'classnames';

import LogoNav from 'dibs-logo/exports/LogoNav';
import Notifications from '../Notifications/Notifications';
import { SearchBar } from '../SearchBar/SearchBar';
import AccountNav from '../AccountNav/AccountNav';
import Likes from '../Likes/Likes';
import CartNav from '../CartNav/CartNav';
import DisplaySettingsModalLazy from '../DisplaySettingsModal/DisplaySettingsModalLazy';

import styles from './TopBar-style.scss';

const TopBarComponent = props => {
    const {
        hasRootPendingRefetch,
        hasUserId,
        hasUser,
        isClient,
        user,
        userId,
        viewer,
        onMouseOver,
        onMouseOut,
        hasError,
        isEmailOnly,
        isSticky,
        hasTradeUserId,
    } = props;

    const barClasses = classnames({
        [styles.bar]: true,
        [styles.stickyBar]: isSticky,
    });

    if (hasError) {
        return (
            <div className={barClasses}>
                <div className={`${styles.barContent} js-nav-utils-wrap`}>
                    <div className={styles.leftContainer}>
                        <LogoNav />
                    </div>
                </div>
            </div>
        );
    }

    return (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <div className={barClasses} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            <div className={`${styles.barContent} js-nav-utils-wrap`} data-tn="main-nav-top">
                <div className={styles.leftContainer}>
                    <SearchBar
                        hasClickableSearchButton
                        hasRootPendingRefetch={hasRootPendingRefetch}
                        isClient={isClient}
                        viewer={viewer}
                    />
                </div>
                <LogoNav isStickyLogo={isSticky} />
                <div className={styles.rightContainer}>
                    <AccountNav
                        isClient={isClient}
                        hasUser={hasUser}
                        hasUserId={hasUserId}
                        user={user}
                        hasTradeUserId={hasTradeUserId}
                        viewer={viewer}
                    />
                    <DisplaySettingsModalLazy
                        placement="header"
                        viewer={viewer}
                        user={user || null}
                    />
                    {!isEmailOnly && (
                        <Notifications
                            hasRootPendingRefetch={hasRootPendingRefetch}
                            hasUserId={hasUserId}
                            isClient={isClient}
                            userId={userId}
                            viewer={viewer}
                            initialViewer={viewer}
                            user={user}
                        />
                    )}

                    <Likes
                        hasRootPendingRefetch={hasRootPendingRefetch}
                        hasUserId={hasUserId}
                        fetchNewEvents={hasUserId && isClient && !hasRootPendingRefetch}
                        isClient={isClient}
                        userId={userId}
                        viewer={viewer}
                    />

                    <CartNav
                        hasRootPendingRefetch={hasRootPendingRefetch}
                        hasUserId={hasUserId}
                        isClient={isClient}
                        user={user}
                        userId={userId}
                        viewer={viewer}
                    />
                </div>
            </div>
        </div>
    );
};

TopBarComponent.propTypes = {
    hasError: bool,
    hasRootPendingRefetch: bool,
    hasUser: bool,
    hasUserId: bool,
    isClient: bool,
    openDrawer: func,
    user: object,
    userId: string,
    viewer: object,
    onMouseOver: func,
    onMouseOut: func,
    isEmailOnly: bool,
    isSticky: bool,
    hasTradeUserId: bool,
};

const mapStateToProps = ({ header }) => ({
    isEmailOnly: header.isEmailOnly,
});

export const TopBar = createFragmentContainer(connect(mapStateToProps)(TopBarComponent), {
    viewer: graphql`
        fragment TopBar_viewer on Viewer {
            ...CartNav_viewer
            ...Likes_viewer
            ...Notifications_viewer
            ...Notifications_initialViewer
            ...SearchBar_viewer
            ...AccountNav_viewer
            ...DisplaySettingsModal_viewer
        }
    `,
    user: graphql`
        fragment TopBar_user on User {
            ... @include(if: $isClient) {
                ...AccountNav_user
                ...CartNav_user
                ...Notifications_user
                ...DisplaySettingsModal_user
            }
        }
    `,
});

import { getEmailToken } from './getEmailToken';

const EMAIL_ONLY_ACCOUNT = 'email-only account' as const;
const FULL_ACCOUNT = 'full account' as const;
const NO_ACCOUNT = 'no account' as const;

type Args = {
    user?: $TSFixMe;
    type?: 'email' | 'password';
};
type AccountType = typeof EMAIL_ONLY_ACCOUNT | typeof FULL_ACCOUNT | typeof NO_ACCOUNT;

/*
 *   get current account type, track it on modal change (close) event
 */
export function getCurrentAccountType({ user, type }: Args): AccountType {
    const emailToken = getEmailToken();
    const { everLoggedIn, authMetadata, serviceId } = user || {};
    const { emailOnly, hasPassword } = authMetadata || {};
    const isLoggedIn = !!serviceId;

    switch (type) {
        case 'email':
            return EMAIL_ONLY_ACCOUNT;
        case 'password':
            return FULL_ACCOUNT;
        default:
            if ((isLoggedIn && hasPassword) || (!isLoggedIn && (everLoggedIn || emailToken))) {
                return FULL_ACCOUNT;
            } else if (isLoggedIn && emailOnly) {
                return EMAIL_ONLY_ACCOUNT;
            }
            return NO_ACCOUNT;
    }
}

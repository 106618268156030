import { MouseEvent } from 'react';

import {
    trackEvent,
    TrackingObject,
    eventNameConstants,
    stepInteractionConstants,
    interactionTypeConstants,
    EventNameValues,
} from 'dibs-tracking';

import {
    getTransactionsStatus,
    getCustomerType,
    CUSTOMER_TYPE_CONSUMER,
} from '../../utils/userTracking';
import { getCurrentAccountType } from '../../utils/userTrackingHelpers/getCurrentAccountType';
import { formatDate } from '../../helpers/trackingHelpers';
import {
    ACTION_COMPLETE,
    ACTION_SUBMIT,
    ACTION_ENTRY,
    ACTION_FORGOT_PASSWORD_SELECTED,
    ACTION_LINK_EMAIL_SELECTED,
    ACTION_LINK_EMAIL_SUBMITTED,
    ACTION_RESEND_LOGIN_LINK_SELECTED,
    ACTION_VERIFICATION_FAILED_MODAL_DISPLAYED,
} from './trackingHelpers/sharedAuthTracking';
import { loginTracking } from './trackingHelpers/loginTracking';

const LABEL_LOGIN_LINK = 'login link';
const LABEL_REGISTER_LINK = 'register link';
const LABEL_EMAIL_LOGIN_LINK = 'email login link';
const LABEL_MAKE_OFFER_MODAL = 'make offer modal';
export const LABEL_STALE_LOGIN_MODAL = 'stale login modal';

const TRADE_STATUS_MAP: Record<string, string> = {
    VERIFIED_VETTED: 'verified - vetted',
    VERIFIED_PRE_APPROVED: 'verified - pre-approved',
    SUSPECTED: 'suspected',
    APPLIED_IN_REVIEW: 'applied - in review',
    APPLIED_NOT_ENOUGH_INFORMATION: 'applied - not enough information',
    APPLIED_REJECTED: 'applied - rejected',
};

const { EVENT_LOGIN, EVENT_SIGN_UP } = eventNameConstants;
const {
    STEP_INTERACTION_ENTRY,
    STEP_INTERACTION_COMPLETE,
    STEP_INTERACTION_SOCIAL_BUTTON_CLICK,
    STEP_INTERACTION_PASSWORD_RESET_ENTRY,
    STEP_INTERACTION_PASSWORD_RESET_MODAL_ENTRY,
    STEP_INTERACTION_PASSWORD_RESET_MODAL,
    STEP_INTERACTION_EXISTING_SOCIAL_ACCOUNT_CONNECTED,
    STEP_INTERACTION_NEW_SOCIAL_ACCOUNT_CONNECTED,
    STEP_INTERACTION_REGISTRATION_FORM_INTERACTION,
} = stepInteractionConstants;
const {
    INTERACTION_TYPE_USER_LOGIN,
    INTERACTION_TYPE_USER_REGISTRATION,
    INTERACTION_TYPE_SOCIAL_CONNECTION,
} = interactionTypeConstants;

export type TrackingDataType = {
    action?: string;
    label?: string | number | null;
    value?: string | number;
    category?: string | null;
    ssoType?: string;
    field?: 'email' | 'password';
    triggerType?: string;
    error?: string | null;
    errorMessage?: string;
    additional?: TrackingObject['additional'];
    event?: $TSFixMe; // MouseEvent should not be part of tracking data. MouseEvent should be passed as a separate argument.
    eventName?: EventNameValues;
    userId?: string;
    emailToken?: string;
    user?: {
        profile?: {
            email?: string;
        };
        preferences?: {
            currency?: string;
        };
        createdDate?: string;
        verifiedTradeStatus?: string;
    };
    submittedTransactions?: number;
    confirmedTransactions?: number;
    submittedTransactionsRecent?: number;
    confirmedTransactionsRecent?: number;
    marketingOptIn?: boolean;
    isGdprApplicable?: boolean;
    accountType?: string;
    flow?: string;
};

const registrationTracking = (
    { action, label, value, additional = {} }: TrackingDataType,
    event?: MouseEvent
): void => {
    let currentAccountType;

    if (action === ACTION_COMPLETE) {
        currentAccountType = getCurrentAccountType({ type: 'email' });
        additional = {
            ...additional,
            currentAccountType,
        };
    }

    const ga4Fields: TrackingObject | null =
        action === STEP_INTERACTION_COMPLETE || action === STEP_INTERACTION_ENTRY
            ? {
                  eventName: EVENT_SIGN_UP,
                  step_interaction_name: action,
                  interaction_type: INTERACTION_TYPE_USER_REGISTRATION,
                  trigger: label || '',
                  step_type: currentAccountType,
              }
            : null;

    trackEvent(
        {
            action: `registration ${action}`,
            category: 'registration',
            label,
            value,
            isInteractiveEvent: false,
            additional,
            ...ga4Fields,
        },
        event || null
    );
};

const getTransactions = (data: TrackingDataType): TrackingDataType => {
    return {
        submittedTransactions: data.submittedTransactions,
        confirmedTransactions: data.confirmedTransactions,
        submittedTransactionsRecent: data.submittedTransactionsRecent,
        confirmedTransactionsRecent: data.confirmedTransactionsRecent,
    };
};

const getBuyerActivityStatusFromTransactions = (
    transactions: TrackingDataType
): string | undefined => {
    const {
        submittedTransactions,
        confirmedTransactions,
        submittedTransactionsRecent,
        confirmedTransactionsRecent,
    } = transactions;

    const hasTransactions = submittedTransactions || confirmedTransactions;
    const hasRecentTransactions = submittedTransactionsRecent || confirmedTransactionsRecent;

    if (hasTransactions && hasRecentTransactions) {
        return 'active';
    } else if (hasTransactions && !hasRecentTransactions) {
        return 'lapsed';
    } else {
        return undefined;
    }
};

const getAdditionalRegistrationData = (
    data: TrackingDataType
): Record<string, string | undefined> => {
    const { user } = data || {};
    const userEmail = user?.profile?.email;
    const transactions = getTransactions(data);
    const registrationDate = formatDate(user?.createdDate);

    return {
        loginStatus: 'Logged In',
        userID: data.userId,
        registrationStatus: 'Registered',
        userEmailAddress: userEmail,
        emailToken: data.emailToken,
        defaultCurrency: 'USD Default',
        buyerStatus: (
            getTransactionsStatus({ transactions, resetStorage: true }) as {
                buyerStatus: string;
            }
        ).buyerStatus,
        tradeStatus: undefined,
        customerType: CUSTOMER_TYPE_CONSUMER,
        registrationDate,
    };
};

const getAdditionalLoginData = (data: TrackingDataType): Record<string, string | undefined> => {
    const { user } = data || {};
    const { profile, preferences } = user || {};
    const userCurrency = preferences?.currency;
    const userEmail = profile?.email;
    const tradeStatus = user?.verifiedTradeStatus;
    const transactions = getTransactions(data);
    const customerType = getCustomerType(user || {});

    return {
        loginStatus: 'Logged In',
        userID: data.userId,
        registrationStatus: 'Registered',
        userEmailAddress: userEmail,
        defaultCurrency: `${userCurrency} Selected`,
        buyerStatus: (
            getTransactionsStatus({ transactions, resetStorage: true }) as {
                buyerStatus: string;
            }
        ).buyerStatus,
        buyerActivityStatus: getBuyerActivityStatusFromTransactions(transactions),
        tradeStatus: TRADE_STATUS_MAP[tradeStatus || ''],
        firstPurchasePrice: 'na',
        firstPurchaseDate: 'na',
        contactDealerStatus: undefined,
        customerType,
    };
};

export const trackRegistrationEntry = ({
    action = ACTION_ENTRY,
    label = LABEL_REGISTER_LINK,
    value,
}: TrackingDataType): void => {
    registrationTracking({ action, label, value });
};

export const trackMakeOfferRegistrationComplete = (): void => {
    trackEvent({
        action: 'registration complete',
        category: 'registration',
        label: LABEL_MAKE_OFFER_MODAL,
        isInteractiveEvent: true,
    });
};

const addMarketingOptInTrackingData = ({
    label,
    isGdprApplicable,
    marketingOptIn,
}: TrackingDataType): string | number =>
    isGdprApplicable ? `${label} | marketing opt-${marketingOptIn ? 'in' : 'out'}` : label || '';

export const trackRegistrationComplete = (trackData: TrackingDataType): void => {
    const {
        isGdprApplicable,
        marketingOptIn,
        action = ACTION_COMPLETE,
        label = LABEL_REGISTER_LINK,
        value,
        event,
        flow,
    } = trackData;
    const additionalData = getAdditionalRegistrationData(trackData);
    if (flow === 'MAKE_OFFER_FLOW') {
        trackMakeOfferRegistrationComplete();
    }
    registrationTracking(
        {
            action,
            label: addMarketingOptInTrackingData({ label, isGdprApplicable, marketingOptIn }),
            value,
            additional: additionalData,
        },
        event
    );
};

export const trackLoginEntry = ({
    action = ACTION_ENTRY,
    label = LABEL_LOGIN_LINK,
    value,
}: TrackingDataType): void => {
    loginTracking({ action, label, value });
};

export const trackContactLoginComplete = (trackData: TrackingDataType): void => {
    const {
        action = ACTION_COMPLETE,
        label = LABEL_LOGIN_LINK,
        value,
        isGdprApplicable,
        marketingOptIn,
    } = trackData;
    const additionalData = getAdditionalLoginData(trackData);
    loginTracking({
        action,
        label: addMarketingOptInTrackingData({ label, isGdprApplicable, marketingOptIn }),
        value,
        additional: additionalData,
    });
};

export const trackInputComplete = ({
    field,
    triggerType,
    value,
    event,
}: TrackingDataType): void => {
    const trigger = `registration step complete (${field})`;
    const currentAccountType = getCurrentAccountType({ type: field });
    trackEvent(
        {
            action: 'registration form interaction',
            category: 'registration',
            label: trigger,
            value,
            eventName: EVENT_SIGN_UP,
            step_interaction_name: STEP_INTERACTION_REGISTRATION_FORM_INTERACTION,
            interaction_type: INTERACTION_TYPE_USER_REGISTRATION,
            trigger,
            step_type: currentAccountType,
            additional: {
                ...(triggerType ? { triggerType } : {}),
                currentAccountType,
            },
        },
        event || null
    );
};

export const trackLoginError = (): void => {
    trackEvent({
        action: 'login|login modal|password',
        category: 'form error',
        label: 'incorrect password',
    });
};

export const trackRegistrationError = ({
    field,
    error,
    action = 'register form',
}: TrackingDataType): void => {
    trackEvent({
        action: `registration|${action}|${field}`,
        category: 'form error',
        label: error,
    });
};

export const trackSocialLoginButtonClick = ({
    category,
    label,
    ssoType,
    event,
    eventName,
}: TrackingDataType): void => {
    ssoType = (ssoType || '').toLowerCase();
    trackEvent(
        {
            action: 'continue with button clicked',
            category,
            label,
            eventName,
            step_interaction_name: STEP_INTERACTION_SOCIAL_BUTTON_CLICK,
            interaction_type: INTERACTION_TYPE_SOCIAL_CONNECTION,
            trigger: label || '',
            step_type: ssoType,
            additional: { ssoType },
        },
        event || null
    );
};

export const trackSocialLoginEntry = ({ label, ssoType }: TrackingDataType): void => {
    trackEvent({
        category: 'social integrations',
        action: 'connect account entry',
        label,
        eventName: EVENT_SIGN_UP,
        step_interaction_name: STEP_INTERACTION_ENTRY,
        interaction_type: INTERACTION_TYPE_SOCIAL_CONNECTION,
        trigger: label || '',
        step_type: ssoType,
        additional: { ssoType },
    });
};

export const trackSocialAuthComplete = ({
    category,
    label,
    ssoType,
    eventName,
    ...trackData
}: TrackingDataType): void => {
    trackEvent({
        category,
        action: `${category} ${ACTION_COMPLETE}`,
        label,
        eventName,
        step_interaction_name: STEP_INTERACTION_COMPLETE,
        interaction_type: INTERACTION_TYPE_SOCIAL_CONNECTION,
        trigger: label || '',
        step_type: ssoType,
        additional: {
            ...getAdditionalLoginData(trackData),
            ssoType,
        },
    });
};

export const trackSocialAccountLinked = ({
    label,
    ssoType,
    ...trackData
}: TrackingDataType): void => {
    ssoType = (ssoType || '').toLowerCase();
    trackEvent({
        category: 'social integrations',
        action: 'existing account connected',
        label,
        eventName: EVENT_LOGIN,
        step_interaction_name: STEP_INTERACTION_EXISTING_SOCIAL_ACCOUNT_CONNECTED,
        interaction_type: INTERACTION_TYPE_SOCIAL_CONNECTION,
        trigger: label || '',
        step_type: ssoType,
        additional: {
            ...getAdditionalLoginData(trackData),
            ssoType,
        },
    });
};

export const trackSocialAccountCreated = ({
    label,
    ssoType,
    ...trackData
}: TrackingDataType): void => {
    ssoType = (ssoType || '').toLowerCase();
    trackEvent({
        category: 'social integrations',
        action: 'new account connected',
        label,
        eventName: EVENT_SIGN_UP,
        step_interaction_name: STEP_INTERACTION_NEW_SOCIAL_ACCOUNT_CONNECTED,
        interaction_type: INTERACTION_TYPE_SOCIAL_CONNECTION,
        trigger: label || '',
        step_type: ssoType,
        additional: {
            ...getAdditionalRegistrationData(trackData),
            ssoType,
        },
    });
};

export const trackSocialEmailOptOut = ({ label, value, errorMessage }: TrackingDataType): void => {
    trackEvent({
        action: 'registration|register form|email',
        category: 'form error',
        label: errorMessage,
        value,
    });

    trackEvent({
        action: 'login entry',
        category: 'login',
        label,
        value,
        additional: { ssoType: 'facebook' },
        eventName: EVENT_LOGIN,
        step_interaction_name: STEP_INTERACTION_ENTRY,
        interaction_type: INTERACTION_TYPE_SOCIAL_CONNECTION,
        trigger: label || '',
        step_type: 'facebook',
    });
};

export const trackSocialLoginError = ({ ssoType, errorMessage }: TrackingDataType): void => {
    trackEvent({
        category: 'form error',
        action: 'sign-on error triggered',
        label: errorMessage,
        additional: { ssoType },
    });
};

export const trackForgotYourPassword = (label: string): void => {
    trackEvent({
        action: ACTION_FORGOT_PASSWORD_SELECTED,
        category: 'login',
        label,
        eventName: EVENT_LOGIN,
        interaction_type: INTERACTION_TYPE_USER_LOGIN,
        step_interaction_name: STEP_INTERACTION_PASSWORD_RESET_ENTRY,
        trigger: 'logged out - forgot password click',
    });
};

const trackLoginLinkAction = ({ action, label }: TrackingDataType): void => {
    trackEvent({
        action,
        category: 'login',
        label,
        eventName: EVENT_LOGIN,
        interaction_type: INTERACTION_TYPE_USER_LOGIN,
        step_interaction_name: action,
        trigger: 'logged out - send login link click',
    });
};

export const trackLoginLinkEmailSelected = (label: string): void => {
    trackLoginLinkAction({ action: ACTION_LINK_EMAIL_SELECTED, label });
};

export const trackLoginLinkEmailSubmitted = (label: string): void => {
    trackLoginLinkAction({ action: ACTION_LINK_EMAIL_SUBMITTED, label });
};

export const trackResendLoginLinkSelected = (label: string): void => {
    trackLoginLinkAction({ action: ACTION_RESEND_LOGIN_LINK_SELECTED, label });
};

export const trackEmailLoginLinkComplete = (): void => {
    loginTracking({ action: ACTION_COMPLETE, label: LABEL_EMAIL_LOGIN_LINK });
};

const resetPasswordLoginDefaultTrackingObj = {
    category: 'login',
    eventName: EVENT_LOGIN,
    interaction_type: INTERACTION_TYPE_USER_LOGIN,
};

export const trackResetPasswordLoginEntry = ({ label, value }: TrackingObject): void => {
    trackEvent({
        ...resetPasswordLoginDefaultTrackingObj,
        action: ACTION_ENTRY,
        label,
        value,
        step_interaction_name: STEP_INTERACTION_PASSWORD_RESET_MODAL_ENTRY,
        trigger: 'reset password entry',
    });
};

export const trackResetPasswordLoginSubmit = ({ label, value }: TrackingObject): void => {
    trackEvent({
        ...resetPasswordLoginDefaultTrackingObj,
        action: ACTION_SUBMIT,
        label,
        value,
        step_interaction_name: STEP_INTERACTION_PASSWORD_RESET_MODAL,
        trigger: 'reset password submit',
    });
};

export const trackResetPasswordLoginComplete = ({ label, value }: TrackingObject): void => {
    trackEvent({
        ...resetPasswordLoginDefaultTrackingObj,
        action: ACTION_COMPLETE,
        label,
        value,
        additional: {
            currentAccountType: getCurrentAccountType({ type: 'password' }),
        },
        step_interaction_name: STEP_INTERACTION_PASSWORD_RESET_MODAL,
        trigger: 'reset password complete',
    });
};

export const trackVerificationFailedModalDisplayed = (): void => {
    trackEvent({
        category: 'login',
        action: ACTION_VERIFICATION_FAILED_MODAL_DISPLAYED,
    });
};

const trackEmailOnlyCheckoutAction = ({
    action,
    label,
    step_interaction_name,
}: {
    action: string;
    label: string | number;
    step_interaction_name?: string;
}): void => {
    trackEvent({
        category: 'login',
        action,
        label,
        eventName: EVENT_LOGIN,
        step_interaction_name: step_interaction_name || action,
        interaction_type: INTERACTION_TYPE_USER_LOGIN,
        trigger: label,
        isInteractiveEvent: true,
    });
};

export const trackEmailOnlyCheckoutAuthButtonClick = ({
    accountType,
    isGdprApplicable,
    marketingOptIn,
}: TrackingDataType): void => {
    trackEmailOnlyCheckoutAction({
        action: 'continue button click',
        label: addMarketingOptInTrackingData({
            label: `guest checkout - ${accountType}`,
            isGdprApplicable,
            marketingOptIn,
        }),
    });
};

export const trackEmailOnlyCheckoutResendLinkClick = (): void => {
    trackEmailOnlyCheckoutAction({
        action: 'resend login link selected',
        label: 'guest checkout - email only account',
    });
};

export const trackEmailOnlyCheckoutAuthError = (label: string): void => {
    trackEmailOnlyCheckoutAction({
        action: 'guest login error',
        label,
    });
};

export const trackEmailOnlyCheckoutEmailLoginComplete = (): void => {
    trackEmailOnlyCheckoutAction({
        action: 'login complete',
        label: 'guest checkout - email only account',
        step_interaction_name: STEP_INTERACTION_COMPLETE,
    });
};

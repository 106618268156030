import { FC, Suspense } from 'react';
import { Spinner } from 'dibs-elements/exports/Spinner';

import { useRecentlyViewedItemsContext } from './RecentlyViewedItemsContext';
import {
    RecentlyViewedItemsCarousel,
    RecentlyViewedItemsCarouselProps,
} from './RecentlyViewedItemsCarousel';
import { RecentlyViewedItemsWrapper } from './RecentlyViewedItemsWrapper';

import { ACTION_LABEL } from './constants';

import styles from './RecentlyViewedItemsLazy.scss';

type Props = Omit<RecentlyViewedItemsCarouselProps, 'queryReference'>;

export const RecentlyViewedItemsLazy: FC<Props> = props => {
    const {
        actionLabel = ACTION_LABEL,
        wrapperClassName,
        trackViewMoreClick,
        isMobile,
        centerTitle,
        size = 'xSmall',
        isBot,
    } = props;

    const { queryReference } = useRecentlyViewedItemsContext();
    //Don't show recently viewer items module for bots
    if (isBot) {
        return null;
    }

    const loading = (
        <RecentlyViewedItemsWrapper
            wrapperClassName={wrapperClassName}
            isMobile={isMobile}
            centerTitle={centerTitle}
            actionLabel={actionLabel}
            trackViewMoreClick={trackViewMoreClick}
        >
            <div className={`${styles.loading} ${styles[size]}`}>
                <Spinner />
            </div>
        </RecentlyViewedItemsWrapper>
    );

    if (!queryReference) {
        /**
         * For this query reference we're calling loadQuery only on the client-side because of this
         * queryReference will be null on the server-side. Render loading component on the server-side.
         */
        return loading;
    }

    return (
        <>
            <Suspense fallback={loading}>
                <RecentlyViewedItemsCarousel {...props} queryReference={queryReference} />
            </Suspense>
        </>
    );
};

import { FormattedMessage } from 'dibs-react-intl';
import { ConnectRow } from '../ConnectRow/ConnectRow';
import socialLinks from '../messages/socialLinks';
import { FooterLink } from '../FooterLinkTypes';
import FooterSubscribeLazy from '../FooterSubscribe/FooterSubscribeLazy';

import styles from '../ConnectRow/ConnectRow.scss';

export const blog: FooterLink = {
    linkName: 'blog',
    trackingLabel: 'Blog',
    href: '/blogs/the-study/',
    dataTn: 'footer-blog',
    enablerKey: 'wpEditorialLink',
    message: (
        <span className={styles.subheader}>
            <FormattedMessage id="dbl.Footer.site_links_blog" defaultMessage="The Study" />
        </span>
    ),
};

export const magazine: FooterLink = {
    linkName: 'magazine',
    trackingLabel: 'Magazine',
    href: '/introspective-magazine/',
    dataTn: 'footer-magazine',
    enablerKey: 'wpEditorialLink',
    message: (
        <FormattedMessage
            id="dbl.Footer.site_links_magazine"
            defaultMessage="<span>Introspective</span> Magazine"
            values={{ span: content => <span className={styles.subheader}>{content}</span> }}
        />
    ),
};

export const connectLinks: FooterLink[] = [
    {
        linkName: 'subscribe',
        CustomComponent: FooterSubscribeLazy,
        dataTn: 'footer-subscribe',
    },
    {
        linkName: 'connect',
        CustomComponent: ConnectRow,
        items: socialLinks,
        dataTn: 'connect-row',
    },
];

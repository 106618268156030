import { graphql, readInlineData } from 'react-relay/legacy';
import { VERTICALS } from 'dibs-constants/exports/uriConstants';

import {
    shippingCostHelpers_item$data,
    shippingCostHelpers_item$key,
    VerticalType,
} from './__generated__/shippingCostHelpers_item.graphql';

export const REST_OF_WORLD_REGION = 'Rest of the World';
export const FREE_SHIPPING_PRICE = 0;

function getItemData(
    itemReference: shippingCostHelpers_item$key | null | undefined
): shippingCostHelpers_item$data | null | undefined {
    return readInlineData(
        graphql`
            fragment shippingCostHelpers_item on Item @inline {
                vertical
                shipmentQuotes {
                    isComplimentary
                    isCalculated
                    totalAmount {
                        convertedAmounts {
                            USD
                        }
                    }
                    serviceMethod {
                        region {
                            regionName
                        }
                    }
                }
            }
        `,
        itemReference
    );
}

type ShipmentQuotesType = NonNullable<shippingCostHelpers_item$data['shipmentQuotes']>;
const getShipmentQuotes = (
    itemData: shippingCostHelpers_item$data | null | undefined
): ShipmentQuotesType =>
    // filter out CALCULATED quotes because their cost is misleadingly free (BE are calculating the real cost for these quotes later)
    itemData?.shipmentQuotes?.filter(quote => !quote?.isCalculated) || [];

function isEligibleRestOfWorld(regionName: string): boolean {
    return regionName === REST_OF_WORLD_REGION;
}

export type UserRegions = ReadonlyArray<{
    readonly displayName: string | null;
} | null> | null;

type RegionShipping = {
    readonly userRegions: UserRegions;
    regionName: string;
    vertical: VerticalType | null;
};

function isEligibleUserRegion({ userRegions, regionName, vertical }: RegionShipping): boolean {
    // FINDING-14549: Rest of world (ROW) shipping quotes are not considered in S/B free shipping, since items may
    // have free shipping to ROW but not to user specific region. However, few items in jewelry fall in to that
    // situation and due to big marketing pushes around jewelry free shipping, we are temporarily considering ROW quotes
    // for jewelry vertical only on the FE until we revisit how free shipping is determined.
    if (vertical === VERTICALS.JEWELRY && regionName === REST_OF_WORLD_REGION) {
        return true;
    } else {
        const userRegionNames = (userRegions || [])
            .map(region => region?.displayName)
            .filter(Boolean);
        return userRegionNames.includes(regionName);
    }
}

type Props = {
    item: shippingCostHelpers_item$key | null | undefined;
    readonly userRegions: UserRegions;
};

// userRegions will only populate for non-private IPs. If you are in office or vpn, manually set zipCode in sessionStorage userGeoInfo
export const getIsFreeShipping = ({ item, userRegions }: Props): boolean => {
    const itemData = getItemData(item);
    const shipmentQuotes = getShipmentQuotes(itemData);
    const vertical = itemData?.vertical || null;

    const userRegion = shipmentQuotes.filter(shipmentQuote => {
        const regionName = shipmentQuote?.serviceMethod?.region?.regionName || '';
        return isEligibleUserRegion({
            userRegions,
            regionName,
            vertical,
        });
    });

    if (userRegion.length > 0) {
        return userRegion.some(
            shipmentQuote =>
                shipmentQuote?.isComplimentary ||
                shipmentQuote?.totalAmount?.convertedAmounts?.USD === FREE_SHIPPING_PRICE
        );
    } else {
        return shipmentQuotes.some(shipmentQuote => {
            const hasFreeShipping =
                shipmentQuote?.isComplimentary ||
                shipmentQuote?.totalAmount?.convertedAmounts?.USD === FREE_SHIPPING_PRICE;
            const regionName = shipmentQuote?.serviceMethod?.region?.regionName || '';
            return hasFreeShipping && isEligibleRestOfWorld(regionName);
        });
    }
};

export const getLowestEligibleShippingCost = ({ item, userRegions }: Props): number | undefined => {
    const itemData = getItemData(item);
    const shipmentQuotes = getShipmentQuotes(itemData);
    const vertical = itemData?.vertical || null;

    const eligibleQuotesInUSD = shipmentQuotes
        .map(shipmentQuote => {
            const regionName = shipmentQuote?.serviceMethod?.region?.regionName || '';

            const isEligible =
                isEligibleUserRegion({ userRegions, regionName, vertical }) ||
                isEligibleRestOfWorld(regionName);
            return isEligible ? shipmentQuote?.totalAmount?.convertedAmounts?.USD : undefined;
        })
        .filter(value => typeof value === 'number') as number[];

    if (!eligibleQuotesInUSD.length) {
        // lowest eligible quote not found
        return undefined;
    }

    // return lowest eligible quote
    return Math.min(...eligibleQuotesInUSD);
};

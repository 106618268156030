import { lazy, Suspense, FC, ComponentProps } from 'react';
import { useClientState } from 'dibs-react-hooks/exports/useClientState';

const DisplaySettingsModalReactLazy = lazy(
    () => import(/* webpackChunkName: "DisplaySettingsModal" */ './DisplaySettingsModal')
);

const DisplaySettingsModalLazy: FC<
    ComponentProps<typeof DisplaySettingsModalReactLazy>
> = props => {
    const isClient = useClientState();
    if (!isClient) {
        return null;
    }
    return (
        <Suspense fallback={null}>
            <DisplaySettingsModalReactLazy {...props} />
        </Suspense>
    );
};

export default DisplaySettingsModalLazy;

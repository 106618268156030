import { MetricWithAttribution } from 'web-vitals';
import serverVars from 'server-vars';
import { LOCALE_US } from 'dibs-intl/exports/locales';
import { LABEL_VALUE_UNKNOWN, isValidMonitoringGroup } from 'dibs-monitoring/exports/constants';
import { sendAnalyticsData } from './perfAnalyticsHelpers';

const queryPath = 'soa/graphql/persisted/analytics/v1/trackCoreWebVitalsMutation';
const validWebVitals = ['TTFB', 'LCP', 'INP', 'CLS'];

const MOBILE = 'mobile';
const DESKTOP = 'desktop';

let trackWebVitalsEnabled: boolean | null = null;

export const promTrackWebVitalsMetric = (metric: MetricWithAttribution): void => {
    if (trackWebVitalsEnabled === null) {
        trackWebVitalsEnabled = serverVars.get('enablers.trackPromWebVitals');
    }
    const monitoringGroupName = serverVars.get('monitoringGroupName');
    const monitoringGroup = isValidMonitoringGroup(monitoringGroupName)
        ? monitoringGroupName
        : LABEL_VALUE_UNKNOWN;
    const locale = serverVars.get('locale') || LOCALE_US;
    const deviceType = serverVars.get('settings.deviceType') === MOBILE ? MOBILE : DESKTOP;
    const geoContinent = serverVars.get('geo.continent') || LABEL_VALUE_UNKNOWN;

    if (
        trackWebVitalsEnabled &&
        typeof location !== 'undefined' &&
        validWebVitals.includes(metric.name)
    ) {
        sendAnalyticsData(`${location.protocol}//${location.host}/${queryPath}`, {
            variables: {
                input: {
                    name: metric.name,
                    value: metric.value,
                    delta: metric.delta,
                    rating: metric.rating,
                    navigationType: metric.navigationType,
                    monitoringGroup,
                    locale,
                    deviceType,
                    geoContinent,
                    ssrOnlyBot: false, // if this script is running, it means it's not SSR-only
                },
            },
        });
    }
};

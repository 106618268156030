import { actionTypes } from '../actions/constants';

type HeaderState = {
    isVipCuratedFolderTooltipVisible: boolean;
    isActiveLiveChatVisible: boolean;
    initialTrackingFired: boolean;
    updateUserState?: boolean;
    isUserTypeTrade?: boolean;
    isUserTypeVip?: boolean;
    deviceType?: string;
    isMobileMenuOpen?: boolean;
    isEmailOnly?: boolean;
};

type HeaderActionType =
    | {
          type: typeof actionTypes.SET_UPDATE_USER_STATE;
          updateUserState: boolean;
      }
    | { type: typeof actionTypes.INITIAL_TRACKING_FIRED }
    | {
          type: typeof actionTypes.RESET_TRACKING_FIRED;
      }
    | {
          type: typeof actionTypes.SET_USER_TYPE_TRADE;
          isUserTypeTrade: boolean;
      }
    | {
          type: typeof actionTypes.SET_USER_TYPE_VIP;
          isUserTypeVip: boolean;
      }
    | {
          type: typeof actionTypes.SET_DEVICE_TYPE;
          deviceType: string;
      }
    | {
          type: typeof actionTypes.TOGGLE_MOBILE_MENU;
          isMobileMenuOpen: boolean;
      }
    | { type: typeof actionTypes.SET_IS_EMAIL_ONLY; isEmailOnly: boolean }
    | {
          type: typeof actionTypes.TOGGLE_VIP_CURATED_FOLDER_TOOLTIP_STATE;
      }
    | {
          type: typeof actionTypes.SET_ACTIVE_LIVE_CHAT_VISIBILITY;
          isActiveLiveChatVisible: boolean;
      };

export default function headerReducer(
    state = {
        isVipCuratedFolderTooltipVisible: false,
        isActiveLiveChatVisible: false,
        initialTrackingFired: false,
    },
    action: HeaderActionType
): HeaderState {
    switch (action.type) {
        case actionTypes.SET_UPDATE_USER_STATE:
            return {
                ...state,
                updateUserState: action.updateUserState,
                initialTrackingFired: false,
            };
        case actionTypes.INITIAL_TRACKING_FIRED:
            return {
                ...state,
                initialTrackingFired: true,
            };
        case actionTypes.RESET_TRACKING_FIRED:
            return {
                ...state,
                initialTrackingFired: false,
            };
        case actionTypes.SET_USER_TYPE_TRADE:
            return {
                ...state,
                isUserTypeTrade: action.isUserTypeTrade,
            };
        case actionTypes.SET_USER_TYPE_VIP:
            return {
                ...state,
                isUserTypeVip: action.isUserTypeVip,
            };
        case actionTypes.SET_DEVICE_TYPE:
            return {
                ...state,
                deviceType: action.deviceType,
            };
        case actionTypes.TOGGLE_MOBILE_MENU:
            return {
                ...state,
                isMobileMenuOpen: action.isMobileMenuOpen,
            };
        case actionTypes.SET_IS_EMAIL_ONLY:
            return {
                ...state,
                isEmailOnly: action.isEmailOnly,
            };
        case actionTypes.TOGGLE_VIP_CURATED_FOLDER_TOOLTIP_STATE:
            return {
                ...state,
                isVipCuratedFolderTooltipVisible: !state.isVipCuratedFolderTooltipVisible,
            };
        case actionTypes.SET_ACTIVE_LIVE_CHAT_VISIBILITY:
            return {
                ...state,
                isActiveLiveChatVisible: action.isActiveLiveChatVisible,
            };
        default:
            return state;
    }
}

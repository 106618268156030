/**
 * @generated SignedSource<<3a96fa7fe2c3879513b5eb0d5f0b48f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedItemTilesCarouselModule_items$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedItemTilesCarousel_items">;
  readonly " $fragmentType": "HpSharedItemTilesCarouselModule_items";
}>;
export type HpSharedItemTilesCarouselModule_items$key = ReadonlyArray<{
  readonly " $data"?: HpSharedItemTilesCarouselModule_items$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedItemTilesCarouselModule_items">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fetchFavorites"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isTrade"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceBookName"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "HpSharedItemTilesCarouselModule_items",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "fetchFavorites",
          "variableName": "fetchFavorites"
        },
        {
          "kind": "Variable",
          "name": "isTrade",
          "variableName": "isTrade"
        },
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        },
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "HpSharedItemTilesCarousel_items"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "dff60addb7fe45a74e76e176439c1b52";

export default node;

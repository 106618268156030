import { FunctionComponent } from 'react';
import * as React from 'react';
import { createOperationDescriptor, getRequest } from 'relay-runtime';
import { ReactRelayContext, GraphQLTaggedNode, Variables } from 'react-relay/legacy';
import { Environment } from 'relay-runtime';

type PrepareRelayContextProviderProps = {
    environment: Environment;
    query: GraphQLTaggedNode;
    variables: Variables;
    render: <T>(renderProps: { props: T }) => React.ReactNode;
};

export const PrepareRelayContextProvider: FunctionComponent<
    PrepareRelayContextProviderProps
> = props => {
    const { environment, query, variables, render } = props;
    const operation = createOperationDescriptor(getRequest(query), variables);
    environment.retain(operation);
    const snapshot = environment.lookup(operation.fragment);
    const { data } = snapshot;

    return (
        <ReactRelayContext.Provider
            value={{
                environment: environment,
            }}
        >
            {render({ props: data })}
        </ReactRelayContext.Provider>
    );
};

import { useEffect, useState } from 'react';
import { useFragment, graphql } from 'react-relay';

import { getAbTestV2 } from 'dibs-buyer-layout/exports/clientABTestV2';
import { trackAbTestV2Variant } from 'dibs-buyer-layout/exports/clientABTestV2';

import { abTestOffersOnHp_buyerActivePrivateOffers$key } from './__generated__/abTestOffersOnHp_buyerActivePrivateOffers.graphql';

const AB_TEST = 'OffersonHP';

type OffersOnHpVariant = 'variant' | 'control' | null;
export const useOffersOnHpVariant = (): OffersOnHpVariant => {
    const [variant, setVariant] = useState<OffersOnHpVariant>(null);

    useEffect(() => {
        setVariant(getAbTestV2(AB_TEST)?.variant as OffersOnHpVariant);
    }, []);

    return variant;
};

const buyerActivePrivateOffersFragment = graphql`
    fragment abTestOffersOnHp_buyerActivePrivateOffers on BuyerActivePrivateOffersConnection {
        totalResults
    }
`;

export const useTrackOffersOnHp = (
    buyerActivePrivateOffersRef?: abTestOffersOnHp_buyerActivePrivateOffers$key | null
): void => {
    const buyerActivePrivateOffers = useFragment(
        buyerActivePrivateOffersFragment,
        buyerActivePrivateOffersRef
    );

    useEffect(() => {
        /**
         * We want to trigger this action only if there are active private offers (APO).
         * Since APO is specific to the user, it also checks if the user is logged in.
         */
        if (buyerActivePrivateOffers?.totalResults) {
            trackAbTestV2Variant(AB_TEST);
        }
    }, [buyerActivePrivateOffers]);
};

const SRCSET_SIZES: Array<number> = [200, 320, 400, 640, 800, 960];
const SRCSET_HEIGHT_SIZES: Array<number> = [60, 120];

export const RESP_IMAGE_DEFAULT_SIZE: number = 640;
export const MOBILE_IMAGE_DEFAULT_SIZE: number = 400;

type SrcStringArguments = {
    url: string;
    width?: number;
    height?: number;
    quality?: number;
};

export const getSrcString = ({ url, width, height, quality = 60 }: SrcStringArguments): string => {
    const [originalUrl, searchString] = (url || '').split('?');
    const queryParams = new URLSearchParams(searchString);
    queryParams.set('disable', 'upscale');
    queryParams.set('auto', 'webp');
    queryParams.set('quality', quality.toString());

    if (width) {
        queryParams.set('width', width.toString());
    }

    if (height) {
        queryParams.set('height', height.toString());
    }

    return `${originalUrl}?${queryParams.toString()}`;
};

type SrcSetStringArguments = {
    url: string | undefined;
    widths?: Array<number>;
    quality?: number;
};

export const getSrcSetString = ({ url, widths, quality }: SrcSetStringArguments): string => {
    return (widths || SRCSET_SIZES)
        .map(width => `${getSrcString({ url: url || '', width, quality })} ${width}w`)
        .join(', ');
};

type SrcSetStringForHeightsArguments = {
    url: string | undefined;
    heights?: Array<number>;
    quality?: number;
};

export const getSrcSetStringForHeights = ({
    url,
    heights,
    quality,
}: SrcSetStringForHeightsArguments): string => {
    return (heights || SRCSET_HEIGHT_SIZES)
        .map(height => `${getSrcString({ url: url || '', height, quality })} ${height}w`)
        .join(', ');
};

import { createRoot } from 'react-dom/client';

import TrackRageClicks from '../components/TrackRageClicks/TrackRageClicks';

function initRageClickTracking(): void {
    const rageClickContainer = document.createElement('div');
    document.body.appendChild(rageClickContainer);
    const root = createRoot(rageClickContainer);

    root.render(<TrackRageClicks />);
}

export default initRageClickTracking;

import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FC } from 'react';

type ShoppingBagProps = {
    className?: string;
};

const ShoppingBag: FC<ShoppingBagProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'shopping-bag'}
            viewBox="0 0 225 208.5"
        >
            <path d="M199.29 208.5H18.92L0 51.25h225zm-173.9-8h167.72l23.1-141.25H8.39z" />
            <path d="M162 59H62.32l1.3-5.06a120 120 0 0110.95-26.52C84.77 9.5 97.77 0 112.16 0s27.39 9.48 37.6 27.42a120.3 120.3 0 0110.94 26.52zm-89.6-8h79.52c-4.16-12.35-16.82-43-39.76-43S76.57 38.65 72.4 51z" />
        </svg>
    );
};

export default ShoppingBag;

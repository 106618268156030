/**
 * @generated SignedSource<<055989083b2e58049e12552b89527ac7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LazyThumbVideo_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ThumbVideo_viewer">;
  readonly " $fragmentType": "LazyThumbVideo_viewer";
};
export type LazyThumbVideo_viewer$key = {
  readonly " $data"?: LazyThumbVideo_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"LazyThumbVideo_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LazyThumbVideo_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ThumbVideo_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "84c07872b387b33f86b072d07959833a";

export default node;

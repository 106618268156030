import { SELLER_CHAT_ID } from '../constants';
import styles from '../styles/PreChat.scss';

/**
 * When live chat pre-chat button is not rendered on PDP, seller chat (dibs-buyer-chat)
 * will be rendered. In such case when live chat is opened via get help modal both chat
 * button positions conflicts. Adds class to seller chat button to adjust the position.
 */
export const adjustPdpSellerChatPosition = ({ reset = false }: { reset?: boolean }): void => {
    const sellerChat = document.getElementById(SELLER_CHAT_ID);

    if (sellerChat) {
        if (reset) {
            sellerChat.classList.remove(styles.withLiveChat);
        } else {
            sellerChat.classList.add(styles.withLiveChat);
        }
    }
};

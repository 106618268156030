import { createContext, FC, ReactNode } from 'react';

type HeaderFooterServerVarsContext = {
    buyerHost: string;
    isMobile: boolean;
    isTablet?: boolean;
};

export const HeaderFooterServerVarsContext = createContext<HeaderFooterServerVarsContext>({
    isMobile: false,
    buyerHost: '',
});

type Props = {
    children: ReactNode;
    isMobile: boolean;
    isTablet?: boolean;
    buyerHost: string;
};

export const HeaderFooterServerVarsContextProvider: FC<Props> = ({
    children,
    buyerHost,
    isMobile,
    isTablet,
}) => (
    <HeaderFooterServerVarsContext.Provider value={{ buyerHost, isMobile, isTablet }}>
        {children}
    </HeaderFooterServerVarsContext.Provider>
);

import { FC, MouseEvent, useContext } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import classnames from 'classnames';

// Components
import { QuickViewButton } from '../QuickViewButton';
import { LazyImage } from '../LazyImage/LazyImage';
import { SeoLink } from 'dibs-elements/exports/SeoLink';

// Helpers
import { getSrcSetString } from '../helpers/srcSet';
import { TileContext } from '../helpers/TileContext';

import { Product_item$data as ProductItemProp } from './__generated__/Product_item.graphql';

// Styles
import styles from './styles.scss';
import dibsCss from 'dibs-css';

export type ProductProps = {
    disableLinks?: boolean;
    pdpUrl: string;
    item: ProductItemProp;
    itemsPerRow: number;
    index?: number;
    title?: string | null;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    onContentLoaded?: () => void;
    showQuickView?: boolean;
    openQuickView?: () => void;
    showLightBox?: boolean;
    dimensions?: string;
    showQuickViewIcon?: boolean;
    useLoFiLazyLoader: boolean;
    imageLoadVerticalOffset?: number;
    srcSetSizes?: string;
    srcSetWidths?: number[];
    srcSetQuality?: number;
    hideDivider?: boolean;
    hideProductDetails?: boolean;
    hideImagePadding?: boolean;
    hideImageSidePadding?: boolean;
    imageDraggable?: boolean; // undefined draggable will result in default browser behavior
    centerImage?: boolean;
    isMobile?: boolean;
};

export const ProductComponent: FC<ProductProps> = ({
    disableLinks = false,
    pdpUrl,
    index,
    item,
    itemsPerRow = 3,
    title,
    onClick,
    onContentLoaded,
    showQuickView,
    openQuickView,
    showLightBox,
    dimensions,
    useLoFiLazyLoader = false,
    showQuickViewIcon,
    imageLoadVerticalOffset,
    srcSetSizes,
    srcSetWidths,
    srcSetQuality,
    hideDivider,
    hideProductDetails,
    hideImagePadding,
    hideImageSidePadding,
    imageDraggable,
    centerImage = true,
    isMobile,
}) => {
    title = title || '';
    const { imageSize } = useContext(TileContext);
    // FINDING-10742: DON'T USE 'firstPhotoWebPath' - product tile is used on
    // trade profiles and dealer storefront
    // By default the smallPath should be used
    const photo = item?.productPhotos?.[0];
    const smallPath = photo?.smallPath;
    const webPath = photo?.versions?.[0]?.webPath || '';
    const placeholder = photo?.placeholder;
    const src = smallPath || webPath;
    const masterPath = photo?.masterOrZoomPath;

    const srcSetAttributes =
        srcSetSizes && masterPath
            ? {
                  srcSet: getSrcSetString(masterPath, {
                      widths: srcSetWidths,
                      quality: srcSetQuality,
                  }),
                  sizes: srcSetSizes,
              }
            : {};
    const fallbackImageSize = 'imageSmall';
    const thumb = (
        <div
            className={classnames(styles.imageContainer, styles[imageSize || fallbackImageSize], {
                [styles.centerImage]: centerImage,
            })}
            data-tn="product-image-container"
        >
            {typeof index === 'number' && index < itemsPerRow ? (
                <img
                    className={classnames(styles.image, {
                        [styles.centerImage]: centerImage,
                        [styles.centerVerticaly]: !centerImage,
                    })}
                    src={src}
                    alt={title}
                    {...srcSetAttributes}
                    data-tn="product-image"
                    draggable={imageDraggable}
                />
            ) : (
                <>
                    <LazyImage
                        className={classnames(styles.image, {
                            [styles.centerImage]: centerImage,
                            [styles.centerVerticaly]: !centerImage,
                        })}
                        srcSetAttributes={srcSetAttributes}
                        placeholder={placeholder}
                        alt={title}
                        offsetVertical={imageLoadVerticalOffset}
                        onImageLoad={onContentLoaded}
                        useLoFiLazyLoader={useLoFiLazyLoader}
                        src={src}
                        imageSize={imageSize || fallbackImageSize}
                        imageDraggable={imageDraggable}
                    />
                    <noscript>
                        <img
                            className={classnames(styles.image, {
                                [styles.centerImage]: centerImage,
                                [styles.centerVerticaly]: !centerImage,
                            })}
                            src={src}
                            alt={title}
                            {...srcSetAttributes}
                        />
                    </noscript>
                </>
            )}
        </div>
    );
    const productClass = classnames(styles.product, {
        [styles.hasLightBox]: showLightBox,
        [styles.hasDivider]: !hideDivider,
    });
    let imageEl = (
        <SeoLink
            className={styles.link}
            dataTn="product-image-link"
            linkData={{ path: pdpUrl, isLinkable: false }}
            onClick={onClick}
        >
            {thumb}
        </SeoLink>
    );

    if (disableLinks) {
        imageEl = thumb;
    }

    const imageWrapperClass = [dibsCss.relative];
    if (showLightBox) {
        imageWrapperClass.push(dibsCss.pyMedium, dibsCss.pXsmall);
    } else if (hideImagePadding) {
        imageWrapperClass.push(dibsCss.p0);
    } else if (hideImageSidePadding) {
        imageWrapperClass.push(dibsCss.pbXsmall, dibsCss.px0);
    } else {
        imageWrapperClass.push(dibsCss.pbXsmall, dibsCss.pXsmall);
    }

    return (
        <div className={productClass} draggable="false">
            <div className={classnames(...imageWrapperClass)}>{imageEl}</div>
            {!hideProductDetails && (
                <div
                    className={classnames(styles.productDetailWrapper, {
                        [styles.hideDivider]: hideDivider,
                    })}
                >
                    {showQuickView && (
                        <QuickViewButton
                            openQuickView={openQuickView}
                            showQuickViewIcon={showQuickViewIcon}
                            hideDivider={hideDivider}
                        />
                    )}
                    {!!dimensions && (
                        <div className={styles.dimensions} data-tn="dimensions">
                            {dimensions}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export const Product = createFragmentContainer(ProductComponent, {
    item: graphql`
        fragment Product_item on Item
        @argumentDefinitions(photosLimit: { type: "Int", defaultValue: 1 }) {
            productPhotos: photos(limit: $photosLimit) {
                placeholder
                smallPath
                masterOrZoomPath
                versions {
                    webPath
                }
            }
        }
    `,
});

import { ReactNode, useEffect, useState, FunctionComponent } from 'react';
import classnames from 'classnames';

import styles from './ErrorMessage.scss';

type DangerouslySetInnerHTMLType = { __html: string };

type MessageType = ReactNode | DangerouslySetInnerHTMLType | undefined;

type ErrorMessageProps = {
    alignRight?: boolean;
    dataTn: string;
    message?: MessageType;
    showErrorAsNote?: boolean;
};

const getErrorMessageText = (
    message: MessageType,
    dataTn: string,
    className: string
): ReactNode => {
    if (message && typeof message === 'object' && message.hasOwnProperty('__html')) {
        return (
            <div
                data-tn={dataTn}
                className={className}
                dangerouslySetInnerHTML={message as DangerouslySetInnerHTMLType}
            />
        );
    } else {
        return (
            <div data-tn={dataTn} className={className}>
                {message as ReactNode | undefined}
            </div>
        );
    }
};

export const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({
    alignRight,
    dataTn,
    message,
    showErrorAsNote = false,
}) => {
    const className = classnames(styles.errorMessage, {
        [styles.errorMessageRight]: alignRight,
        [styles.showErrorAsNote]: showErrorAsNote,
    });
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(
        !!message || showErrorAsNote
    );
    const [messageText, setMessageText] = useState<MessageType>(
        getErrorMessageText(message, dataTn, className)
    );
    const [hasAnimationTransitionClass, setHasAnimationTransitionClass] = useState(false);

    useEffect(() => {
        if (isErrorMessageVisible && !message) {
            setHasAnimationTransitionClass(false);
            setTimeout(() => {
                setIsErrorMessageVisible(false);
                setMessageText(message);
            }, 150);
        } else if (!isErrorMessageVisible && !!message) {
            setIsErrorMessageVisible(true);
            setHasAnimationTransitionClass(true);
            setMessageText(getErrorMessageText(message, dataTn, className));
        } else if (isErrorMessageVisible && !!message) {
            setIsErrorMessageVisible(true);
            setHasAnimationTransitionClass(true);
            setMessageText(getErrorMessageText(message, dataTn, className));
        }
    }, [isErrorMessageVisible, message, dataTn, className]);

    return isErrorMessageVisible ? (
        <div
            className={classnames(styles.errorMessageTransition, {
                [styles.activeErrorMessageTransition]: hasAnimationTransitionClass,
            })}
        >
            {messageText as ReactNode}
        </div>
    ) : null;
};

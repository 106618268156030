import { createElement, useState, useRef, useEffect, RefObject, FC, ReactNode } from 'react';

export type VisibilityChangeCallback = {
    intersectionRatio?: number;
    isVisible: boolean;
    disconnect: () => void;
    boundingClientRect: ClientRect;
};

type VisibilityTrackerProps = {
    observerOptions?: IntersectionObserverInit;
    elementRef?: RefObject<Element>;
    elementType?: string;
    className?: string;
    watchAfterFirstVisible?: boolean;
    onVisibilityChange: (arg: VisibilityChangeCallback) => void;
    threshold?: number;
    /**
     * Polyfill settings
     * https://github.com/w3c/IntersectionObserver/tree/master/polyfill#configuring-the-polyfill
     */
    pollInterval?: number;
    children?: ReactNode;
};

const POLL_INTERVAL = 800;

export const VisibilityTracker: FC<VisibilityTrackerProps> = ({
    observerOptions,
    elementRef,
    elementType,
    className,
    watchAfterFirstVisible,
    onVisibilityChange,
    threshold,
    pollInterval,
    children,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const observer = useRef(
        (() => {
            if (typeof IntersectionObserver === 'undefined') {
                return null;
            }
            const _observer = new IntersectionObserver(
                (entries: Array<IntersectionObserverEntry>) => {
                    entries.forEach(({ isIntersecting, intersectionRatio, boundingClientRect }) => {
                        // istanbul ignore else
                        onVisibilityChange({
                            intersectionRatio,
                            boundingClientRect,
                            isVisible: isIntersecting,
                            disconnect: () => {
                                // istanbul ignore else
                                if (observer.current) {
                                    observer.current.disconnect();
                                }
                            },
                        });

                        if (
                            isIntersecting &&
                            !watchAfterFirstVisible &&
                            intersectionRatio > (threshold || 0)
                        ) {
                            // istanbul ignore else
                            if (observer.current) {
                                observer.current.disconnect();
                            }
                        }

                        // i'm not a fan of this -- since it's being called inside an array, it can theoretically have it's
                        // value changed multiple times per intersection, each call potentially triggering a re-render;
                        // would it be better to track if it's already been called and only to flip to true if uncalled?
                        setIsVisible(isIntersecting);
                    });
                },
                observerOptions || {}
            );
            //@ts-ignore polyfill property
            _observer.POLL_INTERVAL = pollInterval || POLL_INTERVAL;
            return _observer;
        })()
    );

    const tempRef = useRef();
    const _elementRef = elementRef || tempRef;

    useEffect(() => {
        // istanbul ignore else
        if (observer.current) {
            //@ts-ignore polyfill property
            observer.current.POLL_INTERVAL = pollInterval || POLL_INTERVAL;
        }
    }, [pollInterval]);

    useEffect(
        /* istanbul ignore next */ () => {
            if (observer.current && _elementRef.current) {
                observer.current.observe(_elementRef.current);
            }
            const curr = observer ? observer.current : null;
            return () => {
                if (curr) {
                    curr.disconnect();
                }
            };
        },
        [_elementRef]
    );

    // istanbul ignore next
    const _children = isVisible && children ? children : null;
    /*
     * If an element ref _isn't_ passed in as a prop, wrap the children in a new DOM element and track that.
     * If an element ref _is_ passed in, just return the children unmodified.
     */
    if (!elementRef) {
        const res = createElement(elementType || 'div', { ref: _elementRef, className }, _children);
        return res;
    } else {
        return <>{_children}</>;
    }
};

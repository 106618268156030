import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type ArrowDownProps = {
    className?: string;
};

const ArrowDown: FunctionComponent<ArrowDownProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'arrow-down'}
            viewBox="0 -8.5 32 32"
        >
            <path d="M32 1c0 .3-.1.6-.3.8l-15 13c-.4.3-.9.3-1.3 0l-15-13C0 1.4-.1.8.3.4c.4-.4 1-.5 1.4-.1L16 12.6 30.3.2c.4-.4 1-.3 1.4.1.2.2.3.5.3.7z" />
        </svg>
    );
};
export default ArrowDown;

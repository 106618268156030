/**
 * @generated SignedSource<<df3ae2ccf85c7f3878fedbd160c4221e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Product_item$data = {
  readonly productPhotos: ReadonlyArray<{
    readonly masterOrZoomPath: string | null;
    readonly placeholder: string | null;
    readonly smallPath: string | null;
    readonly versions: ReadonlyArray<{
      readonly webPath: string | null;
    } | null> | null;
  } | null> | null;
  readonly " $fragmentType": "Product_item";
};
export type Product_item$key = {
  readonly " $data"?: Product_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"Product_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 1,
      "kind": "LocalArgument",
      "name": "photosLimit"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Product_item",
  "selections": [
    {
      "alias": "productPhotos",
      "args": [
        {
          "kind": "Variable",
          "name": "limit",
          "variableName": "photosLimit"
        }
      ],
      "concreteType": "ItemPhoto",
      "kind": "LinkedField",
      "name": "photos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeholder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "smallPath",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "masterOrZoomPath",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemPhotoVersion",
          "kind": "LinkedField",
          "name": "versions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "webPath",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "5594710f24bbb93e704aaec45ae5da22";

export default node;

/**
 * @generated SignedSource<<fbb66621bb3de78a490d8970d9eaed36>>
 * @relayHash 84cb16321727cd51e6d7b9dafb6929fe
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/494.0.0-2025-01-09T20:04:30.590Z/userTrackingQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type userTrackingQuery$variables = {
  fetchTrackingGdprInfo?: boolean | null;
  hasUserId: boolean;
  isLoggedIn: boolean;
  userCountryCode?: string | null;
  userId: string;
};
export type userTrackingQuery$data = {
  readonly viewer: {
    readonly gdprDisplayInfo?: {
      readonly isGdprApplicable: boolean | null;
    } | null;
    readonly transactionsCounter?: {
      readonly confirmedTransactions: number | null;
      readonly submittedTransactions: number | null;
    } | null;
    readonly user?: {
      readonly isVerifiedTrade: boolean | null;
    } | null;
  };
};
export type userTrackingQuery = {
  response: userTrackingQuery$data;
  variables: userTrackingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "fetchTrackingGdprInfo"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isLoggedIn"
},
v3 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v5 = {
  "condition": "hasUserId",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "buyerId",
          "variableName": "userId"
        }
      ],
      "concreteType": "TransactionsCounter",
      "kind": "LinkedField",
      "name": "transactionsCounter",
      "plural": false,
      "selections": [
        {
          "alias": "submittedTransactions",
          "args": null,
          "kind": "ScalarField",
          "name": "submitted",
          "storageKey": null
        },
        {
          "alias": "confirmedTransactions",
          "args": null,
          "kind": "ScalarField",
          "name": "confirmed",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v6 = {
  "condition": "fetchTrackingGdprInfo",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "gdprDisplayInfo",
      "kind": "LinkedField",
      "name": "gdprDisplayInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "countryCode",
              "variableName": "userCountryCode"
            }
          ],
          "kind": "ScalarField",
          "name": "isGdprApplicable",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v7 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVerifiedTrade",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "userTrackingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "condition": "isLoggedIn",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "userTrackingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "condition": "isLoggedIn",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/494.0.0-2025-01-09T20:04:30.590Z/userTrackingQuery",
    "metadata": {},
    "name": "userTrackingQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b3b1b75ccfac3fae0052096db4739332";

export default node;

export function getRedirectDestination(): string | null {
    let dest = new URLSearchParams(window.location.search).get('ep');
    if (dest?.includes('//')) {
        // id ep contains '//' then redirect to current page
        // if phishing is happening, just redirect to the homepage (uri.path = '/')
        dest = window.location.href;
    }

    return dest && decodeURIComponent(dest);
}

import {
    getMasqueradeValue,
    getMasqueradeUserIdByType,
    getSellerTokenString,
    getUserTokenString,
    splitToken,
    getDirectLoginOriginalUser,
    getMasqueradeSellerId,
    getMasqueradeBuyerId,
} from './helpers';
import { getCookieValue } from './helpers/getCookieValue';
import { getEmailTokenString } from './helpers/emailOnlyHelpers';

import { Cookie, MasqueradeUserType } from './helpers/types';
import { COOKIE_KEYS, INTERNAL_TRAFFIC } from './helpers/constants';

export const cookieJarConstants = COOKIE_KEYS;

export function getPasswordReset(cookie: Cookie): string | null {
    return getCookieValue(cookie, COOKIE_KEYS.PASSWORD_RESET);
}

type GetBuyerIdOptions = {
    getRealUser?: boolean;
};

export function getUserType(cookie: Cookie, options: GetBuyerIdOptions = {}): string | null {
    const { getRealUser } = options;
    return getCookieValue(cookie, getRealUser ? COOKIE_KEYS.REAL_USER_TYPE : COOKIE_KEYS.USER_TYPE);
}

export function getGuestId(cookie: Cookie): string | null {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(
            'dibs-cookie-jar | "getGuestId" is deprecated. guestId should be managed in localStorage.'
        );
    }
    return getCookieValue(cookie, COOKIE_KEYS.GUEST_ID);
}

export function getUserToken(cookie: Cookie): string | null {
    /* istanbul ignore else */
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn('dibs-cookie-jar | "getUserToken" is deprecated. Use "getBuyerToken".');
    }
    return getUserTokenString(cookie);
}

export function getEverLoggedIn(cookie: Cookie): string | null {
    return getCookieValue(cookie, COOKIE_KEYS.EVER_LOGGED_IN);
}

export function getUserId(
    cookie: Cookie,
    userType?: MasqueradeUserType,
    getRealUser?: boolean
): string | null {
    // eslint-disable-next-line no-console
    console.warn(
        'DEPRECATION WARNING: getUserId is deprecated, use getAdminMasqueradingAsBuyerUserId(cookie)'
    );

    if (typeof cookie !== 'string') {
        throw new Error(
            `a cookie string must be passed in. a ${typeof cookie} was passed passed in instead.`
        );
    }

    const masqueradeInfo = getMasqueradeValue(cookie, userType);

    if (masqueradeInfo && !getRealUser) {
        return masqueradeInfo;
    }

    const userId = splitToken(getUserTokenString(cookie));
    return userId || null;
}

export function getBuyerId(cookie: Cookie): string {
    const masqueradedUserId = getMasqueradeUserIdByType(cookie, COOKIE_KEYS.MASQUERADE_BUYER);
    const token = getUserTokenString(cookie) || getEmailTokenString(cookie);
    const realUserId = splitToken(token) || '';

    return masqueradedUserId || realUserId;
}

// return masquerade seller id if masquerading, otherwise return seller id
export function getSellerId(cookie: Cookie): string | null {
    const masqueradeSellerId = getMasqueradeSellerId(cookie);
    if (masqueradeSellerId) {
        return splitToken(masqueradeSellerId) || null;
    }
    return splitToken(getSellerTokenString(cookie)) || null;
}

type isMasqueradingByTypeFunction = (userType: MasqueradeUserType) => boolean;
export function isMasqueradingByType(cookie: Cookie): isMasqueradingByTypeFunction {
    const userId = getBuyerId(cookie);

    return userType => {
        // return `false` instead of `null`
        return (userId ? !!userId : false) && !!getMasqueradeUserIdByType(cookie, userType);
    };
}

export function isMasqueradingAsSeller(cookie: Cookie): boolean {
    return isMasqueradingByType(cookie)(COOKIE_KEYS.MASQUERADE_SELLER);
}

export function isMasqueradingAsBuyer(cookie: Cookie): boolean {
    return isMasqueradingByType(cookie)(COOKIE_KEYS.MASQUERADE_BUYER);
}

export function isMasquerading(cookie: Cookie): boolean {
    const sellerId = isMasqueradingAsSeller(cookie);
    const buyerId = isMasqueradingAsBuyer(cookie);
    return !!(sellerId || buyerId);
}

export type BuyerTokenObject = {
    userToken?: string;
    emailToken?: string;
};

export type SellerTokenObject = {
    sellerToken?: string;
};

export function getBuyerToken(cookie: Cookie): BuyerTokenObject {
    const userToken = getUserTokenString(cookie);
    const emailToken = getEmailTokenString(cookie);
    if (userToken) {
        return {
            userToken,
        };
    } else if (emailToken) {
        return {
            emailToken,
        };
    }
    return {};
}

export function getBuyerMasqueradeToken(cookie: Cookie): BuyerTokenObject {
    const userToken = getCookieValue(cookie, COOKIE_KEYS.MASQUERADE_BUYER_USER_TOKEN);
    if (userToken) {
        return { userToken };
    } else {
        return {};
    }
}

/**
 * Used to get the token of the masquerade buyer if logged in as one
 * Otherwise the regular buyer token will be retrieved
 */
export function getBuyerOrMasqueradeBuyerToken(cookie: Cookie): string | null {
    const masqueradeToken = getBuyerMasqueradeToken(cookie);
    const buyerToken = getBuyerToken(cookie);

    if (typeof masqueradeToken.userToken === 'string') {
        return masqueradeToken.userToken;
    } else if (buyerToken.userToken) {
        return buyerToken.userToken;
    } else {
        return null;
    }
}

/**
 * For use on admin dealer facing pages only
 * Will use sellerToken if available (logged in as seller)
 * Otherwise will use buyer token for masqueraded admin
 */
export function getSellerToken(cookie: Cookie): SellerTokenObject | BuyerTokenObject {
    // if seller token, return seller token
    const sellerToken = getSellerTokenString(cookie);
    if (sellerToken) {
        const sellerTokenObject: SellerTokenObject = {
            sellerToken,
        };
        return sellerTokenObject;
    }

    // either a logged in admin user or masquerading as a seller
    // leveraging `getBuyerToken` logic
    // returns an object already
    // and will also return an empty object
    // for a falsey value
    return getBuyerToken(cookie);
}

export function getBuyerTokenParams(cookie: Cookie): URLSearchParams {
    const result = new URLSearchParams();
    const userToken = getUserTokenString(cookie);
    const emailToken = getEmailTokenString(cookie);
    if (userToken) {
        result.set('userToken', userToken);
    } else if (emailToken) {
        result.set('emailToken', emailToken);
    }
    return result;
}

export function getInternalTokenParams(cookie: Cookie): URLSearchParams {
    const result = new URLSearchParams();
    const userToken = getUserTokenString(cookie);
    if (userToken) {
        result.set('userToken', userToken);
    }
    return result;
}

export function getSellerTokenParams(cookie: Cookie): URLSearchParams {
    // if seller token, return seller token
    const sellerToken = getSellerTokenString(cookie);
    if (sellerToken) {
        return new URLSearchParams({ sellerToken });
    }

    // either a logged in admin user or masquerading as a seller
    // leveraging `getBuyerToken` logic
    // returns an object already
    // and will also return an empty object
    // for a falsey value
    return getInternalTokenParams(cookie);
}

/**
 * For use on admin-v2 internal pages only
 */
export const getInternalToken = getBuyerToken;

/**
 * @deprecated
 */
export function getAdminToken(cookie: Cookie): SellerTokenObject | BuyerTokenObject {
    // eslint-disable-next-line no-console
    console.warn('DEPRECATION WARNING: getAdminToken is deprecated, use getInternalToken(cookie)');
    return getSellerToken(cookie);
}

export function isDirectlyLoggedInAsUser(cookie: Cookie): boolean {
    return !!getDirectLoginOriginalUser(cookie);
}

/**
 * return the internal user id. never returns id from masquerade token or email token
 */
export function getInternalId(cookie: Cookie): string | null {
    const userId = splitToken(getUserTokenString(cookie));
    return userId || null;
}

export function getAdminUser(cookie: Cookie): string | null {
    return getInternalId(cookie);
}

/**
 * userId of admin who is masquerading as a buyer
 */
export function getAdminMasqueradingAsBuyerUserId(cookie: Cookie): string | null {
    return getInternalId(cookie);
}

// userId of admin who is masquerading as a seller
export function getAdminMasqueradingAsSellerUserId(cookie: Cookie): string | null {
    return getInternalId(cookie);
}

/**
 * returns userType from cookie. Will be the user type of the user being masqueraded as if an admin is masquerading
 */
export function getBuyerUserType(cookie: Cookie): string | null {
    return getCookieValue(cookie, COOKIE_KEYS.USER_TYPE);
}

/**
 * returns real userType from cookie. Will be the userType of the admin who is masquerading
 */
export function getMasqueradingBuyerUserType(cookie: Cookie): string | null {
    return getCookieValue(cookie, COOKIE_KEYS.REAL_USER_TYPE);
}

// userId of admin who is directly logged in as a buyer. returns null if not directly logged in.
export function getAdminDirectlyLoggedInAsBuyerUserId(cookie: Cookie): string | null {
    return splitToken(getCookieValue(cookie, COOKIE_KEYS.DIRECT_LOGIN_ORIGINAL_USER));
}

// userId of admin who is directly logged in as a seller.
export function getAdminDirectlyLoggedInAsSellerUserId(cookie: Cookie): string | null {
    return getInternalId(cookie);
}

export function getAbOptIn(cookie: Cookie): string | null {
    return getCookieValue(cookie, COOKIE_KEYS.AB_OPT_IN);
}

export function getPriceBookName(cookie: Cookie): string | null {
    return getCookieValue(cookie, COOKIE_KEYS.PRICE_BOOK_NAME);
}

export function getTrafficType(cookie: Cookie, url: URL | null): string | null {
    if (getMasqueradeSellerId(cookie) || getMasqueradeBuyerId(cookie) || getAbOptIn(cookie)) {
        return INTERNAL_TRAFFIC;
    }
    if (url?.searchParams.has('abtests')) {
        return INTERNAL_TRAFFIC;
    }
    return getCookieValue(cookie, COOKIE_KEYS.TRAFFIC_TYPE);
}

export function hasInternalTrafficCookie(cookie: Cookie, url: URL | null): boolean {
    return getTrafficType(cookie, url) === INTERNAL_TRAFFIC;
}

export function getEngagedItemsCookie(cookie: Cookie): string | null {
    return getCookieValue(cookie, COOKIE_KEYS.ENGAGED_ITEMS);
}

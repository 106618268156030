import { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Spinner } from 'dibs-elements/exports/Spinner';

import styles from './ItemMediaSpinner.scss';

const REMOVE_TIMEOUT = parseInt(styles.FADE_TIMEOUT) + 50; // 50ms longer than the transition for styles.fade

export const ItemMediaSpinner: FC<{ isOpen?: boolean }> = ({ isOpen }) => {
    const [isRemoved, setIsRemoved] = useState(false);
    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (!isOpen && !isRemoved) {
            timeout = setTimeout(() => {
                setIsRemoved(true);
            }, REMOVE_TIMEOUT);
        }
        return () => clearTimeout(timeout);
    }, [isRemoved, isOpen]);

    return isRemoved ? null : (
        <Spinner
            black
            containerClass={classnames(styles.container, styles.important, {
                [styles.fade]: !isOpen,
            })}
        />
    );
};

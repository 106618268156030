/**
 * Action Types
 */
const headerActionTypes = {
    SET_BANNER_MESSAGES: 'SET_BANNER_MESSAGES',
    SET_UPDATE_USER_STATE: 'SET_UPDATE_USER_STATE',
    SET_USER_TYPE_TRADE: 'SET_USER_TYPE_TRADE',
    SET_USER_TYPE_VIP: 'SET_USER_TYPE_VIP',

    SET_DEVICE_TYPE: 'SET_DEVICE_TYPE',
    TOGGLE_MOBILE_MENU: 'TOGGLE_MOBILE_MENU',
    INITIAL_TRACKING_FIRED: 'INITIAL_TRACKING_FIRED',
    RESET_TRACKING_FIRED: 'RESET_TRACKING_FIRED',
    TOGGLE_APP_BANNER: 'TOGGLE_APP_BANNER',
    SET_IS_EMAIL_ONLY: 'SET_IS_EMAIL_ONLY',
    SET_ADD_CART_ITEM_MUTATION_COMPLETED: 'SET_ADD_CART_ITEM_MUTATION_COMPLETED',

    SET_DRAWER_STATE: 'SET_DRAWER_STATE',
    TOGGLE_VIP_CURATED_FOLDER_TOOLTIP_STATE: 'TOGGLE_VIP_CURATED_FOLDER_TOOLTIP_STATE',
    SHOW_TRADE_REWARDS_NAVIGATION_TOOLTIP: 'SHOW_TRADE_REWARDS_NAVIGATION_TOOLTIP',
    HIDE_TRADE_REWARDS_NAVIGATION_TOOLTIP: 'HIDE_TRADE_REWARDS_NAVIGATION_TOOLTIP',
    SET_ACTIVE_LIVE_CHAT_VISIBILITY: 'SET_ACTIVE_LIVE_CHAT_VISIBILITY',
} as const;

const cartActionTypes = {
    SET_CART_REFETCH_TRIGGER: 'SET_CART_REFETCH_TRIGGER',
    SET_CART_ITEMS: 'SET_CART_ITEMS',
    REMOVE_PORTFOLIO_CART_ITEM: 'REMOVE_PORTFOLIO_CART_ITEM',
} as const;

const currencyActionTypes = {
    POPULATE_CURRENCY: 'POPULATE_CURRENCY',
} as const;

export const actionTypes = {
    ...headerActionTypes,
    ...currencyActionTypes,
    ...cartActionTypes,
} as const;

import { trackEvent, trackEcommerce, eventNameConstants } from 'dibs-tracking';

function trackCollectionsImpression({ items, startIndex, name }) {
    trackEcommerce({
        type: 'promotionImpression',
        eventName: eventNameConstants.EVENT_VIEW_PROMOTION,
        promotions: items.map((item, index) => ({
            id: 'small-item-carousel',
            name: name || 'Small Item Carousel',
            creative: item.title,
            position: startIndex + index + 1,
        })),
    });
}

function trackCollectionsItemClick({ item, index }) {
    trackEcommerce({
        type: 'promoClick',
        eventName: eventNameConstants.EVENT_SELECT_PROMOTION,
        promotions: [
            {
                id: 'small-item-carousel',
                name: 'Small Item Carousel',
                creative: `Small Item Carousel : ${item.title}`,
                position: index + 1,
            },
        ],
    });
}

function trackArrowClick({ type }) {
    trackEvent({
        action: 'small item carousel',
        category: 'promo interaction',
        label: `${type} arrow clicked`,
    });
}

export { trackCollectionsImpression, trackCollectionsItemClick, trackArrowClick };

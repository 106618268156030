/**
 * @generated SignedSource<<0321bf365f80559359a31c490a910b86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemShippingPrequotesLazy_item$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ItemShippingPrequotes_item">;
  readonly " $fragmentType": "ItemShippingPrequotesLazy_item";
};
export type ItemShippingPrequotesLazy_item$key = {
  readonly " $data"?: ItemShippingPrequotesLazy_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemShippingPrequotesLazy_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemShippingPrequotesLazy_item",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemShippingPrequotes_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "e46ed7229abc06a1b342b379fde4596d";

export default node;

import {
    trackEvent,
    StepInteractionNameValues,
    eventNameConstants,
    interactionTypeConstants,
} from 'dibs-tracking';

type trackFavoritingArgs = {
    itemId?: string | null;
    action: string;
    label: string;
    step_interaction_name: StepInteractionNameValues;
};

export const trackFavoriting = ({
    action,
    itemId,
    label,
    step_interaction_name,
}: trackFavoritingArgs): void => {
    trackEvent(
        {
            action,
            label,
            category: 'item favoriting',
            isInteractiveEvent: true,
            ...(itemId ? { additional: { itemId } } : {}),
            eventName: eventNameConstants.EVENT_WISHLIST,
            interaction_type: interactionTypeConstants.INTERACTION_TYPE_ITEM_FAVORITING,
            step_interaction_name,
            trigger: label,
        },
        null
    );
};

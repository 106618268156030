import { trackEcommerce, eventNameConstants } from 'dibs-tracking';
import { getPromoCreative } from '../../helpers/getPromoCreative';

function buildItemTrackingObject({ index, item }) {
    const url = item?.linkData?.path || '';
    const position = index + 1;
    return {
        id: `promo-banner-${position}`,
        name: `Promo Banner #${position}`,
        creative: getPromoCreative(url),
        position: position,
    };
}

function trackPromoImpressions({ items, startingIndex }) {
    trackEcommerce({
        type: 'promotionImpression',
        eventName: eventNameConstants.EVENT_VIEW_PROMOTION,
        promotions: items.map((item, index) =>
            buildItemTrackingObject({ index: index + startingIndex, item })
        ),
    });
}

function trackPromoClick({ item, index }) {
    trackEcommerce({
        type: 'promoClick',
        eventName: eventNameConstants.EVENT_SELECT_PROMOTION,
        promotions: [buildItemTrackingObject({ index, item })],
    });
}

export { trackPromoImpressions, trackPromoClick };

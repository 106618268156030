import { ReactNode, FunctionComponent } from 'react';
import classnames from 'classnames';
import { HeadingType } from 'dibs-controlled-heading/exports/HeadingLevel';

import styles from './main.scss';

type HeaderProps = {
    size?: 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge';
    dataTn?: string;
    title: ReactNode;
    subtitle?: ReactNode;
    htmlElementType?: 'div' | 'h1' | 'h2' | HeadingType;
    id?: string;
};

export const Header: FunctionComponent<HeaderProps> = props => {
    const { size = 'large', title, subtitle, dataTn, htmlElementType = 'div', id } = props;

    const TitleTag = htmlElementType;

    return (
        <>
            <TitleTag
                id={id}
                data-heading
                data-tn={`header-title${dataTn ? `-${dataTn}` : ''}`}
                className={classnames(styles.title, styles[size])}
            >
                {title}
            </TitleTag>
            {subtitle && (
                <div
                    data-tn={`header-subtitle${dataTn ? `-${dataTn}` : ''}`}
                    className={classnames(styles.subtitle, styles[size])}
                >
                    {subtitle}
                </div>
            )}
        </>
    );
};

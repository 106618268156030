/**
 * @generated SignedSource<<906f8c336fc7cce0f8b0269a73155306>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedRecentActivityModule_componentModule$data = {
  readonly cmsDisplayTitle: string | null;
  readonly recentActivityItemsWithMeta: {
    readonly items: ReadonlyArray<{
      readonly serviceItem: {
        readonly browseUrl: string | null;
        readonly categoryCode: string | null;
        readonly contemporaryTrackingString: string;
        readonly ecommerceTrackingParams: any | null;
        readonly localizedPdpUrl: string | null;
        readonly photos: ReadonlyArray<{
          readonly smallPath: string | null;
        } | null> | null;
        readonly seller: {
          readonly serviceId: string | null;
        } | null;
        readonly serviceId: string | null;
        readonly title: string | null;
      } | null;
      readonly type: string | null;
    } | null> | null;
    readonly itemsPerPage: number | null;
  } | null;
  readonly " $fragmentType": "HpSharedRecentActivityModule_componentModule";
};
export type HpSharedRecentActivityModule_componentModule$key = {
  readonly " $data"?: HpSharedRecentActivityModule_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedRecentActivityModule_componentModule">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "localRecentHistoryList"
    },
    {
      "kind": "RootArgument",
      "name": "recentActivityDesignVariant"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedRecentActivityModule_componentModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cmsDisplayTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "localRecentHistoryList",
          "variableName": "localRecentHistoryList"
        },
        {
          "kind": "Variable",
          "name": "recentActivityDesignVariant",
          "variableName": "recentActivityDesignVariant"
        },
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "concreteType": "RecentActivityItemsWithMeta",
      "kind": "LinkedField",
      "name": "recentActivityItemsWithMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "itemsPerPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RecentActivityItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "serviceItem",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ecommerceTrackingParams",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "contemporaryTrackingString",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "browseUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "categoryCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Seller",
                  "kind": "LinkedField",
                  "name": "seller",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "limit",
                      "value": 1
                    }
                  ],
                  "concreteType": "ItemPhoto",
                  "kind": "LinkedField",
                  "name": "photos",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "smallPath",
                      "storageKey": null
                    }
                  ],
                  "storageKey": "photos(limit:1)"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "localizedPdpUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RecentActivityModule",
  "abstractKey": null
};
})();

(node as any).hash = "e2ef5b1dd2c3c790fccc56f1a54d6d69";

export default node;

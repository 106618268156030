import PropTypes from 'prop-types';
import styles from './SearchBarSection.scss';

export const SearchBarSection = ({ title, children }) => (
    <div data-tn="search-bar-section" className={styles.section}>
        <div data-tn="search-bar-header" className={styles.header}>
            {title}
        </div>
        {children}
    </div>
);

SearchBarSection.propTypes = {
    title: PropTypes.node,
    children: PropTypes.node,
};

import * as tracking from 'dibs-tracking';
import { VisitCountModel } from './visitCountModel';

export class VisitorTracking {
    constructor() {
        this.model = new VisitCountModel();

        tracking.incrementSessionPageCount();

        this.model.incrementTracking();
        this.checkPdpEngaged(document);
        this.trackNonBouncePdp(tracking);
        this.model.save();
    }

    /**
     * Was the prior page pdp?
     *
     * @param document
     * @property {number} priorPagePdp - is prior page PDP
     */
    checkPdpEngaged(document) {
        let value = 0;
        if (
            (this.wasInPreviousUrl(document, '1stdibs.com') &&
                this.wasInPreviousUrl(document, 'id-')) ||
            document.location.href.indexOf('id-') > -1
        ) {
            value = 1;
        }
        this.model.set('priorPagePdp', value);
    }

    /**
     * check if nonBound Pdp Tracking event can be fired
     * @returns {boolean}
     */
    canFireNonBoundPdpTracking() {
        return (
            !this.model.get('gaTrackerEventFired') &&
            this.model.get('priorPagePdp') &&
            this.model.get('gaSessionPageCount') >= 2
        );
    }

    /**
     * @param  {Object} gaTracker [dibs-tracking wrapper around google analytics that fires events]
     * @return {None}
     */
    trackNonBouncePdp(gaTracker) {
        if (this.canFireNonBoundPdpTracking()) {
            gaTracker.trackEvent({
                category: 'adwords',
                action: 'session type',
                label: 'nbpdp',
            });
            this.model.set('gaTrackerEventFired', 1);
        }
    }

    /**
     * Is the text in the url?
     * @returns {boolean}
     */
    wasInPreviousUrl(document, linkText) {
        return document.referrer.indexOf(linkText) > -1;
    }
}

export const PAGE_TYPE = Object.freeze({
    ABOUT: 'about',
    ASSOCIATION: 'association',
    ATTRIBUTE: 'attribute',
    BROWSE: 'browse',
    CARAT_WEIGHT: 'carat-weight',
    CART: 'cart',
    CHECKOUT: 'checkout',
    CHECKOUT_CONFIRMATION: 'checkoutConfirmation',
    COLLECTION: 'collection',
    CREATOR: 'creator',
    DEALER: 'dealer',
    DEALERS: 'dealers',
    FAVORITES: 'favorites',
    HOME: 'home',
    INVOICE: 'invoice',
    LOCATION: 'location',
    MATERIAL: 'material',
    MESSAGE_CENTER: 'messageCenter',
    METAL: 'metal',
    MORE_FROM_SELLER: 'more-from-seller',
    ORDER_MANAGER: 'orderManager',
    ORIGIN: 'origin',
    PDP: 'pdp',
    PERIOD: 'period',
    RECOGNIZED_DEALERS: 'recognized_dealers',
    SEARCH: 'search',
    SECTION: 'section',
    SOURCING: 'sourcing',
    STONE: 'stone',
    STONE_CUT: 'stone-cut',
    STYLE: 'style',
    TECHNIQUE: 'technique',
    TRADE_PROFILE: 'trade',
    TRADE_REWARDS: 'tradeRewards',
    WORKSPACE: 'workspace',
    BUY: 'buy',
    NFT: 'nft',
    NFT_COLLECTIONS: 'nft-collections',
    INTERIORS: 'interiors',
    DESIGN_FIRMS: 'design-firms',
    DESIGNER_CONNECTION: 'designer-connection',
    PHOTOS: 'photos',
    ANSWERS: 'answers',
    APPLICATION_FORM: 'application-form',
    SIMILAR_SOLD: 'similar-sold',
    NOT_FOUND: 'not_found',
    NOT_AUTHORIZED: 'not_authorized',
    FORBIDDEN: 'forbidden',
    TRADE_APPLICATION: 'trade-application',
});

export const BROWSE_TYPES = Object.freeze([
    PAGE_TYPE.ASSOCIATION,
    PAGE_TYPE.ATTRIBUTE,
    PAGE_TYPE.BROWSE,
    PAGE_TYPE.CARAT_WEIGHT,
    PAGE_TYPE.COLLECTION,
    PAGE_TYPE.CREATOR,
    PAGE_TYPE.LOCATION,
    PAGE_TYPE.MATERIAL,
    PAGE_TYPE.METAL,
    PAGE_TYPE.ORIGIN,
    PAGE_TYPE.PERIOD,
    PAGE_TYPE.RECOGNIZED_DEALERS,
    PAGE_TYPE.SECTION,
    PAGE_TYPE.STONE,
    PAGE_TYPE.STONE_CUT,
    PAGE_TYPE.STYLE,
    PAGE_TYPE.TECHNIQUE,
    PAGE_TYPE.BUY,
]);

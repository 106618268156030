import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import classnames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';
import { SeoLink } from 'dibs-elements/exports/SeoLink';
import styles from './styles/swatchSelectorMinimal.scss';

const DEFAULT_SWATCHES_DISPLAY_COUNT = 4;
const SWATCH_CIRCLE_SIZE = 20;
const SWATCH_IMAGE_WIDTH = SWATCH_CIRCLE_SIZE * 3; // multiplying by 3 to increase PPI, for retina screens

export function SwatchSelectorMinimalComponent({
    item,
    onClick,
    displayCount,
    containerClassName,
    path,
}) {
    const isMultiSku = item?.isMultiSku;
    const isRingItem = item?.isRingItem;
    const swatches = item?.variableAttributes?.[0]?.variableAttributeData?.swatchSort || [];

    if (isRingItem || !isMultiSku || !swatches.length) {
        return null;
    }

    const swatchesCount = swatches.length;
    const showMore = swatchesCount > displayCount;
    const displaySwatches = showMore ? swatches.slice(0, displayCount) : swatches;
    const count = showMore ? swatchesCount - displaySwatches.length : 0;

    return (
        <SeoLink
            dataTn="mini-swatch-selector-anchor"
            className={styles.link}
            linkData={{ path }}
            onClick={onClick}
        >
            <div className={classnames(styles.container, containerClassName)}>
                {displaySwatches.map(
                    ({ swatch }) =>
                        swatch && (
                            <img
                                data-tn="mini-swatch-selector"
                                key={swatch.serviceId}
                                src={`${swatch.imageUrl}?width=${SWATCH_IMAGE_WIDTH}`}
                                alt={swatch.displayName}
                                className={styles.swatchCircle}
                            />
                        )
                )}{' '}
                {showMore && (
                    <span
                        data-tn="mini-swatch-selector-more-label"
                        className={styles.swatchesCount}
                    >
                        + {count}{' '}
                        <FormattedMessage id="dc.DibsMultiSku.More" defaultMessage="more">
                            {msg => <Fragment>{msg}</Fragment>}
                        </FormattedMessage>
                    </span>
                )}
            </div>
        </SeoLink>
    );
}

SwatchSelectorMinimalComponent.propTypes = {
    item: PropTypes.object.isRequired,
    containerClassName: PropTypes.string,
    displayCount: PropTypes.number,
    path: PropTypes.string,
    onClick: PropTypes.func,
};

SwatchSelectorMinimalComponent.defaultProps = {
    containerClassName: '',
    displayCount: DEFAULT_SWATCHES_DISPLAY_COUNT,
    onClick: () => {},
};

export const SwatchSelectorMinimal = createFragmentContainer(SwatchSelectorMinimalComponent, {
    item: graphql`
        fragment SwatchSelectorMinimal_item on Item {
            isMultiSku
            isRingItem
            variableAttributes {
                serviceId
                variableAttributeData {
                    swatchSort {
                        swatch {
                            serviceId
                            displayName
                            imageUrl
                        }
                    }
                }
            }
        }
    `,
});

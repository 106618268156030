import SV from 'server-vars';
import { FormattedMessage } from 'dibs-react-intl';
import taxName from './taxName';

const collectSellerVatInfoFF = !!SV.get('collectSellerVatInfoFF');

const vatMessages = {
    included: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.included"
            defaultMessage="{taxName} Included"
            values={{ taxName: taxName(priceBookName) }}
        />
    ),
    reverseCharge: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.reverseCharge"
            defaultMessage="{taxName}: Reverse charge"
            values={{ taxName: taxName(priceBookName) }}
        />
    ),
    buyer: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.buyer"
            defaultMessage="{priceBookName} Buyer"
            values={{ taxName: taxName(priceBookName), priceBookName }}
        />
    ),
    buyersIncl: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.buyersIncl"
            defaultMessage="{priceBookName} buyers (incl. {taxName})"
            values={{ taxName: taxName(priceBookName), priceBookName }}
        />
    ),
    downloadReport: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.downloadReport"
            defaultMessage="Download {priceBookName}-{taxName} Report"
            values={{ taxName: taxName(priceBookName), priceBookName }}
        />
    ),
    buyerPricesIncludeWhereApplicable: ({
        priceBookName,
        labelWrapper,
        valueWrapper,
    }: {
        priceBookName: string | null;
        labelWrapper: (text: React.ReactNode[]) => JSX.Element;
        valueWrapper: (text: React.ReactNode[]) => JSX.Element;
    }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.buyerPricesIncludeWhereApplicable"
            defaultMessage="<labelWrapper>{priceBookName} buyer.</labelWrapper> <valueWrapper>Prices include {taxName} where applicable</valueWrapper>"
            values={{ taxName: taxName(priceBookName), priceBookName, labelWrapper, valueWrapper }}
        />
    ),
    buyerReverseChargeRequested: ({
        priceBookName,
        labelWrapper,
        valueWrapper,
    }: {
        priceBookName: string | null;
        labelWrapper: (text: React.ReactNode[]) => JSX.Element;
        valueWrapper: (text: React.ReactNode[]) => JSX.Element;
    }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.buyerReverseChargeRequested"
            defaultMessage="<labelWrapper>{priceBookName} buyer.</labelWrapper> <valueWrapper>Reverse charge requested.</valueWrapper>"
            values={{ taxName: taxName(priceBookName), priceBookName, labelWrapper, valueWrapper }}
        />
    ),
    idLabel: ({
        priceBookName,
        vatId,
    }: {
        priceBookName: string | null;
        vatId: string;
    }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.idLabel"
            defaultMessage="{taxName} ID: {vatId}"
            values={{ taxName: taxName(priceBookName), vatId }}
        />
    ),
    includes: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.includes"
            defaultMessage="Includes {taxName}"
            values={{ taxName: taxName(priceBookName) }}
        />
    ),
    buyerIsFromTheCountrySoTheItem: ({
        priceBookName,
    }: {
        priceBookName: string | null;
    }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.buyerIsFromTheCountrySoTheItem"
            defaultMessage="The buyer is from the {priceBookName} so the item and shipping prices include {taxName} where applicable. The {taxName} amount will be sent to your bank account.{break}The commission is charged from the {taxName}-exclusive price."
            values={{ taxName: taxName(priceBookName), priceBookName, break: <br /> }}
        />
    ),
    addStandardRate: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.addStandardRate"
            defaultMessage="Add Standard {priceBookName} {taxName} Rate"
            values={{ taxName: taxName(priceBookName), priceBookName }}
        />
    ),
    standardRate: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.standardRate"
            defaultMessage="Standard {priceBookName} {taxName} Rate"
            values={{ taxName: taxName(priceBookName), priceBookName }}
        />
    ),
    theMultiplierShouldBe: ({
        priceBookName,
        minVatRate,
        maxVatRate,
    }: {
        priceBookName: string | null;
        minVatRate: number;
        maxVatRate: number;
    }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.theMultiplierShouldBe"
            defaultMessage="The {priceBookName} {taxName} Multiplier should be between {minVatRate}% and {maxVatRate}%."
            values={{ taxName: taxName(priceBookName), priceBookName, minVatRate, maxVatRate }}
        />
    ),
    thisRateWillBeAdded: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.thisRateWillBeAdded"
            defaultMessage={`This rate will be added to the price of all of your inventory for {priceBookName} buyers as they browse the site. Based on the buyer's location, 1stDibs will calculate the corresponding {taxName} amount, remit it to you, and display it in the order invoice.`}
            values={{ taxName: taxName(priceBookName), priceBookName }}
        />
    ),
    weHaveSetADefaultRate1: ({
        priceBookName,
        defaultVatRate,
        minVatRate,
    }: {
        priceBookName: string | null;
        defaultVatRate: number;
        minVatRate: number;
    }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.weHaveSetADefaultRate1"
            defaultMessage={`We have set a default rate of {defaultVatRate}% in your inventory settings. If you would like to set a different rate, or do not include {taxName} and need to set it to {minVatRate}%, please update the rate in your inventory settings.`}
            values={{ taxName: taxName(priceBookName), priceBookName, defaultVatRate, minVatRate }}
        />
    ),
    weHaveSetADefaultRate2: ({
        priceBookName,
        defaultVatRate,
        minVatRate,
    }: {
        priceBookName: string | null;
        defaultVatRate: number;
        minVatRate: number;
    }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.weHaveSetADefaultRate2"
            defaultMessage={`We have set a default rate of {defaultVatRate}%. You can change the rate below or set it to {minVatRate}% if you do not want to add {taxName} to your inventory.`}
            values={{ taxName: taxName(priceBookName), priceBookName, defaultVatRate, minVatRate }}
        />
    ),
    whatScheme: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.whatScheme"
            defaultMessage="What {taxName} scheme do you use?"
            values={{ taxName: taxName(priceBookName) }}
        />
    ),
    standard: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.standard"
            defaultMessage="Standard {taxName}"
            values={{ taxName: taxName(priceBookName) }}
        />
    ),
    marginScheme: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.marginScheme"
            defaultMessage="{taxName} Margin Scheme"
            values={{ taxName: taxName(priceBookName) }}
        />
    ),
    unregistered: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.unregistered"
            defaultMessage="Not {taxName} Registered"
            values={{ taxName: taxName(priceBookName) }}
        />
    ),
    multiplier: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.multiplier"
            defaultMessage="{priceBookName} {taxName} Multiplier"
            values={{ taxName: taxName(priceBookName), priceBookName }}
        />
    ),
    pleaseSetTheMultiplier: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.pleaseSetTheMultiplier"
            defaultMessage={`Please, set the multiplier here and we'll increase your prices for {priceBookName} buyers accordingly.`}
            values={{ taxName: taxName(priceBookName), priceBookName }}
        />
    ),
    setTheMultiplier: ({
        priceBookName,
        minVatRate,
    }: {
        priceBookName: string | null;
        minVatRate: number;
    }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.setTheMultiplier"
            defaultMessage={`Set the multiplier here and we'll increase your prices for {priceBookName} buyers accordingly. You can set it to {minVatRate}% if you do not want to add {taxName} to your inventory.`}
            values={{ taxName: taxName(priceBookName), priceBookName, minVatRate }}
        />
    ),
    selectAnRate: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.selectAnRate"
            defaultMessage={`Select an {priceBookName} {taxName} rate to add to the prices of all your inventory for {priceBookName} buyers. Based on the buyer's location, 1stDibs will then calculate the correct {taxName} amount and include it in your payout.`}
            values={{ taxName: taxName(priceBookName), priceBookName }}
        />
    ),
    ifThisPurchaseIsEligible: ({
        priceBookName,
    }: {
        priceBookName: string | null;
    }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.ifThisPurchaseIsEligible"
            defaultMessage="If this purchase is eligible for a reverse charge, please provide a valid {taxName} identification number."
            values={{ taxName: taxName(priceBookName) }}
        />
    ),
    listPriceShouldNotInclude: ({
        priceBookName,
    }: {
        priceBookName: string | null;
    }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.listPriceShouldNotInclude"
            defaultMessage="List Price should not include {taxName} if you have selected an {priceBookName} {taxName} rate in settings. We will automatically calculate {taxName} and include it in the price for {priceBookName} buyers."
            values={{ taxName: taxName(priceBookName), priceBookName }}
        />
    ),
    yourOfferIncludes: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.yourOfferIncludes"
            defaultMessage="Your offer includes {taxName}"
            values={{ taxName: taxName(priceBookName), priceBookName }}
        />
    ),
    vatRate: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.vatRate"
            defaultMessage="{taxName} Rate"
            values={{ taxName: taxName(priceBookName) }}
        />
    ),
    pleaseSelectYourVAT: ({
        priceBookName,
        below,
    }: {
        priceBookName: string | null;
        below: boolean;
    }): JSX.Element =>
        collectSellerVatInfoFF ? (
            <FormattedMessage
                id="vatMessages.pleaseSelectYourVAT.1"
                defaultMessage="Select the {taxName} scheme that applies to your business{below, select, true { below} other {}}. If you select standard {taxName} or margin scheme, prices for your inventory will be increased according to the {taxName} percentage you select for applicable buyers."
                values={{ taxName: taxName(priceBookName), below }}
            />
        ) : (
            <FormattedMessage
                id="vatMessages.pleaseSelectYourVAT"
                defaultMessage="Select the {taxName} scheme that applies to your business{below, select, true { below} other {}}. If you select standard {taxName} or margin scheme, prices for your inventory will be increased according to the {taxName} percentage you select for applicable buyers. If you select that you are not {taxName} registered, your inventory will not be listed as {taxName} inclusive. You will not be able to charge buyers for {taxName} post order confirmation."
                values={{ taxName: taxName(priceBookName), below }}
            />
        ),
    selectTheVATScheme: ({ priceBookName }: { priceBookName: string | null }): JSX.Element => (
        <FormattedMessage
            id="vatMessages.selectTheVATScheme"
            defaultMessage="Select the {taxName} scheme that applies to your business"
            values={{ taxName: taxName(priceBookName) }}
        />
    ),
    ifYouIndicate: ({ priceBookName }: { priceBookName: string | null }): JSX.Element | null =>
        collectSellerVatInfoFF ? null : (
            <FormattedMessage
                id="vatMessages.ifYouIndicate"
                defaultMessage="If you indicate that your business is not {taxName} registered, {taxName} will not be added to your prices. You will not be able to charge {taxName} for any of your orders."
                values={{ taxName: taxName(priceBookName) }}
            />
        ),
};

export default vatMessages;

import { FC } from 'react';
import { connect } from 'react-redux';
import SocialLinkRowItemLazy from './SocialLinkRowItemLazy';
import AppStoreLinkLazy from '../AppStoreLink/AppStoreLinkLazy';
import { SocialLinkRowItemProps } from './SocialLinkRowItem';
import classnames from 'classnames';

import style from './SocialLinkRow-style.scss';

type Props = {
    items: SocialLinkRowItemProps[];
    isMobile: boolean;
};

export const SocialLinkRow: FC<Props> = ({ isMobile, items }) => {
    const rowItems: SocialLinkRowItemProps[] = [...items];

    return (
        <ul className={classnames(style.row, { [style.isMobile]: isMobile })}>
            {rowItems.map((item, i) => (
                <SocialLinkRowItemLazy key={i} {...item} />
            ))}
            {!isMobile && (
                <li>
                    <AppStoreLinkLazy />
                </li>
            )}
        </ul>
    );
};

const mapStateToProps = ({ header }: { header: { isMobile: boolean } }): { isMobile: boolean } => ({
    isMobile: header.isMobile,
});

export default connect(mapStateToProps)(SocialLinkRow);

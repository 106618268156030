import { FC, ReactElement } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

type Props = {
    sellerName: string;
    className?: string;
    message?: ReactElement | null;
};

const OfferedBy: FC<Props> = ({ sellerName, className, message }) => (
    <span className={className || ''} data-tn="portfolio-row-dealer">
        <FormattedMessage
            id="dc.searchProductTile.offeredBy"
            defaultMessage={`Offered By {sellerName}`}
            values={{ sellerName }}
        />
        {message || null}
    </span>
);

export default OfferedBy;

import { useSelector } from 'react-redux';

type CurrencyObj = {
    currency: string;
    showCurrency: boolean;
    localCurrency?: string;
};
export function useCurrency(): CurrencyObj {
    const c = useSelector(({ currency }: { currency: CurrencyObj }) => currency);
    return c;
}

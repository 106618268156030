import { FunctionComponent } from 'react';
import { FormattedNumber } from 'dibs-react-intl';

export const handleMinimumFractionDigits = (value: number | string): number => {
    return parseFloat(value + '') % 1 === 0 ? 0 : 2;
};

type Props = {
    value: number | string;
    currency: string;
};

export const FormattedPrice: FunctionComponent<Props> = ({ value, currency }) => (
    <FormattedNumber
        value={Number(value)}
        style="currency"
        currency={currency}
        // if an amount doesn't have fraction digits, don't display them
        // ie, $100 won't display as $100.00 but $100.10 will display the fraction digits
        // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
        minimumFractionDigits={handleMinimumFractionDigits(value)}
    />
);

import PropTypes from 'prop-types';
import { useEffect, useState, useContext } from 'react';
import { ReactRelayContext } from 'react-relay/legacy';
import classnames from 'classnames';

import { FormattedMessage, useIntl } from 'dibs-react-intl';
import { getRecentHistoryList, userFragment } from 'dibs-recent-history/exports/getRecentHistory';

import { getSectionPropsByType, createSearchLink } from '../../utils/sharedSearchBarHelpers';
import { unmaskFragmentData } from '../../helpers/relayUnmaskingHelper';
import { HeaderFooterServerVarsContext } from '../HeaderFooterServerVarsContext/HeaderFooterServerVarsContext';
import { SearchBarSection } from './SearchBarSection';
import { SearchBarTerm } from './SearchBarTerm';
import { SearchBarRecentlyViewedItems } from './SearchBarRecentlyViewedItems';
import { trackSellerBrandingRemovalAbTestVariant } from '../../utils/abTest/sellerBrandingRemovalAbTestHelpers';

import styles from './SearchBar-style.scss';

const TERM_SECTION_TYPE_RECENT = 'RECENT';
const titleMessage = (
    <FormattedMessage id="dbl.header.searchBar.recentlyViewed" defaultMessage="Recently Viewed" />
);

export const getSuggestedTermElements = (suggestedTerm, searchTerm) => {
    if (!searchTerm) {
        return suggestedTerm;
    }
    const searchTermParts = searchTerm.split(' ');
    const suggestedTermParts = suggestedTerm.split(/(\s+)/);
    return suggestedTermParts.reduce((suggestedTermEls, suggestedTermPart, i) => {
        let maxSearchTermPartLength = 0;
        let unboldedSuggestedTermPart;
        let boldedSuggestedTermPart;
        searchTermParts.forEach(searchTermPart => {
            const matchingIndex = suggestedTermPart
                .toLowerCase()
                .indexOf(searchTermPart.toLowerCase());
            if (matchingIndex === 0 && searchTermPart.length > maxSearchTermPartLength) {
                maxSearchTermPartLength = searchTermPart.length;
                unboldedSuggestedTermPart = suggestedTermPart.substr(0, searchTermPart.length);
                boldedSuggestedTermPart = suggestedTermPart.substr(searchTermPart.length);
            }
        });
        if (unboldedSuggestedTermPart) {
            suggestedTermEls.push(unboldedSuggestedTermPart);
        }
        if (boldedSuggestedTermPart) {
            suggestedTermEls.push(
                <span key={`${suggestedTerm}${suggestedTermPart}${i}`} className={styles.boldTerm}>
                    {boldedSuggestedTermPart}
                </span>
            );
        }

        if (!boldedSuggestedTermPart && !unboldedSuggestedTermPart) {
            suggestedTermEls.push(
                <span key={`${suggestedTerm}${suggestedTermPart}${i}`} className={styles.boldTerm}>
                    {suggestedTermPart}
                </span>
            );
        }

        return suggestedTermEls;
    }, []);
};

export const SearchBarOverlayComponent = ({
    visible,
    suggestions,
    handleRowClick,
    cursorHighlightId,
    focusOnOverlayScroll = false,
    overlayClassName,
    canShowRecentlyViewed,
    searchTerm,
    user,
}) => {
    const intl = useIntl();
    const relayContext = useContext(ReactRelayContext);
    const { isMobile } = useContext(HeaderFooterServerVarsContext);

    const [recentlyViewedItems, setRecentlyViewedItems] = useState();
    const [userRecentHistoryItems, setUserRecentHistoryItems] = useState();

    if (relayContext && user !== null && !userRecentHistoryItems) {
        const { recentHistoryItems } =
            unmaskFragmentData({
                relayContext,
                relayData: user,
                fragment: userFragment.user,
            }) || {};

        // if recentHistoryItems is undefined that means that its still being fetched, in other falsy cases it will be null or empty arr
        if (recentHistoryItems !== undefined) {
            setUserRecentHistoryItems(recentHistoryItems?.edges || []);
        }
    }

    const showRecentlyViewedItems =
        canShowRecentlyViewed && recentlyViewedItems && !!recentlyViewedItems.length;
    const hasSuggestions =
        !!suggestions &&
        suggestions.find(suggestion => suggestion.terms && suggestion.terms.length);

    useEffect(() => {
        const recentHistoryItemsList = getRecentHistoryList({
            recentHistoryItems: (userRecentHistoryItems || []).map(({ node }) => node),
            isClient: true,
        });

        setRecentlyViewedItems(recentHistoryItemsList.slice(0, 5));
    }, [userRecentHistoryItems]);

    useEffect(() => {
        if (visible) {
            trackSellerBrandingRemovalAbTestVariant();
        }
    }, [visible]);

    const overlayClasses = classnames(styles.overlay, {
        [overlayClassName]: !!overlayClassName,
        [styles.isVisible]: visible,
    });

    if (!hasSuggestions && !showRecentlyViewedItems) {
        return null;
    }

    return (
        <div
            aria-live={visible ? 'assertive' : 'off'}
            data-tn="search-bar-overlay"
            className={overlayClasses}
            onTouchStart={focusOnOverlayScroll ? () => document.activeElement.blur() : () => {}}
        >
            {isMobile && showRecentlyViewedItems && (
                <SearchBarSection key="recentlyViewed" title={titleMessage}>
                    <SearchBarRecentlyViewedItems recentlyViewedItems={recentlyViewedItems} />
                </SearchBarSection>
            )}
            {suggestions.map(section => {
                const { title, urlSection } = getSectionPropsByType(section.type, intl);
                const { terms, type } = section;
                if (!terms || !terms.length) {
                    return null;
                }
                return (
                    <SearchBarSection key={title} title={title}>
                        {terms.map(({ term, urlLabel }, index) => {
                            const termElements =
                                type === TERM_SECTION_TYPE_RECENT
                                    ? term
                                    : getSuggestedTermElements(term, searchTerm);
                            const href = createSearchLink({ term, urlSection, urlLabel, type });
                            return (
                                <SearchBarTerm
                                    key={term}
                                    id={`${term}-${type}-${index}`}
                                    termElements={termElements}
                                    term={term}
                                    type={type}
                                    href={href}
                                    handleRowClick={handleRowClick}
                                    cursorHighlightId={cursorHighlightId}
                                />
                            );
                        })}
                    </SearchBarSection>
                );
            })}
            {!isMobile && showRecentlyViewedItems && (
                <SearchBarSection key="recentlyViewed" title={titleMessage}>
                    <SearchBarRecentlyViewedItems recentlyViewedItems={recentlyViewedItems} />
                </SearchBarSection>
            )}
        </div>
    );
};

SearchBarOverlayComponent.propTypes = {
    visible: PropTypes.bool,
    suggestions: PropTypes.array,
    focusOnOverlayScroll: PropTypes.bool,
    handleRowClick: PropTypes.func,
    cursorHighlightId: PropTypes.string,
    canShowRecentlyViewed: PropTypes.bool,
    searchTerm: PropTypes.string,
    user: PropTypes.object,
    overlayClassName: PropTypes.string,
};

export const SearchBarOverlay = SearchBarOverlayComponent;

import { FunctionComponent } from 'react';

import { FormattedMessage } from 'dibs-react-intl';
import { RelativeDateFormat } from 'dibs-relative-date-format/exports/RelativeDateFormat';

// components
import { PriceAmount, PriceAmountProps } from './PriceAmount';

interface Props extends PriceAmountProps {
    price?: number;
    expiration: string | null;
    showExpirationDate: boolean;
}

export const PrivateOfferItemPrices: FunctionComponent<Props> = ({
    price,
    currency,
    expiration,
    showExpirationDate,
    quantityDisplay,
    displayVatLabel,
    priceBookName,
}) => {
    const formattedExpiration = showExpirationDate && expiration && (
        <RelativeDateFormat value={expiration} />
    );
    const expirationNode = showExpirationDate && (
        <FormattedMessage
            id="price.privateOfferExpires"
            defaultMessage="Expires {expiration}"
            values={{ expiration: formattedExpiration }}
        />
    );

    return (
        <PriceAmount
            type="PRIVATE_OFFER"
            currency={currency}
            amount={price}
            expiration={expirationNode}
            showLabel
            quantityDisplay={quantityDisplay}
            displayVatLabel={displayVatLabel}
            priceBookName={priceBookName}
        />
    );
};

import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import { GridRow } from 'dibs-elements/exports/Grid';
import { InViewport } from 'dibs-in-viewport/exports/InViewport';

import HpSharedPromoModulePromoItem from './HpSharedPromoModulePromoItem';
import { HpSharedModuleContainer } from '../HpSharedModuleContainer/HpSharedModuleContainer';
import { trackPromoImpressions, trackPromoClick } from './helpers/PromoTracking';
import { trackModuleLocation, trackModule } from '../helpers/moduleLocationTracking';
import { HpSharedHeader } from '../HpSharedHeader/HpSharedHeader';

import { HpSharedPromoModulePrimary_componentModule$data } from './__generated__/HpSharedPromoModulePrimary_componentModule.graphql';

type Props = {
    componentModule: HpSharedPromoModulePrimary_componentModule$data;
    useLazyLoadImages: boolean;
    isMobile: boolean;
    moduleIndex: number;
    totalModules: number;
};

const HpSharedPromoModulePrimary: FC<Props> = ({
    componentModule,
    useLazyLoadImages,
    isMobile,
    moduleIndex,
    totalModules,
}) => {
    if (!componentModule) {
        return null;
    }

    const { messages, cmsDisplayTitle } = componentModule;
    const { title } = messages || {};
    const items = (messages?.items || []).filter(filterNulls);
    const isSinglePromo = items.length === 1;

    const handleInitialDisplay = ({ inViewport }: { inViewport: boolean }): void => {
        if (componentModule && inViewport) {
            trackPromoImpressions({ items, startingIndex: 0 });
        }
    };

    return (
        <HpSharedModuleContainer addTopGap={!title}>
            <HpSharedHeader title={title} />
            <InViewport stopWhenInViewport onInViewportChange={handleInitialDisplay}>
                <GridRow>
                    {items.map((promotion, index) => (
                        <HpSharedPromoModulePromoItem
                            key={promotion?.cmsId || index}
                            index={index}
                            promotion={promotion}
                            useLazyLoadImages={useLazyLoadImages}
                            onClick={() => {
                                trackPromoClick({ item: promotion, index });
                                trackModuleLocation({
                                    label: `promo banner #${index + 1}`,
                                    moduleIndex,
                                    totalModules,
                                });
                                trackModule(cmsDisplayTitle);
                            }}
                            isSinglePromo={isSinglePromo}
                            isMobile={isMobile}
                        />
                    ))}
                </GridRow>
            </InViewport>
        </HpSharedModuleContainer>
    );
};

export default createFragmentContainer(HpSharedPromoModulePrimary, {
    componentModule: graphql`
        fragment HpSharedPromoModulePrimary_componentModule on PromoModule {
            cmsDisplayTitle
            messages(userType: $userType) {
                title
                items {
                    cmsId
                    linkData {
                        # path is used in PromoTrackingOld.js
                        path
                    }
                    ...HpSharedPromoModulePromoItem_promotion
                }
            }
        }
    `,
});

import { useId, FC, ReactNode } from 'react';

import styles from './LinkColumn.scss';

type Props = {
    title: ReactNode;
    children: ReactNode;
};

const LinkColumn: FC<Props> = ({ title, children }) => {
    const labelId = `${useId()}-label`;

    return (
        <div className={styles.column}>
            <div id={labelId} className={styles.title}>
                {title}
            </div>
            <ul aria-labelledby={labelId} className={styles.list}>
                {children}
            </ul>
        </div>
    );
};

export default LinkColumn;

/**
 * @generated SignedSource<<354d8102102d6276cb4132f416957e2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecentlyViewedItemsHeart_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useRecentHistoryList_user">;
  readonly " $fragmentType": "RecentlyViewedItemsHeart_user";
};
export type RecentlyViewedItemsHeart_user$key = {
  readonly " $data"?: RecentlyViewedItemsHeart_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedItemsHeart_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "excludeItemPks"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecentlyViewedItemsHeart_user",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "count",
          "variableName": "count"
        },
        {
          "kind": "Variable",
          "name": "excludeItemPks",
          "variableName": "excludeItemPks"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useRecentHistoryList_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "8d1e97ca95313b6bfed392e7bbd7b986";

export default node;

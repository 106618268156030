import foldToASCII from './fold-to-ascii';

export default function slugify(str: string | null | undefined): string {
    if (!str) {
        return '';
    }

    return (
        foldToASCII(str.trim().toLowerCase())
            // Replace & symbols to `and`
            .replace(/&/g, 'and')
            // Remove all non alphanumeric characters except dash and whitespace from a string
            .replace(/[^a-z0-9\s-]/g, '')
            // Replace any multiple whitespaces and dashes to single dash
            .replace(/(\s|-)+/g, '-')
            // Remove dashes from beginning and the end of the string
            .replace(/^-|-$/g, '')
    );
}

/**
 * Helper to set the transform property. Sets any vendor prefixes necessary.
 * @param {Object} style      The style object to mutate.
 * @param {String} transform CSS string to add vendor prefixes to.
 */
function setTransformStyle(style, transform) {
    style.transform = transform;
    style.WebkitTransform = transform;
}

function setTransformOriginStyle(style, transformOrigin) {
    style.transformOrigin = transformOrigin;
}

/**
 * Helper to set the transition property. Sets any vendor prefixes necessary.
 * @param {Object} style      The style object to mutate.
 * @param {String} transition CSS string to add vendor prefixes to.
 */
function setTransitionStyle(style, transition) {
    style.transition = transition;
    style.WebkitTransition = '-webkit-' + transition;
}

/**
 * Simple helper to set the width of items.
 * @param {[type]} style [description]
 * @param {[type]} width [description]
 */
function setWidthStyle(style, width) {
    // Need both min and max width for this to work consistently.
    style.minWidth = `${width}%`;
    style.maxWidth = `${width}%`;
}

/**
 * Calculate the total number of pages in a paged carousel.
 * @param  {Number} itemCount    Total number of items in the carousel.
 * @param  {Number} itemsPerPage Items to display per page.
 * @return {Number}
 */
function getTotalPages(itemCount, itemsPerPage, hasInfiniteLoop) {
    if (hasInfiniteLoop) {
        return Math.ceil(itemCount / itemsPerPage);
    } else {
        return Math.ceil(itemCount / Math.floor(itemsPerPage));
    }
}

/**
 * Get the default width pf items in a paged carousel.
 * @param  {Number} itemsPerPage Items to display per page.
 * @return {Number}
 */
function getPagedItemWidth(itemsPerPage) {
    return 100 / itemsPerPage;
}

/**
 * Normalize page number
 * @param  {Number} itemsPerPage Items to display per page.
 * @return {Number}
 */
function normalizePage(page, totalPages, hasInfiniteLoop) {
    // If the requested page is less than 0, only go to the first page.
    if (page < 0) {
        page = 0;
    }

    // If the requested page is greater than the last page, only go to the last page.
    if (page >= totalPages) {
        page = totalPages;
    }

    /**
     * Because of the cloned infinite swiper pages, carousels with infinite loops are
     * shifted by one page.
     */
    if (hasInfiniteLoop) {
        page++;
    }

    return page;
}

export {
    setTransformStyle,
    setTransitionStyle,
    setWidthStyle,
    normalizePage,
    getTotalPages,
    getPagedItemWidth,
    setTransformOriginStyle,
};

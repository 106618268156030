import { addUserToAutopilotContacts } from '../mutations/addUserToAutopilotContacts';
import { fetchQuery_DEPRECATED as fetchQuery, Environment } from 'react-relay/legacy';
import { sessionStorage } from 'dibs-browser-storage';
import { initAutopilotQuery } from './initAutopilotQuery';
import { initAutopilotQuery as Query } from './__generated__/initAutopilotQuery.graphql';

export const AUTOPILOT_SESSION_STORAGE_KEY = 'userLoggedToAutopilot';

const addUserToContacts = async ({
    environment,
    userId,
    sessionId,
}: {
    environment: Environment;
    userId: string;
    sessionId: string;
}): Promise<void> => {
    try {
        await addUserToAutopilotContacts(environment, {
            userId,
            sessionId,
        });
        if (window.Autopilot.GDPROptedIn === 'true') {
            sessionStorage.setItem(AUTOPILOT_SESSION_STORAGE_KEY, true);
        }
    } catch (err) {
        console.log(err);
    }
};

const initAutopilot = async ({
    environment,
    userId,
}: {
    environment: Environment;
    userId: string;
}): Promise<void> => {
    if (!userId || sessionStorage.getItem(AUTOPILOT_SESSION_STORAGE_KEY)) {
        return;
    }
    const response = await fetchQuery<Query>(environment, initAutopilotQuery, {
        anchors: [`USER-${userId}`],
    });

    if (response.viewer?.disableAutopilotIntegration || !response.viewer?.anchorLists?.length) {
        return;
    }

    let sessionId: string;

    const interval = setInterval(() => {
        sessionId = window.Autopilot?.sessionId || window.AutopilotAnywhere?.sessionId;

        if (sessionId) {
            // if user is logged in GDPROptedIn has to be set to true
            // so that sessionId would not change during session
            if (window.Autopilot.GDPROptedIn === 'false') {
                // eslint-disable-next-line new-cap
                window.Autopilot.GDPRCookieOptIn();
            }

            addUserToContacts({ environment, userId, sessionId });
            clearInterval(interval);
        }
    }, 3000);
};

export default initAutopilot;

export const sendAnalyticsData = (url: RequestInfo | URL, body: unknown): void => {
    if (typeof fetch !== 'undefined') {
        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            keepalive: true, // see https://developer.mozilla.org/en-US/docs/Web/API/fetch#keepalive
            body: JSON.stringify(body),
            // eslint-disable-next-line no-console
        }).catch(e => console.log(`Error sending performance analytics: ${e}`));
    }
};

export const supportsPerformanceAPI =
    typeof performance !== 'undefined' &&
    typeof performance.getEntriesByName === 'function' &&
    typeof performance.mark === 'function' &&
    typeof performance.measure === 'function';

export const supportsPerformanceObserver =
    supportsPerformanceAPI &&
    typeof PerformanceObserver !== 'undefined' &&
    typeof PerformanceObserver.prototype.observe === 'function' &&
    Array.isArray(PerformanceObserver.supportedEntryTypes) &&
    PerformanceObserver.supportedEntryTypes.includes('measure');

import { getTouchDistance, touchToCoordinates, getTouchCenterCoord } from './utils';
import MultiTouchDetector from './MultiTouchDetector';
const noop = () => {};

function distanceFromTouches(e) {
    const { target, touches } = e;

    return getTouchDistance(
        touchToCoordinates(target, touches[0]),
        touchToCoordinates(target, touches[1])
    );
}

function centerCoordFromTouches(e) {
    const { target, touches } = e;

    return getTouchCenterCoord(
        touchToCoordinates(target, touches[0]),
        touchToCoordinates(target, touches[1])
    );
}

/**
 * Detect a pinch on the given DOM element.
 * @param {Object} options.el The DOM element.
 * @param {Function} [options.onZoomIn] Callback to fire on zooming in.
 * @param {Function} [options.onZoomOut] Callback to fire on zooming out.
 */
class PinchDetector {
    constructor({ el, onPinch = noop, onZoomIn = noop, onZoomOut = noop } = {}) {
        if (!el) {
            throw new Error('Must pass `el` to PinchDetector');
        }

        Object.assign(this, {
            _previousDistance: null,
            _el: el,
            _onPinch: onPinch,
            _onZoomIn: onZoomIn,
            _onZoomOut: onZoomOut,
            _multiTouchDetector: new MultiTouchDetector({
                onDoubleTouchStart: this._onDoubleTouchStart.bind(this),
                onDoubleTouchMove: this._onDoubleTouchMove.bind(this),
                el,
            }),
        });
    }

    _onDoubleTouchStart(e) {
        // Get the initial position and time so we can do a difference on them later.
        this._previousDistance = distanceFromTouches(e);

        const { x: centerCoordX, y: centerCoordY } = centerCoordFromTouches(e);

        this._onPinch(centerCoordX, centerCoordY);
    }

    _onDoubleTouchMove(e, isPassive) {
        // preventDefault on a passive listener does nothing and logs warning.
        if (!isPassive) {
            e.preventDefault();
        }

        const distance = distanceFromTouches(e);
        const change = this._previousDistance - distance;
        const absChange = Math.abs(change);
        const { x: centerCoordX, y: centerCoordY } = centerCoordFromTouches(e);

        this._onPinch(centerCoordX, centerCoordY);

        // Check if this is a zoom in or a zoom out based on the sign of the change and fire the appropriate callback.
        if (change < 0) {
            this._onZoomIn(absChange);
        } else {
            this._onZoomOut(absChange);
        }

        this._previousDistance = distance;
    }

    destroy() {
        this._multiTouchDetector.destroy();
    }
}

export default PinchDetector;

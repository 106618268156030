import commitMutation from 'relay-commit-mutation-promise';
import { fetchQuery_DEPRECATED as fetchQuery, graphql } from 'react-relay/legacy';
import { getBuyerId } from 'dibs-cookie-jar';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';

const verifyEmailHandlerQuery = graphql`
    query verifyEmailHandlerQuery(
        $email: String = ""
        $buyerId: String = ""
        $hasBuyerId: Boolean!
    ) {
        viewer {
            user(userId: $buyerId) @include(if: $hasBuyerId) {
                profile {
                    email
                }
            }
            userExistsMetadata(email: $email) {
                emailOnly
                hasPassword
            }
        }
    }
`;

const mutation = graphql`
    mutation verifyEmailHandlerMutation($input: TransactionVerifyEmailMutationInput!) {
        transactionVerifyEmail(input: $input) {
            __typename
        }
    }
`;

function verifyEmailHandlerMutation(environment, input) {
    return commitMutation(environment, {
        mutation,
        variables: { input },
    });
}

export async function verifyEmailHandler({
    environment,
    flow,
    email: transactionEmail,
    destination = '',
    emailVerificationToken,
}) {
    const buyerId = getBuyerId(document.cookie);

    async function verifyAndRedirect(redirectUrl) {
        try {
            await verifyEmailHandlerMutation(environment, {
                emailVerificationToken,
            });
        } catch (e) {
            // TODO: add in new relic logging if verification fails?
            // eslint-disable-next-line no-console
            console.error(e);
        }
        const { pathname, search } = window.location;
        // only redirect to new pages
        if (destination !== `${pathname}${search}`) {
            window.location.href = handleLocaleUrl(redirectUrl, GLOBAL_CLIENT_ONLY_LOCALE);
        }
    }

    const data = await fetchQuery(environment, verifyEmailHandlerQuery, {
        email: transactionEmail,
        buyerId,
        hasBuyerId: !!buyerId,
    });

    const viewer = data?.viewer || {};
    const loggedInEmail = viewer.user?.profile?.email;
    const { emailOnly, hasPassword } = viewer.userExistsMetadata || {};
    // 1) VERIFY AND REDIRECT WITH CONFIRMATION (full account logged in correct email address)
    if (hasPassword && buyerId && loggedInEmail === transactionEmail) {
        // verify all orders tied to email address
        verifyAndRedirect(`${destination}?showOrderConfirmationModal=true`);
    }
    // 2) PROMPT FOR LOGIN THEN VERIFY THEN REDIRECT (full account logged in wrong email address / full account logged out)
    else if (hasPassword && ((buyerId && loggedInEmail !== transactionEmail) || !buyerId)) {
        const { userId } = await authModalLoader.show({
            action: authModalLoader.constants.LOGIN,
            flow,
            email: transactionEmail,
            additionalProps: {
                forceVerifyEmailFlow: true,
            },
        });
        if (userId) {
            verifyAndRedirect(destination);
        }
    }
    // 3) PROMPT FOR CREATE PW THEN VERIFY THEN REDIRECT (email only)
    else if (emailOnly) {
        const { userId } = await authModalLoader.show({
            action: authModalLoader.constants.RESET_PASSWORD,
            flow,
            email: transactionEmail,
        });
        if (userId) {
            verifyAndRedirect(destination);
        }
    }
}

/**
 * @generated SignedSource<<08078c3db609c13f9d32087c7efc4ac8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuickViewCategoryParagraph_itemSearch$data = {
  readonly meta: {
    readonly header: string | null;
  } | null;
  readonly pageType: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"useValidFilter_itemSearch">;
  readonly " $fragmentType": "QuickViewCategoryParagraph_itemSearch";
};
export type QuickViewCategoryParagraph_itemSearch$key = {
  readonly " $data"?: QuickViewCategoryParagraph_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuickViewCategoryParagraph_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuickViewCategoryParagraph_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MetaData",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "header",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useValidFilter_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "fec20a355d6fd22da3794bbcba4ea9fd";

export default node;

import { FunctionComponent } from 'react';

type Props = {
    layout: string;
    renderTile: (key: string) => JSX.Element | null;
    gap?: number;
    autoAspectRatio?: boolean;
    rowHeight?: number;
};

export const Mosaic: FunctionComponent<Props> = props => {
    const { layout, renderTile, gap, autoAspectRatio, rowHeight } = props;

    const gridTemplateAreas = layout
        .split('\n')
        .map(i => i.trim())
        .filter(i => !!i);

    const keyRegister: {
        [key: string]: {
            distinctColIndexes: number[];
            distinctRowIndexes: number[];
        };
    } = {};

    let rowsCount = 0;
    let colsCount = 0;

    gridTemplateAreas.forEach((line, rowIndex) => {
        rowsCount = Math.max(rowsCount, rowIndex + 1);

        const keys = line.split(' ');
        keys.forEach((key, colIndex) => {
            colsCount = Math.max(colsCount, colIndex + 1);

            keyRegister[key] = keyRegister[key] || {
                distinctColIndexes: [],
                distinctRowIndexes: [],
            };

            if (!keyRegister[key].distinctColIndexes.includes(colIndex)) {
                keyRegister[key].distinctColIndexes.push(colIndex);
            }

            if (!keyRegister[key].distinctRowIndexes.includes(rowIndex)) {
                keyRegister[key].distinctRowIndexes.push(rowIndex);
            }
        });
    });

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: '1fr '.repeat(colsCount),
                gridTemplateRows: `${rowHeight ? `${rowHeight}px ` : '1fr '}`.repeat(rowsCount),
                gridTemplateAreas: `'${gridTemplateAreas.join("' '")}'`,
                gap,
            }}
        >
            {Object.keys(keyRegister).map(key => {
                const width = keyRegister[key].distinctColIndexes.length;
                const height = keyRegister[key].distinctRowIndexes.length;
                const ratio = autoAspectRatio ? `${width} / ${height}` : 'auto';

                return (
                    <div
                        key={key}
                        style={{
                            aspectRatio: ratio,
                            gridArea: `${key}`,
                        }}
                    >
                        {renderTile(key)}
                    </div>
                );
            })}
        </div>
    );
};

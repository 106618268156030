import { Component, Fragment, ReactNode, ReactElement } from 'react';
import { findDOMNode } from 'react-dom';
import debounce from 'lodash.debounce';

import { parentScroll } from '../src/utils/parentScroll';
import { inViewport } from '../src/utils/inViewport';

type State = {
    inViewport: boolean;
};
type Props = {
    children: ((state: State) => ReactElement) | ReactElement;
    onInViewportChange?: (state: State) => void;
    stopWhenInViewport?: boolean;
};
class InViewport extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.inViewportHandler = debounce(this.inViewportHandler, 250);
    }

    state = {
        inViewport: false,
    };

    componentDidMount(): void {
        const eventNode = this.getEventNode();
        this.inViewportHandler();

        window.addEventListener('resize', this.inViewportHandler);
        eventNode.addEventListener('scroll', this.inViewportHandler);
    }

    componentWillUnmount(): void {
        this.detachListeners();
    }

    getEventNode(): HTMLElement | (Window & typeof globalThis) {
        return parentScroll(findDOMNode(this));
    }

    inViewportHandler = (): void => {
        const node = findDOMNode(this);
        const eventNode = this.getEventNode();

        if (inViewport(node, eventNode)) {
            const { stopWhenInViewport } = this.props;
            if (stopWhenInViewport) {
                this.detachListeners();
            }

            this.handleState({ inViewport: true });
        } else {
            this.handleState({ inViewport: false });
        }
    };

    handleState(state: State = { inViewport: false }): void {
        const { onInViewportChange } = this.props;

        this.setState(state, () => {
            if (onInViewportChange) {
                onInViewportChange(state);
            }
        });
    }

    detachListeners(): void {
        const eventNode = this.getEventNode();

        window.removeEventListener('resize', this.inViewportHandler);
        eventNode.removeEventListener('scroll', this.inViewportHandler);
    }

    render(): ReactNode {
        const { children } = this.props;

        return (
            <Fragment>{typeof children === 'function' ? children(this.state) : children}</Fragment>
        );
    }
}

export { InViewport };

import { sessionStorage } from 'dibs-browser-storage';
import { getBuyerId } from 'dibs-cookie-jar';
import { VisitCountModel } from 'dibs-visit-tracking/exports/visitCountModel';
import { getStoredTradeFirmId } from '../utils/userTracking';
import authModalLoader from '../utils/AuthModalLoader';
import { wasUserRedirectedByEmailLoginLink } from '../notificationQueue/globalNotifications/helpers/wasUserRedirectedByEmailLoginLink';

import { isTradeCampaign } from './queryParamsHelpers';

const TRADE_CAMPAIGN_LOCAL_STORAGE_KEY = 'isCampaignTrade';

export const isLoggedOutTrade = () => {
    if (getBuyerId(document.cookie)) {
        return false;
    }

    switch (true) {
        case !!getStoredTradeFirmId() && getStoredTradeFirmId() !== 'undefined':
            return true;
        case !!sessionStorage.getItem(TRADE_CAMPAIGN_LOCAL_STORAGE_KEY):
            return true;
        default:
            return false;
    }
};

export const setTradeCampaign = () => {
    if (isTradeCampaign()) {
        sessionStorage.setItem(TRADE_CAMPAIGN_LOCAL_STORAGE_KEY, true);
    }
};

export const shouldShowTradeLogin = () => {
    const visitCountModel = new VisitCountModel();
    const isAtSessionCount =
        visitCountModel.get('session') === visitCountModel.get('regPromptLimit');
    const wasRedirectedByEmailLoginLink = wasUserRedirectedByEmailLoginLink();

    return !wasRedirectedByEmailLoginLink && isAtSessionCount && isLoggedOutTrade();
};

export const initTradeLogin = () => {
    const { constants } = authModalLoader;

    authModalLoader.show({
        action: constants.LOGIN,
        flow: constants.LOGGED_OUT_TRADE_FLOW,
        ga: {
            label: 'third pageview trade login',
        },
        fireEntryGa: true,
    });
};

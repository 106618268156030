import { FC, ReactNode, CSSProperties, useState, useRef, useCallback } from 'react';
import classnames from 'classnames';

import { FormattedMessage, defineMessages, useIntl } from 'dibs-react-intl';
import ChatAnimation from 'dibs-icons/exports/legacy/ChatAnimation';
import { useMutationObserver } from 'dibs-react-hooks/exports/useMutationObserver';

import { getSfChatContainer } from './helpers/sfChatContainer';
import { SALESFORCE_CHAT_KEY } from './constants';

import styles from './styles/PreChat.scss';

const messages = defineMessages({
    startLiveChat: {
        id: 'getHelp.liveChat.startLiveChat',
        defaultMessage: 'Start live chat',
    },
});

const SF_EMBEDDED_CHAT_WINDOW_CLASS = 'embeddedServiceSidebar';
const SF_EMBEDDED_CHAT_WINDOW_MINIMIZED_CLASS = 'sidebarMinimized';

const PreChatContainer: FC<{ children: ReactNode; className?: string; style?: CSSProperties }> = ({
    children,
    className,
    style,
}) => (
    <div className={classnames(styles.container, className)} style={style}>
        {children}
    </div>
);

const PreChatButton: FC<{
    onClick: () => void;
    showLoadingAnimation?: boolean;
    showEnterAnimation?: boolean;
}> = ({ onClick, showLoadingAnimation, showEnterAnimation = true }) => {
    const { formatMessage } = useIntl();
    const [hidden, setHidden] = useState(!!sessionStorage.getItem(SALESFORCE_CHAT_KEY));
    const sfContainerRef = useRef(getSfChatContainer());

    const observerCallback = useCallback(
        (mutations: MutationRecord[], observer: MutationObserver) => {
            for (const { type, target, addedNodes, removedNodes } of mutations) {
                if (type === 'childList') {
                    for (const addedNode of addedNodes) {
                        if (addedNode instanceof HTMLElement) {
                            if (addedNode.classList.contains(SF_EMBEDDED_CHAT_WINDOW_CLASS)) {
                                /**
                                 * Start listening for className changes on SF chat window container
                                 */
                                observer.observe(addedNode, {
                                    attributes: true,
                                    attributeFilter: ['class'],
                                });
                                break;
                            }
                        }
                    }
                    for (const removedNode of removedNodes) {
                        if (removedNode instanceof HTMLElement) {
                            if (removedNode.classList.contains(SF_EMBEDDED_CHAT_WINDOW_CLASS)) {
                                /**
                                 * Show pre-chat button once chat session is terminated
                                 */
                                setHidden(false);
                                break;
                            }
                        }
                    }
                }

                if (type === 'attributes') {
                    if (hidden) {
                        break;
                    }

                    if (target instanceof HTMLElement) {
                        if (target.classList.contains(SF_EMBEDDED_CHAT_WINDOW_MINIMIZED_CLASS)) {
                            /**
                             * Hide pre-chat button once SF chat window is minimized,
                             * active chat button (injected by SF script) has minor
                             * positioning lag when scrolling on mobile devices and
                             * there are instances when both buttons are visible
                             */
                            setHidden(true);
                        }
                    }
                }
            }
        },
        [hidden]
    );

    useMutationObserver({
        ref: sfContainerRef,
        callback: observerCallback,
        /**
         * Initially only listen for changes on direct children
         */
        options: {
            childList: true,
        },
    });

    return (
        // eslint-disable-next-line react/forbid-elements
        <button
            className={classnames(styles.button, {
                [styles.enter]: showEnterAnimation,
                [styles.hidden]: hidden,
            })}
            aria-label={formatMessage(messages.startLiveChat)}
            type="button"
            onClick={onClick}
        >
            <ChatAnimation className={styles.chatIcon} showAnimation={showLoadingAnimation} />
            <span className={styles.chatLabel}>
                <FormattedMessage id="getHelp.LiveChat.buttonText" defaultMessage="Support" />
            </span>
        </button>
    );
};

/**
 * `Pre-chat` button to match minimised SF chat button styles
 *
 * ```tsx
 * <PreChat.Container>
 *   <PreChat.Button />
 * </PreChat.Container>
 * ```
 */
const PreChat = {
    /**
     * Container that matches position of minimised SF chat button
     */
    Container: PreChatContainer,
    /**
     * Button that matches minimised SF chat button
     */
    Button: PreChatButton,
};

export default PreChat;

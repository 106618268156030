import { useFragment, graphql } from 'react-relay';
import { useValidFilter_itemSearch$key as ItemSearchRef } from './__generated__/useValidFilter_itemSearch.graphql';

export const VALID_FILTER = {
    PERIOD_GROUP_FILTER: 'periodGroup',
    ESTATE_FILTER: 'estate',
    TOP_DESIGNER_FILTER: 'topDesigner',
} as const;
const VALID_FILTER_VALUES = Object.values(VALID_FILTER);
export type ValidFilterKey = keyof typeof VALID_FILTER;
export type ValidFilterValue = (typeof VALID_FILTER)[ValidFilterKey];

export type AppliedFilter = {
    readonly name: string | null;
    readonly values:
        | readonly ({
              readonly displayName: string | null;
          } | null)[]
        | null;
};

export function useValidFilter({
    itemSearch: itemSearchRef,
}: {
    itemSearch: ItemSearchRef | null | undefined;
}): AppliedFilter | null {
    const itemSearch = useFragment(
        graphql`
            fragment useValidFilter_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                    values {
                        displayName
                    }
                }
            }
        `,
        itemSearchRef
    );
    const validFilter = itemSearch?.appliedFilters?.find(
        filter => filter?.name && VALID_FILTER_VALUES.includes(filter.name as ValidFilterValue)
    );

    return validFilter || null;
}

/**
 * @generated SignedSource<<c85dcb004a832f365d8d2349ea164c98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSearchSuggestions_viewer$data = {
  readonly isPopularSearchesEnabledByLocale: boolean | null;
  readonly searchSuggestions?: {
    readonly suggestions: ReadonlyArray<{
      readonly terms: ReadonlyArray<{
        readonly term: string | null;
        readonly urlLabel: string | null;
      } | null> | null;
      readonly type: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "useSearchSuggestions_viewer";
};
export type useSearchSuggestions_viewer$key = {
  readonly " $data"?: useSearchSuggestions_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSearchSuggestions_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "hasQuery"
    },
    {
      "kind": "RootArgument",
      "name": "includeTypes"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useSearchSuggestions_viewer",
  "selections": [
    {
      "alias": "isPopularSearchesEnabledByLocale",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "POPULAR_SEARCHES"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"POPULAR_SEARCHES\")"
    },
    {
      "condition": "hasQuery",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "includeTypes",
              "variableName": "includeTypes"
            },
            {
              "kind": "Variable",
              "name": "query",
              "variableName": "query"
            }
          ],
          "concreteType": "SearchSuggestion",
          "kind": "LinkedField",
          "name": "searchSuggestions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SearchSuggestionList",
              "kind": "LinkedField",
              "name": "suggestions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SearchSuggestionTerm",
                  "kind": "LinkedField",
                  "name": "terms",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "term",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "urlLabel",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "36250f006f308eda3e15040a5efe5c0b";

export default node;

/**
 * @generated SignedSource<<e855f074afe9b8e1c461c2bc3a6bf6e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedLayout_viewer$data = {
  readonly buyerActivePrivateOffers?: {
    readonly totalResults: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"HpMobilePrivateOffers_buyerActivePrivateOffers" | "HpRespPrivateOffersToast_buyerActivePrivateOffers" | "abTestOffersOnHp_buyerActivePrivateOffers">;
  } | null;
  readonly homepage: {
    readonly modules: ReadonlyArray<{
      readonly index?: number | null;
      readonly messages?: {
        readonly items: ReadonlyArray<{
          readonly __typename: "PromoItem";
        } | null> | null;
      } | null;
      readonly type?: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"HpSharedCollectionSpotlightModule_componentModule" | "HpSharedCollectionsModule_componentModule" | "HpSharedEditorialModule_componentModule" | "HpSharedFeaturedModule_componentModule" | "HpSharedHeroModule_componentModule" | "HpSharedLocationsModule_componentModule" | "HpSharedPersonalizedCategories_componentModule" | "HpSharedPromoModulePrimary_componentModule" | "HpSharedPromoModuleSecondary_componentModule" | "HpSharedRecentActivityModule_componentModule" | "HpSharedRecentlyViewedItems_componentModule" | "HpSharedRecommendedItems_componentModule" | "HpSharedShopByModule_componentModule" | "HpSharedTopHeroBannerModule_componentModule">;
    } | null> | null;
    readonly personalization?: {
      readonly " $fragmentSpreads": FragmentRefs<"HpSharedPersonalizedCategories_personalization">;
    } | null;
  } | null;
  readonly user?: {
    readonly hasLoggedInWithApp: boolean | null;
    readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroModule_user" | "HpSharedLocationsModule_user">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroModule_viewer" | "HpSharedLocationsModule_viewer" | "HpSharedRecentActivityModule_viewer" | "HpSharedRecommendedItems_viewer">;
  readonly " $fragmentType": "HpSharedLayout_viewer";
};
export type HpSharedLayout_viewer$key = {
  readonly " $data"?: HpSharedLayout_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedLayout_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "abTestList"
    },
    {
      "kind": "RootArgument",
      "name": "activePrivateOfferCount"
    },
    {
      "kind": "RootArgument",
      "name": "fetchBuyerActiveOffers"
    },
    {
      "kind": "RootArgument",
      "name": "fetchInitialHomepage"
    },
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "homepageId"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    },
    {
      "kind": "RootArgument",
      "name": "userType"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedLayout_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "abTestList",
          "variableName": "abTestList"
        },
        {
          "kind": "Variable",
          "name": "rootId",
          "variableName": "homepageId"
        }
      ],
      "concreteType": "Homepage",
      "kind": "LinkedField",
      "name": "homepage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/)
              ],
              "type": "SkinnyBannerModule",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "HpSharedTopHeroBannerModule_componentModule"
                }
              ],
              "type": "TopHeroBannerModule",
              "abstractKey": null
            },
            {
              "condition": "fetchInitialHomepage",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "HpSharedHeroModule_componentModule"
                    }
                  ],
                  "type": "HeroModule",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "HpSharedCollectionsModule_componentModule"
                    }
                  ],
                  "type": "CollectionsModule",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "HpSharedFeaturedModule_componentModule"
                    }
                  ],
                  "type": "FeaturedModule",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "HpSharedEditorialModule_componentModule"
                    }
                  ],
                  "type": "EditorialModule",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "HpSharedShopByModule_componentModule"
                    }
                  ],
                  "type": "ShopByModule",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "HpSharedPersonalizedCategories_componentModule"
                    }
                  ],
                  "type": "ArrivalsModule",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "HpSharedRecentlyViewedItems_componentModule"
                    }
                  ],
                  "type": "RecentlyViewedModule",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "HpSharedRecentActivityModule_componentModule"
                    }
                  ],
                  "type": "RecentActivityModule",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "HpSharedRecommendedItems_componentModule"
                    }
                  ],
                  "type": "RecommendedItemsModule",
                  "abstractKey": null
                }
              ]
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "index",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "userType",
                      "variableName": "userType"
                    }
                  ],
                  "concreteType": "PromoModuleMessages",
                  "kind": "LinkedField",
                  "name": "messages",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PromoItem",
                      "kind": "LinkedField",
                      "name": "items",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "HpSharedPromoModulePrimary_componentModule"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "HpSharedPromoModuleSecondary_componentModule"
                }
              ],
              "type": "PromoModule",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "HpSharedLocationsModule_componentModule"
                }
              ],
              "type": "LocationsModule",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "HpSharedCollectionSpotlightModule_componentModule"
                }
              ],
              "type": "CollectionSpotlightModule",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "condition": "hasUserId",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 3
                },
                {
                  "kind": "Literal",
                  "name": "numItems",
                  "value": 3
                },
                (v1/*: any*/)
              ],
              "concreteType": "PersonalizationModuleConnection",
              "kind": "LinkedField",
              "name": "personalization",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "HpSharedPersonalizedCategories_personalization"
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HpSharedHeroModule_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HpSharedLocationsModule_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HpSharedRecentActivityModule_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HpSharedRecommendedItems_viewer"
    },
    {
      "condition": "hasUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            (v1/*: any*/)
          ],
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": "hasLoggedInWithApp",
              "args": [
                {
                  "kind": "Literal",
                  "name": "deviceType",
                  "value": "IOS_APP"
                }
              ],
              "kind": "ScalarField",
              "name": "hasLoggedInWithDeviceType",
              "storageKey": "hasLoggedInWithDeviceType(deviceType:\"IOS_APP\")"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HpSharedHeroModule_user"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HpSharedLocationsModule_user"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "fetchBuyerActiveOffers",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "activePrivateOfferCount"
            },
            {
              "kind": "Literal",
              "name": "page",
              "value": 0
            },
            (v1/*: any*/)
          ],
          "concreteType": "BuyerActivePrivateOffersConnection",
          "kind": "LinkedField",
          "name": "buyerActivePrivateOffers",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "abTestOffersOnHp_buyerActivePrivateOffers"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HpRespPrivateOffersToast_buyerActivePrivateOffers"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HpMobilePrivateOffers_buyerActivePrivateOffers"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalResults",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "7f050057fe880fe64034e9f81f0049bf";

export default node;

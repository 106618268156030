import { trackEvent } from 'dibs-tracking';

type trackModuleLocationArgs = {
    moduleIndex?: number;
    totalModules?: number;
    action: string;
};

export const trackModuleLocation = ({
    moduleIndex,
    totalModules,
    action,
}: trackModuleLocationArgs): void => {
    const actionField: { moduleLocation?: string } = {};
    if (moduleIndex && totalModules) {
        actionField.moduleLocation = `${moduleIndex + 1}/${totalModules}`;
    }
    trackEvent({
        category: 'promo interaction',
        action,
        label: 'Recommended for you module',
        ...actionField,
    });
};

type trackModuleArgs = {
    label: string | null;
    action?: string;
};

export const trackModule = ({ label, action }: trackModuleArgs): void => {
    if (label) {
        trackEvent({
            category: 'promo interaction',
            action,
            label,
            noninteraction: 0,
        });
    }
};

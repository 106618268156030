export const SERVER_GRAPHQL_PATH = '/graphql/';
export const GRAPHQL_PATH = '/soa' + SERVER_GRAPHQL_PATH;
export const QUERY_STRING_HEADER = 'x-dibs-query-params';

export const PASS_THROUGH_PARAM_PREFIXES = [
    'enabler-',
    'featureFlag-',
    'userAbTest-',
    'abtests',
] as const;

import { getAbTestV2, trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';

export const HIDE_MW_MARKETING_BANNER = 'hideMwMarketingBanner' as const;

export const isHideMwMarketingBannerTestVariant = (): boolean => {
    return getAbTestV2(HIDE_MW_MARKETING_BANNER)?.variant === 'variant';
};

export const trackHideMwMarketingBannerAbTestVariant = (): void => {
    trackAbTestV2Variant(HIDE_MW_MARKETING_BANNER);
};

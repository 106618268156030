import { lazy, Suspense } from 'react';

import { getEverLoggedIn } from 'dibs-cookie-jar';

import getRelayEnvironment from '../relayClientEnvironment';
import * as constants from '../authModal/authentication/authFlowConstants';
import { steps as postAuthSteps } from '../authModal/postAuth/constants';
import asyncAuth from '../authModal/async';
import LoadingModal from '../sharedModalComponents/LoadingModal';
import { getInitialState } from '../authModal/authentication/authReducer';

let Auth;
let AuthContent;

let authModalLoader = {
    /**
     * Splits the AuthModal to a separate chunk during build time. Loads the authModal when called.
     * For now there's no way to handle errors. Should be possible when we switch to webpack 2.
     * This is mostly an espace hatch if you need direct access to the authModal instance.
     *
     * @example
     * authModalLoader.load().then(AuthModal => DO STUFF)
     *
     * @returns {Promise} - A promise that resolves with the AuthModal module.
     */
    load() {
        return asyncAuth().then(mod => {
            Auth = mod;

            return mod;
        });
    },

    /**
     * returns true or false depending upon whether the current auth modal instance is in a paused state
     * for more info about pausing see `shouldPauseAuthFlow in` authentication/readme.md
     */
    isPaused() {
        if (!Auth) {
            return false;
        }
        return Auth.getAuthModalState().isPaused;
    },

    /**
     * returns true or false depending upon whether the current auth content instance is in a paused state
     * for more info about pausing see `shouldPauseAuthFlow in` authentication/readme.md
     */
    isContentPaused() {
        if (!Auth) {
            return false;
        }
        return Auth.getAuthContentState().isPaused;
    },

    /**
     * resumes the auth flow wherever it last left off (which is derived from the savedState field in the auth reducer)
     * similar functionality to `show`, but uses the already existing Auth instance instead of initializing a new one
     */
    resume() {
        if (!Auth) {
            throw new Error(
                'Auth Modal cannot resume if it has not been started... use `authModalLoader.show` instead!'
            );
        }
        const savedState = Auth.getAuthModalState().savedState;
        return new Promise(resolve => {
            this._launchAuth({
                action: savedState.modal,
                options: { ...savedState, step: savedState.step + 1 },
                resolve,
            });
        }).then(authResult => {
            const isRegister = authResult.modal === constants.REGISTER;
            //LoginMutation and RegistrationMutation passes isNewUser, newUser deprecated
            authResult = { ...authResult, newUser: isRegister };
            return authResult;
        });
    },

    /**
     * get default auth action depending on "eli" cookie.
     */
    getDefaultAction() {
        const everLoggedIn = getEverLoggedIn(document.cookie);
        return everLoggedIn ? constants.LOGIN : constants.REGISTER;
    },

    /**
     * Shortcut to AuthModal.show.
     *
     * @example
     * authModalLoader.show(options).then((authResponse) => if (authResponse.err) FAILURE else SUCCESS);
     * authResponse.user will be an object containing user data: user.userId, user.userToken, user.profile {}.
     * authResponse.err will be an object with shape {err: 'closed'}.
     * @returns {Promise}
     */
    show(options = {}) {
        const environment =
            options.relayEnvironment ||
            getRelayEnvironment({}, { getGraphQLNetworkContext: () => 'auth-modal' });
        return new Promise(resolve => {
            this.load().then(AuthModal => {
                AuthModal.initAuth({ environment });
                this._launchAuth({
                    action: options.action || this.getDefaultAction(),
                    options: { ...getInitialState(), isPaused: false, ...options },
                    resolve,
                });
            });
        }).then(authResult => {
            const isRegister = authResult.modal === constants.REGISTER;
            //LoginMutation and RegistrationMutation passes isNewUser, newUser deprecated
            authResult = { ...authResult, newUser: isRegister };
            return authResult;
        });
    },

    /**
     * Launches postAuth with specified configuration
     *
     * @example
     * authModalLoader.showPostAuth(options).then(() => DO STUFF);
     * @param {Object} options.props - get passed to PostAuthModal component
     * @param {String} options.step - which post auth flow to run ../authModal/postAuth/constants
     * @param {Object} options.relayEnvironment
     * @param {String} options.flow
     *
     * @returns {Promise} - resolved when modal is closed
     */
    showPostAuth(options) {
        const {
            step = postAuthSteps.USER_TYPE_SELECTION,
            relayEnvironment,
            postAuthFlow,
            authFlow,
            launchOrigin,
        } = options;
        const environment =
            relayEnvironment ||
            getRelayEnvironment({}, { getGraphQLNetworkContext: () => 'auth-modal' });

        return new Promise(resolve => {
            this.load().then(postAuth => {
                postAuth.init(environment);
                if (postAuthFlow || authFlow) {
                    postAuth.run(postAuthFlow, authFlow, launchOrigin);
                }
                postAuth.show(resolve);
                if (step) {
                    postAuth.goToStep(step);
                }
            });
        });
    },

    _launchAuth({ action, options, resolve }) {
        switch (action) {
            case constants.LOGIN:
                Auth.launchLogin({ ...options, resolve });
                break;
            case constants.RESET_PASSWORD:
                Auth.launchResetPassword({ ...options, resolve });
                break;
            case constants.UPDATE_PASSWORD:
                Auth.launchUpdatePassword({ ...options, resolve });
                break;
            case constants.REQUEST_PASSWORD_EMAIL:
                Auth.launchRequestPasswordEmail({ ...options, resolve });
                break;
            case constants.SSO:
                Auth.launchSso({ ...options, resolve });
                break;
            default:
                Auth.launchRegistration({ ...options, resolve });
                break;
        }
    },

    /**
     * method for resuming CONTENT ONLY auth, the difference being we don't want to wrap the launching in a promise like we do in the other above `resume` method
     * because we are passing in a resolve method from the consumer in this case
     * @param {*} options options to resume content with -- if empty, it will just used savedState
     */
    _resumeAuthContent(options) {
        if (!Auth) {
            throw new Error(
                'Auth Modal cannot resume if it has not been started... use `authModalLoader.show` instead!'
            );
        }
        const savedState = Auth.getAuthContentState().savedState;
        this._launchAuth({
            action: savedState.modal,
            options: {
                ...savedState,
                step: savedState.step + 1,
                isPaused: false,
                ...options,
            },
            resolve: options.onAuthComplete,
        });
    },

    /**
     * serves the same purpose as `authModalLoader.show`, but returns the auth content as a fragment instead of launching it within it's own modal container
     * returns the AuthContentRenderer wrapper in redux provider (within React.Suspense if it hasn't been loaded yet, but caches for future loads)
     * for an example of usage, see `ContactDealerModalContent.jsx` and `ContactDealer.jsx`
     * @param {*} options object containing config for the auth modal-- functions the same as the options in `show` above
     */
    loadAuthContent(options = {}) {
        const environment =
            options.relayEnvironment ||
            getRelayEnvironment({}, { getGraphQLNetworkContext: () => 'auth-modal' });
        if (AuthContent && Auth) {
            if (this.isContentPaused() && options.resume) {
                this._resumeAuthContent(options);
            } else {
                this._launchAuth({
                    action: options.action,
                    options: { ...getInitialState(), ...options, isContentOnly: true },
                    resolve: options.onAuthComplete,
                });
            }
            return <AuthContent environment={environment} />;
        }

        AuthContent =
            AuthContent ||
            lazy(() =>
                import(
                    /* webpackChunkName: "AuthProvider" */
                    '../authModal/authentication/AuthProvider'
                ).then(module => {
                    Auth = module;
                    this._launchAuth({
                        action: options.action,
                        options: { ...getInitialState(), ...options, isContentOnly: true },
                        resolve: options.onAuthComplete,
                    });
                    return module;
                })
            );

        return (
            <Suspense fallback={<LoadingModal />}>
                <AuthContent environment={environment} />
            </Suspense>
        );
    },

    /**
     * expose the contents closing functionality to the consumer
     * resets auth store to initial state
     */
    closeAuthContent() {
        if (Auth) {
            Auth.closeAuthContent();
        }
    },
};

authModalLoader = Object.assign(authModalLoader, { constants });

export default authModalLoader;

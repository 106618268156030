import { connect } from 'react-redux';

import { object } from 'prop-types';
import { PrepareRelayContextProvider } from '../PrepareRelayContextProvider/PrepareRelayContextProvider';

import Header from '../Header/Header';

function HeaderRenderer(props) {
    const { environment, initialVariables, query, relayVariables } = props;

    const variables = {
        ...initialVariables,
        ...relayVariables,
    };

    return (
        <PrepareRelayContextProvider
            environment={environment}
            query={query}
            variables={variables}
            render={({ props: rendererProps }) => <Header {...rendererProps} {...variables} />}
        />
    );
}

HeaderRenderer.propTypes = {
    environment: object,
    initialVariables: object,
    query: object,
    relayVariables: object,
};

const mapStateToProps = ({ header }) => {
    return {
        relayVariables: header.relayVariables || {},
    };
};

export default connect(mapStateToProps)(HeaderRenderer);

import { FC, ReactNode, ComponentProps } from 'react';

import {
    InternalScrollCarouselProvider,
    useInternalScrollCarousel,
} from '../helpers/InternalScrollCarouselContext';
import { Arrow } from '../Arrow/Arrow';
import { ScrollContainer } from '../ScrollContainer/ScrollContainer';

import { OnArrowClick } from '../types';

import styles from './InnerScrollCarousel.scss';

const Inner: FC<{
    children: ReactNode;
    showArrows: boolean;
    onArrowClick?: OnArrowClick;
    dataTn?: string;
}> = ({ children, showArrows, onArrowClick, dataTn }) => {
    const { carouselOrientation } = useInternalScrollCarousel();

    return (
        <div className={`${styles.wrapper} ${styles[carouselOrientation]}`} data-tn={dataTn}>
            {showArrows && <Arrow type="previous" onArrowClick={onArrowClick} />}

            <ScrollContainer>{children}</ScrollContainer>

            {showArrows && <Arrow type="next" onArrowClick={onArrowClick} />}
        </div>
    );
};

export const InnerScrollCarousel: FC<
    {
        children: ReactNode;
        showArrows?: boolean;
        onArrowClick?: OnArrowClick;
        dataTn?: string;
    } & ComponentProps<typeof InternalScrollCarouselProvider>
> = ({ children, showArrows = false, onArrowClick, dataTn, ...props }) => {
    return (
        <InternalScrollCarouselProvider {...props}>
            <Inner showArrows={showArrows} onArrowClick={onArrowClick} dataTn={dataTn}>
                {children}
            </Inner>
        </InternalScrollCarouselProvider>
    );
};

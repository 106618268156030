import PropTypes from 'prop-types';
import { graphql, useFragment } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import Tile from 'dibs-search-product-tile';

import { trackPersonalizationItemClick } from '../helpers/personalizationTracking';
import { trackModule } from '../helpers/moduleLocationTracking';
import { TRACKING_VARIANT_PDP } from '../constants';

import styles from './HpSharedCategoryProducts.scss';

export function onProductClick({ item, moduleVariantType, variant, categoryUrl, index }) {
    trackPersonalizationItemClick({ item, moduleVariantType, variant, categoryUrl, index });
}

export const HpSharedCategoryProducts = props => {
    const {
        title,
        products,
        shopNowUrl,
        variant,
        imageSize,
        srcSetSizes,
        categoryIndex,
        onButtonClick,
        componentModule: componentModuleRef,
    } = props;

    const componentModule = useFragment(
        graphql`
            fragment HpSharedCategoryProducts_componentModule on ArrivalsModule {
                cmsDisplayTitle
            }
        `,
        componentModuleRef
    );

    return (
        <>
            <div className={styles.category}>
                <div data-tn="personalized-category-title" className={styles.title}>
                    {title}
                </div>
                <Link
                    dataTn="personalized-category-cta"
                    className={styles.shopNow}
                    href={shopNowUrl}
                    onClick={onButtonClick}
                >
                    <FormattedMessage
                        id="homepage.modules.categoryProducts.viewAll"
                        defaultMessage="View All"
                    />
                </Link>
            </div>
            <div className={styles.products}>
                <div className="rowFlex">
                    {(products || []).map((item, index) => (
                        <div className={`colXs6 ${styles.productTile}`} key={item.localizedPdpUrl}>
                            <Tile
                                item={item}
                                showLightBox
                                index={index}
                                itemsPerRow={2}
                                imageSize={imageSize}
                                srcSetSizes={srcSetSizes}
                                useLoFiLazyLoader
                                showQuickView={false}
                                showSellerName={false}
                                showPrice={false}
                                hideProductDetails
                                showByLine={false}
                                onClick={() => {
                                    onProductClick({
                                        item,
                                        url: item.localizedPdpUrl,
                                        moduleVariantType: variant,
                                        categoryUrl: shopNowUrl,
                                        index: categoryIndex + index + 1,
                                        variant: TRACKING_VARIANT_PDP,
                                    });
                                    trackModule(componentModule.cmsDisplayTitle);
                                }}
                                viewer={null}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

HpSharedCategoryProducts.propTypes = {
    title: PropTypes.string,
    shopNowUrl: PropTypes.string,
    variant: PropTypes.string,
    products: PropTypes.array,
    imageSize: PropTypes.string,
    srcSetSizes: PropTypes.string,
    categoryIndex: PropTypes.number,
    onButtonClick: PropTypes.func,
    componentModule: PropTypes.object,
};

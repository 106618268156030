import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import { ProductCarousel } from '../Product';
import { ItemTileBase } from './ItemTileBase';

export const ItemTileCarouselComponent = ({ item, itemSearch, viewer, ...props }) => {
    return (
        <ItemTileBase item={item} itemSearch={itemSearch} viewer={viewer} {...props}>
            {productProps => (
                <ProductCarousel
                    {...productProps}
                    item={item}
                    viewer={viewer}
                    itemSearch={itemSearch}
                />
            )}
        </ItemTileBase>
    );
};

ItemTileCarouselComponent.propTypes = {
    item: PropTypes.object,
    itemSearch: PropTypes.object,
    viewer: PropTypes.object,
};

export const ItemTileCarousel = createFragmentContainer(ItemTileCarouselComponent, {
    viewer: graphql`
        fragment ItemTileCarousel_viewer on Viewer
        @argumentDefinitions(
            fetchRegionalInfo: { type: "Boolean!" }
            userZipCode: { type: "String", defaultValue: "" }
            userCountryCode: { type: "String", defaultValue: "" }
        ) {
            ...ItemTileBase_viewer
                @arguments(
                    fetchRegionalInfo: $fetchRegionalInfo
                    userZipCode: $userZipCode
                    userCountryCode: $userCountryCode
                )
            ...ProductCarousel_viewer
        }
    `,
    itemSearch: graphql`
        fragment ItemTileCarousel_itemSearch on ItemSearchQueryConnection {
            ...ItemTileBase_itemSearch
            ...ProductCarousel_itemSearch
        }
    `,
    item: graphql`
        fragment ItemTileCarousel_item on Item
        @argumentDefinitions(
            isTrade: { type: "Boolean", defaultValue: false }
            showSeller: { type: "Boolean", defaultValue: false }
            pageDisplayEnum: { type: "PageDisplayEnum", defaultValue: searchAndBrowse }
            buyerId: { type: "String", defaultValue: "" }
            fetchShippingCostInfo: { type: "Boolean", defaultValue: false }
            fetchVideo: { type: "Boolean", defaultValue: false }
            fetchLiveShipmentQuote: { type: "Boolean", defaultValue: true }
            priceBookName: { type: "String" }
        ) {
            ...ItemTileBase_item
                @arguments(
                    showSeller: $showSeller
                    isTrade: $isTrade
                    pageDisplayEnum: $pageDisplayEnum
                    buyerId: $buyerId
                    fetchShippingCostInfo: $fetchShippingCostInfo
                    fetchLiveShipmentQuote: $fetchLiveShipmentQuote
                    priceBookName: $priceBookName
                )
            ...ProductCarousel_item @arguments(fetchVideo: $fetchVideo)
        }
    `,
});

import { FunctionComponent } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

// components
import { onHoldLabel } from './messages/priceMessages';

// styles
import styles from './PriceDisplay.scss';

type Props = {
    holdType: string;
    holdDisplayTime: string | null;
    showCompactedPrice?: boolean;
};

const HOLD_TYPES: Record<string, string> = {
    HOLD: 'price-on-hold',
    HOLD_CUSTOM: 'price-on-hold-for-you',
};

const expiresMessage = <FormattedMessage id="priceHold.expires" defaultMessage="Expires" />;

/**
 *  onHold Components for an item
 *  (HOLD or HOLD_CUSTOM)
 */
export const Hold: FunctionComponent<Props> = ({
    holdType,
    holdDisplayTime,
    showCompactedPrice = false,
}) => {
    if (HOLD_TYPES[holdType]) {
        const showExpiration =
            HOLD_TYPES[holdType] === HOLD_TYPES.HOLD_CUSTOM &&
            holdDisplayTime &&
            !showCompactedPrice;
        return (
            <div
                className={showCompactedPrice ? styles.showCompactedPrice : styles.hold}
                data-tn={HOLD_TYPES[holdType]}
            >
                {onHoldLabel(holdType)}
                {showExpiration && (
                    <div className={styles.countdown}>
                        {expiresMessage} {holdDisplayTime}
                    </div>
                )}
            </div>
        );
    } else {
        return null;
    }
};

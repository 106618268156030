import { graphql, Environment, commitMutation } from 'react-relay';
import { USER_EVENT_TYPE } from './constants';

import { getPageId, getUserData } from './helpers';
import {
    trackUserEventMutation,
    UserTrackingMutationInput,
} from './__generated__/trackUserEventMutation.graphql';

const mutation = graphql`
    mutation trackUserEventMutation($input: UserTrackingMutationInput!) {
        userTrackingMutation(input: $input) {
            __typename
        }
    }
`;

type TrackUserInput = Omit<UserTrackingMutationInput, 'userId' | 'sessionId'>;

const trackUserEvent = (environment: Environment, input: TrackUserInput): void => {
    const userData = getUserData();
    if (userData) {
        commitMutation<trackUserEventMutation>(environment, {
            mutation,
            variables: { input: { ...userData, ...input } },
        });
    }
};

export const trackUserEventSearch = (environment: Environment, searchQuery: string): void =>
    trackUserEvent(environment, {
        eventType: USER_EVENT_TYPE.SEARCH,
        pageId: getPageId(),
        searchQuery,
    });

export const trackUserEventHomepageView = (environment: Environment): void =>
    trackUserEvent(environment, { eventType: USER_EVENT_TYPE.HOMEPAGE_VIEW });

export const trackUserEventItemPageView = (
    environment: Environment,
    items: UserTrackingMutationInput['items']
): void =>
    trackUserEvent(environment, {
        eventType: USER_EVENT_TYPE.ITEM_PAGE_VIEW,
        pageId: getPageId(),
        items,
    });

export const trackUserEventPageView = (environment: Environment): void =>
    trackUserEvent(environment, { eventType: USER_EVENT_TYPE.PAGE_VIEW, pageId: getPageId() });

export const trackUserEventAddToCart = (
    environment: Environment,
    items: UserTrackingMutationInput['items']
): void =>
    trackUserEvent(environment, {
        eventType: USER_EVENT_TYPE.ADD_TO_CART,
        pageId: getPageId(),
        items,
    });

export const trackUserEventItemAddToFavorites = (
    environment: Environment,
    items: UserTrackingMutationInput['items']
): void =>
    trackUserEvent(environment, {
        eventType: USER_EVENT_TYPE.ADD_TO_FAVORITES,
        pageId: getPageId(),
        items,
    });

export const trackUserEventCheckout = (
    environment: Environment,
    items: UserTrackingMutationInput['items']
): void =>
    trackUserEvent(environment, {
        eventType: USER_EVENT_TYPE.CHECKOUT,
        pageId: getPageId(),
        items,
    });

import { FunctionComponent, ComponentProps, MouseEvent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { Button } from 'dibs-elements/exports/Button';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';

import { SeoButton_linkData$data } from './__generated__/SeoButton_linkData.graphql';
import { CustomLinkData } from '../types';

export const PARTIALLY_LINKABLE_BUTTON = 'PARTIALLY_LINKABLE_BUTTON';
export const ALWAYS_LINKABLE_BUTTON = 'ALWAYS_LINKABLE_BUTTON';

type ButtonProps = ComponentProps<typeof Button>;
type Props = Omit<ButtonProps, 'href'> & {
    linkData: SeoButton_linkData$data | CustomLinkData;
    variant?: typeof PARTIALLY_LINKABLE_BUTTON | typeof ALWAYS_LINKABLE_BUTTON;
};

export const SeoButtonComponent: FunctionComponent<Props> = ({
    linkData,
    variant = ALWAYS_LINKABLE_BUTTON,
    ...buttonProps
}) => {
    const { path, isLinkable } = linkData;
    const { onClick = () => {}, ...restButtonProps } = buttonProps;

    if (!path) {
        return <>{buttonProps.children}</>;
    }

    if (isLinkable) {
        return <Button href={path} {...buttonProps} />;
    }

    if (variant === PARTIALLY_LINKABLE_BUTTON) {
        return <Button {...buttonProps} />;
    }

    if (variant === ALWAYS_LINKABLE_BUTTON) {
        return (
            <Button
                onClick={(e: MouseEvent<HTMLElement>) => {
                    //TODO: shift click
                    onClick(e);
                    window.location.href = handleLocaleUrl(path, GLOBAL_CLIENT_ONLY_LOCALE);
                }}
                {...restButtonProps}
            />
        );
    }

    return null;
};

export const SeoButton = createFragmentContainer(SeoButtonComponent, {
    linkData: graphql`
        fragment SeoButton_linkData on LinkData {
            path
            isLinkable
        }
    `,
});

import { FC, useEffect } from 'react';
import { getPriceAttr } from 'dibs-intl/exports/helpers';
import { useCurrency } from 'dibs-buyer-layout/exports/useCurrency';

export const PriceIntlHandler: FC = () => {
    const { currency } = useCurrency();

    useEffect(() => {
        const priceElements = document.querySelectorAll(`[${getPriceAttr(currency)}]`);
        if (priceElements.length) {
            const priceAttr = getPriceAttr(currency);
            priceElements.forEach(el => {
                el.textContent = el.getAttribute(priceAttr);
            });
        }
    }, [currency]);

    return null;
};

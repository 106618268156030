/* eslint-disable import/no-default-export */
/**
 * @ignore
 * Created by daletan on 4/24/17.
 */

module.exports = {
    mobile: require('./src/mobileCheck'),
    browser: require('./src/browserCheck'),
};

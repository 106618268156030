// "export * from" so webpack does not tree shake them out of the dll
export * as React from 'react';
export * as ReactDOM from 'react-dom';
export * as ReactDOMClient from 'react-dom/client';
export * as ReactRelayLegacy from 'react-relay/legacy';
export * from 'react-relay';
export * from 'relay-runtime';
export * from 'redux';
export * from 'react-redux';
export * from 'dibs-react-intl';
export * from 'intersection-observer';
export * from 'wicg-inert/dist/inert';
export * from 'focus-visible/dist/focus-visible';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

import { ChangeEvent, FC, ReactNode, useId } from 'react';
import classNames from 'classnames';

import CheckIcon from 'dibs-icons/exports/Check';
import CloseIcon from 'dibs-icons/exports/Close';

import styles from './main.scss';

type SwitchProps = {
    checked: boolean;
    children?: ReactNode;
    dataTn: string;
    label?: ReactNode;
    name?: string;
    onChange: (checked: boolean, e?: ChangeEvent<HTMLInputElement>) => void | null;
    value?: string | number;
    disabled?: boolean;
};

export const Switch: FC<SwitchProps> = props => {
    const { children, dataTn, label, name, value, checked, disabled = false } = props;

    const id = useId();

    function onChange(e: ChangeEvent<HTMLInputElement>): void {
        const { checked: nextChecked } = e?.currentTarget;

        /* istanbul ignore else */
        if (typeof nextChecked === 'boolean') {
            props.onChange?.(nextChecked, e);
        }
    }

    const wrapperClasses = classNames(styles.wrapper, {
        [styles.isDisabled]: disabled,
    });

    const switchClasses = classNames(styles.switch, {
        [styles.isChecked]: checked,
    });

    return (
        <div className={wrapperClasses} data-tn={`${dataTn}-${checked ? 'checked' : 'unchecked'}`}>
            {label && <div className={styles.label}>{label}</div>}
            <label
                htmlFor={id}
                className={switchClasses}
                data-tn={`${dataTn}-label`}
                tabIndex={disabled ? -1 : 0}
            >
                <input
                    id={id}
                    name={name}
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    value={value}
                    data-tn={`${dataTn}-input`}
                    hidden
                />
                <div className={styles.dot} />
                <div className={styles.slider}>
                    <span className={classNames(styles.icon, styles.sliderOn)}>
                        <CheckIcon variation="20" />
                    </span>
                    <span className={classNames(styles.icon, styles.sliderOff)}>
                        <CloseIcon variation="20" />
                    </span>
                </div>
            </label>
            {children}
        </div>
    );
};

import { FC, useCallback } from 'react';

import { Link } from 'dibs-elements/exports/Link';
import ArrowLeft from 'dibs-icons/exports/legacy/ArrowLeft';
import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';
import ArrowUp from 'dibs-icons/exports/legacy/ArrowUp';
import ArrowDown from 'dibs-icons/exports/legacy/ArrowDown';

import { OnArrowClick } from '../types';

import { useInternalScrollCarousel } from '../helpers/InternalScrollCarouselContext';

import styles from './Arrow.scss';

//Arrow
const ARROW_ICONS = {
    left: ArrowLeft,
    right: ArrowRight,
    up: ArrowUp,
    down: ArrowDown,
} as const;

const NEXT_ARROW_DIRECTION = {
    horizontal: 'right',
    vertical: 'down',
} as const;

const PREV_ARROW_DIRECTION = {
    horizontal: 'left',
    vertical: 'up',
} as const;

export const Arrow: FC<{ type: 'previous' | 'next'; onArrowClick?: OnArrowClick }> = ({
    type,
    onArrowClick,
}) => {
    const {
        carouselOrientation,
        currentIndex,
        stepSize,
        totalItems,
        isInfinite,
        itemsToShow,
        hasMultipleSlides,
        setCurrentIndex,
    } = useInternalScrollCarousel();

    const isPrev = type === 'previous';
    const arrowDirection = isPrev
        ? PREV_ARROW_DIRECTION[carouselOrientation]
        : NEXT_ARROW_DIRECTION[carouselOrientation];
    const Icon = ARROW_ICONS[arrowDirection];

    const onClick = useCallback((): void => {
        const index = isPrev ? currentIndex - stepSize : currentIndex + stepSize;
        setCurrentIndex(index);

        onArrowClick?.({ direction: isPrev ? 'previous' : 'next' });
    }, [currentIndex, isPrev, stepSize, setCurrentIndex, onArrowClick]);

    const endIndex = totalItems - 1;
    const showNextArrow = currentIndex + Math.floor(itemsToShow) <= endIndex;
    const showPrevArrow = currentIndex + (totalItems % stepSize) - stepSize >= 0;

    if (
        //if showArrows enabled always show both arrows on infinite carousels if there are items to scroll to
        (isInfinite && hasMultipleSlides) ||
        /**
         * if showArrows enabled on not infinite carousels:
         * - hide both arrows if only one slide
         * - hide the previous arrow on the first slide
         * - hide the next arrow on the last slide
         */
        (!isInfinite && hasMultipleSlides && (isPrev ? showPrevArrow : showNextArrow))
    ) {
        return (
            <Link className={styles.arrowLink} onClick={onClick}>
                <Icon className={styles.arrow} />
            </Link>
        );
    }

    //need to show arrow filler when arrow is hidden on not infinite carousels to prevent content flickering
    return <div className={styles.arrowFiller} />;
};

import { FC } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'dibs-react-intl';
import { ErrorBoundaryFallbackProps as FallbackProps } from 'dibs-error-tools/ErrorBoundary';
import { TrackErrorPageView } from '../TrackErrorPageView/TrackErrorPageView';
import styles from './Error500Layout.scss';

const errorImgSrc = 'https://a.1stdibscdn.com/dist/adhoc/wristwatch.jpg';

const messages = defineMessages({
    errorMessageLinkTitle: {
        id: 'dbl.Error500Layout.messageLinkTitle',
        defaultMessage: 'Reload current page',
    },
    errorImgAlt: {
        id: 'dbl.Error500Layout.imgAlt',
        defaultMessage: 'wristwatch',
    },
});

type Props = { rumTransactionName?: string } & FallbackProps;

const Error500Layout: FC<Props> = ({ error, rumTransactionName }) => {
    const intl = useIntl();
    const unkownError = (
        <FormattedMessage
            id="dbl.Error500Layout.unknownError"
            defaultMessage="An unknown or server error occurred."
        />
    );

    const errMsg = (error && error.message) || unkownError;

    return (
        <div className={styles.container}>
            <TrackErrorPageView rumTransactionName={rumTransactionName} />
            <div className={styles.info}>
                <h3 className={styles.header}>
                    <FormattedMessage
                        id="dbl.Error500Layout.title"
                        defaultMessage="Sorry, we're currently experiencing technical difficulties."
                    />
                </h3>
                <p>
                    <FormattedMessage
                        id="dbl.Error500Layout.message"
                        defaultMessage="We know your time is valuable. Give us a few seconds and <a>try loading this page again</a>."
                        values={{
                            a: content => (
                                /* eslint-disable jsx-a11y/anchor-is-valid, react/forbid-elements, ferrum/intl-links */
                                <a
                                    href=""
                                    title={intl.formatMessage(messages.errorMessageLinkTitle)}
                                >
                                    {content}
                                </a>
                                /* eslint-enable */
                            ),
                            messageLink: content => <span>{content}</span>,
                        }}
                    />
                </p>
                <details>
                    <summary>
                        <FormattedMessage
                            id="dbl.Error500Layout.detailSummary"
                            defaultMessage="An error occurred."
                        />
                    </summary>
                    <FormattedMessage
                        id="dbl.Error500Layout.printError"
                        defaultMessage="Error: {errMsg}."
                        values={{ errMsg }}
                    />
                </details>
            </div>
            <div className={styles.imageWrap}>
                <img
                    className={styles.image}
                    src={errorImgSrc}
                    alt={intl.formatMessage(messages.errorImgAlt)}
                />
            </div>
        </div>
    );
};

export default Error500Layout;

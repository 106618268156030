import { trackEvent } from 'dibs-tracking';

const trackInteraction = ({ action, label }: { action: string; label: string }): void => {
    trackEvent({
        category: 'promo interaction',
        action,
        label,
        value: 0,
    });
};

export const trackArrowClick = ({ direction }: { direction: string }): void => {
    trackInteraction({
        action: 'recommended for you items arrow clicked',
        label: direction,
    });
};

export const trackInitialDisplay = ({ itemsReturned }: { itemsReturned: number }): void => {
    trackInteraction({
        action: 'recommended for you items displayed',
        label: itemsReturned.toString(),
    });
};

import { graphql, Environment } from 'react-relay/legacy';
import commitMutation from 'relay-commit-mutation-promise';
import {
    AddUserToAutopilotContactsInput,
    addUserToAutopilotContactsMutation$data,
    addUserToAutopilotContactsMutation,
} from './__generated__/addUserToAutopilotContactsMutation.graphql';

export const mutation = graphql`
    mutation addUserToAutopilotContactsMutation($input: AddUserToAutopilotContactsInput!) {
        addUserToAutopilotContacts(input: $input) {
            clientMutationId
        }
    }
`;

export function addUserToAutopilotContacts(
    environment: Environment,
    input: AddUserToAutopilotContactsInput
): Promise<addUserToAutopilotContactsMutation$data> {
    return commitMutation<addUserToAutopilotContactsMutation>(environment, {
        mutation,
        variables: { input },
    });
}

import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import HpLazyImageWrapper from '../HpLazyImageWrapper/HpLazyImageWrapper';
import ResponsiveImage from '../ResponsiveImage';

import styles from './HpSharedCollectionsModuleItem.scss';

import { HpSharedCollectionsModuleItem_item$data } from './__generated__/HpSharedCollectionsModuleItem_item.graphql';

type Props = {
    item: HpSharedCollectionsModuleItem_item$data;
    useLazyLoadImages: boolean;
    onClick: () => void;
};

const HpSharedCollectionsModuleItem: FC<Props> = ({ item, onClick, useLazyLoadImages }) => {
    const { title, imageUrl, linkData } = item;

    return (
        <SeoLink
            linkData={linkData}
            className={styles.link}
            onClick={onClick}
            dataTn="collections-item-link"
        >
            <HpLazyImageWrapper useLazyLoad={useLazyLoadImages} className={styles.imageWrapper}>
                <ResponsiveImage
                    className={styles.image}
                    src={imageUrl || ''}
                    // no need for alt if title is present bc labeling the img with title is
                    // redundant with title as link text. only use alt if != title.
                    ariaHidden={!!title}
                    dataTn="collections-item-image"
                    srcSetWidths={[120, 150]}
                />
            </HpLazyImageWrapper>
            <span className={styles.title} data-tn="collections-item-title">
                {title}
            </span>
        </SeoLink>
    );
};

export default createFragmentContainer(HpSharedCollectionsModuleItem, {
    item: graphql`
        fragment HpSharedCollectionsModuleItem_item on Product {
            title
            imageUrl
            linkData {
                ...SeoLink_linkData
            }
        }
    `,
});

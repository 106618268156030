import * as tracking from 'dibs-tracking';

const listName = 'Collection Spotlight';

type ProductItem = {
    contemporaryTrackingString: string | null;
    linkData?: {
        path?: string | null;
    } | null;
    title: string | null;
    itemId: string | null;
};

type ProductTrackingData = {
    dimension83: string;
    url: string;
    name: string;
    position: number;
    list?: string;
};

function buildProductTrackingData({
    item,
    index,
    list,
}: {
    item: ProductItem;
    index: number;
    list?: string;
}): ProductTrackingData {
    const product = {
        dimension83: item.contemporaryTrackingString || '',
        url: item.linkData?.path || '',
        name: item.title || '',
        position: index + 1,
        list,
    };

    return product;
}

const {
    trackingConstants: { ECOM_PRODUCT_CLICK, ECOM_PRODUCT_IMPRESSION },
    eventNameConstants: { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST },
} = tracking;

export function trackProductImpression({ items }: { items: ProductItem[] }): void {
    tracking.trackEcommerce({
        type: ECOM_PRODUCT_IMPRESSION,
        eventName: EVENT_VIEW_ITEM_LIST,
        products: items.map((item, index) =>
            buildProductTrackingData({
                item,
                index,
                list: listName,
            })
        ),
    });
}

export function trackProductClick({ item, index }: { item: ProductItem; index: number }): void {
    tracking.trackEcommerce({
        type: ECOM_PRODUCT_CLICK,
        eventName: EVENT_SELECT_ITEM,
        actionField: { list: listName, itemId: item.itemId },
        products: [buildProductTrackingData({ item, index })],
    });
}

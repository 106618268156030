/**
 * @generated SignedSource<<59ebdea3c5b71eede0687507df80ed83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemTileCarousel_item$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ItemTileBase_item" | "ProductCarousel_item">;
  readonly " $fragmentType": "ItemTileCarousel_item";
};
export type ItemTileCarousel_item$key = {
  readonly " $data"?: ItemTileCarousel_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemTileCarousel_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "buyerId"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "fetchLiveShipmentQuote"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchShippingCostInfo"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchVideo"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isTrade"
    },
    {
      "defaultValue": "searchAndBrowse",
      "kind": "LocalArgument",
      "name": "pageDisplayEnum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceBookName"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showSeller"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemTileCarousel_item",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "buyerId",
          "variableName": "buyerId"
        },
        {
          "kind": "Variable",
          "name": "fetchLiveShipmentQuote",
          "variableName": "fetchLiveShipmentQuote"
        },
        {
          "kind": "Variable",
          "name": "fetchShippingCostInfo",
          "variableName": "fetchShippingCostInfo"
        },
        {
          "kind": "Variable",
          "name": "isTrade",
          "variableName": "isTrade"
        },
        {
          "kind": "Variable",
          "name": "pageDisplayEnum",
          "variableName": "pageDisplayEnum"
        },
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        },
        {
          "kind": "Variable",
          "name": "showSeller",
          "variableName": "showSeller"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ItemTileBase_item"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "fetchVideo",
          "variableName": "fetchVideo"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProductCarousel_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "23662bc6a26c4c996a6b6942b8240197";

export default node;

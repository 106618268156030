/**
 * @generated SignedSource<<85d3b82b0fe8293207a2dffedae6466d>>
 * @relayHash 8976bd522be57bd64b1ce45436f27ede
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/494.0.0-2025-01-09T20:04:30.590Z/initAutopilotQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type initAutopilotQuery$variables = {
  anchors?: ReadonlyArray<string> | null;
};
export type initAutopilotQuery$data = {
  readonly viewer: {
    readonly anchorLists: ReadonlyArray<{
      readonly __typename: "AnchorList";
    } | null> | null;
    readonly disableAutopilotIntegration: boolean | null;
  };
};
export type initAutopilotQuery = {
  response: initAutopilotQuery$data;
  variables: initAutopilotQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "anchors"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "anchors",
    "variableName": "anchors"
  },
  {
    "kind": "Literal",
    "name": "listNames",
    "value": [
      "LEAD_NURTURING_BUYER_WHITELIST"
    ]
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": "disableAutopilotIntegration",
  "args": [
    {
      "kind": "Literal",
      "name": "key",
      "value": "disableAutopilotIntegration"
    }
  ],
  "kind": "ScalarField",
  "name": "isEnabled",
  "storageKey": "isEnabled(key:\"disableAutopilotIntegration\")"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "initAutopilotQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AnchorList",
            "kind": "LinkedField",
            "name": "anchorLists",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "initAutopilotQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AnchorList",
            "kind": "LinkedField",
            "name": "anchorLists",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/494.0.0-2025-01-09T20:04:30.590Z/initAutopilotQuery",
    "metadata": {},
    "name": "initAutopilotQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "21832c18b05b24130309ac034117d83f";

export default node;

export function square(number) {
    return Math.pow(number, 2);
}

export function hypotenuse(x, y) {
    return Math.sqrt(square(x) + square(y));
}

export function absDistance(number1, number2) {
    return Math.abs(number1 - number2);
}

export function midpoint(number1, number2) {
    return (number1 + number2) / 2;
}

export function getTouchDistance(position1, position2) {
    const { x: x1, y: y1 } = position1;
    const { x: x2, y: y2 } = position2;

    return hypotenuse(absDistance(x1, x2), absDistance(y1, y2));
}

export function getTouchCenterCoord(position1, position2) {
    const { x: x1, y: y1 } = position1;
    const { x: x2, y: y2 } = position2;

    return {
        x: midpoint(x1, x2),
        y: midpoint(y1, y2),
    };
}

export function touchToCoordinates(target, touch) {
    const domRect = target.getBoundingClientRect();
    return {
        x: (touch?.pageX || 0) - domRect.left,
        y: (touch?.pageY || 0) - domRect.top,
    };
}

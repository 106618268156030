import { FC, ReactElement, ReactNode, createElement, Fragment, memo } from 'react';
import { useIntl } from './IntlContext';
import { MessageValue } from './types';

type Props = {
    id: string;
    defaultMessage: string;
    values?: Record<string, MessageValue>;
    children?: (arg0: ReactNode) => ReactElement;
};

function shallowEqual<T extends Record<string, unknown> = Record<string, unknown>>(
    objA?: T,
    objB?: T
): boolean {
    if (objA === objB) {
        return true;
    }

    if (!objA || !objB) {
        return false;
    }

    const aKeys = Object.keys(objA);
    const bKeys = Object.keys(objB);
    const len = aKeys.length;

    if (bKeys.length !== len) {
        return false;
    }

    for (let i = 0; i < len; i++) {
        const key = aKeys[i];

        if (objA[key] !== objB[key] || !Object.prototype.hasOwnProperty.call(objB, key)) {
            return false;
        }
    }

    return true;
}

// adapted from react-intl
function areEqual(prevProps: Props, nextProps: Props): boolean {
    const { values, ...otherProps } = prevProps;
    const { values: nextValues, ...nextOtherProps } = nextProps;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return shallowEqual(nextValues, values) && shallowEqual(otherProps as any, nextOtherProps);
}

const FormattedMessage: FC<Props> = memo(({ id, defaultMessage, values, children }) => {
    const intl = useIntl();
    let nodes: ReactNode = intl.formatMessage({ id, defaultMessage }, values);

    if (!Array.isArray(nodes)) {
        nodes = [nodes];
    }
    if (typeof children === 'function') {
        return children(nodes);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return createElement(Fragment, null, ...(nodes as any));
}, areEqual);

FormattedMessage.displayName = 'FormattedMessage';

export { FormattedMessage };

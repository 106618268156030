import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import { SiteNavDropdownColumn } from './SiteNavDropdownColumn';
import { SiteNavDropdownImageModule } from './SiteNavDropdownImageModule';
import styles from './SiteNav-style.scss';

const SiteNavDropdownContentComponent = ({
    trackingLabelPrefix,
    navigationModule,
    onImageModuleFirstImpression,
    onImageModuleClick,
    isVisible,
}) => {
    const { imageUrl, items } = navigationModule;
    const columns = {};
    let imageModule;

    // Group columns by column number
    items.forEach(item => {
        if (!columns[item.column]) {
            columns[item.column] = [];
        }
        columns[item.column].push(item);
    });

    if (imageUrl) {
        imageModule = (
            <div className={`${styles.dropdownCol} ${styles.imageModule}`}>
                <SiteNavDropdownImageModule
                    navigationModule={navigationModule}
                    isVisible={isVisible}
                    onFirstImpression={onImageModuleFirstImpression}
                    onClick={onImageModuleClick}
                />
            </div>
        );
    }

    return (
        <div className={styles.dropdownContent}>
            {Object.values(columns).map(itemCol => (
                <SiteNavDropdownColumn
                    key={itemCol.map(item => item.name).join()}
                    navigationItems={itemCol}
                    trackingLabelPrefix={trackingLabelPrefix}
                />
            ))}
            {imageModule}
        </div>
    );
};

SiteNavDropdownContentComponent.propTypes = {
    trackingLabelPrefix: PropTypes.string,
    navigationModule: PropTypes.object,
    onImageModuleFirstImpression: PropTypes.func,
    onImageModuleClick: PropTypes.func,
    isVisible: PropTypes.bool,
};

export const SiteNavDropdownContent = createFragmentContainer(SiteNavDropdownContentComponent, {
    navigationModule: graphql`
        fragment SiteNavDropdownContent_navigationModule on NavigationModule {
            name
            items {
                name
                column
                items {
                    name
                }
                ...SiteNavDropdownColumn_navigationTopLevel
            }

            imageUrl
            ...SiteNavDropdownImageModule_navigationModule
        }
    `,
});

const MOBILE_ITEMS_PER_PAGE = 2.5;
const TABLET_ITEMS_PER_PAGE = 4;
const DESKTOP_ITEMS_PER_PAGE = 6;

const DEFAULT_NEW_ARRIVALS_PER_PAGE = 3;
const MOBILE_NEW_ARRIVALS_PER_PAGE = 1;

const TRACKING_VARIANT_BROWSE = 'browse';
const TRACKING_VARIANT_PDP = 'pdp';
const TRACKING_PERSONALIZED_TYPE_TRENDING = 'trending';
const TRACKING_PERSONALIZED_TYPE_PERSONALIZATION = 'personalization';

const LAZY_LOAD_BEGINS_AT = {
    MOBILE: 2,
    TABLET: 3,
    DESKTOP: 3,
};

const NUM_MOBILE_LOCATIONS = 1;
const NUM_RESP_LOCATIONS = 3;

export {
    MOBILE_ITEMS_PER_PAGE,
    TABLET_ITEMS_PER_PAGE,
    DESKTOP_ITEMS_PER_PAGE,
    DEFAULT_NEW_ARRIVALS_PER_PAGE,
    MOBILE_NEW_ARRIVALS_PER_PAGE,
    TRACKING_VARIANT_BROWSE,
    TRACKING_VARIANT_PDP,
    TRACKING_PERSONALIZED_TYPE_TRENDING,
    TRACKING_PERSONALIZED_TYPE_PERSONALIZATION,
    LAZY_LOAD_BEGINS_AT,
    NUM_MOBILE_LOCATIONS,
    NUM_RESP_LOCATIONS,
};

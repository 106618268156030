import { graphql, fetchQuery, Environment } from 'react-relay';

import {
    getFeatureFlagsQuery$data,
    getFeatureFlagsQuery,
} from './__generated__/getFeatureFlagsQuery.graphql';

export const getFeatureFlags = async ({
    environment,
}: {
    environment: Environment;
}): Promise<getFeatureFlagsQuery$data['viewer'] | undefined> => {
    const result = await fetchQuery<getFeatureFlagsQuery>(
        environment,
        graphql`
            query getFeatureFlagsQuery {
                viewer {
                    googleOneTapAutoLogin: featureFlag(feature: "googleOneTapAutoLogin")
                }
            }
        `,
        {},
        {
            networkCacheConfig: { force: false },
            fetchPolicy: 'store-or-network',
        }
    ).toPromise();

    return result?.viewer;
};

export const RECENT_SEARCHES = {
    TYPE: 'RECENT',
    LOCAL_STORAGE: 'recentSearches',
    MAX_TERMS: 6,
};

export const SEARCH_PAGE_PATH = '/search/';

export const SEARCH_QUERY_PARAM = 'q';
export const ORIGINAL_SEARCH_QUERY_PARAM = 'oq';

export const SEARCH_BAR_TRACKING = {
    CATEGORIES: {
        NAVIGATION: 'navigation',
    },
    ACTIONS: {
        HEADER_NAV_CLICK: 'header navigation click',
        SEARCH_BAR_CLICK: 'search bar clicked',
        RECENT_SEARCHES_DISPLAY: 'recent searches displayed',
        SEARCH_SUGGESTIONS_DISPLAY: 'search suggestions displayed',
    },
    LABELS: {
        GLOBAL_SEARCH: 'global search',
        GLOBAL_SEARCH_AUTOCOMPLETE: 'global search|auto-complete selected',
        RECENT_SEARCH: 'recent search',
    },
} as const;

export const KEY_UP_VALUE = 'up';
export const KEY_DOWN_VALUE = 'down';

import { FC } from 'react';
import classnames from 'classnames';

import dibsCss from 'dibs-css';
import styles from './styles.scss';

type Props = {
    message?: string | null;
};

export const UrgencySignal: FC<Props> = ({ message }) => {
    if (!message) {
        return null;
    }
    return (
        <div
            className={classnames(
                dibsCss.sassyFontBodyTypeHeavySizeXSmall,
                dibsCss.textAlertsecondary,
                dibsCss.mbXxsmall,
                styles.urgencySignalMt // negative margin
            )}
            data-tn="urgency-signal"
        >
            {message}
        </div>
    );
};

/**
 * @generated SignedSource<<ea9e6ad75712ba5be2dac92116f53ff9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuickViewItem_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"QuickViewCategoryParagraph_itemSearch">;
  readonly " $fragmentType": "QuickViewItem_itemSearch";
};
export type QuickViewItem_itemSearch$key = {
  readonly " $data"?: QuickViewItem_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuickViewItem_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuickViewItem_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuickViewCategoryParagraph_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "38658c1c8544061422434729e2162789";

export default node;

import { useId, FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { GridCol } from 'dibs-elements/exports/Grid';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import classnames from 'classnames';
import HpSharedModuleItemTile from '../HpSharedModuleItemTile/HpSharedModuleItemTile';
import HpLazyImageWrapper from '../HpLazyImageWrapper/HpLazyImageWrapper';
import ResponsiveImage from '../ResponsiveImage';

import { HpSharedPromoHeroItem } from '../HpSharedSharedHeroBanner/HpSharedSharedHeroBanner';
import styles from './HpSharedPromoModulePromoItem.scss';

import { HpSharedPromoModulePromoItem_promotion$data } from './__generated__/HpSharedPromoModulePromoItem_promotion.graphql';

type Props = {
    promotion: HpSharedPromoModulePromoItem_promotion$data;
    onClick?: () => void;
    useLazyLoadImages: boolean;
    index?: number;
    isSinglePromo: boolean;
    isMobile: boolean;
};

const HpSharedPromoModulePromoItem: FC<Props> = ({
    promotion,
    onClick = () => {},
    useLazyLoadImages,
    isSinglePromo,
    index,
}) => {
    const {
        mobileImageUrl,
        imageUrl,
        linkData,
        imageHeight,
        imageWidth,
        mobileImageHeight,
        mobileImageWidth,
        imageCopy,
        cta,
        title,
        description,
        isFullBleedImage,
        format,
    } = promotion;

    const promoItemId = `promomodulepromo-${useId()}`;

    if (!linkData) {
        return null;
    }

    const shownImageUrl = imageUrl || mobileImageUrl;
    const shownImageWidth = mobileImageWidth || imageWidth;
    const shownImageHeight = mobileImageHeight || imageHeight;
    const isFullBleed = isFullBleedImage && isSinglePromo;

    return (
        <GridCol tabletLandscape={isSinglePromo ? 12 : 6}>
            {imageCopy && (
                <span id={promoItemId} className={styles.imageCopy}>
                    {imageCopy}
                </span>
            )}
            {isSinglePromo ? (
                <>
                    {format === 'CAPTION' ? (
                        <div className={styles.fullWidth}>
                            <HpSharedPromoHeroItem
                                isVisible
                                useLazyLoadImages={useLazyLoadImages}
                                onClick={onClick}
                                promoItem={promotion}
                            />
                        </div>
                    ) : (
                        <SeoLink
                            dataTn="promo-module-item"
                            className={styles.link}
                            linkData={linkData}
                            onClick={onClick}
                            ariaLabel={cta || undefined}
                            ariaDescribedBy={imageCopy ? promoItemId : undefined}
                        >
                            <HpLazyImageWrapper
                                useLazyLoad={useLazyLoadImages}
                                imageHeight={shownImageHeight}
                                imageWidth={shownImageWidth}
                            >
                                <ResponsiveImage
                                    ariaHidden
                                    className={classnames(styles.image, {
                                        [styles.isFullBleed]: isFullBleed,
                                    })}
                                    src={shownImageUrl || ''}
                                    srcSetWidths={[400, 640, 960, 1280, 1440]}
                                />
                            </HpLazyImageWrapper>
                        </SeoLink>
                    )}
                </>
            ) : (
                <div className={styles.item}>
                    <HpSharedModuleItemTile
                        linkData={linkData}
                        dataTn={`editorial-link-${index}`}
                        onClick={onClick}
                        title={title}
                        description={description}
                        cta={cta}
                        imgSrcSetWidths={[400, 640, 800]}
                        imgSizes="(max-width:768px) 95vw, 50vw"
                        imageUrl={shownImageUrl}
                        imageWidth={shownImageWidth}
                        imageHeight={shownImageHeight}
                        imageClass={styles.tileImage}
                        imageOverlayClass={styles.tileImageOverlay}
                        useLazyLoadImages={useLazyLoadImages}
                    />
                </div>
            )}
        </GridCol>
    );
};

export default createFragmentContainer(HpSharedPromoModulePromoItem, {
    promotion: graphql`
        fragment HpSharedPromoModulePromoItem_promotion on PromoItem {
            imageCopy
            ... on PromoItem @skip(if: $isMobile) {
                imageUrl
                imageHeight
                imageWidth
            }
            ... on PromoItem @include(if: $isMobile) {
                mobileImageUrl
                mobileImageHeight
                mobileImageWidth
            }
            cta
            linkData {
                # path is used in PromoTrackingOld.js
                path
                ...HpSharedModuleItemTile_linkData
                ...HpSharedHeroBannerText_linkData
                ...SeoLink_linkData
            }
            ...HpSharedSharedHeroBanner_promoItem

            isFullBleedImage
            title
            description
            format
        }
    `,
});

import { actionTypes } from '../actions/constants';

const defaultState = {
    isAppBannerVisible: false,
};

export const appBannerReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_APP_BANNER: {
            const { isAppBannerVisible } = action.payload;
            return { isAppBannerVisible };
        }
        default:
            return state;
    }
};

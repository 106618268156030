'use client';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import throttle from 'lodash.throttle';
import { useIntl, defineMessages } from 'dibs-react-intl';

import PrevArrow from 'dibs-icons/exports/legacy/ArrowLeft';
import NextArrow from 'dibs-icons/exports/legacy/ArrowRight';

// components
import { CarouselDots } from './CarouselDots';
import { Link } from 'dibs-elements/exports/Link';

// styles
import styles from '../styles/Carousel.scss';

const FOCUSABLE_SELECTORS = [
    'a[href]:not([disabled])',
    'button:not([disabled])',
    'textarea:not([disabled])',
    'input[type="text"]:not([disabled])',
    'input[type="radio"]:not([disabled])',
    'input[type="checkbox"]:not([disabled])',
    'select:not([disabled])',
    '[tabindex]:not([tabindex="-1"]',
].join(', ');

const messages = defineMessages({
    next: { id: 'dibsCarousel.nextLabel', defaultMessage: 'Next' },
    previous: { id: 'dibsCarousel.previousLabel', defaultMessage: 'Previous' },
});

/*
typically, -1 % 5 = 4 (or the last element in an array of length 5) but in JS %
keeps the sign of the dividend meaning -1 % 5 = -1, which cannot be used as an
index. to get the positive modulo value, add the divisor to the result and
modulo that. this ensures that the value is always positive and between 0 and
the divisor, regardless of dividend sign.

concrete example: "Next" and "Previous" change current page by incrementing or
decrementing the current page index. if "Previous" is clicked and the current
index is 0, decrementing results in -1, which is not a real page. to allow the
"Previous" action to wrap, moving backward from the first page to the last page
, the new page index should be divided by the number of pages with the
remainder be used as the new page index. this is accomplished with %. except in
JS, where the above changes are also required.

https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
*/
function traverseCircular(index, length, step) {
    return (((index + step) % length) + length) % length;
}

const ArrowComponent = ({ enableKeyboardControl, onClick, direction, className }) => {
    const intl = useIntl();
    return (
        <Link
            ariaHidden={enableKeyboardControl ? undefined : 'true'}
            ariaLabel={intl.formatMessage(messages[direction])}
            tabIndex={enableKeyboardControl ? 0 : -1}
            className={styles.button}
            dataTn={`carousel-${direction}-arrow`}
            onClick={onClick}
        >
            {direction === 'next' ? (
                <NextArrow className={className} />
            ) : (
                <PrevArrow className={className} />
            )}
        </Link>
    );
};
ArrowComponent.propTypes = {
    enableKeyboardControl: PropTypes.bool.isRequired,
    direction: PropTypes.oneOf(['next', 'previous']).isRequired,
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
const Arrow = ArrowComponent;
export class Carousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            index: props.index, // the current focused index
            pageLeftIndex: props.index, // the left most page index
            carouselLeftIndex: props.index, // the left most carousel index
            slide: false,
        };

        this.jumpToItem = this.jumpToItem.bind(this);
        this.goToItem = this.goToItem.bind(this);
        this.handleDirection = this.handleDirection.bind(this);
        this.onKeyDown = throttle(this.onKeyDown.bind(this), 250);
        this.shouldUpdate = this.shouldUpdate.bind(this);
        this.renderArrow = this.renderArrow.bind(this);
        this.autoRun = this.autoRun.bind(this);
        this.clearAutoRun = this.clearAutoRun.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.getPagingInfo = this.getPagingInfo.bind(this);
    }
    componentDidMount() {
        if (this.props.isAutoRun) {
            this.autoRun();
        }
    }
    componentDidUpdate(prevProps) {
        const { index } = this.props;
        /* istanbul ignore else */
        if (![prevProps.index, this.state.index].includes(index)) {
            const diff = index - this.state.index;
            const { step } = prevProps;
            const previousLastIndex = this.props.totalItems - 1;

            if (prevProps.enableItemControl) {
                // Check if the pageLeftIndex and carouselLeftIndex needs to be pushed
                // when the carousel starts at 0 and the new index is last in interval
                // which means that the index change happened from different source then the
                // the local carousel. So because of this we need to push the carousel
                // so that the index would we be reset correctly for future navigation.
                if (
                    index === previousLastIndex &&
                    this.state.pageLeftIndex === 0 &&
                    this.state.carouselLeftIndex === 0
                ) {
                    this.jumpToItem(index, -2);
                } else {
                    this.jumpToItem(index);
                }
            } else {
                switch (diff) {
                    case 1:
                    case 1 - this.props.totalItems:
                    case step:
                        this.handleDirection('next');
                        break;
                    case -1:
                    case previousLastIndex:
                    case -step:
                        this.handleDirection('previous');
                        break;
                    default:
                        this.goToItem(index, index, index);
                }
            }
        }

        if (this.props.isAutoRun && !prevProps.isAutoRun) {
            this.autoRun();
        } else if (!this.props.isAutoRun && prevProps.isAutoRun) {
            this.clearAutoRun();
        }
    }
    /* istanbul ignore next */
    componentWillUnmount() {
        if (this.props.isAutoRun) {
            this.clearAutoRun();
        }
    }

    autoRun() {
        const { autoRunDuration } = this.props;
        clearInterval(this.timer);
        this.timer = setInterval(() => {
            this.handlePage('next');
        }, autoRunDuration);
    }

    clearAutoRun() {
        clearInterval(this.timer);
    }
    /**
     * Helper - Checks that an index is between 0 and totalItems
     *          and returns a valid index if it is not
     * @param {number} index
     * @returns {number}
     */
    fixIndex(index) {
        const { totalItems } = this.props;
        if (index >= totalItems) {
            return this.fixIndex(index - totalItems);
        } else if (index < 0) {
            return this.fixIndex(index + totalItems);
        }
        return index;
    }
    /**
     * Helper - checks if index is between 0 and totalItems
     *          and returns valid one in the first or last page.
     *
     * @param {number} index
     * @returns {number}
     */
    fixStepJumpIndex(index) {
        const { totalItems } = this.props;
        const itemsPerPage = Math.min(this.props.itemsPerPage, totalItems);
        if (index >= totalItems) {
            return 0;
        } else if (index < 0) {
            /**
             * Gets first item index in the last page.
             */
            return itemsPerPage * (Math.ceil(totalItems / itemsPerPage) - 1);
        }
        return index;
    }
    /**
     * Helper - returns classes from style sheet and props.classNames if provided
     */
    getClass(...keys) {
        return classnames(
            ...keys.reduce((classes, key) => {
                classes.push(styles[key], this.props.classNames[key]);
                return classes;
            }, [])
        );
    }

    /**
     * Keydown handler - for arrow control of carousel
     */
    onKeyDown({ key, code, keyCode }) {
        if ([key, code].includes('ArrowRight') || keyCode === 39) {
            this.handleDirection('next', true);
        } else if ([key, code].includes('ArrowLeft') || keyCode === 37) {
            this.handleDirection('previous', true);
        }
    }
    /**
     * Updates the order of the items
     */
    updateCarouselOrder(carouselLeftIndex) {
        return new Promise(resolve => {
            this.setState({ carouselLeftIndex, slide: false }, resolve);
        });
    }
    /**
     * Updates the carousel index and the page index
     */
    updateCarouselIndex(index, pageLeftIndex, carouselLeftIndex, change, forceFocus) {
        const prev = this.state.index;
        const slide = this.props.slideCarouselItems || false;
        this.setState({ index, pageLeftIndex, slide, forceFocus }, () => {
            if (this.props.onIndexChange) {
                const { itemsPerPage, totalItems } = this.props;
                this.props.onIndexChange({
                    prev,
                    index,
                    change,
                    page: {
                        left: this.fixIndex(pageLeftIndex),
                        right: this.fixIndex(pageLeftIndex + itemsPerPage - 1),
                    },
                    carousel: {
                        left: this.fixIndex(carouselLeftIndex),
                        right: this.fixIndex(carouselLeftIndex + totalItems - 1),
                    },
                });
            }
        });
    }
    /**
     * Checks for what should the values pageLeftIndex and carouselLeftIndex
     * be if the update for page or order should be done
     */
    shouldUpdate(index, direction) {
        const { totalItems } = this.props;
        const { carouselLeftIndex, pageLeftIndex } = this.state;
        const itemsPerPage = Math.min(this.props.itemsPerPage, totalItems);

        // Update page if the index is currently the right most item on the page
        const shouldUpdateNextPage = index === this.fixIndex(pageLeftIndex + itemsPerPage - 1);
        // Update order if the index is currently the right most item in the carousel
        const shouldUpdateNextOrder = index === this.fixIndex(carouselLeftIndex + totalItems - 1);
        // Update page if the index is currently the left most item on the page
        const shouldUpdatePrevPage = index === this.fixIndex(pageLeftIndex);
        // Update order if the index is currently the left most item in the carousel
        const shouldUpdatePrevOrder = index === this.fixIndex(carouselLeftIndex);

        if (direction === 'next') {
            return {
                pageLeftIndex: pageLeftIndex + (shouldUpdateNextPage && 1),
                carouselLeftIndex: carouselLeftIndex + (shouldUpdateNextOrder && 1),
            };
        }
        return {
            pageLeftIndex: pageLeftIndex - (shouldUpdatePrevPage && 1),
            carouselLeftIndex: carouselLeftIndex - (shouldUpdatePrevOrder && 1),
        };
    }
    /**
     * Function used to mimic thumbs feature where if the visible item interval would
     * be '1 2 3 4 5' - where lets say 4th is active and when we click on 5th we would
     * get '2 3 4 5 6' interval with the 5th being active.
     */
    jumpToItem(nextIndex, push = 0) {
        const { index } = this.state;
        const { itemsPerPage } = this.props;
        const diff = nextIndex - index;
        const goNext =
            (diff >= 1 && diff <= itemsPerPage) || (diff <= 1 && Math.abs(diff) >= itemsPerPage);
        const goPrev =
            (diff <= 1 && Math.abs(diff) <= itemsPerPage) || (diff >= 1 && diff >= itemsPerPage);

        let newPageLeftIndex;
        let newCarouselLeftIndex;
        let direction = 'next';
        const { pageLeftIndex: nextPageLeftIndex, carouselLeftIndex: nextCarouselLeftIndex } =
            this.shouldUpdate(nextIndex, direction);
        const { pageLeftIndex: prevPageLeftIndex, carouselLeftIndex: prevCarouselLeftIndex } =
            this.shouldUpdate(nextIndex);

        if (goNext) {
            newPageLeftIndex = nextPageLeftIndex;
            newCarouselLeftIndex = nextCarouselLeftIndex;
        } else if (goPrev) {
            newPageLeftIndex = prevPageLeftIndex + push;
            newCarouselLeftIndex = prevCarouselLeftIndex + push;
            direction = 'previous';
        }

        if (this.props.isJumpToItemForced) {
            this.goToItem(nextIndex, nextIndex, nextIndex);
        } else {
            this.goToItem(nextIndex, newPageLeftIndex, newCarouselLeftIndex, direction);
        }
    }
    goToItem(index, pageLeftIndex, carouselLeftIndex, change, forceFocus) {
        this.updateCarouselOrder(carouselLeftIndex).then(() => {
            // wait a bit for the components to render with new order
            setTimeout(() => {
                const { step, itemsPerPage } = this.props;

                if (step === itemsPerPage) {
                    this.props.onPageChange({ page: index / itemsPerPage });
                }

                this.updateCarouselIndex(
                    index,
                    pageLeftIndex,
                    carouselLeftIndex,
                    change,
                    forceFocus
                );
            }, 50);
        });
    }
    handleDirection(direction, forceFocus, isArrowPaging) {
        const { step } = this.props;
        const { index, carouselLeftIndex } = this.state;
        const polarity = direction === 'next' ? 1 : -1;
        const arrowClick =
            direction === 'next' ? this.props.onRightArrowClick : this.props.onLeftArrowClick;
        const polarizedStep = step * polarity;
        let { pageLeftIndex: newPageLeftIndex, carouselLeftIndex: newCarouselLeftIndex } =
            this.shouldUpdate(index, direction);
        let newIndex;
        arrowClick();
        if (isArrowPaging) {
            // isArrowPaging = arrowClick && step == itemsPerPage
            // handle as paging, otherwise handle below bc paging
            // w/ moving frame (step != itemsPerPage) works differently
            this.handlePage(direction);
            return;
        } else if (step === 1) {
            newIndex = this.fixIndex(index + polarity);
        } else {
            newIndex = this.fixStepJumpIndex(index + polarizedStep);
            newPageLeftIndex = newIndex;
            newCarouselLeftIndex = carouselLeftIndex;
        }
        this.goToItem(newIndex, newPageLeftIndex, newCarouselLeftIndex, direction, forceFocus);
    }

    canShowArrow(arrowType) {
        if (this.props.isInfinite && !this.props.dotsPerPage) {
            return true;
        }

        const { totalItems, step } = this.props;
        const { index } = this.state;

        if (arrowType === 'next') {
            if (index + step >= totalItems) {
                return false;
            }
        } else if (arrowType === 'previous') {
            if (index - step < 0) {
                return false;
            }
        }

        return true;
    }
    renderItems() {
        const items = [];
        const { itemKey, totalItems, step, onIndexChange, isVerticallyAligned } = this.props;
        const { index, carouselLeftIndex, forceFocus } = this.state;
        const itemsPerPage = step
            ? this.props.itemsPerPage
            : Math.min(this.props.itemsPerPage, totalItems);

        const className = this.getClass('item');
        const dimensionAttr = isVerticallyAligned ? 'Height' : 'Width';
        const baseItemStyle = {
            [`max${dimensionAttr}`]: `${100 / itemsPerPage}%`,
            [`min${dimensionAttr}`]: `${100 / itemsPerPage}%`,
        };

        let i;
        for (i = 0; i < totalItems; i++) {
            const key = `${itemKey}-${i}`;
            const isCurrentItem = i === index;
            const isVisible =
                this.state.pageLeftIndex <= i && this.state.pageLeftIndex < index + step;
            const order = this.fixIndex(i - carouselLeftIndex);
            const style = { ...baseItemStyle, order };
            const itemIndex = i;
            const onFocus = () => {
                // if manually focusing, ignore step
                const { pageLeftIndex } = this.getPagingInfo(itemIndex);
                if (this.state.pageLeftIndex !== pageLeftIndex) {
                    if (onIndexChange) {
                        onIndexChange({
                            prev: index,
                            index: itemIndex,
                            change: undefined,
                            page: {
                                left: this.fixIndex(pageLeftIndex),
                                right: this.fixIndex(pageLeftIndex + itemsPerPage - 1),
                            },
                            carousel: {
                                left: this.fixIndex(carouselLeftIndex),
                                right: this.fixIndex(carouselLeftIndex + totalItems - 1),
                            },
                        });
                    }
                    this.setState({
                        index: itemIndex,
                        pageLeftIndex,
                        carouselLeftIndex: 0,
                    });
                }
            };
            const focusCallback = element => {
                // if current item is set via arrow key, also focus it to allow complete
                // keyboard focus control without resetting focus on either end of the carousel
                // when combining tab and arrow control
                if (element && forceFocus && isCurrentItem) {
                    const focusableChild = element.querySelector(FOCUSABLE_SELECTORS);
                    if (focusableChild) focusableChild.focus();
                    this.setState({ forceFocus: false });
                }
            };
            items.push(
                <li
                    ref={focusCallback}
                    key={key}
                    className={className}
                    style={style}
                    onFocus={onFocus}
                >
                    {this.props.renderedItem
                        ? this.props.renderedItem[i]
                        : this.props.renderItem({
                              order,
                              index: i,
                              isCurrentItem,
                              isVisible,
                          })}
                </li>
            );
        }

        return items;
    }
    /**
     * @param {number=} index
     * @typedef {Object} PagingData
     * @property {number} page - Page given the index
     * @property {number} pages - Total pages given itemsPerPage
     * @property {number} pageLeftIndex - aka pageStart. ignore if step !== itemsPerPage
     * @returns {PagingData} paging data
     */
    getPagingInfo(index = this.state.index) {
        const { totalItems, itemsPerPage } = this.props;
        const pages = Math.ceil(totalItems / itemsPerPage);
        const page = Math.floor(index / Math.min(itemsPerPage, totalItems)) % pages;
        const pageLeftIndex = page * itemsPerPage;
        return { page, pages, pageLeftIndex };
    }

    /**
     * @param {number|'next'|'previous'} targetPage
     * @returns void
     */
    handlePage(targetPage) {
        const { page, pages } = this.getPagingInfo();
        // when targetPage is direction and not numeric, get new index using
        // traverseCircular to make sure that pages wrap
        if (targetPage === 'next') {
            targetPage = traverseCircular(page, pages, 1);
        } else if (targetPage === 'previous') {
            targetPage = traverseCircular(page, pages, -1);
        }

        const { totalItems } = this.props;
        const { index } = this.state;
        const itemsPerPage = Math.min(this.props.itemsPerPage, totalItems);
        const newIndex = targetPage * itemsPerPage;
        const direction = newIndex > index ? 'next' : 'previous';
        this.goToItem(newIndex, newIndex, 0, direction);
    }

    dotClick(page) {
        const { isAutoRun, onDotClick } = this.props;
        if (isAutoRun) {
            this.clearAutoRun();
            this.autoRun();
        }
        this.handlePage(page);
        onDotClick(page);
    }
    renderDots() {
        const { dotsPerPage, dotKey, totalItems, renderDot } = this.props;
        const { index } = this.state;
        const { pages } = this.getPagingInfo();
        const itemsPerPage = Math.min(this.props.itemsPerPage, totalItems);
        const dots = [];
        const defaultDotProps = { dotsPerPage, index, totalItems };
        const defaultDots = (
            <CarouselDots {...defaultDotProps} onDotClick={({ page }) => this.dotClick(page)} />
        );

        for (let page = 0; page < pages; page++) {
            const isCurrentDot = page === Math.floor(index / itemsPerPage);
            dots.push(
                <Link
                    key={`${dotKey}-${page}`}
                    onClick={() => this.dotClick(page)}
                    dataTn={`carousel-dot-${page}`}
                >
                    {renderDot({ isCurrentDot, pageIndex: page })}
                </Link>
            );
        }

        return (
            <div className={this.getClass('dotsWrapper')}>
                {renderDot() ? dots.map(dot => dot) : defaultDots}
            </div>
        );
    }

    renderArrow({ direction }) {
        const { itemsPerPage, step, enableKeyboardControl } = this.props;
        return (
            <Arrow
                direction={direction}
                className={this.getClass('arrowSVG')}
                enableKeyboardControl={enableKeyboardControl}
                onClick={() => this.handleDirection(direction, null, step === itemsPerPage)}
            />
        );
    }

    render() {
        const { totalItems, showDots, enableKeyboardControl, isVerticallyAligned } = this.props;
        const { carouselLeftIndex, pageLeftIndex } = this.state;
        const itemsPerPage = Math.min(this.props.itemsPerPage, totalItems);
        const hideArrows = this.props.hideArrows || totalItems <= itemsPerPage;
        const offset = (100 * (carouselLeftIndex - pageLeftIndex)) / itemsPerPage;
        const style = {
            transform: isVerticallyAligned
                ? `translate3d(0, ${offset}%, 0)`
                : `translate3d(${offset}%, 0, 0)`,
        };

        const wrapperClass = classnames(this.getClass('wrapper'), {
            [styles.slide]: this.state.slide,
        });

        return (
            <div
                className={wrapperClass}
                data-tn={this.props.dataTn}
                onKeyDown={enableKeyboardControl && totalItems > 1 ? this.onKeyDown : undefined}
                onFocus={() => {
                    if (this.props.isAutoRun) {
                        this.clearAutoRun();
                    }
                }}
                onBlur={e => {
                    if (this.props.isAutoRun && e.currentTarget.contains(e.target)) {
                        this.autoRun();
                    }
                }}
            >
                {/* Prev */}
                {!hideArrows && (
                    <span className={this.getClass('arrow', 'arrowLeft')}>
                        {this.canShowArrow('previous') &&
                            this.renderArrow({ direction: 'previous' })}
                    </span>
                )}
                {/* Carousel List */}
                <div
                    className={this.getClass('listWrapper')}
                    ref={element => {
                        // reset scrollTop and scrollLeft bc if an item in the carousel is tab-focused,
                        // the container will set scroll positions to "move" the focused item to the
                        // visible region. when combined with techniques to reposition the container,
                        // like transform in this component's case, some undesired visual behavior can
                        // occur due to the how the scroll positioning interacts with the
                        // transform/margin/left attributes
                        if (element) {
                            element.scrollTop = 0;
                            element.scrollLeft = 0;
                        }
                    }}
                >
                    <ul
                        className={classnames(this.getClass('list'), {
                            [styles.isVerticallyAligned]: isVerticallyAligned,
                        })}
                        data-tn="carousel-list-wrapper"
                        style={style}
                    >
                        {this.renderItems()}
                    </ul>
                    {showDots && this.renderDots()}
                </div>
                {/* Next */}
                {!hideArrows && (
                    <span className={this.getClass('arrow', 'arrowRight')}>
                        {this.canShowArrow('next') && this.renderArrow({ direction: 'next' })}
                    </span>
                )}
            </div>
        );
    }
}

Carousel.propTypes = {
    classNames: PropTypes.shape({
        wrapper: PropTypes.string,
        listWrapper: PropTypes.string,
        list: PropTypes.string,
        item: PropTypes.string,
        arrow: PropTypes.string,
        arrowSVG: PropTypes.string,
        arrowLeft: PropTypes.string,
        arrowRight: PropTypes.string,
        dotsWrapper: PropTypes.string,
    }),

    // settings
    index: PropTypes.number, // initial index (or current index if state controlled by parent component
    renderItem: PropTypes.func, // render function for rendering a single child
    renderedItem: PropTypes.arrayOf(PropTypes.node), // rendered children elements
    renderDot: PropTypes.func,
    totalItems: PropTypes.number, // number of items in the carouselComponent
    onPageChange: PropTypes.func, // triggered only when this.props.step === this.props.itemsPerPage
    onLeftArrowClick: PropTypes.func,
    onRightArrowClick: PropTypes.func,
    onDotClick: PropTypes.func,

    // options
    autoRunDuration: PropTypes.number,
    dotKey: PropTypes.string,
    dotsPerPage: PropTypes.number,
    dataTn: PropTypes.string,
    enableItemControl: PropTypes.bool,
    enableKeyboardControl: PropTypes.bool,
    hideArrows: PropTypes.bool,
    itemKey: PropTypes.string,
    itemsPerPage: PropTypes.number,
    isAutoRun: PropTypes.bool,
    isInfinite: PropTypes.bool,
    isJumpToItemForced: PropTypes.bool,
    showDots: PropTypes.bool,
    slideCarouselItems: PropTypes.bool,
    step: PropTypes.number,
    isVerticallyAligned: PropTypes.bool,

    // handlers
    onIndexChange: PropTypes.func,
};

Carousel.defaultProps = {
    classNames: {},

    // default settings
    index: 0,
    onPageChange: () => {},
    onLeftArrowClick: () => {},
    onRightArrowClick: () => {},
    onDotClick: () => {},
    renderItem: () => null,
    renderDot: () => null,
    totalItems: 1,

    // default options
    autoRunDuration: 5000,
    dataTn: '',
    dotKey: 'Dot',
    enableItemControl: false,
    enableKeyboardControl: false,
    hideArrows: false,
    itemKey: 'Item',
    itemsPerPage: 1,
    isAutoRun: false,
    isInfinite: true,
    showDots: false,
    slideCarouselItems: false,
    step: 1,
};
export default Carousel;

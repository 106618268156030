import { graphql, Environment } from 'react-relay';
import commitMutation from 'relay-commit-mutation-promise';
import {
    editUserPreferencesMutation as editUserPreferencesMutationType,
    EditUserPreferencesInput,
    editUserPreferencesMutation$data,
} from './__generated__/editUserPreferencesMutation.graphql';

const mutation = graphql`
    mutation editUserPreferencesMutation($input: EditUserPreferencesInput!) {
        editUserPreferences(input: $input) {
            user {
                preferences {
                    shareActivities
                }
            }
            emailSent
        }
    }
`;

export function editUserPreferencesMutation(
    environment: Environment,
    input: EditUserPreferencesInput
): Promise<editUserPreferencesMutation$data> {
    return commitMutation<editUserPreferencesMutationType>(environment, {
        mutation,
        variables: { input },
    });
}

/**
 * @generated SignedSource<<7bc14c189fafc0e28e7856aa88246502>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuickViewCategoryParagraph_item$data = {
  readonly quickViewDisplay: {
    readonly paragraphAttributeNames: {
      readonly origin: string | null;
      readonly period: string | null;
      readonly periodPrefix: string | null;
      readonly style: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "QuickViewCategoryParagraph_item";
};
export type QuickViewCategoryParagraph_item$key = {
  readonly " $data"?: QuickViewCategoryParagraph_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuickViewCategoryParagraph_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuickViewCategoryParagraph_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewDisplayType",
      "kind": "LinkedField",
      "name": "quickViewDisplay",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuickViewCategorySegments",
          "kind": "LinkedField",
          "name": "paragraphAttributeNames",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "period",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "origin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "style",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodPrefix",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "1200a2e512ce9218f0d0b8c85128d593";

export default node;

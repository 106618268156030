type HTMLModelViewerElement = {
    loaded: boolean;
    modelIsVisible: boolean;
    src: string;
    alt: string;
    poster: string;
    loading: 'auto' | 'lazy' | 'eager';
    reveal: 'auto' | 'interaction' | 'manual';
} & HTMLElement;

type TagNameMap = HTMLElementTagNameMap & { 'model-viewer': HTMLModelViewerElement };
type TagName = keyof TagNameMap;

const isTag = <T extends TagName>(
    arg: HTMLElement | EventTarget | undefined | null,
    tagName: T
): arg is TagNameMap[T] =>
    (arg as HTMLElement | undefined | null)?.tagName === tagName.toUpperCase();

/** @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState#value */
export const READY_STATES = {
    /** No information is available about the media resource. */
    HAVE_NOTHING: 0,
    /** Enough of the media resource has been retrieved that the metadata attributes are initialized. Seeking will
     * no longer raise an exception. */
    HAVE_METADATA: 1,
    /** Data is available for the current playback position, but not enough to actually play more than one frame. */
    HAVE_CURRENT_DATA: 2,
    /** Data for the current playback position as well as for at least a little bit of time into the future is
     * available (in other words, at least two frames of video, for example). */
    HAVE_FUTURE_DATA: 3,
    /** Enough data is available—and the download rate is high enough—that the media can be played through to the
     * end without interruption. */
    HAVE_ENOUGH_DATA: 4,
} as const;

export const getReadyState = (element?: HTMLElement | EventTarget | null): number => {
    if (isTag(element, 'video')) {
        return element.readyState ?? READY_STATES.HAVE_NOTHING;
    } else if (isTag(element, 'img')) {
        return element.complete ? READY_STATES.HAVE_ENOUGH_DATA : READY_STATES.HAVE_NOTHING;
    } else if (isTag(element, 'model-viewer')) {
        return element.loaded ? READY_STATES.HAVE_ENOUGH_DATA : READY_STATES.HAVE_NOTHING;
    }
    return READY_STATES.HAVE_NOTHING;
};

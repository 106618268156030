import { FC } from 'react';
import classnames from 'classnames';
import VideoFlat from 'dibs-icons/exports/legacy/VideoFlat';

import styles from './VideoIcon.scss';

type VideoIconProps = {
    hidden: boolean;
};
export const VideoIcon: FC<VideoIconProps> = ({ hidden }) => {
    const classes = classnames(styles.videoIcon, {
        [styles.hideVideoIcon]: hidden,
    });

    return (
        <div className={classes}>
            <VideoFlat />
        </div>
    );
};

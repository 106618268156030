const SRCSET_SIZES = [200, 320, 400, 640, 800, 960];

/**
 * Returns a single url with passed fastly options as query params.
 *
 * @export
 * @param {string} url - url of the image
 * @param {number} width - desired image width
 * @param {number} [quality=60] - image quality
 * @returns {string}
 */
export function getSrcSetUrl(originalUrl: string, width: number, quality: number = 60): string {
    const [url, searchString] = originalUrl.split('?');
    const queryParams = new URLSearchParams(searchString);
    // need to replace following params to generate correct search string
    queryParams.set('disable', 'upscale');
    queryParams.set('auto', 'webp');
    queryParams.set('quality', String(quality));
    queryParams.set('width', String(width));

    return `${url}?${queryParams.toString()}`;
}

type SrcSetOptions = {
    widths?: number[];
    quality?: number;
};
/**
 * Builds a src set string to be used accross this app.
 *
 * @export
 * @param {string} url - url of the image
 * @returns {string}
 */
export function getSrcSetString(url: string, options: SrcSetOptions = {}): string {
    const { widths, quality } = options;
    return (widths || SRCSET_SIZES)
        .map(width => `${getSrcSetUrl(url, width, quality)} ${width}w`)
        .join(', ');
}

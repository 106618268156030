import { getTouchDistance, touchToCoordinates } from './utils';
import MultiTouchDetector from './MultiTouchDetector';

const noop = () => {};

/**
 * Grab relevant state data from the passed in event.
 * @param  {Object} e Event
 * @return {Object}   State data
 */
function stateFromTouch(e) {
    const { changedTouches, target } = e;

    return {
        time: Date.now(),
        position: touchToCoordinates(target, changedTouches[0]),
    };
}

// Maximum pixel distance of second tap from first tap.
const THRESHOLD_DISTANCE = 30;
// Maximum time in ms of second tap from first tap.
const THRESHOLD_TIME = 500;
// Maximum time in ms that a touch will count as a tap.
const THRESHOLD_HOLD_TIME = 100;

/**
 * Detect two quick, sequential taps on the given DOM element.
 *
 * @param {Object} options.el The DOM element.
 * @param {Function} [options.onDoubleTap] Callback to fire after a successful double tap.
 */
class DoubleTapDetector {
    constructor({ el, onDoubleTap = noop } = {}) {
        if (!el) {
            throw new Error('Must pass `el` to DoubleTapDetector');
        }

        Object.assign(this, {
            _el: el,
            _onDoubleTap: onDoubleTap,
            _multiTouchDetector: new MultiTouchDetector({
                onSingleTouchStart: this._onSingleTouchStart.bind(this),
                onSingleTouchEnd: this._onSingleTouchEnd.bind(this),
                el,
            }),
        });

        // Set the initial state
        this._resetState();
    }

    /**
     * Transition to the next state after a tap
     */
    _onSingleTouchStart(e) {
        this._state = stateFromTouch(e);
        this._tap++;
    }

    _onSingleTouchEnd(e) {
        const endData = stateFromTouch(e);

        // Attempt to transition to the next state if the tap passes validation
        if (this._tap === 1) {
            this._tryNextState(endData, this._state, newData => this._handleFirstTap(newData));
        } else if (this._tap === 2) {
            this._tryNextState(endData, this._state, newData => this._handleSecondTap(newData));
        }
    }

    _resetState() {
        if (this._timer) {
            clearTimeout(this._timer);
        }

        this._timer = null;
        this._initialPosition = null;
        this._state = {};
        this._tap = 0;
    }

    /**
     * Validate a tap after it ends and call the passed in callback if it passes.
     * @param  {Object}   endData   Data from previous tap
     * @param  {Object}   newData     Data from current tap
     * @param  {Function} callback  Callback to fire on successfuly validation
     */
    _tryNextState(endData, { time, position }, callback) {
        // Criteria for a successful tap:
        // The touch must not have been held for longer than THRESHOLD_HOLD_TIME
        // The touch must not have moved farther than THRESHOLD_DISTANCE
        if (
            endData.time - time > THRESHOLD_HOLD_TIME ||
            getTouchDistance(endData.position, position) > THRESHOLD_DISTANCE
        ) {
            this._resetState();
        } else {
            callback(endData);
        }
    }

    _handleFirstTap(data) {
        // Create a timer that expires if a second tap does not happen before THRESHOLD_TIME
        this._initialPosition = data.position;
        this._timer = setTimeout(() => {
            // If the timer finishes then start over because we didn't get a second tap in time.
            this._resetState();
        }, THRESHOLD_TIME);
    }

    _handleSecondTap(data) {
        const { position } = data;

        // Check if the second tap was within THRESHOLD_DISTANCE of the first tap
        if (getTouchDistance(position, this._initialPosition) > THRESHOLD_DISTANCE) {
            this._resetState();
        } else {
            this._onDoubleTap(position.x, position.y);
        }
    }

    destroy() {
        clearTimeout(this._timer);
        this._multiTouchDetector.destroy();
    }
}

export default DoubleTapDetector;

import { FC, Suspense, SuspenseProps } from 'react';
import { useClientState } from 'dibs-react-hooks/exports/useClientState';

export const ClientSuspense: FC<SuspenseProps> = ({ fallback, children }) => {
    const isClient = useClientState();

    if (isClient) {
        return <Suspense fallback={fallback}>{children}</Suspense>;
    }

    return <>{fallback}</>;
};

/**
 * @generated SignedSource<<9061400b64171b09ea39ee9a9cd8327f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CmsHomepageBannerFormatEnumType = "BURN_IN" | "CAPTION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HpSharedPromoModulePromoItem_promotion$data = {
  readonly cta: string | null;
  readonly description: string | null;
  readonly format: CmsHomepageBannerFormatEnumType | null;
  readonly imageCopy: string | null;
  readonly imageHeight?: string | null;
  readonly imageUrl?: string | null;
  readonly imageWidth?: string | null;
  readonly isFullBleedImage: boolean | null;
  readonly linkData: {
    readonly path: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroBannerText_linkData" | "HpSharedModuleItemTile_linkData" | "SeoLink_linkData">;
  };
  readonly mobileImageHeight?: string | null;
  readonly mobileImageUrl?: string | null;
  readonly mobileImageWidth?: string | null;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedSharedHeroBanner_promoItem">;
  readonly " $fragmentType": "HpSharedPromoModulePromoItem_promotion";
};
export type HpSharedPromoModulePromoItem_promotion$key = {
  readonly " $data"?: HpSharedPromoModulePromoItem_promotion$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedPromoModulePromoItem_promotion">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isMobile"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedPromoModulePromoItem_promotion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageCopy",
      "storageKey": null
    },
    {
      "condition": "isMobile",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageHeight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageWidth",
          "storageKey": null
        }
      ]
    },
    {
      "condition": "isMobile",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mobileImageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mobileImageHeight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mobileImageWidth",
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cta",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "linkData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedModuleItemTile_linkData"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedHeroBannerText_linkData"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SeoLink_linkData"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HpSharedSharedHeroBanner_promoItem"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFullBleedImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "format",
      "storageKey": null
    }
  ],
  "type": "PromoItem",
  "abstractKey": null
};

(node as any).hash = "c695d94878656539c903b32057ca7bbe";

export default node;

import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type ExclamationProps = {
    className?: string;
};

const Exclamation: FunctionComponent<ExclamationProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'exclamation'}
            viewBox="0 0 250 250"
        >
            <path d="M124.3 248.1c-14 0-25.1-11-25.1-25.1 0-14 11.3-25.4 25.1-25.4 14 0 25.4 11.3 25.4 25.4-.2 14-11.5 25.1-25.4 25.1zm-9-68.1l-.3-4.8c-1.5-35.9-6.8-80.3-10.3-108.8-2.2-17.1-3.5-28.9-3.5-33.4 0-14.8 10.3-26.6 23.4-26.6s23.4 11.4 23.4 26c0 4.8-1.5 17.6-4 37.1-3.7 29.2-8.5 71.3-10.6 105.8l-.3 4.8-17.8-.1z" />
        </svg>
    );
};
export default Exclamation;

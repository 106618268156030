import * as React from 'react';

import { saleLabel } from './messages';

// Component
import { PriceLabel } from './PriceLabel';
import { textPercentageOff } from '../helpers/priceTypeConstants';

// Styles
import styles from './styles.scss';

type SaleProps = {
    currency: string;
    percentageOff?: string | null;
    price: number;
    quantityDisplay?: string | null;
    showPriceVariability?: boolean | null;
};

export const Sale: React.FC<SaleProps> = ({
    percentageOff = null,
    price,
    quantityDisplay,
    currency,
    showPriceVariability = false,
}) => {
    return (
        <React.Fragment>
            <PriceLabel
                price={price}
                label={percentageOff ? saleLabel : ''}
                quantityDisplay={quantityDisplay}
                currency={currency}
                showPriceVariability={showPriceVariability}
            />
            {percentageOff ? (
                <div
                    className={`${styles.percentageOff} ${styles.sale} `}
                    data-tn="discount-amount"
                >
                    {textPercentageOff(percentageOff)}
                </div>
            ) : null}
        </React.Fragment>
    );
};

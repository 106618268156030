import { FunctionComponent } from 'react';
import * as React from 'react';
import styles from './FormLabel.scss';

type FormLabelProps = {
    children?: React.ReactNode;
    htmlFor?: string;
    hideLabel?: boolean;
};

export const FormLabel: FunctionComponent<FormLabelProps> = ({ htmlFor, children }) =>
    !children ? null : (
        <label htmlFor={htmlFor} className={styles.label}>
            {children}
        </label>
    );

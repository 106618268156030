/**
 * @generated SignedSource<<08f1213a54ae7ee567b1db9268b66db0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShareActivityAlert_user$data = {
  readonly preferences: {
    readonly shareActivities: boolean | null;
  } | null;
  readonly serviceId: string | null;
  readonly " $fragmentType": "ShareActivityAlert_user";
};
export type ShareActivityAlert_user$key = {
  readonly " $data"?: ShareActivityAlert_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShareActivityAlert_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShareActivityAlert_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserPreferences",
      "kind": "LinkedField",
      "name": "preferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shareActivities",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "1f8e6e09353d2c2af6a4d8f829b056a1";

export default node;

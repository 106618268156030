import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type MaskProps = {
    className?: string;
};

const Mask: FunctionComponent<MaskProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'mask'}
            viewBox="0 -300 1060 1060"
        >
            <path d="M1044.6 5c-47 44.5-253.2 94.9-330 115.2-77.4 20.6-142.6 56.2-184.6 96.7-42-40.5-107.2-76.1-184.6-96.6C268.5 99.9 62.3 49.6 15.3 5c-40-37.8 9.4 148.4 21.3 186.3C98.8 388.4 142.1 389.4 268.5 411c70.6 12.1 146.6 12.3 206-1.6 26.6-15.4 23.3-89.8 55.5-89.8 32.3 0 28.9 74.4 55.5 89.8 59.4 13.9 135.4 13.7 206 1.6 126.3-21.7 169.7-22.6 231.8-219.7 12-37.8 61.3-224.1 21.3-186.3zm-603 301.6c-11.7 44.2-89 62.1-172.8 39.7-83.5-22.1-142-75.9-130.3-120.2 11.7-44.1 89.2-61.9 172.8-39.7 83.8 22.2 142 76 130.3 120.2zM793 346.3c-83.7 22.5-161 4.5-172.7-39.7-11.7-44.2 46.6-98 130.3-120.2 83.6-22.2 161-4.4 172.7 39.7 11.7 44.2-46.8 98.1-130.3 120.2z" />
        </svg>
    );
};
export default Mask;

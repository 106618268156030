import MultiTouchDetector from './MultiTouchDetector';
const noop = () => {};

/**
 * Detect a "pan" (drag) on the given DOM element.
 * @param {Object} options.el The DOM element.
 * @param {Function} [options.onPan] Callback to fire on drag.
 */
export class PanDetector {
    constructor({ el, onPan = noop, overridePanMoveDefault = false, zoomedIn = false } = {}) {
        if (!el) {
            throw new Error('Must pass `el` to PanDetector');
        }

        Object.assign(this, {
            _previousX: null,
            _previousY: null,
            _el: el,
            _onPan: onPan,
            _overridePanMoveDefault: overridePanMoveDefault,
            _zoomedIn: zoomedIn,
            _multiTouchDetector: new MultiTouchDetector({
                onSingleTouchStart: this._onSingleTouchStart.bind(this),
                onSingleTouchMove: this._onSingleTouchMove.bind(this),
                el,
            }),
        });
    }

    _onSingleTouchStart(e) {
        const { clientX, clientY } = e.touches[0];

        // Get the initial position and time so we can do a difference on them later.
        this._previousX = clientX;
        this._previousY = clientY;
    }

    _onSingleTouchMove(e, isPassive) {
        // if overriding, do not prevent default when zoomed out
        // fixes issues where people can't scroll over children of PinchZoom
        if ((this._overridePanMoveDefault && this._zoomedIn) || !this._overridePanMoveDefault) {
            // preventDefault on a passive listener does nothing and logs warning.
            if (!isPassive) {
                e.preventDefault();
            }
        }

        const { clientX, clientY } = e.touches[0];

        // Pass the distance dragged to the callback
        this._onPan(clientX - this._previousX, clientY - this._previousY);

        this._previousX = clientX;
        this._previousY = clientY;
    }

    destroy() {
        this._multiTouchDetector.destroy();
    }
}

/**
 * @generated SignedSource<<d6e066d10c983da62b93b5e0e1f0aced>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecommendedItemHeart_item$data = {
  readonly ecommerceTrackingParams: any | null;
  readonly serviceId: string | null;
  readonly " $fragmentType": "RecommendedItemHeart_item";
};
export type RecommendedItemHeart_item$key = {
  readonly " $data"?: RecommendedItemHeart_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecommendedItemHeart_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecommendedItemHeart_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ecommerceTrackingParams",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "96b9e57b9810e4ffd2e5944e7846aafc";

export default node;

import { FC, ReactNode } from 'react';
import { colors, SassyColorNames } from 'dibs-sassy/exports/colors';

import styles from './main.scss';

const defaultViewportColor: SassyColorNames = 'sassyColorMoonstone';

type PageLayoutProps = {
    dataTn?: string;
    setOffset?: boolean;
    setViewportBackground?: boolean | SassyColorNames;
    backgroundUrl?: string;
    backgroundRepeat?: string;
    backgroundSize?: string;
    children: ReactNode;
};

export const PageLayout: FC<PageLayoutProps> = props => {
    const {
        children,
        dataTn,
        setOffset = true,
        setViewportBackground,
        backgroundUrl,
        backgroundRepeat,
        backgroundSize,
    } = props;

    let backgroundColor;
    let backgroundImage;
    let backgroundImgStyle;
    let style;

    if (backgroundUrl) {
        backgroundImage = `url('${backgroundUrl}')`;
        backgroundImgStyle = {
            backgroundImage,
            backgroundRepeat: 'inherit',
            backgroundSize: 'auto',
        };
        if (backgroundRepeat) {
            backgroundImgStyle.backgroundRepeat = backgroundRepeat;
        }
        if (backgroundSize) {
            backgroundImgStyle.backgroundSize = backgroundSize;
        }
    }

    const pageClassName = (setOffset && styles.setOffset) || '';

    let page = (
        <div
            className={pageClassName}
            style={backgroundUrl && !setViewportBackground ? backgroundImgStyle : style}
            data-tn={dataTn}
        >
            {children}
        </div>
    );
    let content = page;

    if (!setOffset && !dataTn && children) {
        page = children as JSX.Element;
    }

    if (setViewportBackground) {
        if (backgroundUrl) {
            style = backgroundImgStyle;
        } else {
            backgroundColor =
                colors[
                    typeof setViewportBackground === 'boolean'
                        ? defaultViewportColor
                        : setViewportBackground
                ];
            if (backgroundColor) {
                style = { backgroundColor };
            }
        }
        content = (
            <div className={styles.viewportContainer} style={style}>
                {page}
            </div>
        );
    }

    return content;
};

/**
 * @generated SignedSource<<350f23114fcdc64646eec169c12b1f8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedHeroModule_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroModuleControl_viewer" | "HpSharedHeroModuleVariant_viewer">;
  readonly " $fragmentType": "HpSharedHeroModule_viewer";
};
export type HpSharedHeroModule_viewer$key = {
  readonly " $data"?: HpSharedHeroModule_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedHeroModule_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isClient"
    },
    {
      "kind": "RootArgument",
      "name": "isEditorsPickHpVariant"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedHeroModule_viewer",
  "selections": [
    {
      "condition": "isClient",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "condition": "isEditorsPickHpVariant",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HpSharedHeroModuleControl_viewer"
            }
          ]
        },
        {
          "condition": "isEditorsPickHpVariant",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HpSharedHeroModuleVariant_viewer"
            }
          ]
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "ba10e518c0ab940d2e37ab9a85262e96";

export default node;

import { FC, lazy, Suspense } from 'react';
import { useClientState } from 'dibs-react-hooks/exports/useClientState';

import style from './AppStoreLink-style.scss';

const AppStoreLinkReactLazy = lazy(
    () => import(/* webpackChunkName: "AppStoreLink" */ './AppStoreLink')
);

const AppStoreLinkPlaceholder: FC = () => <div className={style.placeholder} />;

const AppStoreLinkLazy: FC = () => {
    const isClient = useClientState();
    if (!isClient) {
        return <AppStoreLinkPlaceholder />;
    }
    return (
        <Suspense fallback={<AppStoreLinkPlaceholder />}>
            <AppStoreLinkReactLazy />
        </Suspense>
    );
};

export default AppStoreLinkLazy;

import { FC, ComponentProps } from 'react';
import { useIntl } from 'dibs-react-intl';
import {
    LOCALE_US,
    LOCALE_DE,
    LOCALE_FR,
    LOCALE_PSEUDO,
    LOCALE_DEBUG,
    LOCALE_IT,
    LOCALE_ES,
} from 'dibs-intl/exports/locales';
import LogoVip from 'dibs-icons/exports/legacy/LogoVip';
import LogoVipDe from 'dibs-icons/exports/legacy/LogoVipDe';
import LogoVipFr from 'dibs-icons/exports/legacy/LogoVipFr';
import LogoVipIt from 'dibs-icons/exports/legacy/LogoVipIt';
import LogoVipEs from 'dibs-icons/exports/legacy/LogoVipEs';
import { hasKey } from 'dibs-ts-utils/exports/hasKey';

type Props = ComponentProps<typeof LogoVip | typeof LogoVipDe | typeof LogoVipFr>;

export const LocalizedLogoVipIcon: FC<Props> = props => {
    const { locale } = useIntl();

    const logoLookup = {
        [LOCALE_PSEUDO]: LogoVip,
        [LOCALE_DEBUG]: LogoVip,
        [LOCALE_US]: LogoVip,
        [LOCALE_DE]: LogoVipDe,
        [LOCALE_FR]: LogoVipFr,
        [LOCALE_IT]: LogoVipIt,
        [LOCALE_ES]: LogoVipEs,
    };

    const Component = hasKey(logoLookup, locale) ? logoLookup[locale] : LogoVip;

    return <Component {...props} />;
};

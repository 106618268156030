import { element, func, string } from 'prop-types';
import { Link } from 'dibs-elements/exports/Link';
import styles from './Dropdown-style.scss';

const DropdownHeader = props => {
    const { title, link, href, onClick } = props;

    return (
        <div className={styles.header}>
            <div className={styles.headerTitle}>{title}</div>
            {link ? (
                <Link className={styles.headerLink} onClick={onClick} href={href}>
                    {link}
                </Link>
            ) : null}
        </div>
    );
};

DropdownHeader.propTypes = {
    title: element,
    link: element,
    href: string,
    onClick: func,
};

export default DropdownHeader;

/* eslint-disable relay/unused-fields */
import { graphql } from 'react-relay/legacy';

const homepageRootQuery = graphql`
    query homepageRootQuery(
        $isMobile: Boolean!
        $homepageId: String!
        $userId: String!
        $hasUserId: Boolean!
        $fetchInitialHomepage: Boolean!
        $localRecentHistoryList: [LocalRecentHistoryListInputType] = []
        $recentActivityDesignVariant: String = "default"
        $userType: String!
        $abTestList: [String] = []
        $userCountryCode: String!
        $isTrade: Boolean!
        $priceBookName: String!
        $fetchBuyerActiveOffers: Boolean!
        $activePrivateOfferCount: Int!
        $isEditorsPickHpVariant: Boolean!
        $isClient: Boolean!
        $isDynamicCollectionVariant: Boolean!
    ) {
        viewer {
            ...HpSharedLayout_viewer
            homepage(rootId: $homepageId, abTestList: $abTestList) {
                ...getHomePageMetaContent_homepage
                # necessary to throw error & not cache homepage if no modules return
                modules {
                    __id
                }
            }
        }
    }
`;

const homepageVariables = {
    fetchInitialHomepage: true,
    hasRecentlyViewedItems: false,
    isMobile: false,
    homepageId: '',
    userId: '',
    hasUserId: false,
    recentlyViewedItemIds: [],
    userIds: [],
    isClient: false,
    countryCode: '',
    zipCode: '',
    userType: '',
    isTrade: false,
    userCountryCode: '',
    priceBookName: 'DEFAULT',
    fetchBuyerActiveOffers: false,
    activePrivateOfferCount: 0,
    isEditorsPickHpVariant: false,
    isDynamicCollectionVariant: false,
};

export { homepageRootQuery, homepageVariables };

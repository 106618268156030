/**
 * @generated SignedSource<<9d83f7622065f55a38b55a8136111bd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CmsHomepageBannerFormatEnumType = "BURN_IN" | "CAPTION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HpSharedTopHeroBannerModule_componentModule$data = {
  readonly carouselSpeed: string | null;
  readonly cmsDisplayTitle: string | null;
  readonly messages: {
    readonly items: ReadonlyArray<{
      readonly cmsId: number | null;
      readonly format: CmsHomepageBannerFormatEnumType | null;
      readonly imageUrl: string | null;
      readonly isFullBleedImage: boolean | null;
      readonly linkData: {
        readonly path: string | null;
      };
      readonly videoAutoplay: boolean | null;
      readonly " $fragmentSpreads": FragmentRefs<"HpSharedSharedHeroBanner_topItem" | "HpSharedTopHeroBannerImageItem_bannerItem">;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "HpSharedTopHeroBannerModule_componentModule";
};
export type HpSharedTopHeroBannerModule_componentModule$key = {
  readonly " $data"?: HpSharedTopHeroBannerModule_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedTopHeroBannerModule_componentModule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "userType"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedTopHeroBannerModule_componentModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cmsDisplayTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carouselSpeed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "userType",
          "variableName": "userType"
        }
      ],
      "concreteType": "TopHeroBannerMessages",
      "kind": "LinkedField",
      "name": "messages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TopHeroBannerItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "format",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoAutoplay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cmsId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isFullBleedImage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LinkData",
              "kind": "LinkedField",
              "name": "linkData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "path",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HpSharedTopHeroBannerImageItem_bannerItem"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HpSharedSharedHeroBanner_topItem"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TopHeroBannerModule",
  "abstractKey": null
};

(node as any).hash = "93fd51ee7f9d2bbb64fb158e7971a82b";

export default node;

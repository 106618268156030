import { useId, FunctionComponent } from 'react';
import * as React from 'react';
import classNames from 'classnames';

import { SIZES } from '../Common/constants';

import styles from './main.scss';

type SpinnerProps = {
    containerClass?: string;
    dark?: boolean;
    black?: boolean;
    id?: string;
    // add new sizes as needed
    size?: typeof SIZES.small | typeof SIZES.tiny;
};

const GRADIENT_ID_BASE = 'dibs-spinner-gradient';

const renderGradient = (
    gradientId: string,
    solidClass: string,
    transparentClass: string
): React.ReactNode => (
    <linearGradient id={gradientId} x1="1" y1="0" x2="0" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="40%" className={transparentClass} />
        <stop offset="85%" className={solidClass} />
        <stop offset="100%" className={solidClass} />
    </linearGradient>
);

export const Spinner: FunctionComponent<SpinnerProps> = props => {
    const { dark = true, black = false, id = '', containerClass = '', size = 'small' } = props;
    const fallbackId = `${GRADIENT_ID_BASE}-${useId()}`;
    const gradientId = id ? GRADIENT_ID_BASE + id : fallbackId;
    let solid;
    if (black) {
        solid = styles.colorBlackSolid;
    } else if (dark) {
        solid = styles.colorDarkSolid;
    } else {
        solid = styles.colorLightSolid;
    }
    const transparent = dark || black ? styles.colorDarkTransparent : styles.colorLightTransparent;
    const shadow = classNames(styles.shadow, {
        [styles.colorDarkShadow]: dark || black,
        [styles.colorLightShadow]: !(dark || black),
    });
    const container = classNames(containerClass, {
        [styles.small]: size === 'small',
        [styles.tiny]: size === 'tiny',
    });

    return (
        <div className={container} data-tn="dibs-spinner">
            <svg className={styles.spinner} viewBox="0 0 250 250">
                <defs>{renderGradient(gradientId, solid, transparent)}</defs>
                <circle className={shadow} cx={125} cy={125} r={106} />
                <g>
                    <path
                        fill={`url(#${gradientId})`}
                        d="M125,250 C194.035937,250 250,194.035937 250,125 L212.5,125 C212.5,173.246875 173.248437,212.5 125,212.5"
                    />
                    <path
                        className={solid}
                        d="M125.217391,212.5 C76.8850435,212.5 37.5652174,173.246875 37.5652174,125 C37.5652174,76.753125 76.8850435,37.5 125.217391,37.5 C135.590087,37.5 144,29.1046875 144,18.75 C144,8.3953125 135.590087,0 125.217391,0 C56.0613913,0 0,55.9640625 0,125 C0,194.035937 56.0613913,250 125.217391,250"
                    />
                </g>
            </svg>
        </div>
    );
};

'use strict';

import { Network } from 'relay-runtime';
import serverVars from 'server-vars';
import { getBuyerToken } from 'dibs-cookie-jar';
import { createFetcher } from 'dibs-relay-network-layer/client';

export const network = Network.create(
    createFetcher({
        userType: 'buyer',
        getQueries: () => ({
            ...getBuyerToken(document.cookie),
            locale: serverVars.get('locale'),
        }),
    })
);

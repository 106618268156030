import SwipeDetector from './SwipeDetector';
import {
    setTransformStyle,
    setTransitionStyle,
    normalizePage,
    getTotalPages,
    getPagedItemWidth,
    setWidthStyle,
} from './swiperUtils';

const noop = () => {};

// The transition effect to use when the swipe occurs.
const TRANSITION = 'transform 250ms ease';
// The amount to slow down the dragging effect by when the user reaches the end of the list.
const SLOW_DOWN_FACTOR = 2.5;

/**
 * View-model for carousels that are swiped through on a touch device.
 * @class
 */
class Model {
    constructor({
        itemsPerPage,
        itemCount,
        hasInfiniteLoop,
        startingPage = 0,
        onPageChange = noop,
        onStateChange = noop,
        trackedItemIndex,
        onSwipeStart = noop,
        onSwipeEnd = noop,
    } = {}) {
        this.onChangeCallbacks = [];

        this.onTransitionEnd = this.onTransitionEnd.bind(this);

        const pageJumpDistance = Math.floor(itemsPerPage);
        const cloneCount = hasInfiniteLoop ? this.getCloneCount(itemsPerPage) * 2 : 0;
        const totalPages = getTotalPages(itemCount + cloneCount, itemsPerPage, hasInfiniteLoop);
        if (trackedItemIndex) {
            startingPage = this.getPageFromTrackedItemIndex(trackedItemIndex, pageJumpDistance);
        }
        const page = normalizePage(startingPage, totalPages, hasInfiniteLoop);

        Object.assign(this, {
            _el: null,
            _itemsPerPage: itemsPerPage,
            _itemCount: itemCount,
            _hasInfiniteLoop: hasInfiniteLoop,
            // pages are 0 index based
            _totalPages: totalPages - 1,
            _onPageChange: onPageChange,
            // Calculate the width of each item.
            _width: getPagedItemWidth(itemsPerPage),
            _swipeDetector: null,
            _onSwipeStart: onSwipeStart,
            _onSwipeEnd: onSwipeEnd,
            _pageJumpDistance: pageJumpDistance,
            /**
             * If there are not enough items to fully fill the page, we will shift the last and
             * first pages so that cloned pages align consistently.
             */
            _missingItemCount: itemCount % pageJumpDistance,
            _onStateChange: (...args) => {
                onStateChange.call(onStateChange, ...args);
                this.onChangeCallbacks.forEach(callback => callback.call(callback, ...args));
            },
            _state: {
                page,
                /**
                 * Special variable for tracking the last page that was reported by `onPageChange`.
                 * Only used if infinite swipe is enabled
                 */
                lastInfinitePage: page - 1,
                // Track the position of the items when dragging through the list.
                offset: 0,
                // Track whether we should use the transition effect during a given render.
                transition: true,
                disabled: false,
                trackedItemIndex: 0,
            },
        });
    }

    getCloneCount(itemsPerPage = this._itemsPerPage) {
        return Math.ceil(itemsPerPage);
    }

    enableSwipe() {
        this.getState().disabled = false;
    }

    disableSwipe() {
        this.getState().disabled = true;
    }

    /**
     * Pass in the DOM element to manage. This should be a raw DOM element and not a React or jQuery object. The reason
     * the element cannot be passed into the constructor is that initial construction is meant to take place outside of
     * the base carousel but the element is only accessible after construction, once the base carousel has been rendered
     * for the first time. It is possible the "view-model" doesn't need to do anything with the element in which case
     * the `_el` property can be ignored.
     * @param  {Object} el DOM element to manage.
     */
    onMount(el) {
        if (!el) {
            throw new Error('Must pass `el` to carousel model on mount.');
        }

        this._el = el;
        this._afterMount();
    }

    /**
     * Call the state change callback with the current state.
     */
    _updateState() {
        this._onStateChange(this.getState());
    }

    /**
     * Custom logic after an element is passed in `onMount`, e.g. adding event listeners on the element.
     */
    _afterMount() {
        // Add swipe handlers.
        this._swipeDetector = new SwipeDetector({
            onPartialSwipe: this._updateOffset.bind(this),
            onSwipeSuccess: this._swipe.bind(this),
            onSwipeFailure: this._resetOffset.bind(this),
            onSwipeStart: this._onSwipeStart.bind(this),
            onSwipeEnd: this._onSwipeEnd.bind(this),
            el: this._el,
        });
    }

    /**
     * Do any cleanup necessary to avoid memory leaks, e.g. removing event listeners from the passed in element.
     */
    destroy() {
        if (this._swipeDetector) {
            this._swipeDetector.destroy();
        }
    }

    _getOffset() {
        let distanceModifier = 0;
        if (this._hasInfiniteLoop) {
            const cloneCount = this.getCloneCount();
            const offsetFirstItem = cloneCount - this._pageJumpDistance;
            distanceModifier = offsetFirstItem;
            if (this._missingItemCount) {
                if (this._isFirstPage()) {
                    distanceModifier = cloneCount - this._missingItemCount;
                } else if (this._isLastPage()) {
                    distanceModifier = -(
                        this._pageJumpDistance -
                        offsetFirstItem -
                        this._missingItemCount
                    );
                }
            }
        } else if (this._isLastPage() && this._totalPages !== 0) {
            if (this._missingItemCount) {
                distanceModifier = -(this._itemsPerPage - this._missingItemCount);
            } else {
                distanceModifier = -(this._itemsPerPage - this._pageJumpDistance);
            }
        }

        return this._pageJumpDistance * this._state.page + distanceModifier;
    }

    _getTransformValue() {
        const percentage = this._getOffset() * -this._width;
        const state = this.getState();
        /**
         * The `_offset` is used to track the "panning" that happens when the user drags
         * the carousel before the full swipe occurs
         */
        if (state.offset !== 0) {
            return `calc(${percentage}% + ${state.offset}px)`;
        }
        return `${percentage}%`;
    }

    /**
     * Modify the styles object for the carousel `ul`. This gets called before a re-render of the carousel.
     *
     * @param {Object} style - Style object to mutate for the carousel `el`.
     */
    transformListStyle(style) {
        const transformValue = this._getTransformValue();

        setTransformStyle(style, `translateX(${transformValue})`);

        // When the user is just "panning" we don't want to have a transition effect.
        if (this.getState().transition) {
            setTransitionStyle(style, TRANSITION);
        }
    }

    /**
     * Modify the styles object for the carousel item `li`s. This gets called before a re-render of the carousel.
     *
     * @param {Object} style - Style object to mutate for each carousel item `li`.
     * @param {Number} i - The index of the item.
     */
    transformItemStyle(style) {
        setWidthStyle(style, this._width);
    }

    onTransitionEnd() {
        if (this._hasInfiniteLoop) {
            if (this._isFirstPage()) {
                this._setPage(this._totalPages - 1);
            } else if (this._isLastPage()) {
                this._setPage(1);
            }
        }
    }

    /**
     * Update the position of the carousels in response to "drag/pan" by the user.
     * @param {Array} touchCords Array consisting of the touch coordinates [x, y], passed from the `SwipeDetector`.
     * @param {String} direction The swipe direction.
     */
    _updateOffset(touchCords, direction) {
        const state = this.getState();
        // We don't wanna move the carousel when the user is swiping vertically
        if (state.disabled || direction === 'up' || direction === 'down') {
            return;
        }

        let xOffset = touchCords[0];

        // If we've reached either end of the list, slow down the dragging effect to indicate to the user that they can't continue.
        if (
            (this._isLastPage() && direction === 'left') ||
            (this._isFirstPage() && direction === 'right')
        ) {
            xOffset = xOffset / SLOW_DOWN_FACTOR;
        }

        state.transition = false;
        state.offset = xOffset;
        this._updateState();
    }

    /**
     * Call in response to a complete swipe.
     * @param {String} direction The swipe direction.
     */
    _swipe(direction) {
        if (this.getState().disabled) {
            return;
        }

        if (direction === 'left') {
            this._nextPage();
        } else if (direction === 'right') {
            this._previousPage();
        }
    }

    /**
     * Unset the "drag" offset and go back to the initial page position.
     */
    _resetOffset() {
        const state = this.getState();
        if (state.disabled) {
            return;
        }

        state.transition = true;
        state.offset = 0;

        this._updateState();
    }

    setPage(page) {
        this._setPage(normalizePage(page, this._totalPages, this._hasInfiniteLoop));
    }

    /**
     * Set the new page. Handles bounds issues by going staying on either the first or last page.
     * @param {Number} page The page to go to.
     */
    _setPage(nextPage) {
        const state = this.getState();
        // Only update if the page actually changed.
        if (nextPage !== state.page) {
            state.offset = 0;
            const previous = state.page;
            /**
             * Only transition when jumping one page.
             */
            if (Math.abs(previous - nextPage) > 1) {
                state.transition = false;
            } else {
                state.transition = true;
            }
            state.page = nextPage;

            /**
             * Because of the cloned pages, carousels with infinite loops are shifted by
             * one page. We do not want to leak this fact to the consumers, so we hide it by:
             *
             * Not reporting pages on cloned pages
             * Subtracting one from the reported page, so that they still appear 0 index based.
             */
            if (this._hasInfiniteLoop) {
                if (nextPage !== 0 && nextPage !== this._totalPages) {
                    const nextPageToReport = nextPage - 1;
                    this._onPageChange(nextPageToReport, state.lastInfinitePage);
                    state.lastInfinitePage = nextPageToReport;
                }
            } else {
                this._onPageChange(nextPage, previous);
            }
            this._updateState();
        }
    }

    /**
     * Update itemCount. For cases when more images are added to carousel after initial construction.
     * @param {Number} newItemCount new item count.
     */
    setItemCount(newItemCount) {
        const cloneCount = this._hasInfiniteLoop ? this.getCloneCount() * 2 : 0;

        const totalPages = getTotalPages(
            newItemCount + cloneCount,
            this._itemsPerPage,
            this._hasInfiniteLoop
        );

        Object.assign(this, {
            _itemCount: newItemCount,
            _totalPages: totalPages - 1,
        });
    }

    setTrackedItemIndex(trackedItemIndex) {
        const state = this.getState();
        const goToFirstPageClone =
            state.page === this._totalPages - 1 &&
            trackedItemIndex + 1 < Math.floor(this._itemsPerPage);
        const goToLastPageClone = state.page === 1 && trackedItemIndex === this._itemCount - 1;

        if (this._hasInfiniteLoop && goToFirstPageClone) {
            this._nextPage();
        } else if (this._hasInfiniteLoop && goToLastPageClone) {
            this._previousPage();
        } else {
            const page = this.getPageFromTrackedItemIndex(trackedItemIndex);
            this.setPage(page);
        }

        state.trackedItemIndex = trackedItemIndex;
    }

    getPageFromTrackedItemIndex(trackedItemIndex, pageJumpDistance = this._pageJumpDistance) {
        return Math.floor(trackedItemIndex / pageJumpDistance);
    }

    /**
     * Return the current state.
     * @return {[type]} [description]
     */
    getState() {
        return this._state;
    }

    /**
     * Add callbacks when model state changes
     * @param  {Function} callback
     */
    addChangeListener(callback) {
        this.onChangeCallbacks.push(callback);
    }

    /**
     * Remove callback from model state change listener
     * @param  {Function} callback function to remove
     */
    removeChangeListener(callback) {
        this.onChangeCallbacks = this.onChangeCallbacks.filter(cb => cb !== callback);
    }

    /**
     * Go to the next page.
     */
    _nextPage() {
        if (this._isLastPage()) {
            this._resetOffset();
            return;
        }
        this._setPage(this.getState().page + 1);
    }

    /**
     * Go to the previous page.
     */
    _previousPage() {
        if (this._isFirstPage()) {
            this._resetOffset();
            return;
        }
        this._setPage(this.getState().page - 1);
    }

    /**
     * Check if we are currently on the first page.
     * @return {Boolean}
     */
    _isFirstPage() {
        return this.getState().page === 0;
    }

    /**
     * Check if we are currently on the last page.
     * @return {Boolean}
     */
    _isLastPage() {
        return this.getState().page === this._totalPages;
    }
}

export default Model;

// Do not modify, this file was generated
// to modify, reference the files in dibs-sassy/src/spacing/spacing.json

export const spacing = {
    sassySpacingXxSmall: '5px',
    sassySpacingXSmall: '9px',
    sassySpacingSmaller: '14px',
    sassySpacingSmall: '18px',
    sassySpacingMedium: '27px',
    sassySpacingLarge: '36px',
    sassySpacingLarger: '45px',
    sassySpacingXLarge: '54px',
    sassySpacingXxLarge: '72px',
} as const;
export const spacingNumbers = {
    sassySpacingXxSmall: 5,
    sassySpacingXSmall: 9,
    sassySpacingSmaller: 14,
    sassySpacingSmall: 18,
    sassySpacingMedium: 27,
    sassySpacingLarge: 36,
    sassySpacingLarger: 45,
    sassySpacingXLarge: 54,
    sassySpacingXxLarge: 72,
} as const;
export const spacingVars = {
    sassySpacingXxSmall: '$sassy-spacing-xx-small',
    sassySpacingXSmall: '$sassy-spacing-x-small',
    sassySpacingSmaller: '$sassy-spacing-smaller',
    sassySpacingSmall: '$sassy-spacing-small',
    sassySpacingMedium: '$sassy-spacing-medium',
    sassySpacingLarge: '$sassy-spacing-large',
    sassySpacingLarger: '$sassy-spacing-larger',
    sassySpacingXLarge: '$sassy-spacing-x-large',
    sassySpacingXxLarge: '$sassy-spacing-xx-large',
} as const;
export const spacingPrefix = 'sassySpacing' as const;

export type SassySpacingPxNames = keyof typeof spacing;
export type SassySpacingPxValues = (typeof spacing)[SassySpacingPxNames];

export type SassySpacingNumberNames = keyof typeof spacingNumbers;
export type SassySpacingNumberValues = (typeof spacing)[SassySpacingNumberNames];

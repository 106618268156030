/**
 * @generated SignedSource<<4ee537388382c04894b764c533d8638c>>
 * @relayHash 531b781fde4e7a29fd61d82c22cf29a5
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/494.0.0-2025-01-09T20:04:30.590Z/getSocialNetworksQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type getSocialNetworksQuery$variables = Record<PropertyKey, never>;
export type getSocialNetworksQuery$data = {
  readonly viewer: {
    readonly socialNetworks: ReadonlyArray<{
      readonly name: string | null;
      readonly parameters: {
        readonly clientId: string | null;
      } | null;
    } | null> | null;
  };
};
export type getSocialNetworksQuery = {
  response: getSocialNetworksQuery$data;
  variables: getSocialNetworksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "SocialNetworkParameters",
  "kind": "LinkedField",
  "name": "parameters",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getSocialNetworksQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SocialNetwork",
            "kind": "LinkedField",
            "name": "socialNetworks",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getSocialNetworksQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SocialNetwork",
            "kind": "LinkedField",
            "name": "socialNetworks",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/494.0.0-2025-01-09T20:04:30.590Z/getSocialNetworksQuery",
    "metadata": {},
    "name": "getSocialNetworksQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9c25b76e788503e4a2417072e940c08c";

export default node;

/**
 * @generated SignedSource<<0908ff0beecabc62c25c1837f494942e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Info_seller$data = {
  readonly sellerProfile: {
    readonly company: string | null;
  } | null;
  readonly " $fragmentType": "Info_seller";
};
export type Info_seller$key = {
  readonly " $data"?: Info_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"Info_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Info_seller",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerProfile",
      "kind": "LinkedField",
      "name": "sellerProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "company",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "dbc0fbd5fd54c33d049ccb69e1f04d7a";

export default node;

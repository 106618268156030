import { MasqueradeUserType } from './types';
import serverVars from 'server-vars';

export const USER = 'userToken' as const;
export const SELLER = 'sellerToken' as const; // <VERTICAL PREFIX>_<id>_<hash>
export const MASQUERADE_BUYER: MasqueradeUserType = 'masqueradeBuyer'; // U_<id>
export const MASQUERADE_BUYER_USER_TOKEN = 'masqueradeBuyerUserToken' as const;
export const MASQUERADE_SELLER: MasqueradeUserType = 'masqueradeSeller'; // S_<id>_<VERTICAL PREFIX>
export const USER_TYPE = 'userType' as const;
export const REAL_USER_TYPE = 'realUserType' as const;
export const USER_EMAIL = 'userEmail' as const;
export const USER_EMAIL_TOKEN = 'userEmailToken' as const;
export const LANG_USER = 'lang' as const;
export const LANG_SELLER = 'sellerLang' as const;
export const USER_LEGACY = 'userreg' as const;
export const GUEST_ID = 'guestId' as const;
export const EVER_LOGGED_IN = 'eli' as const;
export const PASSWORD_RESET = 'passwordToken' as const;
export const HOMEPAGE_USER_GROUP = 'hpUserGroup' as const;
export const DIRECT_LOGIN_ORIGINAL_USER = 'directLoginOriginalUser' as const;
export const DEFAULT_COOKIE_EXPIRATION_IN_DAYS = 182 as const;
export const AB_OPT_IN = 'ab_opt_in' as const;
export const PRICE_BOOK_NAME = 'priceBookName' as const;
export const TRAFFIC_TYPE = 'traffic_type' as const;
export const ENGAGED_ITEMS = 'e_i' as const; // Keep name as short as possible to consume less size

export const defaultCookieSettings = {
    domain: serverVars.get('cookieDomain'),
    expires: DEFAULT_COOKIE_EXPIRATION_IN_DAYS,
    path: '/',
} as const;

export const COOKIE_KEYS = {
    USER,
    SELLER,
    MASQUERADE_BUYER,
    MASQUERADE_BUYER_USER_TOKEN,
    MASQUERADE_SELLER,
    USER_TYPE,
    REAL_USER_TYPE,
    USER_EMAIL,
    USER_EMAIL_TOKEN,
    LANG_USER,
    LANG_SELLER,
    USER_LEGACY,
    GUEST_ID,
    EVER_LOGGED_IN,
    PASSWORD_RESET,
    HOMEPAGE_USER_GROUP,
    DIRECT_LOGIN_ORIGINAL_USER,
    DEVICE_ID: 'dibs-uuid',
    AB_OPT_IN,
    PRICE_BOOK_NAME,
    TRAFFIC_TYPE,
    ENGAGED_ITEMS,
};

export const INTERNAL_TRAFFIC = 'client_internal';

import { FC, MouseEvent, ElementType, useContext } from 'react';
import classnames from 'classnames';

import { Link } from 'dibs-elements/exports/Link';
import { TileContext } from '../helpers/TileContext';
import { CREATOR_UNKNOWN } from '../constants';

// Styles
import styles from './styles.scss';

// Types
type TitleProps = {
    creationDate?: string | null;
    creatorName?: string | null;
    isArt?: boolean;
    pdpUrl: string;
    title: string | null;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    titleOneLine: boolean;
    titleClassName?: string | null;
    disableLinks?: boolean;
    Heading?: ElementType;
};

const Title: FC<TitleProps> = props => {
    const { imageSize } = useContext(TileContext);
    const {
        isArt,
        creatorName,
        creationDate,
        pdpUrl,
        onClick,
        title,
        titleOneLine,
        titleClassName,
        disableLinks = false,
        Heading = 'h2',
    } = props;

    const shouldShowCreatorName = !!creatorName && creatorName.toLowerCase() !== CREATOR_UNKNOWN;

    const titleEl = isArt ? (
        <Heading className={styles.title}>
            {shouldShowCreatorName && (
                <div className={styles.ellipsis} data-tn="portfolio-row-creator-name">
                    {creatorName}
                </div>
            )}
            <div
                className={classnames(styles.artOneLine, {
                    [styles.noCreator]: !shouldShowCreatorName,
                })}
            >
                <span
                    className={classnames(styles.ellipsis, titleClassName)}
                    data-tn="portfolio-row-product-name"
                >
                    {title}
                </span>
                {creationDate && `, ${creationDate}`}
            </div>
        </Heading>
    ) : (
        <Heading
            className={classnames(styles.title, titleClassName, {
                [styles.ellipsis]: titleOneLine,
            })}
            dataTn="portfolio-row-product-name"
        >
            {title}
        </Heading>
    );

    let imageSizeClass = null;
    if (imageSize === 'imageXLarge') {
        // `large` is the only class size defined
        // in the associated style.scss file
        imageSizeClass = styles[imageSize];
    }

    if (disableLinks) {
        return <span className={classnames(styles.link, imageSizeClass)}>{titleEl}</span>;
    }

    return (
        <Link
            className={classnames(styles.link, imageSizeClass)}
            dataTn="item-title-link"
            href={pdpUrl}
            onClick={onClick}
        >
            {titleEl}
        </Link>
    );
};

export default Title;

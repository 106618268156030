import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import { SIZES } from '../constants';

import styles from './TextInputContainer.scss';
import dibsCss from 'dibs-css';

type TextInputContainerProps = {
    paddingTop?: typeof SIZES.none | typeof SIZES.animatedPlaceholder;
    usePaddingLeft?: boolean;
    isHeaderInput?: boolean;
    isHighlighted?: boolean;
    children: ReactNode;
};

export const TextInputContainer: FC<TextInputContainerProps> = ({
    children,
    paddingTop,
    usePaddingLeft,
    isHeaderInput,
    isHighlighted = false,
}) => {
    const className = classnames(styles.textInputWrapper, {
        [styles.animatedPlaceholderPadding]: paddingTop === SIZES.animatedPlaceholder,
        [styles.usePaddingLeft]: usePaddingLeft,
        [styles.headerInput]: isHeaderInput,
        [styles.isHighlighted]: isHighlighted,
        [dibsCss.relative]: isHighlighted,
        [dibsCss.flex]: isHighlighted,
    });

    return <div className={className}>{children}</div>;
};

import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FC } from 'react';

type LogoVipDeProps = {
    className?: string;
    role?: 'img';
    ariaLabel?: string;
    ariaLabelledby?: string;
};

const LogoVipDe: FC<LogoVipDeProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn="logo-de-vip"
            role={props.role}
            aria-label={props.ariaLabel}
            aria-labelledby={props.ariaLabelledby}
            viewBox="0 0 83 39"
        >
            <path d="M33.34 8.02V20.48C33.34 22.6 32.44 23.19 30.59 23.82H38.45C36.6 23.19 35.7 22.6 35.7 20.48V6.01C35.7 4.71 35.78 3.57 35.86 3.1C34.2 4.09 32.67 4.95 30.03 5.23V5.58C32.86 5.58 33.34 6.33 33.34 8.02Z" />
            <path d="M52.67 13.6C52.67 6.96 48.67 0.0400009 38.17 0.0400009H30.27V1.02H37.66C45.91 1.02 49.33 6.21 49.33 13.6C49.33 22.13 45.67 26.57 38.4 26.57H30.38V27.55H38.48C48.9 27.56 52.67 20.48 52.67 13.6Z" />
            <path d="M40.05 16.75V21.58C40.05 23.03 40.76 23.86 41.98 23.86C43.24 23.86 44.38 23.03 44.85 21.93L44.77 21.81C44.3 22.24 43.59 22.64 42.92 22.64C41.94 22.64 41.58 22.17 41.58 20.91L41.54 16.74H44.29L44.57 15.52H41.54V13.71L38.63 16.54V16.75H40.05Z" />
            <path d="M41.28 4.56C42.07 4.56 42.89 5.19 43.68 6.01V4.32C42.93 4.01 42.42 3.73 41.44 3.73C40.02 3.73 38.89 4.59 38.89 6.01C38.89 7.03 39.56 7.7 40.31 8.21L41.76 9.19C42.47 9.66 42.7 9.98 42.7 10.53C42.7 11.12 42.27 11.55 41.52 11.55C40.66 11.55 39.95 10.92 38.93 9.82V11.67C39.44 12.02 40.23 12.46 41.29 12.46C42.7 12.46 44.04 11.4 44.04 10.02C44.04 8.96 43.41 8.33 42.47 7.7L41.17 6.84C40.58 6.45 40.23 6.09 40.23 5.54C40.21 4.95 40.69 4.56 41.28 4.56Z" />
            <path d="M2.67 33.49H0.2V33.63H0.33C0.74 33.63 0.92 33.79 0.92 34.05V38.29C0.92 38.55 0.75 38.71 0.33 38.71H0.2V38.85H2.49V38.71H2.33C1.92 38.71 1.74 38.55 1.74 38.29V36.54H2.4C3.86 36.54 4.65 35.93 4.65 34.96C4.66 33.99 3.98 33.49 2.67 33.49ZM2.34 36.27H1.74V33.76H2.56C3.42 33.76 3.76 34.14 3.76 34.96C3.76 35.74 3.36 36.27 2.34 36.27Z" />
            <path d="M10.89 38.04L9.57 36.13C10.38 35.99 10.86 35.51 10.86 34.82C10.86 34.07 10.27 33.51 9.25 33.51H6.62V33.65H6.75C7.16 33.65 7.34 33.81 7.34 34.07V38.31C7.34 38.57 7.17 38.73 6.75 38.73H6.62V38.87H8.88V38.73H8.75C8.34 38.73 8.16 38.57 8.16 38.31V36.25H8.7L10.14 38.39C10.48 38.9 10.73 38.95 11.78 38.88V38.74H11.72C11.45 38.71 11.28 38.6 10.89 38.04ZM8.17 35.96V33.77H8.99C9.7 33.77 10 34.25 10 34.87C10 35.49 9.69 35.96 8.94 35.96H8.17Z" />
            <path d="M13.61 33.63C14.02 33.63 14.2 33.79 14.2 34.05V38.29C14.2 38.55 14.03 38.71 13.61 38.71H13.48V38.85H15.74V38.71H15.6C15.19 38.71 15.01 38.55 15.01 38.29V34.05C15.01 33.79 15.18 33.63 15.6 33.63H15.73V33.49H13.47V33.63H13.61Z" />
            <path d="M21.68 33.63H21.86C22.3 33.63 22.31 33.92 22.17 34.25L20.64 37.79L19.05 34.27C18.89 33.91 18.91 33.63 19.36 33.63H19.55V33.49H17.32V33.63H17.38C17.75 33.63 17.97 33.82 18.18 34.28L20.31 38.92H20.47L22.48 34.26C22.68 33.8 22.91 33.63 23.29 33.63H23.35V33.49H21.69V33.63H21.68Z" />
            <path d="M29.17 38.06L27.09 33.35H26.93L24.87 38.08C24.66 38.54 24.43 38.72 24.06 38.72H24V38.86H25.66V38.72H25.48C25.04 38.72 25.03 38.43 25.17 38.1L25.65 37H27.82L28.28 38.08C28.44 38.45 28.42 38.72 27.97 38.72H27.78V38.86H30V38.72H29.94C29.61 38.71 29.38 38.53 29.17 38.06ZM25.78 36.72L26.75 34.48L27.71 36.72H25.78Z" />
            <path d="M31.05 33.49L30.9 34.85H31.02L31.08 34.66C31.28 34.01 31.58 33.76 32.42 33.76H32.97V38.29C32.97 38.55 32.8 38.71 32.38 38.71H32.25V38.85H34.51V38.71H34.38C33.97 38.71 33.79 38.55 33.79 38.29V33.76H34.33C35.17 33.76 35.47 34.02 35.66 34.66L35.72 34.85H35.86L35.7 33.49H31.05Z" />
            <path d="M42.36 38.18L40.1 35.69L41.56 34.35C42.15 33.81 42.57 33.64 42.93 33.64H42.99V33.5H41.15V33.64H41.3C41.69 33.64 41.62 33.92 41.36 34.16L39.36 36.01V34.06C39.36 33.8 39.53 33.64 39.95 33.64H40.08V33.5H37.82V33.64H37.95C38.36 33.64 38.54 33.8 38.54 34.06V38.3C38.54 38.56 38.37 38.72 37.95 38.72H37.82V38.86H40.08V38.72H39.95C39.54 38.72 39.36 38.56 39.36 38.3V36.07L41.33 38.26C41.79 38.77 42.11 38.86 42.72 38.86H43.29V38.72H43.23C42.95 38.71 42.68 38.52 42.36 38.18Z" />
            <path d="M48.92 33.63H49.06C49.57 33.63 49.79 33.92 49.79 34.39V36.7C49.79 37.95 49.11 38.66 48.01 38.66C46.96 38.66 46.34 38.12 46.34 36.81V34.05C46.34 33.79 46.51 33.63 46.93 33.63H47.06V33.49H44.8V33.63H44.93C45.34 33.63 45.52 33.79 45.52 34.05V36.68C45.52 38.3 46.51 38.96 47.91 38.96C49.27 38.96 50.11 38.12 50.11 36.65V34.39C50.11 33.92 50.28 33.63 50.7 33.63H50.84V33.49H48.95V33.63H48.92Z" />
            <path d="M56.72 33.63C57.25 33.63 57.45 33.92 57.45 34.39V37.49L53.98 33.49H52.69V33.61L52.85 33.66C53.33 33.8 53.54 34.2 53.54 34.72V37.94C53.54 38.42 53.33 38.71 52.82 38.71H52.66V38.84H54.72V38.71H54.56C54.05 38.71 53.84 38.42 53.84 37.94V34.61L57.58 38.91H57.74V34.38C57.74 33.91 57.92 33.62 58.33 33.62H58.46V33.48H56.57V33.63H56.72Z" />
            <path d="M63.12 33.49H60.51V33.63H60.64C61.05 33.63 61.23 33.79 61.23 34.05V38.29C61.23 38.55 61.06 38.71 60.64 38.71H60.51V38.85H63.12C64.83 38.85 66.01 37.75 66.01 36.17C66.01 34.59 64.84 33.49 63.12 33.49ZM63.11 38.58H62.06V33.77H63.11C64.42 33.77 65.14 34.75 65.14 36.18C65.14 37.59 64.42 38.58 63.11 38.58Z" />
            <path d="M70.32 34.17C70.08 34.17 69.89 34.46 69.45 34.63C69.47 34.24 69.63 33.98 69.63 33.75C69.63 33.59 69.53 33.49 69.39 33.49C69.25 33.49 69.15 33.59 69.15 33.75C69.15 33.98 69.31 34.24 69.34 34.63C68.89 34.47 68.7 34.17 68.46 34.17C68.32 34.17 68.22 34.28 68.22 34.41C68.22 34.52 68.29 34.61 68.41 34.65C68.62 34.71 68.9 34.65 69.3 34.76C68.97 35.16 68.58 35.23 68.58 35.53C68.58 35.66 68.69 35.77 68.82 35.77C68.9 35.77 68.97 35.72 69.03 35.64C69.17 35.46 69.19 35.17 69.4 34.84C69.61 35.17 69.64 35.46 69.78 35.64C69.84 35.72 69.92 35.77 69.99 35.77C70.12 35.77 70.23 35.66 70.23 35.53C70.23 35.23 69.83 35.16 69.5 34.75C69.9 34.65 70.18 34.71 70.39 34.65C70.52 34.61 70.59 34.52 70.59 34.41C70.57 34.27 70.47 34.17 70.32 34.17Z" />
            <path d="M72.73 33.63H72.86C73.27 33.63 73.45 33.79 73.45 34.05V38.29C73.45 38.55 73.28 38.71 72.86 38.71H72.73V38.85H74.99V38.71H74.86C74.45 38.71 74.27 38.55 74.27 38.29V34.05C74.27 33.79 74.44 33.63 74.86 33.63H74.99V33.49H72.73V33.63Z" />
            <path d="M80.94 33.49V33.63H81.07C81.6 33.63 81.8 33.92 81.8 34.39V37.49L78.33 33.49H77.04V33.61L77.2 33.67C77.68 33.81 77.89 34.21 77.89 34.73V37.95C77.89 38.43 77.68 38.72 77.17 38.72H77.01V38.85H79.07V38.72H78.91C78.4 38.72 78.19 38.43 78.19 37.95V34.62L81.93 38.92H82.09V34.39C82.09 33.92 82.27 33.63 82.68 33.63H82.81V33.49H80.94Z" />
        </svg>
    );
};

export default LogoVipDe;

import type { FC } from 'react';

import { useRageClick } from 'dibs-react-hooks/exports/useRageClick';
import { trackEvent } from 'dibs-tracking';

const TrackRageClicks: FC = () => {
    useRageClick(e => {
        if (e.target instanceof HTMLElement) {
            trackEvent({
                category: 'ecommerce',
                action: 'rage click',
                label: e.target.nodeName,
            });
        }
    });

    return null;
};

export default TrackRageClicks;

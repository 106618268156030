import { lazy, Suspense, FC, useState } from 'react';

const HpSharedCompleteEmailOptinConfirmationModal = lazy(
    () =>
        import(
            /* webpackChunkName: "HpSharedCompleteEmailOptinConfirmationModal" */ './HpSharedCompleteEmailOptinConfirmationModal'
        )
);

export const HpSharedCompleteEmailOptinConfirmationModalLazy: FC = () => {
    const params = new URLSearchParams(window.location.search);
    const emailConfirmed = params.get('emailConfirmed');
    const [isOpen, setIsOpen] = useState(!!emailConfirmed);

    return emailConfirmed ? (
        <Suspense fallback="">
            <HpSharedCompleteEmailOptinConfirmationModal
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
                isSuccess={emailConfirmed === 'Y'}
            />
        </Suspense>
    ) : null;
};

import { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, createRefetchContainer } from 'react-relay/legacy';
// import HeartWrapper from 'dibs-portfolio-heart'; // eslint-disable-line no-unused-vars

// import FolderWrapperWrapper from './FolderWrapperWrapper'; // eslint-disable-line no-unused-vars

class SharedFavoritesProvider extends Component {
    constructor(props) {
        super(props);

        this.hasInitialFetch = false;
    }

    componentDidMount() {
        const { userId } = this.props;
        if (userId) {
            this.refetchFavorites();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.shouldFetchFavorites(prevProps)) {
            this.refetchFavorites();
            this.hasInitialFetch = true;
        }
    }

    shouldFetchFavorites(prevProps) {
        const { itemIds, userId, fetchFavorites } = this.props;

        // this component should fetch data:
        // 1. on initial mount if user is logged in
        const shouldDoInitialFetch = userId && fetchFavorites && !this.hasInitialFetch;
        // 2. if user logs in later
        const userHasLoggedIn = !prevProps.userId && userId && this.initialFetch;
        // 3. if items change (applies only to search and browse)
        const hasCurrentItems = itemIds && !!itemIds.length;
        const hasPreviousItems = prevProps.itemIds && !!prevProps.itemIds.length;
        const receivedNewItems =
            hasPreviousItems &&
            hasCurrentItems &&
            prevProps.itemIds.toString() !== itemIds.toString();

        return shouldDoInitialFetch || userHasLoggedIn || receivedNewItems;
    }

    refetchFavorites() {
        const { itemIds, additionalRenderVariables, userId } = this.props;
        const userIds = userId ? [userId] : null;
        this.props.relay.refetch(
            {
                userIds,
                selectedItemIds: itemIds,
                loadPortfolioData: !!userId,
                shouldFillInFolder: !!userId,
            },
            {
                userIds,
                selectedItemIds: itemIds,
                loadPortfolioData: !!userId,
                shouldFillInFolder: !!userId,
                ...additionalRenderVariables,
            }
        );
    }

    render() {
        const { disable, userId, itemIds, environment, fetchHeart, fetchFolder, viewer } =
            this.props;
        const userIds = userId ? [userId] : [];

        const favorites = {
            userId,
            itemIds,
            userIds,
            disable,
            environment,
            fetchHeart,
            fetchFolder,
            singlePortfolioItem: true,
            loadPortfolioItemData: true,
            selectedItemIds: itemIds,
            loadPortfolioData: !!userId,
            shouldFillInFolder: !!userId,
            viewer,
        };

        return this.props.children({ favorites });
    }
}

SharedFavoritesProvider.defaultProps = {
    fetchHeart: true,
    fetchFolder: true,
};

SharedFavoritesProvider.propTypes = {
    userId: PropTypes.string,
    disable: PropTypes.bool,
    itemIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    environment: PropTypes.object.isRequired,
    fetchHeart: PropTypes.bool.isRequired,
    fetchFolder: PropTypes.bool.isRequired,
    children: PropTypes.func,
    additionalRenderVariables: PropTypes.object,
    relay: PropTypes.object,
    viewer: PropTypes.object,
    fetchFavorites: PropTypes.bool,
};

export default createRefetchContainer(
    SharedFavoritesProvider,
    {
        viewer: graphql`
            fragment SharedFavoritesProvider_viewer on Viewer
            @argumentDefinitions(
                selectedItemIds: { type: "[String]", defaultValue: [] }
                loadPortfolioData: { type: "Boolean", defaultValue: false }
                shouldFillInFolder: { type: "Boolean", defaultValue: false }
                userIds: { type: "[String]", defaultValue: [] }
            ) {
                ...FolderWrapperWrapper_viewer
                    @arguments(
                        selectedItemIds: $selectedItemIds
                        shouldFillInFolder: $shouldFillInFolder
                        userIds: $userIds
                    )
                ...HeartWrapper_viewer
                    @arguments(
                        loadPortfolioData: $loadPortfolioData
                        selectedItemIds: $selectedItemIds
                        userIds: $userIds
                    )
            }
        `,
    },
    graphql`
        query SharedFavoritesProviderRefetchQuery(
            $loadPortfolioData: Boolean = false
            $selectedItemIds: [String] = []
            $userIds: [String] = []
            $shouldFillInFolder: Boolean = false
        ) {
            viewer {
                ...SharedFavoritesProvider_viewer
                    @arguments(
                        loadPortfolioData: $loadPortfolioData
                        selectedItemIds: $selectedItemIds
                        userIds: $userIds
                        shouldFillInFolder: $shouldFillInFolder
                    )
            }
        }
    `
);

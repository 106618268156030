export const MIN_PASSWORD_LENGTH = 8 as const;
export const MIN_PASSWORD_NUMBERS_OR_SPECIAL_CHARACTERS = 1 as const;
export const MIN_PASSWORD_LETTERS = 1 as const;

const regEx = {
    email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,100}$/i,
    nonEmpty: /^(?!\s*$).+/,
    password: /^.{5,128}$/,
    url: /^(http:\/\/|https:\/\/)?(www\.)?[a-zA-Z0-9-_\.]+\.[a-zA-Z]+(:\d+)?(\/[a-zA-Z\d\.\-_]*)*[a-zA-Z.!@#$%&=-_'":,.?\d*)(]*$/,
    zipcode: /^([-\s]*[a-zA-z\d]?[-\s]*){0,9}$/,
    passwordLength: /^.{8,128}$/,
    passwordNumberOrCharacter: /[0123456789 !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]+/,
    passwordLetter: /[a-zA-Z]+/,
};

export const EMAIL_MAX_LENGTH = 256;

export const checkString =
    ({ minLength = 0, maxLength = 10000 }: { minLength?: number; maxLength?: number }) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    (value: any): boolean => {
        if (typeof value !== 'string') {
            return false;
        }
        return value.length >= minLength && value.length <= maxLength;
    };

type Validator = (value: string) => boolean;

export const isNumericString: Validator = value =>
    checkString({ minLength: 1 })(value) && !isNaN(+value);

export const isValidEmail: Validator = value => regEx.email.test(value);
export const isValidPassword: Validator = value => regEx.password.test(value);
export const isValidUrl: Validator = value => regEx.url.test(value);
export const isValidZipcode: Validator = value => regEx.zipcode.test(value);
export const noRestrictions = (): boolean => true;

export const isValidPasswordLength: Validator = value => regEx.passwordLength.test(value);
export const isValidPasswordNumberOrCharacter: Validator = value =>
    regEx.passwordNumberOrCharacter.test(value);
export const isValidPasswordLetter: Validator = value => regEx.passwordLetter.test(value);

export function isNonEmpty(value: string): boolean {
    return regEx.nonEmpty.test(value);
}

export const all =
    (...validators: Validator[]) =>
    (value: string): boolean => {
        for (let i = 0; i < validators.length; i++) {
            if (!validators[i](value)) {
                return false;
            }
        }

        return true;
    };

export const oneOf =
    (...validators: Validator[]) =>
    (value: string): boolean => {
        for (let i = 0; i < validators.length; i++) {
            if (validators[i](value)) {
                return true;
            }
        }

        return false;
    };

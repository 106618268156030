import { FormattedMessage } from 'dibs-react-intl';

const taxName = (priceBookName: string | null): JSX.Element => {
    // if change this: also change dibs-graphql/src/helpers/vat/taxName.ts
    switch (priceBookName) {
        case 'AU':
            return <FormattedMessage id="ferrum.taxName.GST" defaultMessage="GST" />;
        default:
            return <FormattedMessage id="ferrum.taxName.VAT" defaultMessage="VAT" />;
    }
};

export default taxName;

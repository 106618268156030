import { FunctionComponent } from 'react';
import classnames from 'classnames';

import styles from './main.scss';

type BarProps = {
    size?: 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge';
    type?: 'trade' | 'default';
};

export const Bar: FunctionComponent<BarProps> = props => {
    const { size = 'large', type = 'default' } = props;

    return (
        <div
            className={classnames(styles.bottomBorder, styles[size], {
                [styles.isTrade]: type === 'trade',
            })}
        />
    );
};

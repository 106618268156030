/**
 * @description Loads intl polyfill and locale data if there is no window.Intl
 * @param {string} locale
 */
export async function clientIntlPolyfill() {
    if (!window.Intl.PluralRules) {
        await import(
            /* webpackChunkName: "intlClientPolyfillPluralRules" */ '../src/intl/polyfills/pluralRules'
        );
    }
    if (!window.Intl.ListFormat) {
        await import(
            /* webpackChunkName: "intlClientPolyfillListFormat" */ '../src/intl/polyfills/listFormat'
        );
    }
}

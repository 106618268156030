/**
 * @generated SignedSource<<f597fca7b21a629ddad89da2884e4ab8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Notifications_initialViewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NotificationsIcon_viewer">;
  readonly " $fragmentType": "Notifications_initialViewer";
};
export type Notifications_initialViewer$key = {
  readonly " $data"?: Notifications_initialViewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Notifications_initialViewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isClient"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Notifications_initialViewer",
  "selections": [
    {
      "condition": "isClient",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NotificationsIcon_viewer"
            }
          ]
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "36902f784fb30b8ef37336e48af71a7c";

export default node;

import { FC } from 'react';
import classnames from 'classnames';
import { Badge } from 'dibs-elements/exports/Badge';

import styles from './styles.scss';

type Props = {
    sellerName: string;
    isSbTile?: boolean;
    listingStatus?: string;
};

export const InternalSellerName: FC<Props> = ({ sellerName, isSbTile, listingStatus }) => {
    isSbTile = isSbTile || false;
    return (
        <div
            className={classnames(styles.internalByline, {
                [styles.isSbTile]: isSbTile,
            })}
            data-tn="internal-seller-name"
        >
            {sellerName}
            {listingStatus && !isSbTile ? (
                <Badge letterCase="uppercase" type="pill">
                    {listingStatus}
                </Badge>
            ) : null}
        </div>
    );
};

export default InternalSellerName;

import { graphql, Environment } from 'react-relay/legacy';
import serverVars from 'server-vars';
import commitMutation from 'relay-commit-mutation-promise';

import { TrackingObject } from 'dibs-tracking';
import { commitMutationWithRecaptcha } from 'dibs-recaptcha/exports/commitMutationWithRecaptcha';
import { getUserSessionCountryCode } from 'dibs-regional-info/exports/regionalInfoHelpers';

import { getAuthHash } from '../authHelpers';
import { getRegistrationTriggerValue } from '../registrationTrigger';
import {
    UserLoginInput,
    loginMutation as loginMutationType,
    loginMutation$data,
} from './__generated__/loginMutation.graphql';

import type { SupportedMFATypes } from 'dibs-recaptcha/exports/recaptcha';

const mutation = graphql`
    mutation loginMutation($input: UserLoginInput!) {
        userLogin(input: $input) {
            user {
                serviceId
                userId: serviceId # for backwards compatibility with old versions
                createdDate
                isVerifiedTrade
                isVip
                verifiedTradeStatus
                registrationIntent
                ethereumWalletAddress {
                    address
                }
                profile {
                    email
                    firstName
                    lastName
                }
                preferences {
                    currency
                    measurementUnit
                    preferredLocale
                }
            }
            emailToken
            userToken
            userId
            responseMessage
            isNewUser
            submittedTransactions: transactionsCount(viewName: "buyerTracking.submitted")
            confirmedTransactions: transactionsCount(viewName: "buyerTracking.confirmed")
            submittedTransactionsRecent: transactionsCount(
                viewName: "buyerTracking.submitted"
                monthsBack: 6
            )
            confirmedTransactionsRecent: transactionsCount(
                viewName: "buyerTracking.confirmed"
                monthsBack: 6
            )
        }
    }
`;

export async function loginMutation(
    environment: Environment,
    {
        email,
        password,
        socialUserFirstName,
        socialUserLastName,
        socialUserEmail,
        socialToken,
        socialClientId,
        socialNetworkName,
        marketingOptIn,
        mfaVerificationCode,
        emailLoginToken,
    }: Partial<UserLoginInput>,
    options: { ga?: TrackingObject; isEmailOnly?: boolean }
): Promise<loginMutation$data> {
    // Requires auth hash to prevent bots hitting graphQL directly and making fake auths.
    const authHash = socialToken || emailLoginToken ? '' : getAuthHash({ email });
    const cookieDomain = serverVars.get('cookieDomain'); // for setting response cookies
    const countryCode = getUserSessionCountryCode();
    const { ga = {}, isEmailOnly } = options || {};
    let registrationTrigger;

    if (socialToken) {
        registrationTrigger = getRegistrationTriggerValue({ ga });
    }

    const mutationInputVariables = {
        email,
        password,
        socialUserFirstName,
        socialUserLastName,
        socialUserEmail,
        socialToken,
        socialClientId,
        socialNetworkName,
        authHash,
        cookieDomain,
        marketingOptIn,
        countryCode,
        registrationTrigger,
        emailLoginToken,
    } as const;

    if (!socialToken && !isEmailOnly) {
        // ☝️ to accommodate ENGAGE-1882
        const supportedMFATypes: SupportedMFATypes = ['CODE'];
        const executeCaptchaConfig = {
            action: 'login',
            userName: email,
            mfaVerificationCode,
            supportedMFATypes,
            featureFlag: 'loginCaptcha',
            featureFlagV3: 'loginCaptchaV3',
        };
        return commitMutationWithRecaptcha<
            Partial<UserLoginInput>,
            loginMutationType,
            loginMutation$data
        >(environment, {
            mutation,
            mutationInputVariables,
            executeCaptchaConfig,
        });
    }
    return commitMutation<loginMutationType>(environment, {
        mutation,
        variables: {
            input: mutationInputVariables,
        },
    });
}

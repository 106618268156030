import { FunctionComponent, MouseEventHandler } from 'react';
import classnames from 'classnames';
import { useIntl, defineMessages } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';

// Style
import styles from './styles.scss';

import QuickViewIcon from 'dibs-icons/exports/legacy/InfoCircle';

type QuickViewButtonProps = {
    openQuickView?: MouseEventHandler;
    showQuickViewIcon?: boolean;
    hideDivider?: boolean;
    isSbTile?: boolean;
    classname?: string;
};

const messages = defineMessages({
    label: {
        id: 'dibs-search-product-tile.QuickViewButton.label',
        defaultMessage: 'Show quick view information',
    },
});

export const QuickViewButton: FunctionComponent<QuickViewButtonProps> = ({
    openQuickView = () => {},
    showQuickViewIcon = true,
    hideDivider,
    isSbTile,
    classname,
}) => {
    const intl = useIntl();
    if (!showQuickViewIcon) {
        return null;
    }
    return (
        <Link
            dataTn="quick-view-button"
            ariaLabel={intl.formatMessage(messages.label)}
            className={classnames(
                styles.link,
                {
                    [styles.hideDivider]: hideDivider,
                    [styles.isSbTile]: isSbTile,
                },
                classname
            )}
            onClick={openQuickView}
        >
            <QuickViewIcon className={classnames({ [styles.isSbTile]: isSbTile })} />
        </Link>
    );
};

export const STEPS = {
    INTRO: 'intro',
    SEARCH_RESULTS: 'searchResults',
    NO_SEARCH_RESULTS: 'noSearchResults',
    ARTICLE: 'article',
    ORDERS_LIST: 'ordersList',
    ORDER_INFO: 'orderInfo',
    CONTACT_SUPPORT: 'contactSupport',
    ERROR: 'error',
} as const;

export const ACTIONS = {
    SET_STEP: 'SET_STEP',
    OPEN_ARTICLE: 'OPEN_ARTICLE',
    GO_BACK: 'GO_BACK',
    SET_ORDER_ID: 'SET_ORDER_ID',
    SET_SEARCH_RESULTS_STEP: 'SET_SEARCH_RESULTS_STEP',
    SET_TOPIC: 'SET_TOPIC',
} as const;

export const RENDER_AS = {
    CONSUMER: 'consumer',
    LOGGED_OUT: 'loggedOut',
    SELLER: 'seller',
} as const;

export const GET_HELP_MODAL_ZENDESK_OPTIONS = 'GET_HELP_MODAL_ZENDESK_OPTIONS' as const;

import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import ResponsiveImage from '../ResponsiveImage';
import HpLazyImageWrapper from '../HpLazyImageWrapper/HpLazyImageWrapper';

import styles from './HpSharedCollectionSpotlightItem.scss';

import { HpSharedCollectionSpotlightItem_item$data } from './__generated__/HpSharedCollectionSpotlightItem_item.graphql';

type Props = {
    item: HpSharedCollectionSpotlightItem_item$data;
    useLazyLoadImages: boolean;
    onClick: () => void;
};

const HpSharedCollectionSpotlightItem: FC<Props> = ({ onClick, item, useLazyLoadImages }) => {
    const { linkData, title, image } = item;
    return (
        <SeoLink
            className={styles.link}
            dataTn="maker-item-tile"
            linkData={linkData}
            onClick={onClick}
        >
            <div className={styles.item}>
                <HpLazyImageWrapper
                    useLazyLoad={useLazyLoadImages}
                    imageWidth={image?.imageWidth}
                    imageHeight={image?.imageHeight}
                >
                    <ResponsiveImage
                        ariaHidden
                        className={styles.image}
                        src={image?.imageUrl || ''}
                        srcSetWidths={[240, 360]}
                    />
                </HpLazyImageWrapper>
                <div className={styles.title}>{title}</div>
            </div>
        </SeoLink>
    );
};

export default createFragmentContainer(HpSharedCollectionSpotlightItem, {
    item: graphql`
        fragment HpSharedCollectionSpotlightItem_item on CollectionSpotlightItem {
            title
            linkData {
                ...SeoLink_linkData
            }
            image {
                imageUrl
                imageWidth
                imageHeight
            }
        }
    `,
});

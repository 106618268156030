import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { ALIGN, DIRECTIONS } from '../../Common/constants';

import styles from './GridRow.scss';

type GridRowProps = {
    align?: typeof ALIGN.bottom | typeof ALIGN.center | typeof ALIGN.top;
    justify?: typeof DIRECTIONS.left | typeof ALIGN.center | typeof DIRECTIONS.right;
    children: ReactNode;
    dataTn?: string;
};

export const GridRow: FC<GridRowProps> = props => {
    const { align = 'top', justify = 'left', children, dataTn } = props;
    const rowClassNames = classNames(styles.row, {
        [styles.bottom]: align === ALIGN.bottom,
        [styles.vCenter]: align === ALIGN.center,
        [styles.top]: align === ALIGN.top,
        [styles.left]: justify === DIRECTIONS.left,
        [styles.hCenter]: justify === ALIGN.center,
        [styles.right]: justify === DIRECTIONS.right,
    });

    return (
        <div className={rowClassNames} data-tn={dataTn}>
            {children}
        </div>
    );
};

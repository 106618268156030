function getPromoCreative(url) {
    if (url) {
        const anchor = document.createElement('a');
        anchor.href = url;
        return `${anchor.pathname}${anchor.search}`;
    } else {
        return 'No Link URL provided';
    }
}

export { getPromoCreative };

import { FC, ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';

import classnames from 'classnames';

import { SeoLink } from 'dibs-elements/exports/SeoLink';
import {
    ScrollCarouselProvider,
    useScrollCarousel,
} from 'dibs-scroll-carousel/exports/ScrollCarouselContext';
import { InternalScrollCarouselProvider } from 'dibs-scroll-carousel/exports/InternalScrollCarouselContext';
import { ScrollContainer } from 'dibs-scroll-carousel/exports/ScrollContainer';
import { Arrow } from 'dibs-scroll-carousel/exports/Arrow';

import { ViewInRoomImage } from 'dibs-view-in-room/exports/ViewInRoomImage';

import styles from '../../styles.scss';
import { SwiperItem, ItemProps } from './SwiperItem';
import { VideoIcon } from './VideoIcon';

import { ProductSwiper_viewer$key } from './__generated__/ProductSwiper_viewer.graphql';
import { ProductSwiper_item$key } from './__generated__/ProductSwiper_item.graphql';
import { ProductSwiper_itemSearch$key } from './__generated__/ProductSwiper_itemSearch.graphql';

const itemFragment = graphql`
    fragment ProductSwiper_item on Item
    @argumentDefinitions(
        photosLimit: { type: "Int", defaultValue: 3 }
        viewInRoomLimit: { type: "Int", defaultValue: 0 }
        fetchVideo: { type: "Boolean!" }
    ) {
        ...ViewInRoomImage_item
        serviceId
        carouselPhotos: photos(limit: $photosLimit) {
            ...SwiperItem_photo
            smallPath
            masterOrZoomPath
        }
        hasApprovedVideo @include(if: $fetchVideo)
        viewInRoomPhotos(limit: $viewInRoomLimit) {
            ...ViewInRoomImage_viewInRoomPhoto
        }
    }
`;

const viewerFragment = graphql`
    fragment ProductSwiper_viewer on Viewer {
        ...SwiperItem_viewer
    }
`;

const itemSearchFragment = graphql`
    fragment ProductSwiper_itemSearch on ItemSearchQueryConnection {
        ...SwiperItem_itemSearch
    }
`;

const ITEMS_TO_SHOW = 1;

const Carousel: FC<{
    children: ReactNode;
    arrowsVisible: boolean;
    isMobile: boolean;
    showVideo: boolean;
}> = ({ children, arrowsVisible, isMobile, showVideo }) => {
    const { activeSlide } = useScrollCarousel();

    return (
        <>
            {showVideo && <VideoIcon hidden={activeSlide > 1} />}

            <div className={styles.carouselWrapper}>
                <ScrollContainer>{children}</ScrollContainer>

                {!isMobile && (
                    <>
                        <div
                            className={classnames(styles.arrows, styles.prevArrow, {
                                [styles.visible]: arrowsVisible,
                            })}
                        >
                            <Arrow type="previous" />
                        </div>
                        <div
                            className={classnames(styles.arrows, styles.nextArrow, {
                                [styles.visible]: arrowsVisible,
                            })}
                        >
                            <Arrow type="next" />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export const ProductSwiper: FC<{
    item: ProductSwiper_item$key | null | undefined;
    viewer: ProductSwiper_viewer$key | null | undefined;
    itemSearch: ProductSwiper_itemSearch$key | null | undefined;
    wasHovered: boolean;
    swiperWasClicked: boolean;
    isInitiallyVisibleTile: boolean;
    arrowsVisible: boolean;
    isMobile: boolean;
    itemsPerRow: number;
    index: number;
    onPageChange: (nextPage: number) => void;
    imageProps: ItemProps;
    hasSingleImage: boolean;
    showViewInRoom?: boolean;
    centerImage?: boolean;
}> = ({
    viewer: viewerRef,
    item: itemRef,
    itemSearch: itemSearchRef,
    wasHovered,
    swiperWasClicked,
    isInitiallyVisibleTile,
    arrowsVisible,
    isMobile,
    index: tileIndex,
    onPageChange,
    imageProps,
    hasSingleImage,
    /**
     * if we want to show view in room images we also need to provide `viewInRoomLimit` graphql value to the
     * ProductSwiper_item fragment. Pass `null` to fetch all images.
     */
    showViewInRoom,
    centerImage = true,
}) => {
    const viewer = useFragment(viewerFragment, viewerRef || null);
    const item = useFragment(itemFragment, itemRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);

    const showVideo = !!item?.hasApprovedVideo;

    const carouselPhotos = item?.carouselPhotos || [];
    const viewInRoomPhotos = item?.viewInRoomPhotos || [];

    const mainImagePaths = {
        imagePath: carouselPhotos[0]?.smallPath,
        masterOrZoomPath: carouselPhotos[0]?.masterOrZoomPath,
    };

    const imageContainerClassNames = classnames(
        hasSingleImage ? styles.imageContainer : styles.swiperImageContainer,
        styles[imageProps.imageSize],
        {
            [styles.centerImage]: centerImage,
        }
    );

    let globalIndex = 0;

    const items = carouselPhotos.reduce<ReactNode[]>((acc, photo) => {
        if (photo) {
            // SwiperItem only uses viewer ref to fetch video, so don't pass it if we know the tile doesn't have one
            const videoArgs =
                showVideo && globalIndex === 0
                    ? {
                          viewer,
                          itemId: item.serviceId,
                      }
                    : {};
            acc.push(
                <SwiperItem
                    {...imageProps}
                    useEagerImageLoad={
                        (isInitiallyVisibleTile && globalIndex === 0) ||
                        wasHovered ||
                        swiperWasClicked
                    }
                    photo={photo}
                    itemSearch={itemSearch}
                    index={globalIndex}
                    key={`product-thumb-image-${tileIndex}-${globalIndex}`}
                    containerClassName={imageContainerClassNames}
                    {...videoArgs}
                />
            );
            globalIndex += 1;
        }

        return acc;
    }, []);

    if (showViewInRoom) {
        const link = imageProps.disableLinks
            ? { path: '', onClick: undefined }
            : { path: imageProps.pdpUrl, onClick: imageProps.onClick };

        items.push(
            ...viewInRoomPhotos.reduce<ReactNode[]>((acc, photo) => {
                if (photo && item) {
                    acc.push(
                        <SeoLink
                            key={`product-view-in-room-image-${tileIndex}-${globalIndex}`}
                            dataTn="view-in-room-image-link"
                            linkData={{ path: link.path, isLinkable: false }}
                            onClick={link.onClick}
                            className={styles.swiperLink}
                        >
                            <div className={imageContainerClassNames}>
                                <ViewInRoomImage
                                    {...imageProps}
                                    useEagerImageLoad={
                                        (isInitiallyVisibleTile && globalIndex === 0) ||
                                        wasHovered ||
                                        swiperWasClicked
                                    }
                                    item={item}
                                    viewInRoomPhoto={photo}
                                    mainImagePaths={mainImagePaths}
                                    className={styles.swiperImage}
                                />
                            </div>
                        </SeoLink>
                    );
                    globalIndex += 1;
                }
                return acc;
            }, [])
        );
    }

    return (
        <>
            {hasSingleImage ? (
                items[0]
            ) : (
                <ScrollCarouselProvider
                    totalItems={items.length}
                    largestItemsToShow={ITEMS_TO_SHOW}
                    stepSize={1}
                    onSlideChange={({ activeSlide }) => {
                        onPageChange(activeSlide);
                    }}
                    isInfinite
                >
                    <InternalScrollCarouselProvider
                        animation="slide"
                        enableScroll={isMobile}
                        orientation="horizontal"
                        itemsToShow={ITEMS_TO_SHOW}
                    >
                        <Carousel
                            arrowsVisible={arrowsVisible}
                            isMobile={isMobile}
                            showVideo={showVideo}
                        >
                            {items}
                        </Carousel>
                    </InternalScrollCarouselProvider>
                </ScrollCarouselProvider>
            )}
        </>
    );
};

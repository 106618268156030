import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useState, useEffect, useCallback, useRef } from 'react';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import classNames from 'classnames';
import { useIntl, defineMessages } from 'dibs-react-intl';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;
const {
    STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_ROW_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_SUBMIT_CLICK,
} = stepInteractionConstants;
import { SearchBar as SearchBarElement } from 'dibs-elements/exports/SearchBar';
import { SIZES } from 'dibs-elements/exports/constants';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';

import { SearchBarOverlay } from './SearchBarOverlay';
import { useSearchSuggestions } from '../../hooks/useSearchSuggestions';
import { useSearchBarTracking } from '../../hooks/useSearchBarTracking';
import { getCursorTerms, handleRecentStorage } from '../../utils/sharedSearchBarHelpers';
import {
    KEY_DOWN_VALUE,
    KEY_UP_VALUE,
    SEARCH_PAGE_PATH,
    SEARCH_QUERY_PARAM,
    ORIGINAL_SEARCH_QUERY_PARAM,
    SEARCH_BAR_TRACKING,
} from '../../utils/sharedSearchBarConstants';
import { useDebouncedCallback } from 'dibs-react-hooks/exports/useDebouncedCallback';
import { getLocalHistoryItemIds } from 'dibs-recent-history/exports/getLocalHistoryItemIds';
import { MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS } from 'dibs-recent-history/exports/constants';
import { isSellerBrandingRemovalTestVariant } from '../../utils/abTest/sellerBrandingRemovalAbTestHelpers';

import styles from './SearchBar-style.scss';

const MIN_NUM_VALUE_CHARACTERS = 2;
const SEARCH_BAR_DATA_TN = 'global-header-search-input';
const ELEMENTS_PREVENTING_SEARCH_BAR_SHRINK = [
    `${SEARCH_BAR_DATA_TN}-search-button`,
    `${SEARCH_BAR_DATA_TN}-clear`,
    // in some OS + browsers combos buttons are not focused on click, then tabbable wrapper div gets the focus
    `${SEARCH_BAR_DATA_TN}-search-button-wrapper`,
    `${SEARCH_BAR_DATA_TN}-clear-wrapper`,
];

const labels = defineMessages({
    searchBarPlaceholder: { id: 'dbl.Header.SearchBar.placeholder', defaultMessage: 'Search' },
});

export const SearchBarComponent = props => {
    const intl = useIntl();
    const [value, setValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isSearchBarCleared, setIsSearchBarCleared] = useState(false);
    const [cursorTerms, setCursorTerms] = useState([]);
    const [cursorPosition, setCursorPosition] = useState(-1);
    const [cursorHighlightId, setCursorHighlightId] = useState(undefined);
    const overlayWrapper = useRef(null);

    const { hasRootPendingRefetch, isClient, relay, viewer } = props;
    const { user } = viewer;

    const { suggestions, recentSearches, trackEventLabel } = useSearchSuggestions({ viewer });

    const dropdownChoices = suggestions || recentSearches;
    const isVisible = isFocused || isHovered;

    const onFocus = useCallback(
        event => {
            trackEvent(
                {
                    category: SEARCH_BAR_TRACKING.CATEGORIES.NAVIGATION,
                    action: SEARCH_BAR_TRACKING.ACTIONS.SEARCH_BAR_CLICK,
                    eventName: EVENT_NAVIGATION,
                    interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                    step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_CLICK,
                },
                event
            );
            setIsFocused(true);
        },
        [setIsFocused]
    );

    const onBlur = useCallback(
        event => {
            const focusedElement = event.relatedTarget?.getAttribute('data-tn');
            if (!ELEMENTS_PREVENTING_SEARCH_BAR_SHRINK.includes(focusedElement)) {
                setIsFocused(false);
            }
        },
        [setIsFocused]
    );

    const onClear = useCallback(
        event => {
            event.stopPropagation();
            setIsFocused(false);
        },
        [setIsFocused]
    );

    const onMouseOver = useCallback(() => {
        setIsHovered(true);
    }, [setIsHovered]);

    const onMouseOut = useCallback(() => {
        setIsHovered(false);
    }, [setIsHovered]);

    const setSearchTermFromParams = useCallback(() => {
        const { hash, href, search } = location;
        const hashParams = new URLSearchParams(get(hash.split('?'), '[1]'));
        const oqParam = hashParams.get(ORIGINAL_SEARCH_QUERY_PARAM);

        if (href.indexOf(SEARCH_PAGE_PATH) !== -1 || oqParam) {
            const searchParams = new URLSearchParams(search);
            const searchTerm = oqParam || searchParams.get(SEARCH_QUERY_PARAM);

            if (searchTerm) {
                setValue(searchTerm);
            }
        }
    }, [setValue]);

    const handleRowClick = useCallback(
        (val, href, event) => {
            setValue(val);
            setIsFocused(false);
            setIsHovered(false);

            trackEvent(
                {
                    category: SEARCH_BAR_TRACKING.CATEGORIES.NAVIGATION,
                    action: SEARCH_BAR_TRACKING.ACTIONS.HEADER_NAV_CLICK,
                    label: trackEventLabel,
                    eventName: EVENT_NAVIGATION,
                    interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                    step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_ROW_CLICK,
                    trigger: trackEventLabel,
                },
                event
            );

            handleRecentStorage(val, href);
            location.assign(handleLocaleUrl(href, GLOBAL_CLIENT_ONLY_LOCALE));
        },
        [trackEventLabel, setValue, setIsFocused, setIsHovered]
    );

    const handleCursorChange = useCallback(
        move => {
            const newCursorTerms = cursorTerms.length
                ? cursorTerms
                : getCursorTerms(dropdownChoices, value, intl);
            let newCursorPosition = cursorPosition;

            if (!dropdownChoices) {
                // if we have no section values exit out
                return;
            }

            switch (move) {
                case KEY_UP_VALUE:
                    newCursorPosition =
                        cursorPosition || cursorPosition === 0
                            ? (cursorPosition - 1 + newCursorTerms.length) % newCursorTerms.length
                            : newCursorTerms.length - 2;
                    break;
                case KEY_DOWN_VALUE:
                    newCursorPosition =
                        cursorPosition || cursorPosition === 0
                            ? (cursorPosition + 1) % newCursorTerms.length
                            : 0;
                    break;
                default:
                    break;
            }

            const newValue = newCursorTerms[newCursorPosition].term;
            const newHighlightId = newCursorTerms[newCursorPosition].highlightId;
            setValue(newValue);
            setCursorHighlightId(newHighlightId);
            setCursorTerms(newCursorTerms);
            setCursorPosition(newCursorPosition);
        },
        [
            dropdownChoices,
            value,
            intl,
            cursorPosition,
            cursorTerms,
            setCursorPosition,
            setCursorTerms,
            setCursorHighlightId,
        ]
    );

    const handleKeyDown = event => {
        if (event.keyCode === 27) {
            onBlur(event);
        }

        if (event.keyCode === 40) {
            event.preventDefault();
            handleCursorChange(KEY_DOWN_VALUE);
        }

        if (event.keyCode === 38) {
            event.preventDefault();
            handleCursorChange(KEY_UP_VALUE);
        }
    };

    const fetchSuggestions = useCallback(() => {
        const localRecentHistoryItemIds = getLocalHistoryItemIds({ isClient: true });
        const fragmentVariables = {
            hasQuery: value.length > 1,
            query: value.toLowerCase(),
            localRecentHistoryItemIds,
            fetchUserRecentHistoryItem:
                localRecentHistoryItemIds.length < MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS,
            includeTypes: isSellerBrandingRemovalTestVariant()
                ? ['POPULAR', 'CATEGORY', 'NO_SELLER', 'CREATOR']
                : ['POPULAR', 'CATEGORY', 'SELLER', 'CREATOR'],
        };

        if (relay && !hasRootPendingRefetch) {
            relay.refetch(
                () => fragmentVariables,
                { ...fragmentVariables, fetchUserRecentHistoryItem: false },
                () => setIsSearchBarCleared(false)
            );
        }
    }, [value, relay, hasRootPendingRefetch]);

    const [debouncedFetchSuggestions] = useDebouncedCallback(fetchSuggestions, 250);

    const handleChange = useCallback(
        newValue => {
            if (!newValue) {
                setIsSearchBarCleared(true);
            }
            setValue(newValue);
            setCursorTerms([]);
            setCursorHighlightId(null);
            setCursorPosition(-1);
        },
        [setValue, setCursorTerms, setCursorPosition]
    );

    const handleSubmit = useCallback(
        event => {
            event.preventDefault();
            let urlLabel;

            if (cursorPosition > -1 && cursorTerms[cursorPosition].link) {
                handleRowClick(
                    cursorTerms[cursorPosition].term,
                    cursorTerms[cursorPosition].link,
                    event
                );
            } else if (value) {
                urlLabel = `/search/?q=${encodeURIComponent(value.toLowerCase())}`;
                trackEvent(
                    {
                        category: SEARCH_BAR_TRACKING.CATEGORIES.NAVIGATION,
                        action: SEARCH_BAR_TRACKING.ACTIONS.HEADER_NAV_CLICK,
                        label: SEARCH_BAR_TRACKING.LABELS.GLOBAL_SEARCH,
                        eventName: EVENT_NAVIGATION,
                        interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                        step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_SUBMIT_CLICK,
                        trigger: SEARCH_BAR_TRACKING.LABELS.GLOBAL_SEARCH,
                    },
                    event
                );
                handleRecentStorage(value, urlLabel);
                location.assign(handleLocaleUrl(urlLabel, GLOBAL_CLIENT_ONLY_LOCALE));
            }
        },
        [value, cursorTerms, cursorPosition, handleRowClick]
    );

    useEffect(() => {
        if (value && !cursorHighlightId) {
            debouncedFetchSuggestions(value);
        }
    }, [cursorHighlightId, value, debouncedFetchSuggestions]);

    useEffect(() => {
        if (overlayWrapper && overlayWrapper.current) {
            overlayWrapper.current.addEventListener('mouseover', onMouseOver);
            overlayWrapper.current.addEventListener('mouseout', onMouseOut);
            setSearchTermFromParams();
        }
    }, [overlayWrapper, setSearchTermFromParams, onMouseOut, onMouseOver]);

    const showRecentlyViewed =
        !suggestions || value.length < MIN_NUM_VALUE_CHARACTERS || isSearchBarCleared;
    const searchBarClassName = classNames(styles.searchBar, {
        [styles.isActive]: isVisible,
    });

    useSearchBarTracking({ isVisible, showRecentlyViewed, recentSearches, suggestions });

    return (
        <div className={styles.wrapper}>
            <form className={styles.form} action="/search/" role="search" onSubmit={handleSubmit}>
                <div className={searchBarClassName}>
                    <SearchBarElement
                        dataTn={SEARCH_BAR_DATA_TN}
                        hasClickableSearchButton
                        isClearButtonHidden={!value}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onChange={handleChange}
                        onClear={onClear}
                        onKeyDown={handleKeyDown}
                        placeholder={intl.formatMessage(labels.searchBarPlaceholder)}
                        size="medium"
                        value={value}
                        leftMargin={SIZES.small}
                        isHeaderSearch
                    />
                </div>
            </form>
            <div className={styles.overlayWrapper} ref={overlayWrapper}>
                {isClient ? (
                    <SearchBarOverlay
                        overlayClassName={styles.overlay}
                        canShowRecentlyViewed={isVisible && showRecentlyViewed}
                        handleRowClick={handleRowClick}
                        visible={isVisible}
                        suggestions={showRecentlyViewed ? recentSearches : suggestions}
                        searchTerm={value}
                        cursorHighlightId={cursorHighlightId}
                        user={user || null}
                    />
                ) : null}
            </div>
        </div>
    );
};

SearchBarComponent.propTypes = {
    hasRootPendingRefetch: PropTypes.bool,
    isClient: PropTypes.bool,
    relay: PropTypes.object,
    viewer: PropTypes.object,
};

export const SearchBar = createRefetchContainer(
    SearchBarComponent,
    {
        viewer: graphql`
            fragment SearchBar_viewer on Viewer
            @argumentDefinitions(
                query: { type: "String", defaultValue: "" }
                hasQuery: { type: "Boolean", defaultValue: false }
            ) {
                ...useSearchSuggestions_viewer @arguments(query: $query, hasQuery: $hasQuery)
                user(userId: $userId) @include(if: $hasUserId) {
                    ...getRecentHistory_user @defer
                }
            }
        `,
    },
    graphql`
        query SearchBarRefetchQuery(
            $query: String!
            $hasQuery: Boolean!
            $userId: String!
            $hasUserId: Boolean!
            $localRecentHistoryItemIds: [String] = []
            $fetchUserRecentHistoryItem: Boolean = false
            $includeTypes: [String] = []
        ) {
            viewer {
                ...SearchBar_viewer @arguments(query: $query, hasQuery: $hasQuery)
            }
        }
    `
);

import { trackEvent, eventNameConstants, interactionTypeConstants } from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_NOTIFICATIONS } = interactionTypeConstants;

export const notificationNameMap = {
    AUTOMATED_OFFER: 'automated offer',
    PENDING_FEEDBACK: 'review purchase',
    VIEW_PROMO: 'view promo',
    VIEW_CONVERSATION: 'view conversation',
};

export function getNotificationName(nodeType) {
    return notificationNameMap[nodeType];
}

export function trackNotification({ label, action, itemId }) {
    const trackingAction = `header notifications notification ${action}`;
    trackEvent(
        {
            label,
            category: 'navigation',
            action: trackingAction,
            isInteractiveEvent: false,
            value: 0,
            eventName: EVENT_NAVIGATION,
            interaction_type: INTERACTION_TYPE_NOTIFICATIONS,
            step_interaction_name: action,
            trigger: label,
            itemId,
        },
        null
    );
}

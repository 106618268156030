/**
 * @generated SignedSource<<8522c1d933f189683ee5e7a5cc0b4281>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FooterBottomRow_viewer$data = {
  readonly user?: {
    readonly " $fragmentSpreads": FragmentRefs<"DisplaySettingsModal_user">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"DisplaySettingsModal_viewer">;
  readonly " $fragmentType": "FooterBottomRow_viewer";
};
export type FooterBottomRow_viewer$key = {
  readonly " $data"?: FooterBottomRow_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FooterBottomRow_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FooterBottomRow_viewer",
  "selections": [
    {
      "condition": "hasUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "userId"
            }
          ],
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DisplaySettingsModal_user"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DisplaySettingsModal_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "11e0c4828db30a835fcb11ccdb97c6f8";

export default node;

export const SRCSET_SIZES = [
    200, // portrait mobile 1x
    320, // landscape mobile 1x
    400, // portrait mobile 2x
    640, // landscape mobile 2x
    800, // portrait mobile 3x
    960, // landscape mobile 3x
];

/**
 * Returns a single url with passed fastly options as query params.
 *
 * @export
 * @param {string} url - url of the image
 * @param {number} width - desired image width
 * @param {number} [quality=60] - image quality
 * @param {Object} [options]
 * @param {number} [options.quality = 60] - image quality
 * @param {string} [options.crop] - remove pixels from an image
 * @returns {string}
 */

// add fastly options as needed
// https://developer.fastly.com/reference/io/
type Options = { quality?: number; crop?: string };

export function getSrcsetUrl(url: string, width: number, options?: Options): string {
    const { quality = 60, ...additionalFastlyOptions } = options || {};
    const [originalUrl, searchString] = (url || '').split('?');
    const queryParams = new URLSearchParams(searchString);
    queryParams.set('disable', 'upscale');
    queryParams.set('auto', 'webp');
    queryParams.set('quality', quality.toString());

    if (width) {
        queryParams.set('width', width.toString());
    }

    Object.entries(additionalFastlyOptions).forEach(option => {
        if (option?.[0] && option?.[1]) {
            queryParams.set(option[0], option[1]);
        }
    });

    return `${originalUrl}?${queryParams.toString()}`;
}

/**
 * Builds a src set string to be used accross this app.
 *
 * @export
 * @param {string} url - url of the image
 * @param {array} [widths] - widths used to construct srcset string.  If not provided a default widths will be used
 * @param {Object} [options]
 * @param {number} [options.quality] - image quality
 * @param {string} [options.crop] - remove pixels from an image
 * @returns {string}
 */
export function getSrcsetString(
    url: string,
    widths?: number[] | null,
    options?: Options
): string | undefined {
    return url
        ? (widths || SRCSET_SIZES)
              .map(width => `${getSrcsetUrl(url, width, options)} ${width}w`)
              .join(', ')
        : undefined;
}

/**
 * Get srcset using x-descriptors, which does not require sizes attribute.
 * https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#resolution_switching_same_size_different_resolutions
 * TODO: Get srcSetString from shared place i.e. dibs-components
 */
export const getSrcSetStringXDescriptors = ({
    url,
    width = 60,
    quality = 60,
}: {
    url?: string | null;
    width?: number;
    quality?: number;
}): string | null | undefined => {
    if (!url) {
        return url;
    }
    return [width, width * 2]
        .map(
            (w, index) =>
                `${url}?disable=upscale&auto=webp&quality=${quality}&width=${w} ${index + 1}x`
        )
        .join(', ');
};

import { spacing } from 'dibs-sassy/exports/spacing';

import { ScrollPadding, GapSize } from '../../types';

const gapSizes: Record<GapSize, string> = {
    none: '1px',
    xSmall: spacing.sassySpacingXSmall,
    small: spacing.sassySpacingSmall,
    medium: spacing.sassySpacingMedium,
    large: spacing.sassySpacingLarge,
};

const scrollPaddingSizes: Record<ScrollPadding, string> = {
    none: '0px',
    small: spacing.sassySpacingSmall,
    large: spacing.sassySpacingLarge,
};

export const adjustFlexBasis = ({
    scrollPadding,
    gapSize,
    itemsToShow,
}: {
    scrollPadding: ScrollPadding;
    gapSize: GapSize;
    itemsToShow: number;
}): string => {
    if (scrollPadding !== 'none') {
        return `calc((100% + ${scrollPaddingSizes[scrollPadding]} - ${gapSizes[gapSize]} / 2) / ${itemsToShow})`;
    } else if (gapSize !== 'none' && itemsToShow % 1 !== 0) {
        return `calc((100% - (${gapSizes[gapSize]} / 2)) / ${itemsToShow})`;
    }

    return `${100 / itemsToShow}%`;
};

import PropTypes from 'prop-types';
import { HomePageContext } from '../homepage/HomePageContext';
import { connectContext } from '../context/helpers/connectContext';

const device = {
    DESKTOP: 'desktop',
    TABLET: 'tablet',
    MOBILE: 'mobile',
};

function RenderIn({ children, deviceTypes, isDesktop, isTablet, isMobile }) {
    if (!children) {
        return null;
    }

    let currentDevice;
    if (isDesktop) {
        currentDevice = device.DESKTOP;
    } else if (isTablet) {
        currentDevice = device.TABLET;
    } else if (isMobile) {
        currentDevice = device.MOBILE;
    }

    if (!deviceTypes.includes(currentDevice)) {
        return null;
    }

    if (typeof children === 'function') {
        return children({ currentDevice });
    }

    return children;
}

RenderIn.propTypes = {
    deviceTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
    /**
     * Values from ServerVarsContext
     */
    isDesktop: PropTypes.bool,
    isTablet: PropTypes.bool,
    isMobile: PropTypes.bool,
};

function mapContextToProps({ isMobile, isTablet, isDesktop }) {
    return { isMobile, isTablet, isDesktop };
}

RenderIn = connectContext(HomePageContext, mapContextToProps)(RenderIn);

export { RenderIn, device };

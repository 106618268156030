import {
    fetchQuery_DEPRECATED as fetchQuery,
    graphql,
    Environment,
    GraphQLTaggedNode,
} from 'react-relay/legacy';
import { sessionStorage } from 'dibs-browser-storage';
import { cookieJarConstants } from 'dibs-cookie-jar';
import { includes } from 'dibs-ts-utils/exports/includes';
import SV from 'server-vars';
import Cookies from 'js-cookie';

import {
    checkHomepageGroupQuery as queryType,
    HomepageHoldoutGroup,
} from './__generated__/checkHomepageGroupQuery.graphql';

export const HOMEPAGE_GROUP_CONSUMER = 'consumer' as const;
export const HOMEPAGE_GROUP_TRADE = 'trade' as const;
export const HOMEPAGE_GROUP_CONTROL = 'control' as const;

const { HOMEPAGE_USER_GROUP } = cookieJarConstants;
export const HOMEPAGE_USER_GROUP_KEY = 'hug';

const checkHomepageGroupQuery: GraphQLTaggedNode = graphql`
    query checkHomepageGroupQuery($userId: String!) {
        viewer {
            homepageUserGroup(userId: $userId)
        }
    }
`;

export function _isHomepageGruoupValid(value: HomepageHoldoutGroup | JsonType): boolean {
    return includes([HOMEPAGE_GROUP_CONSUMER, HOMEPAGE_GROUP_TRADE, HOMEPAGE_GROUP_CONTROL], value);
}

export function _isUserGroupInStorage(): boolean {
    const userGroup: JsonType = sessionStorage.getItem(HOMEPAGE_USER_GROUP_KEY);
    const isValidGroup = _isHomepageGruoupValid(userGroup);

    return !!userGroup && !!isValidGroup;
}

export function removeUserGroupData(): void {
    sessionStorage.deleteItem(HOMEPAGE_USER_GROUP_KEY);
    Cookies.remove(HOMEPAGE_USER_GROUP);
}

export async function checkHomepageUserGroup(relayEnv: Environment, userId: string): Promise<void> {
    const isEnabled = SV.get('enablers.checkHomepageGroup');
    if (!isEnabled) {
        // remove the previous flag, so it can be set correctly once it is enabled
        removeUserGroupData();
        return;
    }

    if (!_isUserGroupInStorage()) {
        let userGroup: HomepageHoldoutGroup;
        try {
            const result = await fetchQuery<queryType>(relayEnv, checkHomepageGroupQuery, {
                userId,
            });
            userGroup = result?.viewer?.homepageUserGroup || HOMEPAGE_GROUP_CONSUMER;
        } catch (err) {
            userGroup = HOMEPAGE_GROUP_CONSUMER;
        }

        sessionStorage.setItem(HOMEPAGE_USER_GROUP_KEY, userGroup);
        Cookies.set(HOMEPAGE_USER_GROUP, userGroup);
    }
}

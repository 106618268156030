/**
 * @generated SignedSource<<d7fe26b13c0ddc99ca8c8773124033ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedPromoModulePrimary_componentModule$data = {
  readonly cmsDisplayTitle: string | null;
  readonly messages: {
    readonly items: ReadonlyArray<{
      readonly cmsId: number | null;
      readonly linkData: {
        readonly path: string | null;
      };
      readonly " $fragmentSpreads": FragmentRefs<"HpSharedPromoModulePromoItem_promotion">;
    } | null> | null;
    readonly title: string | null;
  } | null;
  readonly " $fragmentType": "HpSharedPromoModulePrimary_componentModule";
};
export type HpSharedPromoModulePrimary_componentModule$key = {
  readonly " $data"?: HpSharedPromoModulePrimary_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedPromoModulePrimary_componentModule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "userType"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedPromoModulePrimary_componentModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cmsDisplayTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "userType",
          "variableName": "userType"
        }
      ],
      "concreteType": "PromoModuleMessages",
      "kind": "LinkedField",
      "name": "messages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PromoItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cmsId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LinkData",
              "kind": "LinkedField",
              "name": "linkData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "path",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HpSharedPromoModulePromoItem_promotion"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PromoModule",
  "abstractKey": null
};

(node as any).hash = "b3fa45de9eeac2cdf611fc66369003d3";

export default node;

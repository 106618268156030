import * as React from 'react';

import { netLabel } from './messages';

// Component
import { PriceLabel } from './PriceLabel';
import { textPercentageOff } from '../helpers/priceTypeConstants';

// Styles
import styles from './styles.scss';

type NetProps = {
    currency: string;
    percentageOff?: string | null;
    price: number;
    quantityDisplay?: string | null;
    showPriceVariability?: boolean | null;
};

export const Net: React.FC<NetProps> = ({
    percentageOff,
    price,
    quantityDisplay,
    currency,
    showPriceVariability,
}) => {
    return (
        <React.Fragment>
            <PriceLabel
                price={price}
                label={netLabel}
                quantityDisplay={quantityDisplay}
                currency={currency}
                showPriceVariability={showPriceVariability}
            />
            {percentageOff ? (
                <div className={`${styles.percentageOff} ${styles.net}`} data-tn="discount-amount">
                    {textPercentageOff(percentageOff)}
                </div>
            ) : null}
        </React.Fragment>
    );
};

Net.defaultProps = {
    percentageOff: null,
    showPriceVariability: false,
};

/**
 * @generated SignedSource<<15147166ce5b1503c6d5ede449feef14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecentlyViewedItemsCarousel_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedItemsHeart_user" | "useRecentHistoryList_user">;
  readonly " $fragmentType": "RecentlyViewedItemsCarousel_user";
};
export type RecentlyViewedItemsCarousel_user$key = {
  readonly " $data"?: RecentlyViewedItemsCarousel_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedItemsCarousel_user">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "count",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "excludeItemPks",
    "variableName": "excludeItemPks"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "excludeItemPks"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecentlyViewedItemsCarousel_user",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useRecentHistoryList_user"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "RecentlyViewedItemsHeart_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "72d22ba4cb64665bb4d52c5baba64dfc";

export default node;

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';
import SiteNavItem from './SiteNavItem';
import { SITE_NAV_AB_TEST_NAME } from '../../siteNavAbTestConfig';

import styles from './SiteNav-style.scss';

export function SiteNav({ viewer, isNavigationHovered, onMouseEnter, onMouseLeave }) {
    const navigation = viewer?.navigation || null;

    useEffect(() => {
        trackAbTestV2Variant(SITE_NAV_AB_TEST_NAME);
    }, []);

    return (
        <nav className={styles.navigation}>
            <ul className={styles.items}>
                {(navigation?.modules || []).map((module, index) => {
                    return (
                        <SiteNavItem
                            key={`nav-${module.serviceId}-position-${index}`}
                            navigationModule={module}
                            isNavigationHovered={isNavigationHovered}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                        />
                    );
                })}
            </ul>
        </nav>
    );
}

SiteNav.propTypes = {
    isNavigationHovered: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    viewer: PropTypes.object,
};

export default createFragmentContainer(SiteNav, {
    viewer: graphql`
        fragment SiteNav_viewer on Viewer {
            navigation(rootId: $previewKey, variantId: $variantId) {
                modules {
                    ...SiteNavItem_navigationModule
                    serviceId
                }
            }
        }
    `,
});

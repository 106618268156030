import { getBuyerToken } from '../index';
import { Cookie } from './types';
import { getCookieValue } from './getCookieValue';
import { USER_EMAIL_TOKEN } from './constants';

export function getEmailTokenString(cookie: Cookie): string {
    return getCookieValue(cookie, USER_EMAIL_TOKEN) || '';
}

type IsEmailOnlyUserTokenArg = {
    userToken?: string | null;
    emailToken?: string | null;
};
//Info about token prefixes: https://github.com/1stdibs/necrodibsicon/blob/master/back-end/identity-service/tokens/Token_Updates.md
//Currently supporting old emailToken
export const isEmailOnlyUserToken = ({
    userToken,
    emailToken,
}: IsEmailOnlyUserTokenArg = {}): boolean => {
    const emailOnlyTokenPrefixPattern = /\d+\_+m-/;
    return (
        (userToken && !!userToken.match(emailOnlyTokenPrefixPattern)) ||
        (!!emailToken && !userToken)
    );
};

export const isEmailOnly = (cookie: Cookie): boolean => {
    const tokenObj = getBuyerToken(cookie);
    return isEmailOnlyUserToken(tokenObj);
};

import { commitMutation, graphql } from 'react-relay/legacy';

const mutation = graphql`
    mutation itemEventsShownMutation($input: ItemEventsShownInput!) {
        itemEventsShown(input: $input) {
            favoriteNotifications {
                hasNewEvents
            }
        }
    }
`;

export function itemEventsShownMutation({ environment, userId }) {
    return commitMutation(environment, {
        mutation,
        variables: {
            input: {
                userId,
            },
        },
    });
}

import serverVars from 'server-vars';
import { fetchQuery_DEPRECATED as fetchQuery, graphql, Environment } from 'react-relay/legacy';
import widgetLoader from 'dibs-widget-loader';

import './styles/recaptcha.scss';

import {
    recaptchaFeatureFlagQuery$data,
    recaptchaFeatureFlagQuery,
} from './__generated__/recaptchaFeatureFlagQuery.graphql';

import { NODE_RECAPTCHA_SITE_KEY_NAME, NODE_RECAPTCHA_SITE_KEY_V3_NAME } from './recaptchaConsts';

const sitekey = serverVars.get(NODE_RECAPTCHA_SITE_KEY_NAME);
const sitekeyv3 = serverVars.get(NODE_RECAPTCHA_SITE_KEY_V3_NAME);
const elId = `dibs-recaptcha`;

const query = graphql`
    query recaptchaFeatureFlagQuery(
        $featureFlag: String = ""
        $featureFlagV3: String = ""
        $hasFeatureFlag: Boolean!
        $hasFeatureFlagV3: Boolean!
    ) {
        viewer {
            featureFlag(feature: $featureFlag) @include(if: $hasFeatureFlag)
            v3Enabled: featureFlag(feature: $featureFlagV3) @include(if: $hasFeatureFlagV3)
        }
    }
`;

const isLoaded = (): boolean =>
    typeof window !== 'undefined' &&
    typeof window.grecaptcha !== 'undefined' &&
    typeof window.grecaptcha.render === 'function';

const isReady = new Promise<void>(resolve => {
    // `typeof window === 'undefined'` - fixes issue on server-side tests
    if (isLoaded() || typeof window === 'undefined') {
        resolve();
    } else {
        const interval = setInterval(() => {
            if (isLoaded()) {
                clearInterval(interval);
                resolve();
            }
        }, 300);
    }
});

async function setUpCaptcha(): Promise<void> {
    if (document.getElementById(elId)) {
        return;
    }
    await widgetLoader.load('recaptcha');
    await isReady;

    // put callbacks on window or else functions get gc'd (i think?)
    window.dibsCaptchaCallbacks = { reject: () => {}, resolve: () => {} };

    const node = document.createElement('div');
    node.setAttribute('id', elId);
    node.setAttribute('data-sitekey', sitekey);
    node.setAttribute('data-badge', 'inline');
    node.setAttribute(
        'style',
        `position: absolute; top: ${window.pageYOffset + window.innerHeight / 2}px`
    );
    document.body.appendChild(node);
    window.grecaptcha.render(node, {
        sitekey,
        size: 'invisible',
        callback: token => {
            window.dibsCaptchaCallbacks.resolve(token);
            window.grecaptcha.reset();
        },
        'error-callback': () => {
            window.dibsCaptchaCallbacks.reject();
            window.grecaptcha.reset();
        },
    });
}

const loadCaptcha = async (recaptchaV3SiteKey: string): Promise<void> => {
    await widgetLoader.load('recaptchaEnterprise', { recaptchaV3SiteKey });
    await new Promise<void>(resolve => {
        window.grecaptcha.enterprise.ready(() => resolve());
    });
};

export type SupportedMFATypes = 'CODE'[] | null; // UserLoginMutation supportedMFATypes arg

export type CaptchaResponse = {
    // null if captcha is not enabled via feature flag
    captchaToken: string | null;
    // only set when using recaptcha v3
    captchaKey: string | null;
    mfaVerificationCode?: string | null;
    supportedMFATypes?: SupportedMFATypes | null;
};
export type CaptchaV3Response = {
    captchaToken: string;
    captchaKey: string;
    mfaVerificationCode?: string | null;
    supportedMFATypes?: SupportedMFATypes | null;
};

async function recaptchaV3(
    args?: {
        action?: string;
        userName?: string | null;
        mfaVerificationCode?: string | null;
        supportedMFATypes?: SupportedMFATypes;
    },
    TMP_siteKey?: string
): Promise<CaptchaV3Response> {
    const recaptchaV3SiteKey = TMP_siteKey || sitekeyv3;
    await loadCaptcha(recaptchaV3SiteKey);
    const {
        action = 'noAction',
        userName = '',
        mfaVerificationCode = null,
        supportedMFATypes = null,
    } = args || {};
    const captchaPayload = { action, username: userName };
    const captchaToken = await window.grecaptcha.enterprise.execute(
        recaptchaV3SiteKey,
        captchaPayload
    );
    let actualMFAVerificationCode = mfaVerificationCode;
    if (mfaVerificationCode === '') {
        // must set this to `null` otherwise BE will kick
        // off MFA flow even if it's just an empty string
        actualMFAVerificationCode = null;
    }
    return {
        captchaToken,
        captchaKey: recaptchaV3SiteKey,
        mfaVerificationCode: actualMFAVerificationCode,
        supportedMFATypes,
    };
}

function getReCaptchaElement(): ParentNode | null {
    return (
        [...document.getElementsByTagName('iframe')].find(x =>
            x.src.includes('google.com/recaptcha/api2/bframe')
        )?.parentNode?.parentNode || null
    );
}

function detectWhenReCaptchaChallengeIsShown(): Promise<Node> {
    const recaptchaWindow = getReCaptchaElement();
    if (recaptchaWindow) {
        // recaptcha window already loaded;
        return Promise.resolve(recaptchaWindow);
    }
    return new Promise(function (resolve) {
        // recaptcha window not loaded, wait for it using MutationObserver on document.body
        const reCaptchaObserver = new MutationObserver(mutationRecords => {
            mutationRecords.forEach(mutationRecord => {
                if (mutationRecord.addedNodes.length) {
                    const newRecaptchaWindow = getReCaptchaElement();
                    if (newRecaptchaWindow) {
                        reCaptchaObserver.disconnect();
                        resolve(newRecaptchaWindow);
                    }
                }
            });
        });
        reCaptchaObserver.observe(document.body, { childList: true });
    });
}
/**
 * Executes recaptcha,
 * relayEnvironment only needed if featureFlag is provided
 */
async function executeCaptcha(args?: {
    featureFlag?: string;
    featureFlagV3?: string;
    action?: string;
    userName?: string | null; // MFA uses email or phone number
    mfaVerificationCode?: string | null;
    supportedMFATypes?: SupportedMFATypes;
    relayEnvironment?: Environment;
}): Promise<CaptchaResponse> {
    const {
        featureFlag,
        featureFlagV3,
        relayEnvironment,
        action,
        userName,
        mfaVerificationCode,
        supportedMFATypes,
    } = args || {};
    if ((featureFlag || featureFlagV3) && relayEnvironment) {
        const result: recaptchaFeatureFlagQuery$data = await fetchQuery<recaptchaFeatureFlagQuery>(
            relayEnvironment,
            query,
            {
                featureFlag,
                featureFlagV3,
                hasFeatureFlag: !!featureFlag,
                hasFeatureFlagV3: !!featureFlagV3,
            }
        );
        const viewer = result?.viewer;
        // if a v3 flag is passed and it is enabled - use v3 enterprise
        if (featureFlagV3 && viewer?.v3Enabled) {
            return await recaptchaV3({ action, userName, mfaVerificationCode, supportedMFATypes });
        }
        // else if a v2 flag is passed and it is not enabled - return null
        if (featureFlag && !viewer?.featureFlag) {
            return { captchaToken: null, captchaKey: null };
        }
    }
    // no flag is passed or v2 is enabled - setup v2 captcha
    await setUpCaptcha();
    return await new Promise((resolve, reject) => {
        window.dibsCaptchaCallbacks.resolve = captchaToken =>
            resolve({ captchaToken, captchaKey: null });
        window.dibsCaptchaCallbacks.reject = () => {
            reject(new Error('Recaptcha error-callback'));
        };
        window.grecaptcha.execute();

        // There is no callback when the user clicks outside the recaptcha modal.
        // Use MutationObservers to detect when the modal is shown and when it is hidden.
        // Reject the promise with an error if we detect the modal is hidden so the user
        // can try again. Adapted from https://stackoverflow.com/a/59474086
        detectWhenReCaptchaChallengeIsShown().then(function (reCaptchaChallengeOverlayDiv) {
            const reCaptchaChallengeClosureObserver = new MutationObserver(function () {
                if (
                    // modal is hidden
                    (reCaptchaChallengeOverlayDiv as HTMLElement).style.visibility === 'hidden' &&
                    // there is no recaptcha response, so we know its not hidden becuse they
                    // completed the captcha successfully
                    !grecaptcha.getResponse()
                ) {
                    reCaptchaChallengeClosureObserver.disconnect();
                    reject(new Error('Recaptcha window closed'));
                }
            });
            reCaptchaChallengeClosureObserver.observe(reCaptchaChallengeOverlayDiv, {
                attributes: true,
                attributeFilter: ['style'],
            });
        });
    });
}

if (typeof window !== 'undefined') {
    // for easy testing from console
    // @ts-ignore so we don't have declare the `CaptchaResponse` in the global scope
    window.recaptchaV3 = recaptchaV3;
    // @ts-ignore so we don't have declare the `CaptchaResponse` in the global scope
    window.executeCaptcha = executeCaptcha;
}

export { executeCaptcha, recaptchaV3 };

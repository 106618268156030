import { FC, useCallback, ReactNode } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';
import { SeoLink, ALWAYS_LINKABLE_LINK } from 'dibs-elements/exports/SeoLink';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';

import { trackViewMoreClick } from '../recentHistoryTracking';
import { ACTION_LABEL } from './constants';

import styles from './RecentlyViewedItemsWrapper.scss';

export type RecentlyViewedItemsWrapperProps = {
    wrapperClassName?: string;
    centerTitle?: boolean;
    alignTitle?: 'top' | 'left';
    isMobile?: boolean;
    actionLabel?: string;
    trackViewMoreClick?: typeof trackViewMoreClick;
    children: ReactNode;
};

export const RecentlyViewedItemsWrapper: FC<RecentlyViewedItemsWrapperProps> = ({
    wrapperClassName,
    children,
    actionLabel = ACTION_LABEL,
    centerTitle = false,
    alignTitle = 'top',
    isMobile = false,
    ...props
}) => {
    const onViewMoreClick = useCallback(() => {
        (props.trackViewMoreClick || trackViewMoreClick)({ actionLabel });
    }, [actionLabel, props.trackViewMoreClick]);

    const wrapperStyles = classnames(wrapperClassName, {
        [styles.centerTitle]: centerTitle,
        [styles.isSwiperCarousel]: isMobile,
        [styles.alignTitleLeft]: alignTitle === 'left',
    });

    return (
        <div className={wrapperStyles}>
            <div className={styles.header} data-tn="recently-viewed-header">
                <HeadingLevel>
                    {Heading => (
                        <Heading className={styles.title}>
                            <FormattedMessage
                                id="RecentlyViewedItemsCarousel.title"
                                defaultMessage="Recently Viewed"
                            />
                        </Heading>
                    )}
                </HeadingLevel>
                <SeoLink
                    className={styles.viewMore}
                    variant={ALWAYS_LINKABLE_LINK}
                    linkData={{ path: '/favorites/viewed/', isLinkable: false }}
                    onClick={onViewMoreClick}
                    dataTn="recently-viewed-link"
                >
                    <FormattedMessage
                        id="RecentlyViewedItemsCarousel.viewAllCta"
                        defaultMessage="View All"
                    />
                </SeoLink>
            </div>
            <div className={styles.carousel}>{children}</div>
        </div>
    );
};

import { useState, useEffect, useCallback, useRef } from 'react';

export type Config = {
    timeframe: number;
    timeframeBetweenClicks: number;
    lastClickTimeStamp?: number;
    clickCount: number;
    target: EventTarget | null;
    maxClickCount: number;
};

export const getUpdatedConfig = ({
    config,
    timeStamp,
    target,
}: {
    config: Config;
    timeStamp: number;
    target: EventTarget | null;
}): { config: Config; triggered: boolean } => {
    config.timeframeBetweenClicks = timeStamp - (config.lastClickTimeStamp || 0);

    const isSameTarget = config.target === target;

    if (typeof config.lastClickTimeStamp !== undefined && isSameTarget) {
        if (config.timeframeBetweenClicks < config.timeframe) {
            /* increments count if clicks within timeframe */
            config.clickCount++;
        } else {
            /* else resets count to 1 */
            config.clickCount = 1;
        }
    } else {
        /* sets count to 1 on first click or resets on new target */
        config.clickCount = 1;
    }

    config.target = target;
    config.lastClickTimeStamp = timeStamp;

    return { config, triggered: config.clickCount >= config.maxClickCount };
};

const TIMEFRAME_MS = 500;
const CLICK_COUNT = 3;

/**
 * @param {function} callback called when `clickCount` subsequent clicks are each within `timeframeMs`
 * @param {number} [options.timeframeMs] max timeframe in MS between clicks
 * @param {number} [options.clickCount] click count to trigger `callback`
 */
export const useRageClick = (
    callback: (e: MouseEvent) => void,
    options?: { timeframeMs?: number; clickCount?: number }
): void => {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;
    const [config, setConfig] = useState<Config>({
        timeframe: options?.timeframeMs || TIMEFRAME_MS,
        timeframeBetweenClicks: 0,
        target: null,
        clickCount: 0,
        maxClickCount: options?.clickCount || CLICK_COUNT,
    });

    const trackRageClicks = useCallback(
        (e: MouseEvent) => {
            const { config: _config, triggered: _triggered } = getUpdatedConfig({
                config,
                timeStamp: e.timeStamp,
                target: e.target,
            });

            setConfig(_config);

            if (_triggered) {
                callbackRef.current(e);
            }
        },
        [config]
    );

    useEffect(() => {
        /**
         * `capture` used to register event even if `stopPropagation()` is used.
         * https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#capture
         */
        window.addEventListener('click', trackRageClicks, { capture: true });

        return () => {
            window.removeEventListener('click', trackRageClicks, { capture: true });
        };
    }, [trackRageClicks]);
};

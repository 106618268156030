import { trackEvent } from 'dibs-tracking';

type Args = {
    label: string;
    moduleIndex: number;
    totalModules: number;
};

export const trackModuleLocation = ({ label, moduleIndex, totalModules }: Args): void => {
    trackEvent({
        category: 'promo interaction',
        moduleLocation: `${moduleIndex + 1}/${totalModules}`,
        action: 'clicked homepage module',
        label,
    });
};

export const trackModule = (label: string | null): void => {
    if (label) {
        trackEvent({
            category: 'promo interaction',
            action: 'homepage click',
            label,
            noninteraction: 0,
        });
    }
};

import { useId, FC, ReactElement, useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames';

import { FocusTrap } from '../Common/FocusTrap';
import { useOutsideClick } from '../useOutsideClick';
import styles from './main.scss';

type Props = {
    dropdownContent: ReactElement;
    toggleDropdown: () => void;
    dropdownIsOpen: boolean;
};

export const DropdownContent: FC<Props> = ({ dropdownContent, toggleDropdown, dropdownIsOpen }) => {
    const trigger = useRef<HTMLElement | undefined>(undefined);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const id = `${useId()}-dropdown-content`;

    useEffect(() => {
        if (document.activeElement instanceof HTMLElement) {
            trigger.current = document.activeElement;
        }
    }, []);

    const outsideClickCallback = useCallback(() => {
        if (dropdownIsOpen) {
            toggleDropdown();
        }
    }, [dropdownIsOpen, toggleDropdown]);

    useOutsideClick(elementRef, outsideClickCallback);

    useEffect(() => {
        const handleEscapeKey = (e: KeyboardEvent): void => {
            if (dropdownIsOpen && e.key === 'Escape') {
                toggleDropdown();
            }
        };

        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [dropdownIsOpen, toggleDropdown]);

    return (
        <div id={id} data-tn={id} ref={elementRef}>
            <FocusTrap
                options={{
                    fallbackFocus: `#${id}`,
                    clickOutsideDeactivates: true,
                    setReturnFocus: trigger.current,
                }}
                active={dropdownIsOpen}
            >
                <div
                    className={classNames({
                        [styles.hideDropdown]: !dropdownIsOpen,
                    })}
                    tabIndex={-1}
                >
                    {dropdownContent}
                </div>
            </FocusTrap>
        </div>
    );
};

import { trackEcommerce, eventNameConstants } from 'dibs-tracking';
import { getPromoCreative } from '../../helpers/getPromoCreative';

function trackEditorialImpressions(items) {
    trackEcommerce({
        type: 'promotionImpression',
        eventName: eventNameConstants.EVENT_VIEW_PROMOTION,
        promotions: items.map((item, index) => {
            const url = item?.linkData?.path || '';
            const position = index + 1;
            return {
                id: `content-promo-${position}`,
                name: `Content Promo #${position}`,
                creative: getPromoCreative(url),
                position: position,
            };
        }),
    });
}

function trackEditorialClick(item, index) {
    const url = item?.linkData?.path || '';
    const position = index + 1;
    trackEcommerce({
        type: 'promoClick',
        eventName: eventNameConstants.EVENT_SELECT_PROMOTION,
        promotions: [
            {
                id: `content-promo-${position}`,
                name: `Content Promo #${position}`,
                creative: getPromoCreative(url),
                position: position,
            },
        ],
    });
}

export { trackEditorialImpressions, trackEditorialClick };

import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class CurrencyProviderComponent extends Component {
    render() {
        const { currency } = this.props;
        return this.props.render(currency);
    }
}

CurrencyProviderComponent.propTypes = {
    currency: PropTypes.object,
    render: PropTypes.func.isRequired,
};

const mapStateToProps = ({ currency }) => ({ currency });
const CurrencyProvider = connect(mapStateToProps)(CurrencyProviderComponent);

export { CurrencyProvider };

/**
 * @generated SignedSource<<569635000d68793e04f7f04a635bf206>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedLocationsModule_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedBestsellingDesignsCarousel_user" | "HpSharedExcellentValuesCarousel_user" | "HpSharedMostSavedItemsCarousel_user">;
  readonly " $fragmentType": "HpSharedLocationsModule_user";
};
export type HpSharedLocationsModule_user$key = {
  readonly " $data"?: HpSharedLocationsModule_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedLocationsModule_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isDynamicCollectionVariant"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedLocationsModule_user",
  "selections": [
    {
      "condition": "isDynamicCollectionVariant",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedMostSavedItemsCarousel_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedExcellentValuesCarousel_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedBestsellingDesignsCarousel_user"
        }
      ]
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "7dcb69fe918aa3eec7f92c3320d51ee4";

export default node;

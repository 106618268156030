import { actionTypes } from '../actions/constants';
import type { CartItem } from '../actions/cartActions';

const {
    SET_ADD_CART_ITEM_MUTATION_COMPLETED,
    REMOVE_PORTFOLIO_CART_ITEM,
    SET_CART_REFETCH_TRIGGER,
    SET_CART_ITEMS,
} = actionTypes;

type CartState = {
    items: CartItem[] | null;
    addCartItemMutationCompleted: boolean | null;
    removedPortfolioCartItemId: string | null;
    cartRefetchTrigger: number;
};
function getInitialState(): CartState {
    return {
        items: null,
        addCartItemMutationCompleted: false,
        removedPortfolioCartItemId: null,
        cartRefetchTrigger: 0,
    };
}
export type CartActionType =
    | {
          type: typeof SET_ADD_CART_ITEM_MUTATION_COMPLETED;
          payload: { addCartItemMutationCompleted: boolean };
      }
    | { type: typeof REMOVE_PORTFOLIO_CART_ITEM; payload: { removedPortfolioCartItemId: string } }
    | { type: typeof SET_CART_REFETCH_TRIGGER }
    | { type: typeof SET_CART_ITEMS; payload: { items: CartItem[] } };

type ReducerReturn = CartState;
export default function (state = getInitialState(), action: CartActionType): ReducerReturn {
    switch (action.type) {
        case SET_CART_ITEMS:
            return {
                ...state,
                items: action?.payload?.items || null,
            };
        case SET_ADD_CART_ITEM_MUTATION_COMPLETED:
            return {
                ...state,
                addCartItemMutationCompleted: true,
            };
        case REMOVE_PORTFOLIO_CART_ITEM:
            return {
                ...state,
                removedPortfolioCartItemId: action?.payload?.removedPortfolioCartItemId || null,
            };
        case SET_CART_REFETCH_TRIGGER:
            return {
                ...state,
                cartRefetchTrigger: state.cartRefetchTrigger + 1,
            };
        default:
            return state;
    }
}

import { FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

import { Link, LinkProps } from 'dibs-elements/exports/Link';

const PrivacyPolicyMessage: FC = () => (
    <FormattedMessage id="dbl.authmodal.privacyPolicy" defaultMessage="Privacy Policy" />
);
const UserAgreementMessage: FC = () => (
    <FormattedMessage id="dbl.authmodal.userAgreement" defaultMessage="User Agreement" />
);

type LegalLinkProps = Omit<LinkProps, 'href'>;

export const PrivacyPolicyLink: FC<LegalLinkProps> = ({
    className,
    buyerLinkType,
    underline,
    dataTn,
}) => (
    <Link
        dataTn={dataTn || 'legal-privacy-policy-link'}
        href="/about/privacy-policy/"
        target="_blank"
        className={className}
        buyerLinkType={buyerLinkType}
        underline={underline}
    >
        <PrivacyPolicyMessage />
    </Link>
);

export const UserAgreementLink: FC<LegalLinkProps> = ({
    className,
    buyerLinkType,
    underline,
    dataTn,
}) => (
    <Link
        dataTn={dataTn || 'legal-user-agreement-link'}
        href="/about/user-agreement/"
        target="_blank"
        className={className}
        buyerLinkType={buyerLinkType}
        underline={underline}
    >
        <UserAgreementMessage />
    </Link>
);

type LegalEmailNoticeProps = {
    additionalCopy?: string;
};
export const LegalEmailNotice: FC<LegalEmailNoticeProps> = ({ additionalCopy }) => {
    return (
        <FormattedMessage
            id="dbl.basicInfoForm.legal"
            defaultMessage="By entering your email, you agree to our {userAgreementLink} and {privacyPolicy}{hasAdditionalCopy, select, 
                true {{additionalCopy}} 
                other {.}}"
            values={{
                userAgreementLink: (
                    <UserAgreementLink buyerLinkType="invertedLink" underline="underline" />
                ),
                privacyPolicy: (
                    <PrivacyPolicyLink buyerLinkType="invertedLink" underline="underline" />
                ),
                hasAdditionalCopy: !!additionalCopy,
                additionalCopy,
            }}
        />
    );
};

export const AcceptPolicyNotice: FC<LegalLinkProps> = ({ className }) => (
    <FormattedMessage
        key="acceptPolicy"
        id="dbl.authmodal.registration.acceptPolicy"
        defaultMessage="I have read and accepted 1stDibs’ {userAgreementLink} and {privacyPolicyLink}."
        values={{
            privacyPolicyLink: <PrivacyPolicyLink className={className} />,
            userAgreementLink: <UserAgreementLink className={className} />,
        }}
    />
);

export const AcceptPolicyNoticeWithHiddenOptIn: FC<LegalLinkProps> = ({ className }) => (
    <FormattedMessage
        key="acceptPolicyWithHiddenOptIn"
        id="dbl.authmodal.registration.acceptPolicyWithHiddenOptIn"
        defaultMessage="By clicking continue, I acknowledge that I have read and accepted 
            1stDibs’ {userAgreementLink} and {privacyPolicyLink}, and am signing up for marketing emails."
        values={{
            privacyPolicyLink: <PrivacyPolicyLink className={className} />,
            userAgreementLink: <UserAgreementLink className={className} />,
        }}
    />
);

import { FC } from 'react';
import classnames from 'classnames';

import { trackNavigationClick } from '../../../helpers/trackingHelpers';

import { FooterLink } from '../FooterLinkTypes';
import { SeoLink } from 'dibs-elements/exports/SeoLink';

import styles from './LinkColumnItem.scss';

type Props = FooterLink & {
    isLegalLink?: boolean;
    isConnectLink?: boolean;
    isGlobalSitesLink?: boolean;
};

const LinkColumnItem: FC<Props> = ({
    target,
    trackingLabel,
    href,
    dataTn,
    message,
    badgeText,
    isLegalLink,
    isLinkable = true,
    isConnectLink,
    isGlobalSitesLink,
}) => {
    const doNotShowPrivacyPolicyAlerts = true;
    const shouldShowBadge = !!badgeText && !doNotShowPrivacyPolicyAlerts;

    const linkContent = (
        <>
            {message}
            {shouldShowBadge && <div>{badgeText}</div>}
        </>
    );

    const itemClassName = classnames(styles.item, {
        [styles.isLegalLink]: !!isLegalLink,
        [styles.isConnectLink]: !!isConnectLink,
        [styles.isGlobalSitesLink]: !!isGlobalSitesLink,
    });

    return (
        <li className={itemClassName}>
            {href ? (
                <SeoLink
                    onClick={event => {
                        trackNavigationClick({ label: trackingLabel }, event);
                    }}
                    linkData={{
                        path: href,
                        isLinkable,
                    }}
                    target={target}
                    dataTn={dataTn}
                    buyerLinkType="standardLinkNoUnderline"
                >
                    {linkContent}
                </SeoLink>
            ) : (
                message
            )}
        </li>
    );
};

export default LinkColumnItem;

import { FormattedMessage } from 'dibs-react-intl';

export const saleLabel = (
    <FormattedMessage id="dc.searchProductTile.salePrice" defaultMessage="Sale Price" />
);
export const netLabel = (
    <FormattedMessage id="dc.searchProductTile.netPrice" defaultMessage="Net Price" />
);
export const retailLabel = (
    <FormattedMessage id="dc.searchProductTile.listPrice" defaultMessage="List Price" />
);
export const priceVariabilityLabel = (
    <FormattedMessage id={'dc.searchProductTile.priceVariability'} defaultMessage={'From'} />
);
export const soldPriceLabel = (
    <FormattedMessage id="dc.searchProductTile.SimilarSoldPrice" defaultMessage="Sold Price" />
);

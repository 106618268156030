/**
 * @generated SignedSource<<a1791aa93ec65872b608395a3750f0ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useItemDisplayDimensions_item$data = {
  readonly measurement: {
    readonly heightDimensions: ReadonlyArray<{
      readonly unit: string | null;
      readonly value: string | null;
    } | null> | null;
    readonly widthDimensions: ReadonlyArray<{
      readonly unit: string | null;
      readonly value: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "useItemDisplayDimensions_item";
};
export type useItemDisplayDimensions_item$key = {
  readonly " $data"?: useItemDisplayDimensions_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useItemDisplayDimensions_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "addLabel",
  "value": false
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useItemDisplayDimensions_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemMeasurement",
      "kind": "LinkedField",
      "name": "measurement",
      "plural": false,
      "selections": [
        {
          "alias": "widthDimensions",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "fields",
              "value": [
                "width"
              ]
            }
          ],
          "concreteType": "ItemDisplayMeasurementType",
          "kind": "LinkedField",
          "name": "display",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": "display(addLabel:false,fields:[\"width\"])"
        },
        {
          "alias": "heightDimensions",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "fields",
              "value": [
                "height"
              ]
            }
          ],
          "concreteType": "ItemDisplayMeasurementType",
          "kind": "LinkedField",
          "name": "display",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": "display(addLabel:false,fields:[\"height\"])"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "5834545029b737f1211eada927e58b55";

export default node;

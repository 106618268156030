import * as tracking from 'dibs-tracking';
import get from 'lodash.get';

import {
    TRACKING_VARIANT_BROWSE,
    TRACKING_VARIANT_PDP,
    TRACKING_PERSONALIZED_TYPE_TRENDING,
    TRACKING_PERSONALIZED_TYPE_PERSONALIZATION,
} from '../constants';

const {
    trackingConstants: { ECOM_PRODUCT_CLICK, ECOM_PRODUCT_IMPRESSION },
    eventNameConstants: { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST },
} = tracking;

function getModuleType(module) {
    return module === TRACKING_PERSONALIZED_TYPE_TRENDING
        ? TRACKING_PERSONALIZED_TYPE_TRENDING
        : TRACKING_PERSONALIZED_TYPE_PERSONALIZATION;
}

function trackPersonalizationImpression({
    recommendedItems = [],
    categoryUrl,
    startIndex,
    variant,
}) {
    startIndex = startIndex * recommendedItems.length;
    const moduleType = getModuleType(variant);
    tracking.trackEcommerce({
        type: ECOM_PRODUCT_IMPRESSION,
        eventName: EVENT_VIEW_ITEM_LIST,
        products: recommendedItems.map((item, index) => {
            return {
                brand: get(item, 'seller.serviceId'),
                id: item?.serviceId,
                category: categoryUrl,
                dimension83: item?.contemporaryTrackingString,
                price: get(item, 'pricing.retailPrice.amount') || '',
                list: `recommended categories - ${moduleType} module`,
                position: index + startIndex + 1,
                variant: index === 0 ? TRACKING_VARIANT_BROWSE : TRACKING_VARIANT_PDP,
            };
        }),
    });
}

function trackPersonalizationItemClick({ item, variant, categoryUrl, index, moduleVariantType }) {
    const moduleType = getModuleType(moduleVariantType);

    tracking.trackEcommerce({
        type: ECOM_PRODUCT_CLICK,
        eventName: EVENT_SELECT_ITEM,
        actionField: {
            list: `recommended categories - ${moduleType} module`,
            itemId: item?.serviceId,
        },
        products: [
            {
                brand: get(item, 'seller.serviceId'),
                id: item?.serviceId,
                category: categoryUrl,
                dimension83: item?.contemporaryTrackingString,
                price: get(item, 'pricing.retailPrice.amount') || '',
                position: index,
                variant,
            },
        ],
    });
}

export { trackPersonalizationImpression, trackPersonalizationItemClick };

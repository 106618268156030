import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Link } from 'dibs-elements/exports/Link';
// styles
import styles from './SearchBarTerm.scss';

export const SearchBarTerm = props => {
    const { href, term, handleRowClick, cursorHighlightId, id, termElements } = props;
    const searchBarTermClasses = classnames(styles.item, {
        [styles.active]: id === cursorHighlightId,
    });

    return (
        <div className={searchBarTermClasses}>
            <Link
                dataTn="search-bar-term"
                className={styles.link}
                href={href}
                onClick={e => {
                    e.preventDefault();
                    handleRowClick(term, href, e);
                }}
            >
                {termElements}
            </Link>
        </div>
    );
};

SearchBarTerm.propTypes = {
    term: PropTypes.string,
    termElements: PropTypes.any,
    href: PropTypes.string,
    handleRowClick: PropTypes.func,
    cursorHighlightId: PropTypes.string,
    id: PropTypes.string,
};

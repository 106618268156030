export const MAX_TILES = 20;
export const MAX_TILE_ITEMS = 3;
export const ITEM_EVENTS_SHOWN_PERIODE_MS = 2592000000; //30 days

export const VIEW_ALL = 'View All';
export const ITEM_EVENT = 'itemEvent';
export const FAVORITES = 'recentlySavedItems';
export const FOLLOWING = 'following';
export const FOLLOWING_TYPE_SEARCH = 'SEARCH';
export const EMPTY = 'empty';
export const ON_SALE = 'ON_SALE';
export const OFFER_MADE = 'OFFER_MADE';
export const ITEM_SOLD = 'ITEM_SOLD';

/**
 * @generated SignedSource<<a54a46dbd6d0a9eb0c15ba24d858f595>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductCarousel_item$data = {
  readonly carouselPhotos: ReadonlyArray<{
    readonly __typename: "ItemPhoto";
  } | null> | null;
  readonly serviceId: string | null;
  readonly viewInRoomPhotos: ReadonlyArray<{
    readonly __typename: "viewInRoomPhotos";
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProductSwiper_item">;
  readonly " $fragmentType": "ProductCarousel_item";
};
export type ProductCarousel_item$key = {
  readonly " $data"?: ProductCarousel_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductCarousel_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchVideo"
    },
    {
      "defaultValue": 2,
      "kind": "LocalArgument",
      "name": "photosLimit"
    },
    {
      "defaultValue": 1,
      "kind": "LocalArgument",
      "name": "viewInRoomLimit"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductCarousel_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": "carouselPhotos",
      "args": [
        {
          "kind": "Variable",
          "name": "limit",
          "variableName": "photosLimit"
        }
      ],
      "concreteType": "ItemPhoto",
      "kind": "LinkedField",
      "name": "photos",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "limit",
          "variableName": "viewInRoomLimit"
        }
      ],
      "concreteType": "viewInRoomPhotos",
      "kind": "LinkedField",
      "name": "viewInRoomPhotos",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "fetchVideo",
          "variableName": "fetchVideo"
        },
        {
          "kind": "Variable",
          "name": "photosLimit",
          "variableName": "photosLimit"
        },
        {
          "kind": "Variable",
          "name": "viewInRoomLimit",
          "variableName": "viewInRoomLimit"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProductSwiper_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "ae04a31fd4e921960ea2c920a07f793f";

export default node;

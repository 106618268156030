import { ReactNode, ChangeEvent, FocusEvent, KeyboardEvent, HTMLAttributes } from 'react';
import * as React from 'react';
import classnames from 'classnames';
import ErrorIcon from 'dibs-icons/exports/legacy/Exclamation';
import ErrorIconCircle from 'dibs-icons/exports/legacy/ExclamationCircle';
import ValidatedIcon from 'dibs-icons/exports/legacy/Checkmark';
import { IdConsumer } from 'dibs-uid';
import { ErrorMessage } from '../ErrorMessage';
import { Field } from '../Common/Field/Field';
import { FormLabel } from '../Common/FormLabel/FormLabel';
import { InputBorderContainer } from '../Common/InputBorderContainer/InputBorderContainer';
import { AnimatedPlaceholderContainer } from '../Common/AnimatedPlaceholderContainer/AnimatedPlaceholderContainer';
import { TextInputContainer } from '../Common/TextInputContainer/TextInputContainer';
import { SIZES, DIRECTIONS } from '../Common/constants';

import styles from './main.scss';
import dibsCss from 'dibs-css';

export type InputProps = {
    autoCapitalize?: boolean;
    autoCorrect?: boolean;
    autoComplete?: boolean | string;
    autoFocus?: boolean;
    className?: string;
    dataTn: string;
    disabled?: boolean;
    errorDataTn?: string;
    useCircularErrorIcon?: boolean;
    errorMessage?: React.ReactNode | { __html: string };
    errorMessageAlignment?: typeof DIRECTIONS.right | typeof DIRECTIONS.left;
    hasAnimatedPlaceholder?: boolean;
    hasError?: boolean;
    hasValidText?: boolean;
    horizontalSpacing?: typeof SIZES.def | typeof SIZES.small | typeof SIZES.large;
    inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
    highlightColor?: string;
    label?: React.ReactNode;
    leftMargin?: typeof SIZES.small;
    leftDecorator?: React.ReactNode;
    leftDecoratorClass?: string;
    maskForPrivacy?: boolean;
    max?: number | string;
    maxLength?: number;
    min?: number | string;
    name?: string;
    noBorder?: boolean;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
    pattern?: string;
    placeholder?: string;
    readOnly?: boolean;
    rightDecorator?: React.ReactNode;
    rightDecoratorClass?: string;
    showIcons?: boolean;
    size?: typeof SIZES.large | typeof SIZES.medium | typeof SIZES.small;
    spellCheck?: boolean;
    step?: string;
    type?: string;
    defaultValue?: string;
    value?: string;
    title?: string;
    ariaControls?: string;
    ariaDescribedBy?: string;
    ariaLabel?: string | null;
    multiple?: boolean;
    accept?: string;
    inputRef?: (input: HTMLInputElement) => void;
    isHeaderInput?: boolean;
    /** must be universally unique among HTML element ids */
    id?: string;
};

type InputState = {
    isFocused: boolean;
};

export class Input extends React.Component<InputProps, InputState> {
    static defaultProps = {
        autoCapitalize: true,
        autoComplete: '',
        autoCorrect: true,
        autoFocus: false,
        disabled: false,
        errorMessageAlignment: DIRECTIONS.left,
        hasAnimatedPlaceholder: false,
        hasError: false,
        horizontalSpacing: SIZES.def,
        noBorder: false,
        isHeaderInput: false,
        readOnly: false,
        showIcons: true,
        spellCheck: true,
        type: 'text',
        maskForPrivacy: false,
        leftMargin: '',
    };

    constructor(props: InputProps) {
        super(props);
        const { autoFocus } = props;

        this.state = {
            isFocused: !!autoFocus,
        };

        this.inputNode = null;
    }

    componentDidMount(): void {
        // initial call to focus element if autoFocus is passed in as true
        /* istanbul ignore if */
        if (this.props.autoFocus && this.inputNode && this.inputNode.focus) {
            this.inputNode.focus();
        }
    }

    inputNode: HTMLInputElement | null;

    onBlur = (e: FocusEvent<HTMLInputElement>): void => {
        const { onBlur } = this.props;

        this.setState({ isFocused: false });

        /* istanbul ignore else */
        if (onBlur) {
            onBlur(e);
        }
    };

    onFocus = (e: FocusEvent<HTMLInputElement>): void => {
        const { onFocus } = this.props;

        /* istanbul ignore else */
        if (onFocus) {
            onFocus(e);
        }

        this.setState({ isFocused: true });
    };

    shouldShowAnimatedPlaceholder = (): boolean => {
        const { hasAnimatedPlaceholder, placeholder, readOnly, value } = this.props;
        const { isFocused } = this.state;

        if (hasAnimatedPlaceholder && !!placeholder) {
            if (!value) {
                return isFocused && !readOnly;
            }
            return true;
        }
        return false;
    };

    render(): ReactNode {
        const {
            accept,
            autoCorrect,
            autoCapitalize,
            spellCheck,
            autoComplete,
            autoFocus,
            className,
            dataTn,
            disabled,
            errorDataTn,
            errorMessage,
            errorMessageAlignment,
            useCircularErrorIcon,
            hasError,
            hasValidText,
            horizontalSpacing,
            inputMode,
            highlightColor,
            label,
            leftDecorator,
            leftDecoratorClass,
            leftMargin,
            maskForPrivacy,
            max,
            maxLength,
            min,
            multiple,
            name,
            onChange,
            noBorder,
            isHeaderInput,
            onKeyDown,
            pattern,
            placeholder,
            readOnly,
            rightDecorator,
            rightDecoratorClass,
            size,
            showIcons,
            step,
            type,
            title,
            defaultValue,
            value,
            ariaControls,
            ariaDescribedBy,
            ariaLabel,
            inputRef,
            id: propId,
        } = this.props;

        const { isFocused } = this.state;
        const showError = hasError || !!errorMessage;
        const showErrorIcon = showError;
        const showSuccessIcon = !showErrorIcon && hasValidText;
        const shouldShowIcon = showIcons && (showErrorIcon || showSuccessIcon);
        const errorDataTnString = errorDataTn ? errorDataTn : `${dataTn}-error`;

        const leftDecoratorClasses = classnames(styles.decorator, leftDecoratorClass);
        const rightDecoratorClasses = classnames(styles.decorator, rightDecoratorClass);

        let autoCompleteString = '';
        if (typeof autoComplete === 'boolean') {
            autoCompleteString = autoComplete ? 'on' : 'off';
        } else if (typeof autoComplete === 'string') {
            autoCompleteString = autoComplete;
        }

        const autoCorrectString: string = autoCorrect ? 'on' : 'off';
        const autoCapitalizeString: string = autoCapitalize ? 'on' : 'off';
        const spacing = horizontalSpacing === SIZES.small ? SIZES.small : SIZES.medium;
        /* NOTE ON PLACEHOLDERS
            input element placeholder attributes are not accessible. render the
            placeholder text in a hidden span and and associate it to the input
            with aria-describedby on the input. display: none is invisible to
            the accessibility tree except when used with attributes like
            aria-describedby
        */

        // allow null ariaLabel
        const derivedAriaLabel =
            ariaLabel !== null && !label ? ariaLabel || placeholder : undefined;

        return (
            <IdConsumer>
                {uid => {
                    const internalUid = uid ? `input-${uid}` : undefined;
                    const inputId = propId || internalUid;
                    // only render hidden placeholder if it can be related via
                    // id, has truthy value, will not be used in place of label
                    // or ariaLabel, and is not redundant with label or
                    // ariaLabel
                    const renderHiddenPlaceholder =
                        !!(uid && placeholder) &&
                        (label || ariaLabel) &&
                        placeholder !== label &&
                        placeholder !== ariaLabel;
                    const placeholderId = renderHiddenPlaceholder
                        ? `input-placeholder-${uid}`
                        : undefined;

                    const describedBy =
                        [ariaDescribedBy, placeholderId].filter(Boolean).join(' ') || undefined;
                    let marginLeft = leftDecorator ? SIZES.none : spacing;
                    if (leftMargin) {
                        marginLeft = leftMargin;
                    }
                    return (
                        <Field>
                            <FormLabel htmlFor={inputId}>{label}</FormLabel>
                            {renderHiddenPlaceholder && (
                                <span id={placeholderId} className={styles.hiddenPlaceholder}>
                                    {placeholder}
                                </span>
                            )}
                            <InputBorderContainer
                                disabled={disabled}
                                hasError={showError}
                                isFocused={isFocused}
                                noBorder={noBorder}
                                isHeaderInput={isHeaderInput}
                                size={size}
                                maskForPrivacy={maskForPrivacy}
                                onClick={() => {
                                    if (this.inputNode) {
                                        this.inputNode.focus();
                                    }
                                }}
                            >
                                {leftDecorator && (
                                    <div className={leftDecoratorClasses}>{leftDecorator}</div>
                                )}
                                <AnimatedPlaceholderContainer
                                    marginLeft={marginLeft}
                                    marginRight={rightDecorator ? SIZES.none : spacing}
                                    placeholder={placeholder}
                                    showAnimatedPlaceholder={this.shouldShowAnimatedPlaceholder()}
                                >
                                    {({ placeholderDidRender }) => (
                                        <TextInputContainer
                                            paddingTop={
                                                placeholderDidRender
                                                    ? SIZES.animatedPlaceholder
                                                    : SIZES.none
                                            }
                                            isHeaderInput={isHeaderInput}
                                            isHighlighted={!!highlightColor}
                                        >
                                            {/* There's a bug on FF and Safari where the `first-line` pseudo selector
                                            doesn't actually select input content: https://stackoverflow.com/a/22131244
                                            Best bet is to have a hidden container with the same value and a bg color */}
                                            {highlightColor && (
                                                <div
                                                    className={classnames(
                                                        highlightColor,
                                                        dibsCss.absolute,
                                                        dibsCss.selfCenter
                                                    )}
                                                >
                                                    <span className={dibsCss.invisible}>
                                                        {value}
                                                    </span>
                                                </div>
                                            )}
                                            <input
                                                autoCapitalize={autoCapitalizeString}
                                                autoComplete={autoCompleteString}
                                                autoCorrect={autoCorrectString}
                                                autoFocus={autoFocus}
                                                className={className}
                                                data-tn={dataTn}
                                                disabled={disabled}
                                                id={inputId}
                                                inputMode={inputMode}
                                                max={max || undefined}
                                                maxLength={maxLength || undefined}
                                                min={min || undefined}
                                                name={name}
                                                onBlur={this.onBlur}
                                                onChange={onChange}
                                                onFocus={this.onFocus}
                                                onKeyDown={onKeyDown}
                                                pattern={pattern}
                                                placeholder={
                                                    placeholderDidRender ? '' : placeholder
                                                }
                                                readOnly={readOnly}
                                                ref={input => {
                                                    this.inputNode = input;
                                                    if (input && inputRef) {
                                                        inputRef(input);
                                                    }
                                                }}
                                                spellCheck={spellCheck}
                                                step={step}
                                                type={type}
                                                defaultValue={defaultValue}
                                                value={value}
                                                aria-controls={ariaControls}
                                                aria-describedby={describedBy}
                                                aria-label={derivedAriaLabel}
                                                title={title}
                                                multiple={multiple}
                                                accept={accept}
                                            />
                                        </TextInputContainer>
                                    )}
                                </AnimatedPlaceholderContainer>

                                {shouldShowIcon && (
                                    <div
                                        className={classnames(styles.iconContainer, {
                                            [styles.iconContainerSmall]:
                                                horizontalSpacing === SIZES.small,
                                            [styles.iconContainerLarge]:
                                                horizontalSpacing === SIZES.large,
                                        })}
                                    >
                                        {showErrorIcon && (
                                            <>
                                                {useCircularErrorIcon ? (
                                                    <ErrorIconCircle className={styles.errorIcon} />
                                                ) : (
                                                    <ErrorIcon className={styles.errorIcon} />
                                                )}
                                            </>
                                        )}
                                        {showSuccessIcon && (
                                            <ValidatedIcon className={styles.validatedIcon} />
                                        )}
                                    </div>
                                )}
                                {rightDecorator && (
                                    <div className={rightDecoratorClasses}>{rightDecorator}</div>
                                )}
                            </InputBorderContainer>
                            <ErrorMessage
                                alignRight={errorMessageAlignment === DIRECTIONS.right}
                                message={errorMessage}
                                dataTn={errorDataTnString}
                            />
                        </Field>
                    );
                }}
            </IdConsumer>
        );
    }
}

import { FC, ReactNode, Children } from 'react';

import { useInternalScrollCarousel } from '../helpers/InternalScrollCarouselContext';
import { CarouselItem } from '../CarouselItem/CarouselItem';

export const BackFill: FC<{ children: ReactNode; type: 'start' | 'end' }> = ({
    children,
    type,
}) => {
    const { backfill, totalItems } = useInternalScrollCarousel();

    if (!backfill.show) {
        return null;
    }

    const isStart = type === 'start';
    const sliceArgs = isStart ? [-backfill.startCount] : [0, backfill.endCount];

    return (
        <>
            {Children.toArray(children)
                .slice(...sliceArgs)
                .map((child, index) => (
                    <CarouselItem
                        key={`backfill-${index}`}
                        //backfill items need to have the exact same index as the original items
                        snapIndex={isStart ? totalItems - (backfill.startCount - index) : index}
                        trueIndex={isStart ? index : index + backfill.startCount + totalItems}
                    >
                        {child}
                    </CarouselItem>
                ))}
        </>
    );
};

import { getLocalRecentHistoryList } from './getLocalRecentHistory';
import { MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS } from './recentHistoryConstants';

type Props = {
    maxItems?: number;
    isClient: boolean;
};
type LocalHistoryItemIdsReturn = [] | string[];

export const getLocalHistoryItemIds = ({
    isClient,
    maxItems = MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS,
}: Props): LocalHistoryItemIdsReturn => {
    if (!isClient) {
        return [];
    }
    // get local recently viewed items
    const localRecentItems = getLocalRecentHistoryList(undefined, maxItems).reverse();

    return localRecentItems.map(({ itemId }) => itemId);
};

/**
 * @generated SignedSource<<cf155f6c2bc2faf00528733c2a5d6c2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedLocationsModule_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedBestsellingDesignsCarousel_viewer" | "HpSharedExcellentValuesCarousel_viewer" | "HpSharedMostSavedItemsCarousel_viewer">;
  readonly " $fragmentType": "HpSharedLocationsModule_viewer";
};
export type HpSharedLocationsModule_viewer$key = {
  readonly " $data"?: HpSharedLocationsModule_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedLocationsModule_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isDynamicCollectionVariant"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedLocationsModule_viewer",
  "selections": [
    {
      "condition": "isDynamicCollectionVariant",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedMostSavedItemsCarousel_viewer"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedExcellentValuesCarousel_viewer"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedBestsellingDesignsCarousel_viewer"
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "e96f533bde90aee27f0a6db86fb47658";

export default node;

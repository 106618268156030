/**
 * @generated SignedSource<<61106072c19cb53f7bee8c2e908fa230>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Heart_heartPortfolioItems$data = ReadonlyArray<{
  readonly portfolioId: string | null;
  readonly portfolioItemId: string | null;
  readonly " $fragmentType": "Heart_heartPortfolioItems";
}>;
export type Heart_heartPortfolioItems$key = ReadonlyArray<{
  readonly " $data"?: Heart_heartPortfolioItems$data;
  readonly " $fragmentSpreads": FragmentRefs<"Heart_heartPortfolioItems">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Heart_heartPortfolioItems",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portfolioId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portfolioItemId",
      "storageKey": null
    }
  ],
  "type": "PortfolioItemV2",
  "abstractKey": null
};

(node as any).hash = "90ec07cccf758fe32c2edbfb66e9ab69";

export default node;

import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type ExclamationCircleProps = {
    className?: string;
};

const ExclamationCircle: FunctionComponent<ExclamationCircleProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'exclamation-circle'}
            viewBox="0 0 250 250"
        >
            <path d="M125.2-.2C56.2-.2 0 56 0 125s56.2 125.2 125.2 125.2c69.1 0 125.2-56.1 125.2-125.1S194.2-.2 125.2-.2zm.1 241.3C61.2 241.1 9.1 189 9.1 125S61.2 8.8 125.2 8.8c64.1 0 116.3 52 116.3 116.1s-52.1 116.2-116.2 116.2z" />
            <path d="M125.1 171c-8.6 0-15.6 7.1-15.6 15.8 0 8.7 6.9 15.6 15.6 15.6s15.8-7 15.8-15.6c0-8.7-7.1-15.8-15.8-15.8z" />
            <path d="M119.3 156.9l.2 3 11.1.2.2-3c1.3-21.5 4.4-47.7 6.6-66 1.5-12.1 2.5-20.1 2.5-23.1 0-9.2-6.4-16.3-14.6-16.3s-14.6 7.4-14.6 16.6c0 2.8.9 10.2 2.2 20.9 2.2 17.7 5.5 45.3 6.4 67.7z" />
        </svg>
    );
};
export default ExclamationCircle;

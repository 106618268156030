import React, { FC, ComponentProps } from 'react';
import { useIntl } from 'dibs-react-intl';
import { LOCALE_DE, LOCALE_FR, LOCALE_IT, LOCALE_ES } from 'dibs-intl/exports/locales';

const Sold = React.lazy(() => import('dibs-icons/exports/legacy/Sold'));
const SoldDe = React.lazy(() => import('dibs-icons/exports/legacy/SoldDe'));
const SoldFr = React.lazy(() => import('dibs-icons/exports/legacy/SoldFr'));
const SoldIt = React.lazy(() => import('dibs-icons/exports/legacy/SoldIt'));
const SoldEs = React.lazy(() => import('dibs-icons/exports/legacy/SoldEs'));

type Props = ComponentProps<typeof Sold | typeof SoldFr | typeof SoldDe>;

export const LocalizedSoldIcon: FC<Props> = props => {
    const { locale } = useIntl();

    if (locale === LOCALE_DE) {
        return <SoldDe {...props} />;
    }

    if (locale === LOCALE_FR) {
        return <SoldFr {...props} />;
    }

    if (locale === LOCALE_IT) {
        return <SoldIt {...props} />;
    }

    if (locale === LOCALE_ES) {
        return <SoldEs {...props} />;
    }

    return <Sold {...props} />;
};

import { FC, ComponentProps, Children } from 'react';
import { ScrollCarouselProvider } from '../helpers/ScrollCarouselContext';
import { InnerScrollCarousel } from '../InnerScrollCarousel/InnerScrollCarousel';
import { OnSlideChange } from '../types';

export const ScrollCarousel: FC<
    {
        stepSize?: number;
        isInfinite?: boolean;
        autoplay?: boolean;
        autoplaySpeed?: number;
        onSlideChange?: OnSlideChange;
    } & ComponentProps<typeof InnerScrollCarousel>
> = ({ stepSize, isInfinite, autoplay, autoplaySpeed, onSlideChange, ...props }) => {
    return (
        <ScrollCarouselProvider
            totalItems={Children.count(props.children)}
            largestItemsToShow={props.itemsToShow}
            stepSize={stepSize}
            isInfinite={isInfinite}
            autoplay={autoplay}
            autoplaySpeed={autoplaySpeed}
            onSlideChange={onSlideChange}
        >
            <InnerScrollCarousel {...props} />
        </ScrollCarouselProvider>
    );
};

import { Children, cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';
import ArrowLeft from 'dibs-icons/exports/legacy/ArrowLeft';
import { Link } from 'dibs-elements/exports/Link';
import Model from '../utils/SwiperModel';
import styles from './styles/Swiper.scss';

export class Swiper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modelState: {},
        };

        this.onModelChange = this.onModelChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSwipeStart = this.onSwipeStart.bind(this);
        this.onSwipeEnd = this.onSwipeEnd.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { hasInfiniteLoop, children, itemsPerPage, startingPage, trackedItemIndex } =
            this.props;
        this._model =
            this.props.model ||
            new Model({
                startingPage,
                itemsPerPage,
                itemCount: Children.count(children),
                hasInfiniteLoop: hasInfiniteLoop && this.enoughChildrenForInfiniteLoop(),
                onPageChange: this.onPageChange,
                trackedItemIndex,
                onSwipeStart: this.onSwipeStart,
                onSwipeEnd: this.onSwipeEnd,
            });
        if (this.props.swipeDisabled) {
            this.disableSwipe();
        }
    }

    componentDidMount() {
        // Pass the `el` to the view-model
        if (this._refList) {
            this._model.onMount(this._refList.parentElement);
            // Force a re-render if the model changes.
            this._model.addChangeListener(this.onModelChange);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // if startingPage is changed, we want to "jump" to that page
        // and then swipe from there
        if (this.props.startingPage !== nextProps.startingPage) {
            this._model.setPage(nextProps.startingPage);
        }

        if (this.props.trackedItemIndex !== nextProps.trackedItemIndex) {
            this._model.setTrackedItemIndex(nextProps.trackedItemIndex);
        }

        // if more children was added after mounting - sync itemCount in carousel
        if (Children.count(nextProps.children) !== Children.count(this.props.children)) {
            this._model.setItemCount(Children.count(nextProps.children));
        }

        if (nextProps.swipeDisabled) {
            this.disableSwipe();
        } else {
            this.enableSwipe();
        }
    }

    componentWillUnmount() {
        // Do any view-model cleanup.
        this._model.destroy();
        this._model.removeChangeListener(this.onModelChange);
    }

    onPageChange(nextPage, previousPage) {
        this.props.onPageChange(nextPage, previousPage);
    }

    onSwipeStart() {
        this.props.onSwipeStart();
    }

    onSwipeEnd() {
        this.props.onSwipeEnd();
    }

    enoughChildrenForInfiniteLoop() {
        return this.props.children.length > this.props.itemsPerPage;
    }

    onModelChange(modelState) {
        this.setState({ modelState });
    }

    enableSwipe() {
        this._model.enableSwipe();
    }

    disableSwipe() {
        this._model.disableSwipe();
    }

    /**
     * Wraps the passed in children in an `li` so we can apply additional styles to the items.
     * @return {Array}
     */
    _getChildren() {
        let children = Children.map(this.props.children, (child, itemIndex) => {
            const { item } = this.props.classNames;
            const style = {};
            const itemClass = classnames({
                [styles.item]: true,
                [item]: item,
            });

            // Get any item styles specified by the view-model.
            this._model.transformItemStyle(style, itemIndex);

            return (
                <li
                    style={style}
                    className={itemClass}
                    key={itemIndex}
                    data-tn={`carousel-item-${itemIndex}`}
                >
                    {child}
                </li>
            );
        });

        if (this.props.hasInfiniteLoop && this.enoughChildrenForInfiniteLoop()) {
            const itemsToSlice = this._model.getCloneCount();
            const firstClone = this.cloneElements({
                elements: children.slice(0, itemsToSlice),
                key: 'c1',
                dataTn: '-start-clone',
            });
            const lastClone = this.cloneElements({
                elements: children.slice(-itemsToSlice),
                key: 'c2',
                dataTn: '-end-clone',
            });
            children = [...lastClone, ...children, ...firstClone];
        }

        return children;
    }

    cloneElements({ key, elements, dataTn }) {
        return elements.map(clonedElement => {
            return cloneElement(clonedElement, {
                key: clonedElement.key + key,
                'data-tn': clonedElement.props['data-tn'] + dataTn,
            });
        });
    }

    render() {
        const { classNames, showArrows, hasInfiniteLoop, itemsTopAlign } = this.props;
        const { wrapper, list, prevArrow, nextArrow } = classNames;
        const style = {};
        const wrapperClass = classnames({
            [styles.wrapper]: true,
            [wrapper]: wrapper,
            [styles.relative]: showArrows,
        });
        const listClass = classnames({
            [styles.list]: true,
            [styles.itemsTopAlign]: itemsTopAlign,
            [list]: list,
        });
        const prevArrowClass = classnames(styles.arrowWrapper, styles.prevArrowWrapper, prevArrow);
        const nextArrowClass = classnames(styles.arrowWrapper, styles.nextArrowWrapper, nextArrow);

        // Get any styles specified by the view-model.
        this._model.transformListStyle(style);

        const canGoLeft = hasInfiniteLoop || !this._model._isFirstPage();
        const canGoRight = hasInfiniteLoop || !this._model._isLastPage();
        return (
            <div className={wrapperClass} data-tn={this.props.dataTn}>
                {showArrows && canGoLeft && (
                    <Link
                        dataTn="carousel-arrow-prev"
                        className={prevArrowClass}
                        onClick={() => {
                            this._model._previousPage();
                        }}
                    >
                        <ArrowLeft className={classnames(styles.arrow, styles.prevArrow)} />
                    </Link>
                )}
                <ul
                    ref={c => {
                        this._refList = c;
                    }}
                    data-tn="carousel-item-list"
                    style={style}
                    onTransitionEnd={this._model.onTransitionEnd}
                    className={listClass}
                >
                    {this._getChildren()}
                </ul>
                {showArrows && canGoRight && (
                    <Link
                        dataTn="carousel-arrow-next"
                        className={nextArrowClass}
                        onClick={() => {
                            this._model._nextPage();
                        }}
                    >
                        <ArrowRight className={classnames(styles.arrow, styles.nextArrow)} />
                    </Link>
                )}
            </div>
        );
    }
}

Swiper.propTypes = {
    model: PropTypes.object,
    onPageChange: PropTypes.func,
    startingPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
    hasInfiniteLoop: PropTypes.bool,
    itemsTopAlign: PropTypes.bool,
    classNames: PropTypes.object,
    children: PropTypes.node,
    swipeDisabled: PropTypes.bool,
    trackedItemIndex: PropTypes.number,
    showArrows: PropTypes.bool,
    onSwipeStart: PropTypes.func,
    onSwipeEnd: PropTypes.func,
    dataTn: PropTypes.string,
};

Swiper.defaultProps = {
    itemsPerPage: 1,
    hasInfiniteLoop: false,
    itemsTopAlign: false,
    onPageChange: () => {},
    onSwipeEnd: () => {},
    onSwipeStart: () => {},
    startingPage: 0,
    classNames: {},
};

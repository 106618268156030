'use strict';
/* eslint-disable no-var */
var browserCheck;
// get a fresh version of the vendor for mocking purposes
var getVendor = function () {
    return window.navigator && window.navigator.vendor;
};
var getIeDocumentMode = function () {
    return window.document && window.document.documentMode;
};

/**
 * Browser check since jQuery 1.9.x removes jQuery.browser
 * Some resources:
 * http://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
 * this checks for chrome:
 * http://stackoverflow.com/a/13348618/339698
 *
 * formerly:
 * js/global/core/dibs.js#browser
 * dibs.browser
 *
 * @module browserCheck
 */
browserCheck = module.exports = {
    /**
     * @returns {boolean}
     */
    chrome: function () {
        var vendor = getVendor();
        return !!window.chrome && vendor === 'Google Inc.' && !this.opera();
    },
    /**
     * @returns {boolean}
     */
    ie: function () {
        // global.document.body.style.msTextCombineHorizontal: 11+ (http://stackoverflow.com/questions/18907131/detecting-ie11-using-css-properties)
        // global.document.documentMode: 7-10
        return this.ie11() || (window.document && !!window.document.documentMode); //  formerly !!getIeDocumentMode()
    },
    /**
     * @returns {boolean}
     */
    ie8: function () {
        return this.ie() && getIeDocumentMode() === 8;
    },
    /**
     * @returns {boolean}
     */
    ie9: function () {
        return this.ie() && getIeDocumentMode() === 9;
    },
    /**
     * @returns {boolean}
     */
    ie10: function () {
        return this.ie() && !!window.navigator.msPointerEnabled;
    },
    /**
     * @returns {boolean}
     */
    ie11: function () {
        // https://stackoverflow.com/questions/31757852/how-can-i-detect-internet-explorer-ie-and-microsoft-edge-using-javascript
        // https://www.w3schools.com/jsref/prop_nav_appname.asp
        var appVersion = window.navigator.appVersion || [];
        return window.navigator.appName === 'Netscape' && appVersion.indexOf('Trident') !== -1;
    },
    /**
     * @returns {boolean}
     */
    edge: function () {
        // https://stackoverflow.com/questions/33152523/how-do-i-detect-ie-and-edge-browser
        return /Edge/.test(window.navigator.userAgent);
    },
    /**
     * @returns {boolean}
     */
    mozilla: function () {
        return typeof window.InstallTrigger !== 'undefined';
    },
    /**
     * opera is using Blink + V8 so it mimics chrome a lot, but still differentiates the User Agent
     * @returns {boolean}
     */
    opera: function () {
        return (
            !!window.opera ||
            (window.navigator.userAgent && window.navigator.userAgent.indexOf(' OPR/') >= 0)
        );
    },
    /**
     * @returns {boolean}
     */
    safari: function () {
        return (
            !this.chrome() &&
            (!!window.safari ||
                (window.navigator.userAgent && window.navigator.userAgent.indexOf('Safari') >= 0))
        );
    },
    /**
     * @returns {boolean}
     */
    webKit: function () {
        return typeof window.WebKitPoint === 'function';
    },
};

// added for parity in original core/dibs.js
/**
 * @see {@link browserCheck#ie8}
 * @type {Function}
 */
browserCheck.isIE8 = browserCheck.ie8;
/**
 * @see {@link browserCheck#ie9}
 * @type {Function}
 */
browserCheck.isIE9 = browserCheck.ie9;
/**
 * @see {@link browserCheck#ie10}
 * @type {Function}
 */
browserCheck.isIE10 = browserCheck.ie10;
/**
 * @see {@link browserCheck#ie11}
 * @type {Function}
 */
browserCheck.isIE11 = browserCheck.ie11;

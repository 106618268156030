import { sessionStorage } from 'dibs-browser-storage';
import { trackingConstants } from './constants';

const { KEY_SESSION_PAGE_COUNT } = trackingConstants;

export function getSessionPageCount(): number {
    return (sessionStorage.getItem(KEY_SESSION_PAGE_COUNT) as number) || 0;
}

export function incrementSessionPageCount(): void {
    let sessionPageCount = getSessionPageCount();
    sessionPageCount++;
    sessionStorage.setItem(KEY_SESSION_PAGE_COUNT, sessionPageCount);
}

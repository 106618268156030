/* eslint-disable ferrum/invalid-prop-format */
import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FC } from 'react';

type VideoFlatProps = {
    className?: string;
};

const VideoFlat: FC<VideoFlatProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'video-flat'}
            viewBox="0 0 250 166.3"
        >
            <g data-name="Layer 2">
                <g data-name="Layer 1">
                    <rect width="197" height="166.3" />
                    <polygon points="250 144.2 207 112.6 207 53.8 250 22.2 250 144.2" />
                </g>
            </g>
        </svg>
    );
};

export default VideoFlat;

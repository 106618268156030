import PropTypes from 'prop-types';
import { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { connect } from 'react-redux';
import {
    trackEvent,
    trackEcommerce,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
import { FullWidthDropdown } from '../Dropdown/FullWidthDropdown';
import { SiteNavDropdownContent } from './SiteNavDropdownContent';
import slugify from 'dibs-slugify/exports/slugify';
import classnames from 'classnames';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import { ALWAYS_LINKABLE_LINK } from 'dibs-elements/exports/SeoLink';

import styles from './SiteNav-style.scss';

const { EVENT_NAVIGATION, EVENT_PROMO_INTERACTION, EVENT_VIEW_PROMOTION, EVENT_SELECT_PROMOTION } =
    eventNameConstants;

const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;
const {
    STEP_INTERACTION_GLOBAL_NAV_DROPDOWN_DISPLAYED,
    STEP_INTERACTION_GLOBAL_NAV_DROPDOWN_CLICK,
} = stepInteractionConstants;

const getPromoTrackingObj = ({ name, imageLinkData }) => {
    return {
        id: name.toLowerCase(),
        name,
        creative: imageLinkData?.path,
        position: 'global nav',
    };
};

export class SiteNavItemComponent extends Component {
    constructor(props) {
        super(props);

        this.state = { isVisible: false };
        this.onHide = this.onHide.bind(this);
        this.onShow = this.onShow.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleImageModuleClick = this.handleImageModuleClick.bind(this);
        this.handleImageModuleFirstImpression = this.handleImageModuleFirstImpression.bind(this);
    }
    handleClick(event) {
        const { name } = this.props.navigationModule;
        const label = `${name.toLowerCase()} header link|${name.toLowerCase()}`;

        trackEvent(
            {
                action: 'header navigation click',
                category: 'navigation',
                label,
                eventName: EVENT_NAVIGATION,
                interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_DROPDOWN_CLICK,
                trigger: label,
            },
            event
        );
    }
    onHide() {
        this.setState({ isVisible: false }, () => {
            if (typeof this.props.onMouseLeave === 'function') {
                this.props.onMouseLeave();
            }
        });
    }
    onShow() {
        this.setState({ isVisible: true }, () => {
            const label = this.props.navigationModule.name;
            trackEvent(
                {
                    action: 'global nav dropdown displayed',
                    category: 'promo interaction',
                    label,
                    isInteractiveEvent: true,
                    eventName: EVENT_PROMO_INTERACTION,
                    interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                    step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_DROPDOWN_DISPLAYED,
                    trigger: label,
                },
                null
            );

            if (typeof this.props.onMouseEnter === 'function') {
                this.props.onMouseEnter();
            }
        });
    }
    handleImageModuleClick(event) {
        const promoObj = getPromoTrackingObj(this.props.navigationModule);
        trackEcommerce({
            type: 'promoClick',
            promotions: [promoObj],
            eventName: EVENT_SELECT_PROMOTION,
        });
        trackEvent(
            {
                category: 'navigation',
                action: 'header navigation clicked',
                label: 'trending now',
                eventName: EVENT_NAVIGATION,
                interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_DROPDOWN_CLICK,
                trigger: 'trending now',
            },
            event
        );
    }
    handleImageModuleFirstImpression() {
        const promoObj = getPromoTrackingObj(this.props.navigationModule);
        trackEcommerce({
            type: 'promotionImpression',
            promotions: [promoObj],
            eventName: EVENT_VIEW_PROMOTION,
        });
    }
    render() {
        const { isUserTypeTrade, navigationModule, isNavigationHovered } = this.props;
        const { name, items, linkData } = navigationModule;
        const { isVisible } = this.state;
        const itemLinkClasses = classnames(styles.itemLink, {
            [styles.pale]: isNavigationHovered && !isVisible,
        });
        const dropdownProps = {
            onShow: this.onShow,
            onHide: this.onHide,
            wrapperClass: styles.fullWrapper,
        };

        return (
            <li className={styles.item}>
                <FullWidthDropdown {...dropdownProps}>
                    <SeoLink
                        dataTn={`global-nav-item-link-${slugify(name).toLowerCase()}`}
                        className={itemLinkClasses}
                        onClick={this.handleClick}
                        variant={ALWAYS_LINKABLE_LINK}
                        linkData={linkData}
                    >
                        {name}
                        {isVisible && (
                            <div
                                className={
                                    isUserTypeTrade
                                        ? styles.linkUnderlineTrade
                                        : styles.linkUnderline
                                }
                            />
                        )}
                    </SeoLink>
                    {items && items.length ? (
                        <SiteNavDropdownContent
                            trackingLabelPrefix={name}
                            navigationModule={navigationModule}
                            onImageModuleFirstImpression={this.handleImageModuleFirstImpression}
                            onImageModuleClick={this.handleImageModuleClick}
                            isVisible={isVisible}
                        />
                    ) : null}
                </FullWidthDropdown>
            </li>
        );
    }
}

SiteNavItemComponent.propTypes = {
    isUserTypeTrade: PropTypes.bool,
    navigationModule: PropTypes.object,
    isNavigationHovered: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
};

const mapStateToProps = ({ header }) => {
    const { isUserTypeTrade } = header;
    return {
        isUserTypeTrade,
    };
};

export default createFragmentContainer(connect(mapStateToProps)(SiteNavItemComponent), {
    navigationModule: graphql`
        fragment SiteNavItem_navigationModule on NavigationModule {
            ...SiteNavDropdownContent_navigationModule
            name
            imageLinkData {
                path
            }
            linkData {
                ...SeoLink_linkData
            }
            items {
                name
            }
        }
    `,
});

import { Component } from 'react';
import PropTypes from 'prop-types';
import { ZoomPanModel } from '../utils/ZoomPanModel';
import classnames from 'classnames';
import styles from './styles/PinchZoom.scss';
const noop = () => {};

/**
 * Handle both pinching and zooming on the passed in children.
 */
export class PinchZoom extends Component {
    constructor(props) {
        super(props);

        // Wire up touch detection.
        this._model = new ZoomPanModel({
            onZoomMax: this._onZoomMax.bind(this),
            onZoomMin: this._onZoomMin.bind(this),
            onZoomIn: this._onZoomIn.bind(this),
            onZoomOut: this._onZoomOut.bind(this),
            onPan: this._onPan.bind(this),
            onPinchStart: this._onPinchStart.bind(this),
            overridePanMoveDefault: props.overridePanMoveDefault,
            maxZoom: props.maxZoom,
            minZoom: props.minZoom,
        });

        if (props.zoomModelRef) {
            props.zoomModelRef.current = this._model;
        }

        if (!props.enabled) {
            this._model.disable();
        }

        this.state = {
            transition: false,
        };
    }

    componentDidMount() {
        this._model.setEl(this.refs.wrapper);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // Allow this component to be enabled/disabled via props.
        if (nextProps.enabled && !this.props.enabled) {
            this._model.enable();
            return;
        }

        if (!nextProps.enabled && this.props.enabled) {
            this._model.disable();
            return;
        }
    }

    componentWillUnmount() {
        this._model.destroy();
    }

    reset() {
        this._model.reset();
        this.forceUpdate();
    }

    /**
     * Set whether to use CSS transitions. Transition is meant to be set during zoom but not during pan.
     */

    _transition() {
        const el = this.refs.wrapper;
        const handler = () => {
            this.setState({ transition: false });
            el.removeEventListener('transitionend', handler);
        };
        el.addEventListener('transitionend', handler);
        this.setState({ transition: true });
    }

    _onPan() {
        this.props.onPan();
        this.forceUpdate();
    }

    _onPinchStart() {
        this.forceUpdate();
    }

    _onZoomMax() {
        this.props.onZoomIn();
        this._transition();
    }

    _onZoomMin() {
        this.props.onZoomOut();
        this._transition();
    }

    _onZoomIn() {
        this.props.onZoomIn();
        this.forceUpdate();
    }

    _onZoomOut() {
        this.props.onZoomOut();
        this.forceUpdate();
    }

    render() {
        const style = {};
        // Grab the styles from the model.
        this._model.getStyle(style);

        const className = classnames({
            [styles.transition]: this.state.transition,
            [this.props.className]: this.props.className,
        });

        return (
            <div ref="wrapper" style={style} className={className}>
                {this.props.children}
            </div>
        );
    }
}

PinchZoom.propTypes = {
    onZoomIn: PropTypes.func,
    onZoomOut: PropTypes.func,
    onPan: PropTypes.func,
    className: PropTypes.string,
    enabled: PropTypes.bool,
    overridePanMoveDefault: PropTypes.bool,
    children: PropTypes.node,
    maxZoom: PropTypes.number,
    minZoom: PropTypes.number,
    zoomModelRef: PropTypes.object,
};

PinchZoom.defaultProps = {
    onZoomIn: noop,
    onZoomOut: noop,
    onPan: noop,
    enabled: true,
    overridePanMoveDefault: false,
};

import PropTypes from 'prop-types';
import { Component } from 'react';
import classnames from 'classnames';
import styles from './FullWidthDropdown-style.scss';

export class FullWidthDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
        };

        this.dropdown = null;
        this.hide = this.hide.bind(this);
        this.show = this.show.bind(this);
        this.showTimeout = null;
    }

    show() {
        this.showTimeout = setTimeout(() => {
            this.setState({ isVisible: true }, () => {
                const { onShow } = this.props;
                if (onShow) {
                    onShow();
                }
            });
        }, 200);
    }

    hide() {
        clearTimeout(this.showTimeout);
        this.setState({ isVisible: false }, () => {
            const { onHide } = this.props;
            if (onHide) {
                onHide();
            }
        });
    }

    render() {
        const { children, wrapperClass } = this.props;

        if (!children) {
            return null;
        }

        const { isVisible } = this.state;
        const trigger = children[0];
        const body = children.length > 1 && children[1] ? children.slice(1) : null;

        const wrapperClasses = classnames(styles.wrapper, wrapperClass);

        const dropdownClasses = classnames(styles.dropdown, {
            [styles.isVisible]: isVisible,
        });
        const bodyWrapperClasses = classnames(styles.bodyWrapper, {
            [styles.isVisible]: isVisible,
        });

        return (
            <div className={wrapperClasses} onMouseEnter={this.show} onMouseLeave={this.hide}>
                {trigger}
                {body ? (
                    <div className={dropdownClasses}>
                        <div className={bodyWrapperClasses}>{body}</div>
                    </div>
                ) : null}
            </div>
        );
    }
}

FullWidthDropdown.propTypes = {
    children: PropTypes.array,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    wrapperClass: PropTypes.string,
};

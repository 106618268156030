// Boolean flags and methods used to ensure events are fired to Google Tag Manager in the correct order
// (see startTrackingEvents method description)
let gtmInitialInfoFired = false;
let gtmInitialPageviewFired = false;

export function getInitialInfoFiredState(): boolean {
    return gtmInitialInfoFired;
}

export function setInitialInfoFiredState(state: boolean): void {
    gtmInitialInfoFired = state;
}

export function getInitialPageviewFiredState(): boolean {
    return gtmInitialPageviewFired;
}

export function setInitialPageviewFiredState(state: boolean): void {
    gtmInitialPageviewFired = state;
}

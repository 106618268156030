// Flow types automatically removed

/**
 * @ignore
 * Created by shanie on 3/8/17.
 */
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import PropTypes from 'prop-types'; // Components

import Heart from './Heart'; // Types

export function HeartWrapper(props) {
    const heartPortfolioMatches = props.viewer?.heartItemMatches || [];
    const itemMatches = heartPortfolioMatches.filter(
        heart => heart?.item?.serviceId === props.itemId
    );
    const items = props.viewer?.itemRead || [];
    const itemRef = items.filter(item => item?.serviceId === props.itemId)[0] || null;

    function doRefetch(callback) {
        props.relay.refetch(fragmentVariables => fragmentVariables, null, callback);
    }

    return (
        <Heart {...props} doRefetch={doRefetch} item={itemRef} heartPortfolioItems={itemMatches} />
    );
}

HeartWrapper.defaultProps = {
    forceFavoriteItem: false,
    portfolioItemId: '',
    portfolioId: null,
    selectedItemIds: [],
    type: 'outline',
    userId: '',
};
HeartWrapper.propTypes = {
    className: PropTypes.string,
    buttonClass: PropTypes.string,
    authModalShow: PropTypes.func.isRequired,
    forceFavoriteItem: PropTypes.bool,
    itemId: PropTypes.string.isRequired,
    onError: PropTypes.func,
    onFavorited: PropTypes.func,
    onHeartClick: PropTypes.func,
    onUnFavorited: PropTypes.func,
    portfolioId: PropTypes.string,
    relay: PropTypes.object,
    wrapperComponent: PropTypes.oneOf(['div', 'Link']),
    hideTotalLikesCount: PropTypes.bool,
    type: PropTypes.oneOf(['outline', 'button']),
    userId: PropTypes.string,
    theme: PropTypes.oneOf(['dark', 'light']),
    animateHeartPulse: PropTypes.bool,
    onIsFilledChange: PropTypes.func,
    setIsForceFavoriteItem: PropTypes.func,
};
export default createRefetchContainer(
    HeartWrapper,
    {
        viewer: graphql`
            fragment HeartWrapper_viewer on Viewer
            @argumentDefinitions(
                selectedItemIds: { type: "[String]", defaultValue: [] }
                loadPortfolioData: { type: "Boolean", defaultValue: false }
                userIds: { type: "[String]", defaultValue: [] }
                showHeartCount: { type: "Boolean", defaultValue: false }
            ) {
                heartItemMatches: portfolioItemMatches(
                    itemIds: $selectedItemIds
                    userIds: $userIds
                    portfolioTypes: [HEART]
                ) @include(if: $loadPortfolioData) {
                    ...Heart_heartPortfolioItems
                    item {
                        serviceId
                    }
                }
                itemRead(itemIds: $selectedItemIds) @include(if: $showHeartCount) {
                    ... on Item {
                        serviceId
                        ...Heart_item
                    }
                }
                ...Heart_viewer @arguments(userIds: $userIds, loadPortfolioData: $loadPortfolioData)
            }
        `,
    },
    graphql`
        query HeartWrapperRefetchQuery(
            $selectedItemIds: [String]!
            $loadPortfolioData: Boolean!
            $userIds: [String]!
            $showHeartCount: Boolean!
        ) {
            viewer {
                ...HeartWrapper_viewer
                    @arguments(
                        selectedItemIds: $selectedItemIds
                        loadPortfolioData: $loadPortfolioData
                        userIds: $userIds
                        showHeartCount: $showHeartCount
                    )
            }
        }
    `
);

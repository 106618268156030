import { localStorage } from 'dibs-browser-storage';
import { GET_HELP_MODAL_ZENDESK_OPTIONS } from './GetHelpZendeskConstants';
import { GetHelpModalZendeskOptions } from './GetHelpZendeskTypes';

export const separateSearchQueryFromString = (value: string, searchQuery: string): string => {
    const re = new RegExp(searchQuery, 'gi');
    const newText = value.replace(re, `<span>$&</span>`);
    return newText;
};

export const getGetHelpZendeskLocalStorageState = (): GetHelpModalZendeskOptions => {
    return localStorage.getItem(GET_HELP_MODAL_ZENDESK_OPTIONS) as GetHelpModalZendeskOptions;
};

export const resetGetHelpZendeskLocalStorageState = (): void => {
    localStorage.setItem(GET_HELP_MODAL_ZENDESK_OPTIONS, { isOpen: false });
};

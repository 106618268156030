import { FC, useCallback, useMemo } from 'react';
import { graphql, useRefetchableFragment, useFragment } from 'react-relay';
import { Heart } from 'dibs-portfolio-heart/exports/Heart';
import { Maybe } from 'dibs-ts-utils/exports/Maybe';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';

import { RecommendedItemHeart_viewer$key } from './__generated__/RecommendedItemHeart_viewer.graphql';
import { RecommendedItemHeart_item$key } from './__generated__/RecommendedItemHeart_item.graphql';
import { OnFavorited } from '../types';

const viewerFragment = graphql`
    fragment RecommendedItemHeart_viewer on Viewer
    @refetchable(queryName: "RecommendedItemHeartViewerRefetchQuery")
    @argumentDefinitions(
        loadPortfolioData: { type: "Boolean", defaultValue: false }
        selectedItemIds: { type: "[String]", defaultValue: [] }
        userIds: { type: "[String]", defaultValue: [] }
    ) {
        portfolioItemMatches(itemIds: $selectedItemIds, userIds: $userIds, portfolioTypes: [HEART])
            @include(if: $loadPortfolioData) {
            ...Heart_heartPortfolioItems
            item {
                serviceId
            }
        }
        ...Heart_viewer @arguments(userIds: $userIds, loadPortfolioData: $loadPortfolioData)
    }
`;

const itemFragment = graphql`
    fragment RecommendedItemHeart_item on Item {
        serviceId
        ecommerceTrackingParams
    }
`;

type FavoritesRefetch = (callback: (error: Error | null | undefined) => void) => void;

type Props = {
    className?: string;
    buttonClass?: string;
    viewer: RecommendedItemHeart_viewer$key;
    item: RecommendedItemHeart_item$key;
    userId?: string | null;
    itemsIds: Maybe<string>[];
    onFavorited?: OnFavorited;
    onUnFavorited?: ({ itemId }: { itemId: string | null }) => void;
};

export const RecommendedItemHeart: FC<Props> = ({
    className,
    buttonClass,
    viewer: viewerRef,
    item: itemRef,
    userId,
    itemsIds,
    onFavorited = () => {},
    onUnFavorited = () => {},
}) => {
    const [viewer, refetch] = useRefetchableFragment(viewerFragment, viewerRef);
    const item = useFragment(itemFragment, itemRef);
    const { serviceId: itemId, ecommerceTrackingParams } = item || {};

    const doRefetch: FavoritesRefetch = useCallback(
        callback => {
            refetch(
                {
                    loadPortfolioData: true,
                    selectedItemIds: itemsIds,
                    userIds: userId ? [userId] : [],
                },
                {
                    onComplete: callback,
                    //Will prevent suspense on the whole carousel
                    fetchPolicy: 'store-and-network',
                }
            );
        },
        [itemsIds, refetch, userId]
    );

    const authModalShow = useCallback((favoriteItem: $TSFixMe) => {
        authModalLoader
            .show({
                flow: authModalLoader.constants.SAVE_ITEM_FLOW,
                ga: {
                    label: 'save item - similar items page',
                },
            })
            .then(favoriteItem);
    }, []);

    const itemMatch = useMemo(() => {
        const portfolioItemMatches = viewer.portfolioItemMatches || [];
        return portfolioItemMatches.filter(heart => heart?.item?.serviceId === itemId);
    }, [viewer.portfolioItemMatches, itemId]);

    return (
        <Heart
            theme="dark"
            itemId={itemId}
            viewer={viewer}
            userId={userId}
            heartPortfolioItems={itemMatch}
            doRefetch={doRefetch}
            authModalShow={authModalShow}
            className={className}
            buttonClass={buttonClass}
            onFavorited={() => onFavorited({ itemId, ecommerceTrackingParams })}
            onUnFavorited={() => onUnFavorited({ itemId })}
        />
    );
};

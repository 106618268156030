import { getAbTestV2, trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';

const BUYER_GET_HELP_ZENDESK = 'buyer-get-help-modal-zendesk' as const;

export const isGetHelpModalZendeskVariant = (): boolean =>
    getAbTestV2(BUYER_GET_HELP_ZENDESK)?.variant === 'variant';

let isTracked = false;

export const trackGetHelpZendeskVariant = (): void => {
    if (!isTracked) {
        isTracked = true;
        trackAbTestV2Variant(BUYER_GET_HELP_ZENDESK);
    }
};

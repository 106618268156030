import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FC } from 'react';

type LogoVipFrProps = {
    className?: string;
    role?: 'img';
    ariaLabel?: string;
    ariaLabelledby?: string;
};

const LogoVipFr: FC<LogoVipFrProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn="logo-fr-vip"
            role={props.role}
            aria-label={props.ariaLabel}
            aria-labelledby={props.ariaLabelledby}
            viewBox="0 0 72 39"
        >
            <path d="M28.03 8.02V20.48C28.03 22.6 27.13 23.19 25.28 23.82H33.14C31.29 23.19 30.39 22.6 30.39 20.48V6.01C30.39 4.71 30.47 3.57 30.55 3.1C28.9 4.08 27.37 4.95 24.73 5.22V5.57C27.56 5.58 28.03 6.33 28.03 8.02Z" />
            <path d="M34.75 16.75V21.58C34.75 23.03 35.46 23.86 36.68 23.86C37.94 23.86 39.08 23.03 39.55 21.93L39.47 21.81C39 22.24 38.29 22.64 37.62 22.64C36.64 22.64 36.28 22.17 36.28 20.91L36.24 16.74H38.99L39.27 15.52H36.24V13.71L33.33 16.54V16.75H34.75Z" />
            <path d="M35.97 4.56C36.76 4.56 37.58 5.19 38.37 6.01V4.32C37.62 4.01 37.11 3.73 36.13 3.73C34.71 3.73 33.58 4.59 33.58 6.01C33.58 7.03 34.25 7.7 35 8.21L36.45 9.19C37.16 9.66 37.39 9.98 37.39 10.53C37.39 11.12 36.96 11.55 36.21 11.55C35.35 11.55 34.64 10.92 33.62 9.82V11.67C34.13 12.02 34.92 12.46 35.98 12.46C37.39 12.46 38.73 11.4 38.73 10.02C38.73 8.96 38.1 8.33 37.16 7.7L35.86 6.84C35.27 6.45 34.92 6.09 34.92 5.54C34.91 4.95 35.38 4.56 35.97 4.56Z" />
            <path d="M32.86 0.0400009H24.96V1.02H32.35C40.6 1.02 44.02 6.21 44.02 13.6C44.02 22.13 40.36 26.57 33.09 26.57H25.08V27.55H33.18C43.6 27.55 47.37 20.47 47.37 13.6C47.37 6.96 43.36 0.0400009 32.86 0.0400009Z" />
            <path d="M0.279999 36.19C0.279999 37.81 1.55 38.96 3.2 38.96C4.09 38.96 4.69 38.73 5.24 38.35V37.11H5.09V37.3C5.07 38.15 4.38 38.74 3.34 38.74C1.98 38.74 1.17 37.66 1.17 36.18C1.17 34.7 1.98 33.62 3.34 33.62C4.38 33.62 5.07 34.24 5.09 35.12V35.25H5.23V34C4.68 33.61 4.08 33.39 3.19 33.39C1.54 33.38 0.279999 34.56 0.279999 36.19Z" />
            <path d="M11.75 37.53C11.48 38.27 11.12 38.58 10.16 38.58H9V34.05C9 33.79 9.17 33.63 9.59 33.63H9.72V33.49H7.45V33.63H7.58C7.99 33.63 8.17 33.79 8.17 34.05V38.29C8.17 38.55 8 38.71 7.58 38.71H7.45V38.85H11.65L11.94 37.39H11.81L11.75 37.53Z" />
            <path d="M13.82 33.63H13.95C14.36 33.63 14.54 33.79 14.54 34.05V38.29C14.54 38.55 14.37 38.71 13.95 38.71H13.82V38.85H16.08V38.71H15.95C15.54 38.71 15.36 38.55 15.36 38.29V34.05C15.36 33.79 15.53 33.63 15.95 33.63H16.08V33.49H13.82V33.63Z" />
            <path d="M22.6 37.53C22.32 38.27 21.97 38.58 21.01 38.58H19.75V36.22H20.51C21.09 36.22 21.33 36.46 21.41 37.02L21.43 37.15H21.55V35.03H21.43L21.41 35.17C21.33 35.73 21.09 35.96 20.51 35.96H19.75V33.78H20.96C21.75 33.78 22.07 34.05 22.26 34.62L22.31 34.78H22.43L22.28 33.49H18.2V33.63H18.33C18.74 33.63 18.92 33.79 18.92 34.05V38.29C18.92 38.55 18.75 38.71 18.33 38.71H18.2V38.85H22.48L22.77 37.39H22.65L22.6 37.53Z" />
            <path d="M28.75 33.63C29.28 33.63 29.48 33.92 29.48 34.39V37.49L26.01 33.49H24.72V33.61L24.88 33.66C25.36 33.8 25.57 34.2 25.57 34.72V37.94C25.57 38.42 25.36 38.71 24.85 38.71H24.69V38.84H26.75V38.71H26.59C26.08 38.71 25.87 38.42 25.87 37.94V34.61L29.61 38.91H29.77V34.38C29.77 33.91 29.95 33.62 30.36 33.62H30.49V33.48H28.6V33.63H28.75Z" />
            <path d="M32.55 33.49L32.4 34.85H32.52L32.58 34.66C32.78 34.01 33.08 33.76 33.92 33.76H34.47V38.29C34.47 38.55 34.3 38.71 33.88 38.71H33.75V38.85H36.01V38.71H35.88C35.47 38.71 35.29 38.55 35.29 38.29V33.76H35.83C36.67 33.76 36.97 34.02 37.16 34.66L37.22 34.85H37.36L37.2 33.49H32.55Z" />
            <path d="M44.87 33.49H42.4V33.63H42.53C42.94 33.63 43.12 33.79 43.12 34.05V38.29C43.12 38.55 42.95 38.71 42.53 38.71H42.4V38.85H44.69V38.71H44.53C44.12 38.71 43.94 38.55 43.94 38.29V36.54H44.6C46.06 36.54 46.85 35.93 46.85 34.96C46.85 33.99 46.17 33.49 44.87 33.49ZM44.54 36.27H43.94V33.76H44.76C45.62 33.76 45.96 34.14 45.96 34.96C45.96 35.74 45.56 36.27 44.54 36.27Z" />
            <path d="M53.08 38.04L51.76 36.13C52.57 35.99 53.05 35.51 53.05 34.82C53.05 34.07 52.46 33.51 51.44 33.51H48.81V33.65H48.94C49.35 33.65 49.53 33.81 49.53 34.07V38.31C49.53 38.57 49.36 38.73 48.94 38.73H48.81V38.87H51.07V38.73H50.94C50.53 38.73 50.35 38.57 50.35 38.31V36.25H50.89L52.33 38.39C52.67 38.9 52.92 38.95 53.97 38.88V38.74H53.91C53.65 38.71 53.48 38.6 53.08 38.04ZM50.36 35.96V33.77H51.18C51.89 33.77 52.19 34.25 52.19 34.87C52.19 35.49 51.88 35.96 51.13 35.96H50.36Z" />
            <path d="M55.67 33.63H55.8C56.21 33.63 56.39 33.79 56.39 34.05V38.29C56.39 38.55 56.22 38.71 55.8 38.71H55.67V38.85H57.93V38.71H57.8C57.39 38.71 57.21 38.55 57.21 38.29V34.05C57.21 33.79 57.38 33.63 57.8 33.63H57.93V33.49H55.67V33.63Z" />
            <path d="M63.87 33.63H64.05C64.49 33.63 64.5 33.92 64.36 34.25L62.83 37.79L61.24 34.27C61.08 33.91 61.1 33.63 61.55 33.63H61.74V33.49H59.51V33.63H59.57C59.94 33.63 60.16 33.82 60.37 34.28L62.5 38.92H62.66L64.67 34.26C64.87 33.8 65.1 33.63 65.48 33.63H65.54V33.49H63.88V33.63H63.87Z" />
            <path d="M70.25 32.74C70.4 32.7 70.49 32.63 70.49 32.5C70.49 32.37 70.4 32.26 70.27 32.26C70.22 32.26 70.16 32.27 70.08 32.3L68.63 33.02L68.66 33.1L70.25 32.74Z" />
            <path d="M71.61 37.39L71.56 37.53C71.28 38.27 70.93 38.58 69.97 38.58H68.71V36.22H69.47C70.05 36.22 70.29 36.46 70.37 37.02L70.39 37.15H70.51V35.03H70.38L70.36 35.17C70.28 35.73 70.04 35.96 69.46 35.96H68.7V33.78H69.91C70.7 33.78 71.02 34.05 71.21 34.62L71.26 34.78H71.38L71.23 33.51H67.15V33.65H67.28C67.69 33.65 67.87 33.81 67.87 34.07V38.31C67.87 38.57 67.7 38.73 67.28 38.73H67.15V38.87H71.43L71.72 37.41H71.61V37.39Z" />
        </svg>
    );
};

export default LogoVipFr;

import { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from '../styles/CarouselDots.scss';

const DOT_SIZE_WITH_SPACING = 18;
const MIN_SHIFT_VALUE = 3;
const DOTS_TRANSITION_DELAY = 100;
const DOTS_TRANSITION_DURATION = 200;

export class CarouselDots extends Component {
    constructor(props) {
        super(props);

        this.state = {
            leftIndex: 0,
            dotsTransform: `translate3d(0%, 0, 0)`,
            isVisible: false,
        };

        this.updateLeftIndex = this.updateLeftIndex.bind(this);
        this.shiftLeftIndex = this.shiftLeftIndex.bind(this);
        this.shouldUpdateDots = this.shouldUpdateDots.bind(this);
        this.handleDotClick = this.handleDotClick.bind(this);
    }

    componentDidMount() {
        const { index, dotsPerPage } = this.props;

        if (index > dotsPerPage - MIN_SHIFT_VALUE) {
            this.shiftLeftIndex();
        } else {
            this.setState({ isVisible: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { totalItems, index, dotsPerPage } = this.props;
        const { leftIndex } = this.state;
        const lastIndex = totalItems - 1;
        const indexChanged = index !== prevProps.index;
        const diff = index - prevProps.index;
        const isDirectionNext =
            (prevProps.index === lastIndex && index === 0) ||
            (index > prevProps.index && index !== lastIndex && diff === 1);
        const isDirectionPrev =
            (index === lastIndex && prevProps.index === 0) ||
            (index < prevProps.index && diff === -1);

        if (indexChanged) {
            if (isDirectionNext) {
                this.updateLeftIndex({ direction: 'next' });
            } else if (isDirectionPrev) {
                this.updateLeftIndex({ direction: 'prev' });
                // The shifting should happen only if dotsPerPage limit exists
            } else if (dotsPerPage) {
                this.shiftLeftIndex();
            }
        }

        // Slide dots when leftIndex changes
        if (leftIndex !== prevState.leftIndex) {
            const offset = (100 * -leftIndex) / dotsPerPage;
            this.setState({
                dotsTransform: `translate3d(${offset}%, 0, 0)`,
            });
            // wait a bit for the css transformation to start
            /* istanbul ignore next */
            setTimeout(() => {
                this.setState({ isVisible: true });
            }, DOTS_TRANSITION_DELAY);
        }
    }

    shouldUpdateDots({ direction, leftIndex }) {
        const { index, totalItems, dotsPerPage } = this.props;
        const shiftNext = index === totalItems - 2 ? 1 : 2;
        const shiftPrev = index < 2 ? 0 : 1;

        if (direction === 'next') {
            return {
                // Update page if the index is currently the right most item on the page
                shouldUpdatePage: index === leftIndex + dotsPerPage - shiftNext,
            };
        }
        return {
            // Update page if the index is currently the left most item on the page
            shouldUpdatePage: index === leftIndex + shiftPrev,
        };
    }

    shiftLeftIndex() {
        const { index, dotsPerPage, totalItems } = this.props;

        const leftIndexShift = index === totalItems - 2 ? 1 : MIN_SHIFT_VALUE;
        const maxLeftIndex = totalItems - dotsPerPage;
        let leftIndex = index - leftIndexShift;

        leftIndex = leftIndex < maxLeftIndex ? leftIndex : maxLeftIndex;

        // check that active dots wouldn't be on left small dots
        if (leftIndex < MIN_SHIFT_VALUE && leftIndex < maxLeftIndex) {
            leftIndex += 1;
        }

        if (leftIndex < 0) {
            leftIndex = 0;
        }
        this.setState({ leftIndex });
    }

    updateLeftIndex({ direction }) {
        let { leftIndex } = this.state;
        const { shouldUpdatePage } = this.shouldUpdateDots({
            direction,
            leftIndex,
        });

        if (this.props.index === 0) {
            leftIndex = 0;
        } else if (shouldUpdatePage) {
            leftIndex = leftIndex + (direction === 'next' ? 1 : -1);
        }
        this.setState({ leftIndex });
    }

    handleDotClick = page => {
        const { dotsPerPage, onDotClick } = this.props;
        if (!dotsPerPage) {
            onDotClick({ page });
        }
    };

    render() {
        const { dotsPerPage, index, totalItems, isDark } = this.props;
        const { leftIndex, dotsTransform, isVisible } = this.state;
        const hasDotsPerPage = dotsPerPage && dotsPerPage < totalItems;
        const style = hasDotsPerPage
            ? {
                  transform: dotsTransform,
                  transition: `transform ${DOTS_TRANSITION_DURATION}ms ease-out ${DOTS_TRANSITION_DELAY}ms`,
              }
            : { justifyContent: 'center' };
        const dotWrapperStyle = hasDotsPerPage
            ? { width: DOT_SIZE_WITH_SPACING * dotsPerPage }
            : {};
        const dots = [];

        for (let page = 0; page < totalItems; page++) {
            const isCurrentDot = page === index;

            // If the dotsPerPage was provided and because of this the leftIndex changes
            // then the ascending and descending dots sizes will be apllied (small and tiny sizes).
            // The right direction dots will be applied when the current dot is not at the end
            // of the totalItems and the last dots are visible.
            // The left direction dots will be applied when current active dot is not at the start
            // of the visible dots and the first few dots are visible.
            const isRightSmallDot = page === leftIndex + dotsPerPage - 2 && page !== totalItems - 2;
            const isRightTinyDot =
                (page === leftIndex + dotsPerPage - 1 && page !== totalItems - 1) ||
                page > leftIndex + dotsPerPage - 1;

            const isLeftSmallDot = page === leftIndex + 1 && page !== 1;
            const isLeftTinyDot = (page === leftIndex && page !== 0) || page < leftIndex;

            const isDotSmall = isRightSmallDot || isLeftSmallDot;
            const isDotTiny = isRightTinyDot || isLeftTinyDot;

            dots.push(
                <span
                    key={`dot-${page}`}
                    onClick={() => this.handleDotClick(page)}
                    onKeyPress={() => this.handleDotClick(page)}
                    role="button"
                    tabIndex={0}
                    className={classnames(styles.dot, {
                        [styles.isCurrent]: isCurrentDot,
                        [styles.small]: isDotSmall,
                        [styles.tiny]: isDotTiny,
                        [styles.isVisible]: isVisible,
                        [styles.isCurrentDark]: isDark && isCurrentDot,
                    })}
                />
            );
        }

        return (
            <div style={dotWrapperStyle} className={styles.dotsWrapper}>
                <div className={styles.wrapper} style={style}>
                    {dots.map(dot => dot)}
                </div>
            </div>
        );
    }
}

CarouselDots.defaultProps = {
    index: 0,
    isDark: false,
};

CarouselDots.propTypes = {
    dotsPerPage: PropTypes.number,
    index: PropTypes.number,
    onDotClick: PropTypes.func,
    totalItems: PropTypes.number,
    isDark: PropTypes.bool,
};

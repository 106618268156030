import Cookies from 'js-cookie';

import { PASSWORD_TOKEN, USER_EMAIL_TOKEN } from './userStorageConstants';

export const getUserEmailToken = (): string | undefined => Cookies.get(USER_EMAIL_TOKEN);

export const getPasswordToken = (): string | undefined => Cookies.get(PASSWORD_TOKEN);

export const removePasswordToken = (): void => {
    Cookies.remove(PASSWORD_TOKEN);
};

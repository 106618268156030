import { ReactNode, Component, createRef, Fragment } from 'react';
import styles from './lazyImage.scss';
import classnames from 'classnames';
import {
    VisibilityTracker,
    VisibilityChangeCallback,
} from 'dibs-visibility-tracker/exports/VisibilityTracker';

import { ImageSizeType } from '../types/StyleSizes';

/**
 * 1x1 gif so we don't have img tags without src attributes
 */
const PLACEHOLDER_IMG = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';

type GetPlaceholderDimensionsArgs = {
    placeholder?: string | null;
};

type PlaceholderDimensions = {
    height: string;
    width: string;
};

export const getPlaceholderDimensions = ({
    placeholder,
}: GetPlaceholderDimensionsArgs): PlaceholderDimensions | null => {
    const matches = !!placeholder && placeholder.match(/viewBox=\'\d+ \d+ (\d+ \d+)\'/);
    if (matches) {
        const dimensions = matches[1].split(' ');
        const width = dimensions[0];
        const height = dimensions[1];
        return { height, width };
    }
    return null;
};

type SrcSetAttributesType = { srcSet?: string; sizes?: string };

export type LazyImageProps = {
    alt: string;
    onImageLoad: () => void;
    srcSetAttributes?: SrcSetAttributesType;
    placeholder?: string | null;
    useLoFiLazyLoader: boolean;
    className?: string;
    offsetVertical: number;
    src?: string;
    imageSize: ImageSizeType;
    imageDraggable?: boolean; // undefined draggable will result in default browser behavior
};

export class LazyImage extends Component<LazyImageProps> {
    static defaultProps = {
        onImageLoad: (): void => {},
        offsetVertical: '100',
    };

    constructor(props: LazyImageProps) {
        super(props);

        this.imageElementRef = createRef();
        this.placeholderElementRef = createRef();
        this.triggerImageLoad = this.triggerImageLoad.bind(this);
        this.handleImageLoad = this.handleImageLoad.bind(this);
    }

    imageElementRef: { current: null | HTMLImageElement };
    placeholderElementRef: { current: null | HTMLSpanElement };

    triggerImageLoad({ isVisible }: VisibilityChangeCallback): void {
        const imageElement = this.imageElementRef.current;

        /* istanbul ignore next */
        if (!imageElement || !isVisible) {
            return;
        }

        const { srcSetAttributes, src } = this.props;
        const { srcSet, sizes } = srcSetAttributes || {};

        if (srcSet && sizes) {
            // order matters here.  Need to set sizes before srcset
            // to avoid multiple calls of the image in safari
            imageElement.sizes = sizes;
            imageElement.srcset = srcSet;
        }
        if (src) {
            imageElement.src = src;
        }
    }

    handleImageLoad(): void {
        const placeholder = this.placeholderElementRef.current;
        const imageElement = this.imageElementRef.current;

        /**
         * onLoad also can fire for the placeholder img, need to make sure we only handle
         * the actual product image.
         */
        if (imageElement?.src === PLACEHOLDER_IMG) {
            return;
        }

        if (imageElement) {
            imageElement.setAttribute('data-tn', 'product-image-lazy-loaded');
        }

        if (placeholder) {
            placeholder.classList.add(styles.placeholderHidden);
        }
        this.props.onImageLoad();
    }

    renderHtmlPlaceholder(): ReactNode {
        const { placeholder, useLoFiLazyLoader, imageSize, className } = this.props;

        const placeholderDimensions =
            useLoFiLazyLoader && !!placeholder ? getPlaceholderDimensions({ placeholder }) : null;

        if (!placeholderDimensions) {
            return null;
        }

        const classNames = classnames(
            styles.placeholder,
            {
                [styles.isNarrow]:
                    Number(placeholderDimensions.height) > Number(placeholderDimensions.width),
            },
            styles[imageSize],
            className
        );

        return (
            <span
                className={classNames}
                ref={this.placeholderElementRef}
                dangerouslySetInnerHTML={{ __html: placeholder || '' }}
            />
        );
    }

    render(): ReactNode {
        const { alt, className, offsetVertical, imageDraggable } = this.props;
        const visibilityTrackerOptions = {
            rootMargin: `${offsetVertical}px 0px ${offsetVertical}px 0px`,
        };

        return (
            <Fragment>
                {this.renderHtmlPlaceholder()}
                <img
                    className={className}
                    ref={this.imageElementRef}
                    src={PLACEHOLDER_IMG}
                    alt={alt}
                    draggable={imageDraggable}
                    onLoad={this.handleImageLoad}
                    data-tn="product-image-not-lazy-loaded"
                />
                <VisibilityTracker
                    elementRef={this.imageElementRef}
                    onVisibilityChange={this.triggerImageLoad}
                    observerOptions={visibilityTrackerOptions}
                />
            </Fragment>
        );
    }
}

/**
 * @generated SignedSource<<ac9237d50eea91ed62f2f1ded9dc529f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemTileBase_item$data = {
  readonly classification: {
    readonly creationDate: string | null;
  } | null;
  readonly creators: ReadonlyArray<{
    readonly attribution: string | null;
    readonly creator: {
      readonly displayName: string | null;
    } | null;
  } | null> | null;
  readonly isArt: boolean | null;
  readonly linkData: {
    readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
  };
  readonly localizedPdpUrl: string | null;
  readonly pdpMeta: {
    readonly topQuery: string | null;
  } | null;
  readonly seller?: {
    readonly sellerProfile: {
      readonly company: string | null;
    } | null;
    readonly serviceId: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Price_item" | "ProductTileMeasurements_item" | "QuickViewItem_item" | "Shipping_item" | "StorefrontOnly_item" | "SwatchSelectorMinimal_item">;
  readonly " $fragmentType": "ItemTileBase_item";
};
export type ItemTileBase_item$key = {
  readonly " $data"?: ItemTileBase_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemTileBase_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "buyerId"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "fetchLiveShipmentQuote"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchShippingCostInfo"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isTrade"
    },
    {
      "defaultValue": "searchAndBrowse",
      "kind": "LocalArgument",
      "name": "pageDisplayEnum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceBookName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showSeller"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "showShippingBadge"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemTileBase_item",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "pdpMetaItems",
      "kind": "LinkedField",
      "name": "pdpMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "topQuery",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedPdpUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemClassification",
      "kind": "LinkedField",
      "name": "classification",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creationDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemCreator",
      "kind": "LinkedField",
      "name": "creators",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attribution",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Creator",
          "kind": "LinkedField",
          "name": "creator",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArt",
      "storageKey": null
    },
    {
      "condition": "showSeller",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Seller",
          "kind": "LinkedField",
          "name": "seller",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SellerProfile",
              "kind": "LinkedField",
              "name": "sellerProfile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "company",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "linkData",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SeoLink_linkData"
        }
      ],
      "storageKey": null
    },
    {
      "condition": "showShippingBadge",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "buyerId",
              "variableName": "buyerId"
            },
            {
              "kind": "Variable",
              "name": "fetchLiveShipmentQuote",
              "variableName": "fetchLiveShipmentQuote"
            },
            {
              "kind": "Variable",
              "name": "fetchShippingCostInfo",
              "variableName": "fetchShippingCostInfo"
            }
          ],
          "kind": "FragmentSpread",
          "name": "Shipping_item"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontOnly_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwatchSelectorMinimal_item"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isTrade",
          "variableName": "isTrade"
        },
        {
          "kind": "Variable",
          "name": "pageDisplayEnum",
          "variableName": "pageDisplayEnum"
        },
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        }
      ],
      "kind": "FragmentSpread",
      "name": "Price_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuickViewItem_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductTileMeasurements_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "ccbc5f3f5d658290ea9b96d76ccb7486";

export default node;

import { FC, useState, useCallback } from 'react';
import { graphql, Environment, useFragment } from 'react-relay';

import SiteNav from '../SiteNav/SiteNav';

import styles from './BottomBar-style.scss';

import { BottomBar_viewer$key } from './__generated__/BottomBar_viewer.graphql';

type BottomBarProps = {
    viewer: BottomBar_viewer$key;
    userId: string;
    environment: Environment;
};

const BottomBar: FC<BottomBarProps> = ({ viewer: viewerRef }) => {
    const viewer = useFragment(
        graphql`
            fragment BottomBar_viewer on Viewer {
                ...SiteNav_viewer
            }
        `,
        viewerRef
    );

    const [isNavigationHovered, setIsNavigationHovered] = useState(false);
    const onMouseEnter = useCallback((): void => {
        setIsNavigationHovered(true);
    }, [setIsNavigationHovered]);
    const onMouseLeave = useCallback((): void => {
        setIsNavigationHovered(false);
    }, [setIsNavigationHovered]);

    return (
        <div className={styles.bar}>
            <div className={styles.barContent}>
                <div className={styles.wrapper} data-tn="main-nav-bottom">
                    <SiteNav
                        viewer={viewer}
                        isNavigationHovered={isNavigationHovered}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    />
                </div>
            </div>
        </div>
    );
};

export default BottomBar;

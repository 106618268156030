import * as React from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'dibs-react-intl';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import { HeaderFooterServerVarsContext } from '../../HeaderFooterServerVarsContext/HeaderFooterServerVarsContext';
import {
    getSupportedLocales,
    LOCALE_US,
    LOCALE_FR,
    LOCALE_DE,
    LOCALE_IT,
    LOCALE_ES,
} from 'dibs-intl/exports/locales';
import { localeSegmentMap } from 'dibs-intl/exports/urls';
import { hasKey } from 'dibs-ts-utils/exports/hasKey';

const messages = defineMessages({
    [LOCALE_US]: { id: 'dbl.region.en-US', defaultMessage: 'United States' },
    [LOCALE_FR]: { id: 'dbl.region.fr-FR', defaultMessage: 'France' },
    [LOCALE_DE]: { id: 'dbl.region.de-DE', defaultMessage: 'Germany' },
    [LOCALE_IT]: { id: 'dbl.region.it-IT', defaultMessage: 'Italy' },
    [LOCALE_ES]: { id: 'dbl.region.es-001', defaultMessage: 'Spain' },
});
import LinkColumnItem from '../LinkColumn/LinkColumnItem';

import styles from './GlobalSitesLinks.scss';

export const GlobalSitesLinks: React.FC = () => {
    const intl = useIntl();
    const { buyerHost } = React.useContext(HeaderFooterServerVarsContext);
    const host = buyerHost || 'https://www.1stdibs.com/';

    const links = getSupportedLocales()
        .map(locale => {
            if (hasKey(messages, locale)) {
                return {
                    href: locale === LOCALE_US ? host : `${host}/${localeSegmentMap[locale]}/`,
                    dataTn: `footer-country-link-${locale}`,
                    message: intl.formatMessage(messages[locale]),
                };
            }
            return null;
        })
        .filter(filterFalsy)
        .sort((a, z) => a.message.localeCompare(z.message, intl.locale));

    return (
        <div className={styles.container}>
            <span className={styles.label}>
                <FormattedMessage id="dbl.globalLinks.title" defaultMessage="Shop 1stDibs:" />
            </span>
            <ul className={styles.linksContainer}>
                {links.map((link, i) => (
                    <React.Fragment key={i}>
                        <LinkColumnItem key={link.dataTn} {...link} isGlobalSitesLink />
                        <span className={styles.spacer} />
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
};

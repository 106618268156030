/**
 * @generated SignedSource<<20a35d6f81db25f7ae78135b68ea3970>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteNavItem_navigationModule$data = {
  readonly imageLinkData: {
    readonly path: string | null;
  };
  readonly items: ReadonlyArray<{
    readonly name: string | null;
  } | null> | null;
  readonly linkData: {
    readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
  };
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SiteNavDropdownContent_navigationModule">;
  readonly " $fragmentType": "SiteNavItem_navigationModule";
};
export type SiteNavItem_navigationModule$key = {
  readonly " $data"?: SiteNavItem_navigationModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteNavItem_navigationModule">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteNavItem_navigationModule",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteNavDropdownContent_navigationModule"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "imageLinkData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "linkData",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SeoLink_linkData"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NavigationTopLevel",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "NavigationModule",
  "abstractKey": null
};
})();

(node as any).hash = "7dbce8d40bbd840c77f2042051aea372";

export default node;

import { FC, ReactNode } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import ResponsiveImage from '../ResponsiveImage';
import HpLazyImageWrapper from '../HpLazyImageWrapper/HpLazyImageWrapper';
import classnames from 'classnames';

import styles from './HpSharedModuleItemTile.scss';
import dibsCss from 'dibs-css';

import { HpSharedModuleItemTile_linkData$data } from './__generated__/HpSharedModuleItemTile_linkData.graphql';

type Props = {
    linkData: HpSharedModuleItemTile_linkData$data | null | undefined;
    dataTn: string;
    imageUrl?: string | null;
    imageWidth?: string | null;
    imageHeight?: string | null;
    onClick?: () => void;
    title?: string | null;
    description?: string | null;
    cta?: string | null;
    imgSizes?: string;
    imgSrcSetWidths?: number[];
    imgOverlayContent?: ReactNode;
    textClass?: string;
    imageClass?: string;
    imageOverlayClass?: string;
    useLazyLoadImages: boolean;
    backgroundColor?: string;
};

const HpSharedModuleItemTile: FC<Props> = ({
    title,
    description,
    cta,
    linkData,
    imageUrl,
    imageHeight,
    imageWidth,
    dataTn,
    onClick = () => {},
    imgSizes,
    imgSrcSetWidths,
    imgOverlayContent,
    textClass,
    imageClass,
    imageOverlayClass,
    useLazyLoadImages,
    backgroundColor,
}) => {
    const linkContent = (
        <>
            <div className={classnames(styles.imageOverlay, imageOverlayClass)}>
                {imageUrl ? (
                    <HpLazyImageWrapper
                        useLazyLoad={useLazyLoadImages}
                        imageHeight={imageHeight}
                        imageWidth={imageWidth}
                    >
                        <ResponsiveImage
                            // no need for alt if title is present bc labeling the img with title is
                            // redundant with title as link text. only use alt if != title.
                            ariaHidden
                            className={classnames(imageClass, styles.image)}
                            src={imageUrl || ''}
                            sizes={imgSizes}
                            srcSetWidths={imgSrcSetWidths}
                        />
                    </HpLazyImageWrapper>
                ) : (
                    <HpLazyImageWrapper
                        useLazyLoad={false}
                        imageHeight={imageHeight}
                        imageWidth={imageWidth}
                    >
                        <div
                            style={backgroundColor ? { backgroundColor } : undefined}
                            className={classnames(
                                dibsCss.absolute,
                                dibsCss.top0,
                                dibsCss.left0,
                                dibsCss.hFull,
                                dibsCss.wFull
                            )}
                        />
                    </HpLazyImageWrapper>
                )}
                {imgOverlayContent}
            </div>
            <div className={classnames(styles.text, textClass)}>
                {title && (
                    <div className={styles.header} dangerouslySetInnerHTML={{ __html: title }} />
                )}
                {description && (
                    <div
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                )}
                {cta && <div className={styles.cta}>{cta}</div>}
            </div>
        </>
    );

    return linkData ? (
        <SeoLink linkData={linkData} className={styles.link} dataTn={dataTn} onClick={onClick}>
            {linkContent}
        </SeoLink>
    ) : (
        linkContent
    );
};

export default createFragmentContainer(HpSharedModuleItemTile, {
    linkData: graphql`
        fragment HpSharedModuleItemTile_linkData on LinkData {
            ...SeoLink_linkData
        }
    `,
});

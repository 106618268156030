//should be moved into graphql appleApp.parameters.clientId
export const APPLE_CLIENT_ID = 'com.1stdibs.1stdibs.client';

export const SOCIAL_NETWORK_NAMES = {
    FACEBOOK: 'FACEBOOK' as const,
    GOOGLE: 'GOOGLE' as const,
    APPLE: 'APPLE' as const,
};

export const ERROR_MESSAGES = {
    PINTEREST_APP_NOT_AVAILABLE: 'pinterest app is not available',
    FACEBOOK_APP_NOT_AVAILABLE: 'facebook app is not available',
    GOOGLE_APP_NOT_AVAILABLE: 'google app is not available',
    APPLE_APP_NOT_AVAILABLE: 'apple app is not available',
    OKTA_APP_NOT_AVAILABLE: 'okta app is not available',
    MISSING_SOCIAL_NETWORK_PARAMETERS: 'missing social network parameters',
    THIRD_PARTY_COOKIES_DISABLED: 'Third party cookies are disabled',
    PINTEREST_OPTIONS_NOT_PROVIDED: 'pinterest options not provided',
    FACEBOOK_OPTIONS_NOT_PROVIDED: 'facebook options not provided',
    GOOGLE_OPTIONS_NOT_PROVIDED: 'google options not provided',
    APPLE_OPTIONS_NOT_PROVIDED: 'apple options not provided',
    SOCIAL_NETWORK_SDK_LOADING_FAILED: 'social network sdk loading failed',
};

import { setInitialPageviewFiredState } from './gtmStatus';
import { setGtmEventQueue } from './gtmEventQueue';

import { resetGtmTrackingInSamePageView } from './trackingStatus';

const resetGTMPageView = (): void => {
    setInitialPageviewFiredState(false);
    setGtmEventQueue([]);
};

/**
 * resets the google tag manager tracking by setting all the flags back to false, recreating the event queue
 * ** FOR TESTING **
 */
export function resetGtmTracking(): void {
    resetGtmTrackingInSamePageView();
    resetGTMPageView();
}

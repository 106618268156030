/**
 * @generated SignedSource<<1a593267cc2b3208393ff7ca5ef983ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedModuleItemTile_linkData$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
  readonly " $fragmentType": "HpSharedModuleItemTile_linkData";
};
export type HpSharedModuleItemTile_linkData$key = {
  readonly " $data"?: HpSharedModuleItemTile_linkData$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedModuleItemTile_linkData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedModuleItemTile_linkData",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SeoLink_linkData"
    }
  ],
  "type": "LinkData",
  "abstractKey": null
};

(node as any).hash = "e2af1a6ad5618dbc9a3c8959a600a017";

export default node;

import { commitMutation } from 'react-relay';

import type { Environment } from 'react-relay';
import type {
    useAddItemToCart_userCartItemAddMutation$data,
    useAddItemToCart_userCartItemAddMutation,
} from '../../hooks/__generated__/useAddItemToCart_userCartItemAddMutation.graphql';

import { userCartItemAddMutation } from '../../hooks/useAddItemToCart';

import type { CartItemType, SkuCartItem } from '../../actions/cartActions';

function addMultiCartItemsMutation({
    environment,
    input,
    onCompleted,
    onError,
}: {
    environment: Environment;
    input: {
        skuId: string;
        itemId: string;
        quantity: number;
        type: 'CHECKOUT' | 'SAVE_FOR_LATER';
    };
    onCompleted: () => void;
    onError: (error: Error) => void;
}): Promise<useAddItemToCart_userCartItemAddMutation$data> {
    return new Promise(() =>
        commitMutation<useAddItemToCart_userCartItemAddMutation>(environment, {
            mutation: userCartItemAddMutation,
            variables: {
                input,
            },
            onCompleted,
            onError,
        })
    );
}

export type FailedCartItem = {
    itemId: string;
    quantity: number;
    type: CartItemType;
    skuId: string;
};

export async function addMultiCartItems({
    items,
    environment,
    onCompleted,
    onFailedItems,
    removeLocalCart = () => {},
}: {
    items: SkuCartItem[];
    environment: Environment;
    onCompleted: () => void;
    removeLocalCart?: () => void;
    onFailedItems?: (
        items: { itemId: string; quantity: number; type: CartItemType; skuId: string }[]
    ) => void;
}): Promise<void> {
    const failedCartItems: FailedCartItem[] = [];
    await Promise.all(
        items.map(item => {
            const itemInput = {
                itemId: item.id,
                quantity: item.quantity,
                type: item.type,
                skuId: item.skuId,
            };
            addMultiCartItemsMutation({
                environment,
                input: itemInput,
                onCompleted: () => {},
                onError: e => {
                    failedCartItems.push(itemInput);
                    // eslint-disable-next-line no-console
                    console.error(e);
                },
            });
        })
    );
    removeLocalCart();
    if (failedCartItems?.length && onFailedItems) {
        onFailedItems(failedCartItems);
    }
    onCompleted();
}

import { graphql } from 'react-relay/legacy';

import { getLocalRecentHistoryList } from './getLocalRecentHistory';
import { MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS } from './recentHistoryConstants';

// types
import { getRecentHistory_item$data as UserRecentHistoryItemType } from './__generated__/getRecentHistory_item.graphql';
import { ItemType as RecentHistoryItemType } from './recentHistoryTypes';

type Props = {
    itemId?: string;
    maxItems?: number;
    recentHistoryItems: UserRecentHistoryItemType[];
    isClient: boolean;
};

export const getRecentHistoryList = ({
    itemId = '',
    maxItems,
    recentHistoryItems = [],
    isClient,
}: Props): RecentHistoryItemType[] => {
    if (!isClient) {
        return [];
    }

    const maxRecentlyViewedItems = maxItems || MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS;

    // get local recently viewed items
    const localRecentItems = getLocalRecentHistoryList(itemId, maxRecentlyViewedItems).reverse();

    // format passed in recently viewed items to match items in local storage
    const formattedRecentHistoryItems: RecentHistoryItemType[] = recentHistoryItems.reduce(
        (
            validItems: RecentHistoryItemType[],
            item: UserRecentHistoryItemType
        ): RecentHistoryItemType[] => {
            // items should  have title, link, and image otherwise it's NOT AN ITEM!! >:-(
            if (item && item.title && item.pdpURL && item.firstPhotoWebPath && item.serviceId) {
                validItems.push({
                    category: item.categoryPath,
                    contemporaryTrackingString: item.contemporaryTrackingString,
                    dealerPk: item?.seller?.serviceId,
                    itemBrowseUrl: item.browseUrl,
                    itemCategoryCode: item.categoryCode,
                    itemId: item.serviceId,
                    itemImageUrl: item.firstPhotoWebPath,
                    itemPdpUrl: item.pdpURL,
                    itemTitle: item.title,
                });
            }
            return validItems;
        },
        []
    );

    // appending user history items b/c those are older (48 hrs+) than local ones
    // then filter out the current viewing item if there's one
    const recentItems = [...localRecentItems, ...formattedRecentHistoryItems].filter(
        recentItem => recentItem.itemId !== itemId
    );

    // return max number of most recent items
    return recentItems.slice(0, maxRecentlyViewedItems);
};

export const userFragment = {
    user: graphql`
        fragment getRecentHistory_user on User {
            ... @include(if: $fetchUserRecentHistoryItem) {
                recentHistoryItems(excludeItemPks: $localRecentHistoryItemIds, first: 12)
                    @connection(key: "getRecentHistory_recentHistoryItems") {
                    edges {
                        node {
                            # item needs to be unmasked in order for reformatting to work.
                            ...getRecentHistory_item @relay(mask: false)
                        }
                    }
                }
            }
        }
    `,
    item: graphql`
        fragment getRecentHistory_item on Item {
            contemporaryTrackingString
            firstPhotoWebPath(size: small)
            pdpURL
            title
            browseUrl
            categoryCode
            serviceId
            categoryPath
            seller {
                serviceId
            }
        }
    `,
};

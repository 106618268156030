import { actionTypes } from '../actions/constants';

type DrawerState = {
    isDrawerOpen: boolean;
};

type DrawerActionType = {
    type: typeof actionTypes.SET_DRAWER_STATE;
    payload: { isDrawerOpen: boolean };
};

const defaultState = {
    isDrawerOpen: true,
};

export function drawerReducer(state = defaultState, action: DrawerActionType): DrawerState {
    switch (action.type) {
        case actionTypes.SET_DRAWER_STATE: {
            const { isDrawerOpen } = action.payload;
            return { isDrawerOpen };
        }
        default:
            return state;
    }
}

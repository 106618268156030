import { graphql, useFragment } from 'react-relay';
import { useUserSessionRegions } from 'dibs-regional-info/exports/regionalInfoHelpers';
import { getIsFreeShipping } from './shippingCostHelpers';

import { useShippingPrice_viewer$key } from './__generated__/useShippingPrice_viewer.graphql';
import { useShippingPrice_item$key } from './__generated__/useShippingPrice_item.graphql';

const viewerFragment = graphql`
    fragment useShippingPrice_viewer on Viewer
    @argumentDefinitions(
        fetchRegionalInfo: { type: "Boolean!", defaultValue: false }
        userZipCode: { type: "String", defaultValue: "" }
        userCountryCode: { type: "String", defaultValue: "" }
    ) {
        regionsInfo: regionalInfo(zipCode: $userZipCode, countryCode: $userCountryCode)
            @include(if: $fetchRegionalInfo) {
            regionsByZipCode {
                displayName
            }
        }
    }
`;

const itemFragment = graphql`
    fragment useShippingPrice_item on Item
    @argumentDefinitions(
        buyerId: { type: "String", defaultValue: "" }
        fetchShippingCostInfo: { type: "Boolean", defaultValue: false }
        fetchLiveShipmentQuote: { type: "Boolean", defaultValue: true }
        userZipCode: { type: "String", defaultValue: "" }
        userCountryCode: { type: "String", defaultValue: "" }
        itemQuantity: { type: "Int" }
        fdfQuoteABTestVariant: { type: "String" }
    ) {
        ...shippingCostHelpers_item
        relevantShippingInfo(
            buyerId: $buyerId
            useLiveEndpoint: $fetchLiveShipmentQuote
            quantity: $itemQuantity
            fdfQuoteABTestVariant: $fdfQuoteABTestVariant
            zipCode: $userZipCode
            countryCode: $userCountryCode
        ) @include(if: $fetchShippingCostInfo) {
            shipmentQuote {
                buyerTotalAmount {
                    convertedAmountList {
                        currency
                        amount
                    }
                }
            }
        }
    }
`;

export const useShippingPrice = ({
    viewer: viewerRef,
    item: itemRef,
    currency,
}: {
    viewer: useShippingPrice_viewer$key | null | undefined;
    item: useShippingPrice_item$key | null | undefined;
    currency: string;
}): number | null => {
    const viewer = useFragment(viewerFragment, viewerRef);
    const item = useFragment(itemFragment, itemRef);
    const userSessionRegions = useUserSessionRegions();

    if (!item) {
        return null;
    }

    const userRegions = userSessionRegions || viewer?.regionsInfo?.[0]?.regionsByZipCode || [];
    const relevantQuote = item.relevantShippingInfo?.shipmentQuote;

    const { amount } =
        relevantQuote?.buyerTotalAmount?.convertedAmountList?.find(p => p?.currency === currency) ||
        {};

    if (typeof amount === 'number') {
        return amount;
    } else if (getIsFreeShipping({ item, userRegions })) {
        return 0;
    }

    return null;
};

import * as React from 'react';
import PropTypes from 'prop-types';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';
import classnames from 'classnames';
import styles from './LazyImage.scss';
const { Children, cloneElement, Fragment } = React;

export default class LazyImage extends React.Component {
    constructor() {
        super();

        this.onImageLoad = this.onImageLoad.bind(this);
        this.onContentVisible = this.onContentVisible.bind(this);

        this.state = {
            imageLoaded: false,
            imageVisible: false,
        };
    }

    onContentVisible() {
        this.setState({
            imageVisible: true,
        });

        this.props.onContentVisible();
    }

    onImageLoad() {
        this.setState({
            imageLoaded: true,
        });

        this.props.onImageLoaded();
    }

    renderDefaultChildren() {
        return cloneElement(this.props.children, {
            onLoad: this.onImageLoad,
        });
    }

    renderHtmlChildren() {
        const child = Children.only(this.props.children);
        const childClasses = classnames(styles.child, child.props.className);

        return cloneElement(child, {
            className: childClasses,
            onLoad: this.onImageLoad,
        });
    }

    renderHtmlPlaceholder() {
        const { placeholder, placeholderClass, height = '100%', width = '100%' } = this.props;
        const { imageLoaded } = this.state;
        const style = { height, width };

        const classNames = classnames(
            styles.placeholderPositioning,
            styles.placeholderTransition,
            placeholderClass || styles.placeholder,
            {
                [styles.placeholderHidden]: imageLoaded,
            }
        );

        return (
            <span
                className={classNames}
                style={style}
                dangerouslySetInnerHTML={{ __html: placeholder }}
            />
        );
    }

    render() {
        const {
            className,
            placeholderClass,
            placeholder,
            offsetVertical,
            offsetHorizontal,
            visibilityRef,
        } = this.props;
        const { imageLoaded, imageVisible } = this.state;
        const lazyLoadPlaceholder = placeholder ? this.renderHtmlPlaceholder() : null;
        const lazyLoadChildren = placeholder
            ? this.renderHtmlChildren()
            : this.renderDefaultChildren();

        /**
         * Very important to not keep the animation running while the user can't see it. We don't have GPU rendering,
         * so it defers to the CPU. Some browsers continue running animations even when they are off-screen.
         */
        const classNames = classnames({
            [placeholderClass]: !imageLoaded && placeholderClass,
            [className]: imageLoaded && className,
            [styles.animationRunning]: !imageLoaded && imageVisible,
            [styles.background]: !imageLoaded,
        });

        return (
            <Fragment>
                {lazyLoadPlaceholder}
                <VisibilityTracker
                    elementRef={visibilityRef}
                    onVisibilityChange={({ isVisible, disconnect }) => {
                        if (isVisible) {
                            disconnect();
                            this.onContentVisible();
                        }
                    }}
                    observerOptions={{
                        rootMargin: `${offsetVertical}px ${offsetHorizontal}px`,
                    }}
                />
                <div className={placeholder ? className : classNames}>
                    {imageVisible ? lazyLoadChildren : null}
                </div>
            </Fragment>
        );
    }
}

LazyImage.defaultProps = {
    onContentVisible: () => {},
    onImageLoaded: () => {},
    placeholderClass: '',
    offsetVertical: 0,
    offsetHorizontal: 0,
};

LazyImage.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onContentVisible: PropTypes.func,
    onImageLoaded: PropTypes.func,
    placeholderClass: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    offsetHorizontal: PropTypes.number,
    offsetVertical: PropTypes.number,
    visibilityRef: PropTypes.object,
};

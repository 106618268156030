import { FormattedMessage } from 'dibs-react-intl';
import getBuyerSupportLink from 'dibs-support-link/exports/getBuyerSupportLink';

import Contact1stdibsFooterLink from '../../Contact1stdibsLink/Contact1stdibsFooterLink';
import { FooterLink } from '../FooterLinkTypes';

const supportLinks: FooterLink[] = [
    {
        trackingLabel: 'Contact 1stDibs',
        dataTn: 'footer-contact-1stdibs',
        CustomComponent: Contact1stdibsFooterLink,
    },
    {
        trackingLabel: 'Support Center',
        href: getBuyerSupportLink('/'),
        target: '_blank',
        dataTn: 'footer-support',
        message: (
            <FormattedMessage
                id="dbl.Footer.site_links_support_center"
                defaultMessage="Visit Support Center"
            />
        ),
    },
    {
        trackingLabel: '1stDibs Promise',
        href: '/about/promise/',
        target: '_blank',
        dataTn: 'footer-1stdibs-promise-variant',
        message: (
            <FormattedMessage
                id="dbl.Footer.site_links_1stdibs_promise_variant"
                defaultMessage="1stDibs Promise"
            />
        ),
    },
];

export default supportLinks;

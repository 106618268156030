/* eslint-disable import/no-default-export */
import { createRoot } from 'react-dom/client';
import serverVars from 'server-vars';
import { IntlProvider } from 'dibs-react-intl';
import { isDefaultLang } from 'dibs-intl/exports/languages';

export function backToTopLauncher(options = {}) {
    const backToTopContainer = document.createElement('div');
    document.body.appendChild(backToTopContainer);

    const { showChatWidget } = options;

    return import(
        /* webpackChunkName: "backToTop" */
        '../src/BackToTop/BackToTop'
    ).then(mod => {
        const { BackToTop } = mod;
        const locale = serverVars.get('locale');
        const root = createRoot(backToTopContainer);

        root.render(
            <IntlProvider locale={locale} messages={serverVars.get('messages')}>
                <BackToTop isCopyEnabled={isDefaultLang(locale)} showChatWidget={showChatWidget} />
            </IntlProvider>
        );
    });
}

/**
 * @generated SignedSource<<af40ca9f09b229ec2f7848a8172c842c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteNavDropdownImageModule_navigationModule$data = {
  readonly imageHeight: string | null;
  readonly imageLinkData: {
    readonly path: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
  };
  readonly imageTitlePrimary: string | null;
  readonly imageTitleSecondary: string | null;
  readonly imageUrl: string | null;
  readonly imageWidth: string | null;
  readonly " $fragmentType": "SiteNavDropdownImageModule_navigationModule";
};
export type SiteNavDropdownImageModule_navigationModule$key = {
  readonly " $data"?: SiteNavDropdownImageModule_navigationModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteNavDropdownImageModule_navigationModule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteNavDropdownImageModule_navigationModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "imageLinkData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SeoLink_linkData"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageTitlePrimary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageTitleSecondary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageHeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageWidth",
      "storageKey": null
    }
  ],
  "type": "NavigationModule",
  "abstractKey": null
};

(node as any).hash = "0ff3b2450399692f331aae9328e7c9c6";

export default node;

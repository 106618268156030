import {
    trackEvent,
    TrackingObject,
    eventNameConstants,
    interactionTypeConstants,
} from 'dibs-tracking';

import { getCurrentAccountType } from '../../../utils/userTrackingHelpers/getCurrentAccountType';

import { ACTION_COMPLETE } from './sharedAuthTracking';

export function loginTracking({ action, label, value, additional = {} }: TrackingObject): void {
    let currentAccountType;

    if (action === ACTION_COMPLETE) {
        currentAccountType = getCurrentAccountType({ type: 'password' });
        additional = {
            ...additional,
            currentAccountType,
        };
    }

    trackEvent({
        action: `login ${action}`,
        category: 'login',
        label,
        value,
        additional,
        eventName: eventNameConstants.EVENT_LOGIN,
        interaction_type: interactionTypeConstants.INTERACTION_TYPE_USER_LOGIN,
        step_interaction_name: action,
        trigger: label || '',
        step_type: currentAccountType,
    });
}

/**
 * @generated SignedSource<<11236932ec8867d4b3c32d66f802554f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedPersonalizedCategories_personalization$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly categoryItems: ReadonlyArray<{
        readonly contemporaryTrackingString: string;
        readonly localizedPdpUrl: string | null;
        readonly pricing: {
          readonly retailPrice: {
            readonly amount: number | null;
          } | null;
        } | null;
        readonly seller: {
          readonly serviceId: string | null;
        } | null;
        readonly serviceId: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"Tile_item">;
      } | null> | null;
      readonly categoryMainItem: {
        readonly contemporaryTrackingString: string;
        readonly pricing: {
          readonly retailPrice: {
            readonly amount: number | null;
          } | null;
        } | null;
        readonly seller: {
          readonly serviceId: string | null;
        } | null;
        readonly serviceId: string | null;
      } | null;
      readonly categoryTitle: string | null;
      readonly linkData: {
        readonly path: string | null;
      };
      readonly variant: string | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "HpSharedPersonalizedCategories_personalization";
};
export type HpSharedPersonalizedCategories_personalization$key = {
  readonly " $data"?: HpSharedPersonalizedCategories_personalization$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedPersonalizedCategories_personalization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contemporaryTrackingString",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Seller",
  "kind": "LinkedField",
  "name": "seller",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemPricing",
  "kind": "LinkedField",
  "name": "pricing",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialAmount",
      "kind": "LinkedField",
      "name": "retailPrice",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedPersonalizedCategories_personalization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonalizationModuleEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonalizationModule",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LinkData",
              "kind": "LinkedField",
              "name": "linkData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "path",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "categoryTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "variant",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "categoryMainItem",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "categoryItems",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "localizedPdpUrl",
                  "storageKey": null
                },
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "showPrice",
                      "value": false
                    },
                    {
                      "kind": "Literal",
                      "name": "showSeller",
                      "value": false
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "Tile_item"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PersonalizationModuleConnection",
  "abstractKey": null
};
})();

(node as any).hash = "a9e36a99ebb6bf63f71c88ee8894ecbe";

export default node;

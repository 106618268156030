/**
 * @generated SignedSource<<de40a8382edbd0b14df3ee51366b51e2>>
 * @relayHash 485e58bcec6a33793659965477bacb0b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/494.0.0-2025-01-09T20:04:30.590Z/verifyEmailHandlerQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type verifyEmailHandlerQuery$variables = {
  buyerId?: string | null;
  email?: string | null;
  hasBuyerId: boolean;
};
export type verifyEmailHandlerQuery$data = {
  readonly viewer: {
    readonly user?: {
      readonly profile: {
        readonly email: string | null;
      } | null;
    } | null;
    readonly userExistsMetadata: {
      readonly emailOnly: boolean | null;
      readonly hasPassword: boolean | null;
    } | null;
  };
};
export type verifyEmailHandlerQuery = {
  response: verifyEmailHandlerQuery$data;
  variables: verifyEmailHandlerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "buyerId"
},
v1 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "email"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasBuyerId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "buyerId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Profile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailOnly",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasPassword",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "verifyEmailHandlerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasBuyerId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "UserExistsMetadataType",
            "kind": "LinkedField",
            "name": "userExistsMetadata",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "verifyEmailHandlerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasBuyerId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "UserExistsMetadataType",
            "kind": "LinkedField",
            "name": "userExistsMetadata",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/494.0.0-2025-01-09T20:04:30.590Z/verifyEmailHandlerQuery",
    "metadata": {},
    "name": "verifyEmailHandlerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a5ca83631de3a0f9b8d2c2be6441740d";

export default node;

import { commitMutation, graphql, Environment } from 'react-relay/legacy';

import {
    SourceAttributionInput,
    sourceAttributionMutation as sourceAttributionMutationType,
} from './__generated__/sourceAttributionMutation.graphql';

const mutation = graphql`
    mutation sourceAttributionMutation($input: SourceAttributionInput!) {
        sourceAttribution(input: $input) {
            message
        }
    }
`;

export function sourceAttributionMutation(
    environment: Environment,
    args: SourceAttributionInput
): void {
    const {
        guestUserId,
        landingPage,
        type,
        userId,
        userToken,
        emailToken,
        visitCount,
        pageVisits,
    } = args;
    commitMutation<sourceAttributionMutationType>(environment, {
        mutation,
        variables: {
            input: {
                guestUserId,
                landingPage,
                type,
                userId,
                userToken,
                emailToken,
                visitCount,
                pageVisits,
            },
        },
    });
}

/**
 * @generated SignedSource<<15c2ef76fa770b218ccba5b2054da7b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VerticalType = "art" | "fashion" | "furniture" | "jewelry" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Tile_item$data = {
  readonly isMultiSku: boolean;
  readonly localizedPdpUrl: string | null;
  readonly seller?: {
    readonly serviceId: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"Info_seller">;
  } | null;
  readonly serviceId: string | null;
  readonly title: string | null;
  readonly vertical: VerticalType | null;
  readonly " $fragmentSpreads": FragmentRefs<"Info_item" | "Product_item" | "QuickViewItem_item">;
  readonly " $fragmentType": "Tile_item";
};
export type Tile_item$key = {
  readonly " $data"?: Tile_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"Tile_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isInternalAdmin"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isTrade"
    },
    {
      "defaultValue": "searchAndBrowse",
      "kind": "LocalArgument",
      "name": "pageDisplayEnum"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "showPrice"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showSeller"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Tile_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMultiSku",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedPdpUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vertical",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isInternalAdmin",
          "variableName": "isInternalAdmin"
        },
        {
          "kind": "Variable",
          "name": "isTrade",
          "variableName": "isTrade"
        },
        {
          "kind": "Variable",
          "name": "pageDisplayEnum",
          "variableName": "pageDisplayEnum"
        },
        {
          "kind": "Variable",
          "name": "showPrice",
          "variableName": "showPrice"
        }
      ],
      "kind": "FragmentSpread",
      "name": "Info_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Product_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuickViewItem_item"
    },
    {
      "condition": "showSeller",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Seller",
          "kind": "LinkedField",
          "name": "seller",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Info_seller"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "ad5e271de17a6d837af061c7abd27df9";

export default node;

import { graphql } from 'react-relay';
import { getUserSessionCountryCode } from 'dibs-regional-info/exports/regionalInfoHelpers';
export type { footerRootQuery as footerRootQueryType } from './__generated__/footerRootQuery.graphql';

export default graphql`
    query footerRootQuery(
        $userId: String = ""
        $hasUserId: Boolean!
        $userCountryCode: String = ""
    ) {
        viewer {
            ...Footer_viewer
        }
    }
`;

export const initialVariables = {
    hasUserId: false,
    userId: '',
    userCountryCode: getUserSessionCountryCode(),
};

import { lazy, Suspense } from 'react';
import { useClientState } from 'dibs-react-hooks/exports/useClientState';

const FooterSubscribeReactLazy = lazy(() =>
    import(/* webpackChunkName: "FooterSubscribe" */ './FooterSubscribe')
);

const FooterSubscribeLazy = props => {
    const isClient = useClientState();
    if (!isClient) {
        return null;
    }
    return (
        <Suspense fallback={null}>
            <FooterSubscribeReactLazy {...props} />
        </Suspense>
    );
};

export default FooterSubscribeLazy;

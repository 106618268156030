import { graphql, Environment } from 'react-relay/legacy';
import commitMutation from 'relay-commit-mutation-promise';
import {
    setUserPriceBookNameCookieMutation,
    setUserPriceBookNameCookieMutation$data,
    SetUserPriceBookNameCookieInput,
} from './__generated__/setUserPriceBookNameCookieMutation.graphql';

const mutation = graphql`
    mutation setUserPriceBookNameCookieMutation($input: SetUserPriceBookNameCookieInput!) {
        setUserPriceBookNameCookie(input: $input) {
            clientMutationId
        }
    }
`;

export function setUserPriceBookNameCookie(
    environment: Environment,
    input: SetUserPriceBookNameCookieInput
): Promise<setUserPriceBookNameCookieMutation$data> {
    return commitMutation<setUserPriceBookNameCookieMutation>(environment, {
        mutation,
        variables: { input },
    });
}

import { readInlineData, graphql } from 'react-relay';
import { LazyImageProps } from '../../../LazyImage/LazyImage';
import { getSrcSetString } from '../../../helpers/srcSet';

import { ThumbVideo_viewer$data } from './__generated__/ThumbVideo_viewer.graphql';
import { helpers_getPhotoImgProps_photo$key } from './__generated__/helpers_getPhotoImgProps_photo.graphql';

type VideoData = NonNullable<ThumbVideo_viewer$data['video']>;
export type VideoFormat = 'landscape' | 'square' | 'portrait';

/**
 * Function to determine format of video.
 * Accepts either a ThumbVideo_video fragment or width and height
 *
 * Purpose is to let tiles use format as conditional for css styles in case we want
 * different constraints depending on aspect ratio
 */
export function getVideoFormat(video: VideoData): VideoFormat;
export function getVideoFormat(width: number, height: number): VideoFormat;
export function getVideoFormat(a: number | VideoData, b?: number): VideoFormat {
    let width: number;
    let height: number;
    if (typeof a === 'number') {
        // If a is number, then args should be width and height
        if (!b) throw new Error('If passing width in first arg, second arg height is required.');
        width = a;
        height = b;
    } else {
        // Else get width and height from video fragment
        width = a.width || 0;
        height = a.height || 0;
    }

    if (width === height) return 'square';
    else if (width > height) return 'landscape';
    else return 'portrait';
}

export function getVideoImgProps(video: VideoData): Pick<LazyImageProps, 'src'> {
    const thumb = video.videoThumb || '';
    const src = thumb.replace(/_(\d*)x\d*(\??)/, '_$1x$1$2');
    return { src };
}

const photoFragment = graphql`
    fragment helpers_getPhotoImgProps_photo on ItemPhoto @inline {
        placeholder
        smallPath
        masterOrZoomPath
    }
`;

export function getPhotoImgProps(
    photoRef: helpers_getPhotoImgProps_photo$key,
    srcSetSizes?: string,
    srcSetWidths?: number[],
    srcSetQuality?: number
): Pick<LazyImageProps, 'src' | 'placeholder' | 'srcSetAttributes'> {
    const photo = readInlineData(photoFragment, photoRef);
    const { smallPath, masterOrZoomPath: masterPath, placeholder } = photo || {};

    const src = smallPath || masterPath || '';

    const srcSetAttributes =
        srcSetSizes && masterPath
            ? {
                  srcSet: getSrcSetString(masterPath, {
                      widths: srcSetWidths,
                      quality: srcSetQuality,
                  }),
                  sizes: srcSetSizes,
              }
            : {};

    return { src, placeholder, srcSetAttributes };
}

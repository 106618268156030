import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'dibs-elements/exports/Link';
import { trackEvent, trackEcommerce, trackingConstants, eventNameConstants } from 'dibs-tracking';

import styles from './SearchBarRecentlyViewedItems.scss';

export const SearchBarRecentlyViewedItems = props => {
    const [hasFiredImpressions, setHasFiredImpressions] = useState(false);
    const { recentlyViewedItems } = props;
    const { ECOM_PRODUCT_CLICK, ECOM_PRODUCT_IMPRESSION } = trackingConstants;
    const { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST } = eventNameConstants;

    const trackClick = (item, index) => {
        trackEvent({
            category: 'promo interaction',
            action: 'recently viewed - global search items clicked',
            label: 'none',
            value: 0,
        });
        trackEcommerce({
            type: ECOM_PRODUCT_CLICK,
            eventName: EVENT_SELECT_ITEM,
            actionField: { list: 'recently viewed - global search', itemId: item.itemId },
            products: [
                {
                    id: item.itemId,
                    dimension83: item.contemporaryTrackingString,
                    position: index + 1,
                    brand: item.dealerPk,
                    list: 'recently viewed - global search',
                },
            ],
        });
    };

    useEffect(() => {
        if (!hasFiredImpressions) {
            trackEcommerce({
                type: ECOM_PRODUCT_IMPRESSION,
                eventName: EVENT_VIEW_ITEM_LIST,
                actionField: { list: 'recently viewed - global search' },
                products: recentlyViewedItems.map((item, index) => ({
                    id: item.itemId,
                    dimension83: item.contemporaryTrackingString,
                    position: index + 1,
                    brand: item.dealerPk,
                    list: 'recently viewed - global search',
                })),
            });
            setHasFiredImpressions(true);
        }
    }, [
        recentlyViewedItems,
        hasFiredImpressions,
        setHasFiredImpressions,
        ECOM_PRODUCT_IMPRESSION,
        ECOM_PRODUCT_CLICK,
        EVENT_VIEW_ITEM_LIST,
        EVENT_SELECT_ITEM,
    ]);

    return (
        <div className={styles.items}>
            {recentlyViewedItems.map((item, index) => (
                <Link
                    key={item.itemPdpUrl}
                    className={styles.item}
                    href={item.itemPdpUrl}
                    onClick={() => trackClick(item, index)}
                >
                    <img className={styles.image} src={item.itemImageUrl} alt={item.itemTitle} />
                </Link>
            ))}
        </div>
    );
};

SearchBarRecentlyViewedItems.propTypes = {
    recentlyViewedItems: PropTypes.array,
};

/**
 * @generated SignedSource<<ffb2c62024e0c0350662909c3e5eee9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getRecentHistory_user$data = {
  readonly recentHistoryItems?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly browseUrl: string | null;
        readonly categoryCode: string | null;
        readonly categoryPath: string | null;
        readonly contemporaryTrackingString: string;
        readonly firstPhotoWebPath: string | null;
        readonly pdpURL: string | null;
        readonly seller: {
          readonly serviceId: string | null;
        } | null;
        readonly serviceId: string | null;
        readonly title: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "getRecentHistory_user";
};
export type getRecentHistory_user$key = {
  readonly " $data"?: getRecentHistory_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"getRecentHistory_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "fetchUserRecentHistoryItem"
    },
    {
      "kind": "RootArgument",
      "name": "localRecentHistoryItemIds"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "recentHistoryItems"
        ]
      }
    ]
  },
  "name": "getRecentHistory_user",
  "selections": [
    {
      "condition": "fetchUserRecentHistoryItem",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "recentHistoryItems",
          "args": [
            {
              "kind": "Variable",
              "name": "excludeItemPks",
              "variableName": "localRecentHistoryItemIds"
            }
          ],
          "concreteType": "RecentHistoryItemsConnection",
          "kind": "LinkedField",
          "name": "__getRecentHistory_recentHistoryItems_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RecentHistoryItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Item",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "contemporaryTrackingString",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "size",
                          "value": "small"
                        }
                      ],
                      "kind": "ScalarField",
                      "name": "firstPhotoWebPath",
                      "storageKey": "firstPhotoWebPath(size:\"small\")"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "pdpURL",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "browseUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "categoryCode",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "categoryPath",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Seller",
                      "kind": "LinkedField",
                      "name": "seller",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "4b01444c8aa7cd585a9a368510d6b9d4";

export default node;

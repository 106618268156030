/**
 * @generated SignedSource<<2562798a76e1ea6bf42a979bf2eaff7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ViewInRoomImage_item$data = {
  readonly measurement: {
    readonly convertedMeasurements: {
      readonly width: string | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useItemDisplayDimensions_item">;
  readonly " $fragmentType": "ViewInRoomImage_item";
};
export type ViewInRoomImage_item$key = {
  readonly " $data"?: ViewInRoomImage_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewInRoomImage_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewInRoomImage_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemMeasurement",
      "kind": "LinkedField",
      "name": "measurement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "unit",
              "value": "IN"
            }
          ],
          "concreteType": "ItemConvertedMeasurementsType",
          "kind": "LinkedField",
          "name": "convertedMeasurements",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "width",
              "storageKey": null
            }
          ],
          "storageKey": "convertedMeasurements(unit:\"IN\")"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useItemDisplayDimensions_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "a267240d5a91506ca0ccc86c3d561505";

export default node;

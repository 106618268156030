import { FunctionComponent, ReactNode } from 'react';
import cn from 'classnames';
import dibsCss from 'dibs-css';
import { FormattedMessage } from 'dibs-react-intl';

// components
import { FormattedPrice } from './FormattedPrice';
import { priceLabel, discountLabel } from './messages/priceMessages';
import { vatMessages } from 'dibs-vat-utils/exports/vatMessages';

import { PRICE_TYPES } from './constants';

// styles
import styles from './PriceAmount.scss';

// helpers
import classnames from 'classnames';

export interface PriceAmountProps {
    amount?: number;
    currency: string;

    discount?: number;
    type?: string;
    hasNetPrice?: boolean;
    hasSalePrice?: boolean;
    hasPrivateOffer?: boolean;
    isUnpurchasable?: boolean;

    // options
    quantityDisplay: string | null;
    showLabel?: boolean;
    expiration?: ReactNode;
    appendElementToPrice?: ReactNode;
    displayVatLabel?: boolean;
    priceBookName: string | null;
    isInline?: boolean;
    hasPriceBlur?: boolean;
}

export const PriceAmount: FunctionComponent<PriceAmountProps> = ({
    amount = 0,
    currency,
    discount,
    hasNetPrice,
    hasSalePrice,
    hasPrivateOffer,
    quantityDisplay,
    showLabel,
    expiration,
    isUnpurchasable,
    type = PRICE_TYPES.RETAIL,
    appendElementToPrice = null,
    displayVatLabel = false,
    priceBookName,
    isInline = false,
    hasPriceBlur = false,
}) => {
    const className = classnames({
        [styles.retail]: type === PRICE_TYPES.RETAIL,
        [styles.net]: type === PRICE_TYPES.NET,
        [styles.hasNet]: hasNetPrice,
        [styles.hasSale]: hasSalePrice,
        [styles.hasPrivateOffer]: hasPrivateOffer,
        [styles.unpurchasable]: isUnpurchasable,
        [styles.inline]: isInline,
    });

    return (
        <>
            {/* Expiration Date for Private Offers */}
            {expiration && (
                <span className={styles.expiration} data-tn="price-offer-expiration-date">
                    {expiration}
                </span>
            )}
            <div className={className} data-tn={`price-${type.toLowerCase()}`}>
                {/* Price */}
                {hasPriceBlur && (
                    <span className={dibsCss.mrXxsmall}>
                        <FormattedMessage id="PriceAmount.prefix" defaultMessage="Price:" />
                    </span>
                )}
                <span
                    className={cn(styles.price, {
                        [styles.blur]: hasPriceBlur,
                    })}
                    data-tn="price-amount"
                >
                    <FormattedPrice value={amount} currency={currency} />
                </span>
                {/* List Price */}
                {showLabel && (
                    <span className={styles.label} data-tn="price-list-price">
                        {priceLabel(type)}
                    </span>
                )}
                {/* Quantity */}
                {quantityDisplay && (
                    <span className={styles.label} data-tn="price-quantity-display">
                        {quantityDisplay}
                    </span>
                )}
                {/* Percent Off */}
                {!!discount && (
                    <span className={styles.discount} data-tn="price-discount">
                        {discountLabel(discount)}
                    </span>
                )}
                {/* VAT label */}
                {displayVatLabel && (
                    <>
                        {(showLabel || quantityDisplay || discount) && appendElementToPrice && (
                            <span className={styles.label}>|</span>
                        )}
                        <span className={styles.label} data-tn="price-vat-label">
                            {vatMessages.included({ priceBookName })}
                        </span>
                    </>
                )}
                {appendElementToPrice && (
                    <span
                        className={classnames(styles.label, {
                            [styles.hideSeparator]: !displayVatLabel,
                        })}
                        data-tn="price-append-element"
                    >
                        {appendElementToPrice}
                    </span>
                )}
            </div>
        </>
    );
};

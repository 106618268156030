import PinchDetector from './PinchDetector';
import DoubleTapDetector from './DoubleTapDetector';
import { PanDetector } from './PanDetector';
import { setTransformStyle, setTransformOriginStyle } from './swiperUtils';
const noop = () => {};

function getBounds(zoom, distance) {
    const zoomedDistance = zoom * distance;
    return (zoomedDistance - distance) * zoom;
}

/**
 * Handles zooming and panning on a React component. Meant to be used with PinchZoomComponent.
 *
 * @param {Number} [options.maxZoom] Maximum zoom factor. Defaults to 2.
 * @param {Number} [options.onZoomIn] Callback to fire on zoom in.
 * @param {Number} [options.onZoomOut] Callback to fire on zoom out.
 * @param {Number} [options.onPan] Callback to fire on pan.
 */
export class ZoomPanModel {
    constructor({
        maxZoom = 2,
        onZoomMax = noop,
        onZoomMin = noop,
        onZoomIn = noop,
        onZoomOut = noop,
        onPan = noop,
        onPinchStart = noop,
        overridePanMoveDefault = false,
        zoomIntensity = 150,
        minZoom = 1,
    }) {
        Object.assign(this, {
            _pinchDetector: null,
            _panDetector: null,
            _doubleTapDetector: null,
            _zoomMaxCallback: onZoomMax,
            _zoomMinCallback: onZoomMin,
            _zoomInCallback: onZoomIn,
            _zoomOutCallback: onZoomOut,
            _overridePanMoveDefault: overridePanMoveDefault,
            _panCallback: onPan,
            _pinchStartCallback: onPinchStart,
            _maxZoom: maxZoom,
            _minZoom: minZoom,
            _zoomIntensity: zoomIntensity,
            _el: null,
            _state: {},
        });

        this._setInitialState();
    }

    setEl(el) {
        this._el = el;

        // Wire up the touch detection components.
        this._pinchDetector = new PinchDetector({
            onZoomIn: (...args) => this._doCallback(() => this._onZoomIn(...args)),
            onZoomOut: (...args) => this._doCallback(() => this._onZoomOut(...args)),
            onPinch: (...args) => this._doCallback(() => this._onPinchStart(...args)),
            el,
        });

        this._panDetector = new PanDetector({
            onPan: (...args) => this._doCallback(() => this._onPanUpdate(...args)),
            el,
            overridePanMoveDefault: this._overridePanMoveDefault,
        });

        this._doubleTapDetector = new DoubleTapDetector({
            onDoubleTap: (...args) => this._doCallback(() => this._onDoubleTap(...args)),
            el,
        });
    }

    /**
     * Enable touch detection.
     */
    enable() {
        this._state.enabled = true;
    }

    /**
     * Disable touch detection.
     */
    disable() {
        this._state.enabled = false;
    }

    _doCallback(callback) {
        // Check if touch detection is enabled and then fire the callback if it is.
        if (this._state.enabled) {
            callback();
        }
    }

    reset() {
        this._setInitialState();
    }

    _setInitialState() {
        this._state.zoom = 1;
        this._state.left = 0;
        this._state.right = 0;
        this._state.bottom = 0;
        this._state.top = 0;
        this._state.panX = 0;
        this._state.panY = 0;
        this._state.pinchX = 0;
        this._state.pinchY = 0;
        this._state.pinchOffsetX = 0;
        this._state.pinchOffsetY = 0;
        this._state.enabled = true;
    }

    destroy() {
        // The touch detectors add event listeners so we need to destroy it to prevent memory leaks.
        this._pinchDetector.destroy();
        this._panDetector.destroy();
        this._doubleTapDetector.destroy();
    }

    /**
     * Add the necessary styles based on the pan position and zoom. Meant to be called during render of the React view.
     * @param  {Object} style The style object to add properties to.
     */
    getStyle(style) {
        const { zoom, panX, panY, pinchX, pinchY, pinchOffsetX, pinchOffsetY } = this._state;

        setTransformOriginStyle(style, `${pinchX}px ${pinchY}px`);

        setTransformStyle(
            style,
            `translateX(${panX + pinchOffsetX}px) translateY(${
                panY + pinchOffsetY
            }px) scale(${zoom})`
        );
    }

    /**
     * Go to max zoom factor after a double tap.
     */
    _onDoubleTap(tapX, tapY) {
        if (this._state.zoom === this._maxZoom) {
            this._onZoomOut(this._minZoom, true);
            this._zoomMinCallback();
        } else {
            this._onPinchStart(tapX, tapY);
            this._onZoomIn(this._maxZoom, true);
            this._zoomMaxCallback();
        }
    }

    /**
     * Set the current bounds on panning based on the size of the element and the current zoom factor.
     * @param {Number} zoom Current zoom factor.
     */
    _setBounds() {
        const { zoom, pinchX, pinchY, pinchOffsetX, pinchOffsetY } = this._state;
        const width = this._el.clientWidth;
        const height = this._el.clientHeight;

        this._state.left = getBounds(zoom, pinchX - pinchOffsetX);
        this._state.right = getBounds(zoom, width - pinchX + pinchOffsetX);
        this._state.top = getBounds(zoom, pinchY - pinchOffsetY);
        this._state.bottom = getBounds(zoom, height - pinchY + pinchOffsetY);
    }

    /**
     * Make sure zoom value does not exceeed min & max values
     */
    _clampZoomValue(value) {
        return Math.min(Math.max(value, this._minZoom), this._maxZoom);
    }

    _getNewZoomValue(value, override) {
        if (override) {
            return Math.abs(value);
        } else {
            return this._clampZoomValue(this._state.zoom + value / this._zoomIntensity);
        }
    }

    _onZoomIn(value, override) {
        const zoom = (this._state.zoom = this._getNewZoomValue(value, override));

        if (this._panDetector._overridePanMoveDefault) {
            this._panDetector._zoomedIn = true;
        }
        this._setBounds(zoom);
        this._zoomInCallback();
    }

    _onZoomOut(value, override) {
        const { zoom } = this._state;
        const newZoom = this._getNewZoomValue(-value, override);

        this._synchronizeOffset(newZoom, zoom);

        this._state.zoom = newZoom;

        if (this._panDetector._overridePanMoveDefault) {
            this._panDetector._zoomedIn = false;
        }

        this._setBounds();
        this._zoomOutCallback();
    }

    /**
     * Prevent panning beyond a certain boundary so that the element cannot be dragged off screen.
     * @param  {Number} pan  Actual pan distance.
     * @param  {Number} edge Position of top or left boundary.
     * @return {Number}      Constrained pan distance.
     */
    _constrainPan(pan, firstEdge, secondEdge) {
        const { zoom } = this._state;

        if (pan > 0 && Math.abs(pan * zoom) >= firstEdge) {
            pan = firstEdge / zoom;
        }

        if (pan < 0 && Math.abs(pan * zoom) >= secondEdge) {
            pan = -secondEdge / zoom;
        }

        return pan;
    }

    _onPanUpdate(distanceX, distanceY) {
        // Constrain the actual pan distance based on the boundaries.
        this._state.panX = this._constrainPan(
            this._state.panX + distanceX,
            this._state.left,
            this._state.right
        );
        this._state.panY = this._constrainPan(
            this._state.panY + distanceY,
            this._state.top,
            this._state.bottom
        );

        this._panCallback();
    }

    _updatePinchOffset(currentPinch, previousPinch) {
        const { zoom } = this._state;
        const change = currentPinch - previousPinch;

        return change * (zoom - 1);
    }

    _onPinchStart(currentPinchX, currentPinchY) {
        const { zoom } = this._state;
        const currentPinchXByZoom = currentPinchX / zoom;
        const currentPinchYByZoom = currentPinchY / zoom;
        this._state.pinchOffsetX += this._updatePinchOffset(
            currentPinchXByZoom,
            this._state.pinchX
        );
        this._state.pinchOffsetY += this._updatePinchOffset(
            currentPinchYByZoom,
            this._state.pinchY
        );

        this._state.pinchX = currentPinchXByZoom;
        this._state.pinchY = currentPinchYByZoom;

        this._pinchStartCallback();
    }

    _synchronizeOffset(newZoom, previousZoom) {
        const { panX, panY, pinchOffsetX, pinchOffsetY } = this._state;
        const zoomChange = previousZoom - newZoom;
        if (zoomChange !== 0) {
            const percentage = (previousZoom - 1) / zoomChange;

            this._state.panX -= panX / percentage;
            this._state.panY -= panY / percentage;

            this._state.pinchOffsetX -= pinchOffsetX / percentage;
            this._state.pinchOffsetY -= pinchOffsetY / percentage;
        }
    }
}

import {
    trackEvent,
    trackEcommerce,
    EventNameValues,
    trackingConstants,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
import serverVars from 'server-vars';
import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';

// types
import { ItemType as RecentHistoryItemType } from './recentHistoryTypes';

const trackInteraction = ({ action, label }: { action: string; label: string }): void => {
    trackEvent({
        category: 'promo interaction',
        action,
        label,
        value: 0,
    });
};

const trackViewMoreCtaClick = ({
    label,
    actionLabel,
}: {
    label: string;
    actionLabel?: string;
}): void => {
    trackInteraction({
        action: `${actionLabel || 'recently viewed'} view more clicked`,
        label,
    });
};

export const trackHpViewMoreCtaClick = (actionLabel?: string): void =>
    trackViewMoreCtaClick({ label: '[homepage]', actionLabel });
export const trackPdpViewMoreCtaClick = (actionLabel?: string): void =>
    trackViewMoreCtaClick({ label: '[pdp]', actionLabel });
export const trackSbViewMoreCtaClick = (actionLabel?: string): void =>
    trackViewMoreCtaClick({ label: '[s&b]', actionLabel });

const pageType: string = serverVars.get('settings.pageType');
export const trackViewMoreClick = ({ actionLabel }: { actionLabel?: string }): void => {
    const pageTypeMap: Record<string, string> = {
        [PAGE_TYPE.HOME]: 'homepage',
        [PAGE_TYPE.BROWSE]: 's&b',
    };

    const label = `[${pageTypeMap[pageType] || pageType}]`;
    trackViewMoreCtaClick({ label, actionLabel });
};

export const trackHeart = ({
    action,
    itemId,
}: {
    action: 'added' | 'removed';
    itemId: string;
}): void => {
    const pageTypeMap: Record<string, string> = {
        [PAGE_TYPE.HOME]: 'homepage',
        [PAGE_TYPE.BROWSE]: 'search/browse',
    };
    const trigger = `${pageTypeMap[pageType] || pageType}-recently viewed`;
    trackEvent(
        {
            action: `item ${action}`,
            category: interactionTypeConstants.INTERACTION_TYPE_ITEM_FAVORITING,
            label: trigger,
            eventName: eventNameConstants.EVENT_WISHLIST,
            interaction_type: interactionTypeConstants.INTERACTION_TYPE_ITEM_FAVORITING,
            step_interaction_name:
                action === 'added'
                    ? stepInteractionConstants.STEP_INTERACTION_ITEM_ADDED
                    : stepInteractionConstants.STEP_INTERACTION_ITEM_REMOVED,
            isInteractiveEvent: true,
            additional: { itemId },
            trigger,
        },
        null
    );
};

type InitialDisplayProp = {
    itemsReturned: string;
    actionLabel?: string;
};
export const trackInitialDisplay = ({ itemsReturned, actionLabel }: InitialDisplayProp): void => {
    trackInteraction({
        action: `${actionLabel || 'recently viewed'} items displayed`,
        label: itemsReturned,
    });
};

type ArrowClickProp = {
    direction: string;
    actionLabel?: string;
};
export const trackArrowClick = ({ direction, actionLabel }: ArrowClickProp): void => {
    trackInteraction({
        action: `${actionLabel || 'recently viewed'} items arrow clicked`,
        label: direction,
    });
};

const { ECOM_PRODUCT_CLICK, ECOM_PRODUCT_IMPRESSION } = trackingConstants;
const { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST } = eventNameConstants;

type RecentItemsProp = {
    type: typeof ECOM_PRODUCT_CLICK | typeof ECOM_PRODUCT_IMPRESSION;
    items: RecentHistoryItemType[];
    startIndex: number;
    variant: string;
    list: string;
    eventName: EventNameValues;
};

export const trackRecentItems = ({
    type,
    items,
    startIndex,
    variant,
    list,
    eventName,
}: RecentItemsProp): void => {
    const actionField: { list: string; itemId?: string } = { list };
    if (eventName === EVENT_SELECT_ITEM) {
        actionField.itemId = items?.[0]?.itemId;
    }
    trackEcommerce({
        type,
        eventName,
        actionField,
        products: items.map((item, index) => ({
            brand: item.dealerPk,
            category: item.itemBrowseUrl || item.category,
            categoryCode: item.itemCategoryCode,
            id: item.itemId,
            variant,
            name: item.itemTitle,
            list,
            dimension83: item.contemporaryTrackingString,
            position: startIndex + index,
        })),
    });
};

type ItemClickProp = {
    item: RecentHistoryItemType;
    index: number;
    actionLabel?: string;
    variant?: string;
};

export const trackItemClick = ({
    item,
    index,
    actionLabel,
    variant = 'products',
}: ItemClickProp): void => {
    trackInteraction({
        action: `${actionLabel || 'recently viewed'} items item clicked`,
        label: 'none',
    });
    trackRecentItems({
        type: ECOM_PRODUCT_CLICK,
        items: [item],
        startIndex: index + 1, // Tracking needs non zero index position.
        variant,
        list: `${actionLabel || 'recently viewed'} items carousel`,
        eventName: EVENT_SELECT_ITEM,
    });
};

type ItemsClickProp = {
    items: [RecentHistoryItemType];
    index: number;
    actionLabel?: string;
    variant?: string;
};

export const trackItemImpression = ({
    items,
    index,
    actionLabel,
    variant = 'products',
}: ItemsClickProp): void => {
    trackRecentItems({
        type: ECOM_PRODUCT_IMPRESSION,
        items: items,
        startIndex: index + 1, // Tracking needs non zero index position.
        variant,
        list: `${actionLabel || 'recently viewed'} items carousel`,
        eventName: EVENT_VIEW_ITEM_LIST,
    });
};

/**
 * @generated SignedSource<<6503c277b0f48e46837e37b527b0e10e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BottomBar_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SiteNav_viewer">;
  readonly " $fragmentType": "BottomBar_viewer";
};
export type BottomBar_viewer$key = {
  readonly " $data"?: BottomBar_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BottomBar_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BottomBar_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteNav_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "5be0e6dea8a08175c40f68b833ac7bf6";

export default node;

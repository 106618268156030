import { Price_item$data as ItemType } from '../__generated__/Price_item.graphql';

type ConvertedAmount = NonNullable<
    NonNullable<NonNullable<ItemType['displayPrice']>[0]>['convertedAmountList']
>[0];
type ConvertedAmountList = readonly ConvertedAmount[];

type Props = {
    convertedAmountList: ConvertedAmountList;
    currency: string;
};

export const findConvertedAmount = ({
    convertedAmountList,
    currency,
}: Props): ConvertedAmount | null | undefined =>
    (convertedAmountList || []).find(convertedAmount => convertedAmount?.currency === currency);

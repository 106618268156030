export function connectContext(Context, mapContextToProps) {
    return Component => {
        const WrappedContextComponent = props => (
            <Context.Consumer>
                {context => <Component {...mapContextToProps(context)} {...props} />}
            </Context.Consumer>
        );

        const componentName = Component.displayName || Component.name;
        const providerName = Context.displayName || Context.name;
        const wrapperName = `${providerName}(${componentName})`;
        WrappedContextComponent.displayName = wrapperName;

        return WrappedContextComponent;
    };
}

import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type CheckmarkProps = {
    className?: string;
};

const Checkmark: FunctionComponent<CheckmarkProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'checkmark'}
            viewBox="0 0 250 250"
        >
            <polygon points="86.1,218.4 2.2,140.1 28.8,112 86.1,165.5 222.7,37.9 248.8,65.4" />
        </svg>
    );
};
export default Checkmark;

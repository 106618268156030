import { FC } from 'react';
import classNames from 'classnames';

import styles from './SharedCarouselDot.scss';

type Props = {
    isActive?: boolean;
    onClick?: () => void;
};

export const SharedCarouselDot: FC<Props> = ({ isActive = false, onClick = () => {} }) => {
    return (
        <span
            className={classNames(styles.dot, {
                [styles.activeDot]: isActive,
            })}
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyPress={onClick}
        />
    );
};

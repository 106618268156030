import { SALESFORCE_LIVE_CHAT_CONTAINER_ID } from '../constants';

export function getSfChatContainer(): HTMLElement | null {
    return document.getElementById(SALESFORCE_LIVE_CHAT_CONTAINER_ID);
}

export function createSfChatContainer(): void {
    if (!getSfChatContainer()) {
        const sfChatContainer = document.createElement('div');
        sfChatContainer.setAttribute('id', SALESFORCE_LIVE_CHAT_CONTAINER_ID);
        document.body.appendChild(sfChatContainer);
    }
}

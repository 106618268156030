import { FunctionComponent } from 'react';
import { useIntl } from 'dibs-react-intl';
import { formatRelativeDate } from './formatRelativeDate';

type Props = {
    value: Date | string;
};

const RelativeDateFormat: FunctionComponent<Props> = ({ value }) => {
    const intl = useIntl();
    return (
        <span
            title={intl.formatDate(value, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            })}
        >
            {formatRelativeDate(intl, value)}
        </span>
    );
};

export default RelativeDateFormat;

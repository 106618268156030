import { graphql } from 'react-relay/legacy';

export const initAutopilotQuery = graphql`
    query initAutopilotQuery($anchors: [String!]) {
        viewer {
            anchorLists(listNames: ["LEAD_NURTURING_BUYER_WHITELIST"], anchors: $anchors) {
                __typename
            }
            disableAutopilotIntegration: isEnabled(key: "disableAutopilotIntegration")
        }
    }
`;

import { useId, FC, useEffect, useState, MouseEventHandler } from 'react';
import { useFragment, graphql } from 'react-relay';
import { defineMessages, useIntl } from 'dibs-react-intl';

import classnames from 'classnames';
import AlertBellIcon from 'dibs-icons/exports/legacy/AlertBell';
import { Link } from 'dibs-elements/exports/Link';
import styles from './Notifications-style.scss';

import { LOCAL_STORAGE_KEY_NAME } from './Notifications';
import { wasPromoCodeNotificationShown } from '../../utils/promoCode.es';

import { NotificationsIcon_viewer$key } from './__generated__/NotificationsIcon_viewer.graphql';
import {
    NotificationsIcon_user$data,
    NotificationsIcon_user$key,
} from './__generated__/NotificationsIcon_user.graphql';

type Props = {
    viewer: NotificationsIcon_viewer$key;
    user: NotificationsIcon_user$key;
    lastUnreadNotificationDate: string;
    onClick: MouseEventHandler;
    onMouseEnter: MouseEventHandler;
    hasUnread?: boolean;
    isOpen: boolean;
    forceOpened: boolean;
    onActivePromotionFetched: (
        activePromotion: NotificationsIcon_user$data['activePromotion']
    ) => void;
};
const messages = defineMessages({
    label: {
        id: 'dbl.Header.NotificationsIcon.label',
        defaultMessage: 'Notifications',
    },
    description: {
        id: 'dbl.Header.NotificationsIcon.description',
        defaultMessage: 'Unread messages',
    },
});

const hasNewNotification = (
    lastUnreadMessageDate?: string | null,
    lastUnreadNotificationDate?: string | null
): boolean => {
    const mostRecentMessageReceivedAt = lastUnreadMessageDate
        ? new Date(lastUnreadMessageDate).getTime()
        : 0;
    const mostRecentNotificationReceivedAt = lastUnreadNotificationDate
        ? new Date(lastUnreadNotificationDate).getTime()
        : 0;

    const mostRecent = Math.max(mostRecentMessageReceivedAt, mostRecentNotificationReceivedAt);
    const lastOpen = Number(localStorage.getItem(LOCAL_STORAGE_KEY_NAME)) || 0;
    return mostRecent > lastOpen;
};

const NotificationsIcon: FC<Props> = ({
    onClick,
    isOpen,
    onMouseEnter,
    viewer: viewerRef,
    user: userRef,
    onActivePromotionFetched,
}) => {
    const viewer = useFragment(
        graphql`
            fragment NotificationsIcon_viewer on Viewer {
                conversationsSummary(userId: $userId) @include(if: $hasUserId) {
                    lastUnreadMessageDate
                }
            }
        `,
        viewerRef
    );

    const user = useFragment(
        graphql`
            fragment NotificationsIcon_user on User {
                activePromotion {
                    serviceId
                }
                activityFeed(
                    first: 3
                    elementTypes: [
                        PENDING_FEEDBACK
                        TRADE_REWARD
                        TRADE_REWARD_PRORATED
                        TRADE_REWARD_PROGRAM
                        AUTOMATED_OFFER
                    ]
                ) {
                    edges {
                        node {
                            type
                            eventDate
                        }
                    }
                }
            }
        `,
        userRef
    );

    const htmlId = useId();
    const intl = useIntl();

    const { activePromotion, activityFeed } = user || {};

    const notifications = activityFeed?.edges || [];
    const lastNotification = notifications.find(() => true);
    const lastUnreadNotificationDate = lastNotification?.node?.eventDate;
    const lastUnreadMessageDate = viewer?.conversationsSummary?.lastUnreadMessageDate;
    const hasNew = hasNewNotification(lastUnreadMessageDate, lastUnreadNotificationDate);

    const hasUnread =
        hasNew || (activePromotion && !wasPromoCodeNotificationShown(activePromotion));

    /**
     * Since we can only @defer fragment spreads (and not fields), Notifications cannot fetch activePromotion while deferring it.
     * Here we're letting Notifications know that activePromotion has been fetched and is ready to use.
     */
    const [parentNotified, setParentNotified] = useState(false);
    useEffect(() => {
        if (!parentNotified && user.activePromotion) {
            onActivePromotionFetched(user.activePromotion);
            setParentNotified(true);
        }
    }, [user, onActivePromotionFetched, parentNotified, setParentNotified]);

    const iconClassName = classnames(styles.icon, {
        [styles.hasUnread]: hasUnread,
    });

    return (
        <Link
            ariaLabel={intl.formatMessage(messages.label)}
            ariaDescribedBy={hasUnread ? htmlId : undefined}
            ariaExpanded={isOpen}
            ariaHasPopup
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            dataTn={`notifications-link${hasUnread ? '-unread' : ''}`}
            className={iconClassName}
        >
            {hasUnread && htmlId && (
                <span id={htmlId} className={styles.hidden}>
                    {intl.formatMessage(messages.description)}
                </span>
            )}
            <AlertBellIcon />
        </Link>
    );
};

export default NotificationsIcon;

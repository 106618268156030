import { FC, ReactNode, useMemo, useCallback } from 'react';
import classNames from 'classnames';

import { useInternalScrollCarousel } from '../helpers/InternalScrollCarouselContext';
import { ScrollCarouselItemProvider } from '../helpers/ScrollCarouselItemContext';
import { getGapClassName } from '../helpers/gapStyles/getGapClassName';
import { adjustFlexBasis } from '../helpers/gapStyles/adjustFlexBasis';

import styles from './CarouselItem.scss';

export const CarouselItem: FC<{
    children: ReactNode;
    //is used to determine the snap position of the item
    snapIndex: number;
    //is used to determine the index of the item
    trueIndex: number;
}> = ({ children, snapIndex, trueIndex }) => {
    const {
        itemsToShow,
        stepSize,
        totalItems,
        isEndSnapScroll,
        currentIndex,
        setCurrentIndex,
        hasMultipleSlides,
        gapSize,
        scrollPadding,
        carouselOrientation,
    } = useInternalScrollCarousel();

    const endIndex = totalItems - 1;
    const lastIndexForStartSnap = endIndex - (totalItems % stepSize);

    /**
     * On full page scroll carousels don't apply start snap on last item if the page is not full. This ensures
     * better overall scroll experience.
     */
    const willApplyEndSnap = isEndSnapScroll && snapIndex > lastIndexForStartSnap;

    const setActive = useCallback(() => {
        setCurrentIndex(trueIndex);
    }, [setCurrentIndex, trueIndex]);

    const contextValue = useMemo(
        () => ({
            isActive:
                trueIndex === currentIndex ||
                //if carousel doesn't have enough items to fill the page, but sync is enabled, we need to adjust the active item
                (!hasMultipleSlides && trueIndex === currentIndex - totalItems),
            setActive,
        }),
        [trueIndex, currentIndex, setActive, hasMultipleSlides, totalItems]
    );

    return (
        <ScrollCarouselItemProvider value={contextValue}>
            <div
                className={classNames(
                    styles.carouselItem,
                    styles[getGapClassName({ gapSize, orientation: carouselOrientation })],
                    {
                        [styles.carouselSnapItemStart]:
                            !willApplyEndSnap && snapIndex % stepSize === 0,
                        [styles.carouselSnapItemEnd]: willApplyEndSnap && snapIndex === endIndex,
                    }
                )}
                style={{
                    flexBasis: adjustFlexBasis({
                        scrollPadding,
                        gapSize,
                        itemsToShow,
                    }),
                }}
            >
                {children}
            </div>
        </ScrollCarouselItemProvider>
    );
};

import { FormattedMessage } from 'dibs-react-intl';

export const onHoldLabel = holdType => (
    <FormattedMessage
        values={{ holdType }}
        id="priceMessages.onHoldLabel"
        defaultMessage={`{holdType, select,
                HOLD {On Hold}
                HOLD_CUSTOM {On Hold For You}
                other {On Hold}
            }`}
    />
);

export const textPriceValue = textPriceType => (
    <FormattedMessage
        values={{ textPriceType }}
        id="priceMessages.textPriceValue"
        defaultMessage={`{textPriceType, select,
            SOLD {Sold}
            UNAVAILABLE {Unavailable}
            PUR {Price Upon Request}
            other {Sold}
        }`}
    />
);

export const priceLabel = type => (
    <FormattedMessage
        values={{ type }}
        id="priceMessages.label"
        defaultMessage={`{type, select,
            NET {Net Price}
            RETAIL {List Price}
            PRIVATE_OFFER {Private Offer}
            FINAL {Final Price}
            other {Asking Price}
        }`}
    />
);

export const discountLabel = discount => (
    <FormattedMessage
        values={{ discount }}
        id="priceMessages.discount"
        defaultMessage="{discount}% Off"
    />
);

import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import styles from './HpSharedModuleContainer.scss';

type Props = {
    children: ReactNode;
    addTopGap?: boolean;
    className?: string;
    dataTn?: string;
};

export const HpSharedModuleContainer: FC<Props> = ({ children, addTopGap, className, dataTn }) => {
    const classNames = classnames({ [styles.addTopGap]: addTopGap }, className);
    return (
        <div data-tn={dataTn} className={classNames}>
            {children}
        </div>
    );
};

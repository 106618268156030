/**
 * @generated SignedSource<<25a2c8aa21d6f72a986dc446a8f79030>>
 * @relayHash 83daaced7fe6f834074db6015ffa5436
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/494.0.0-2025-01-09T20:04:30.590Z/LikesRefetchQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LikesRefetchQuery$variables = {
  createdAfter: string;
  eventsLimit: number;
  fetchNewEvents: boolean;
  fetchNotifications: boolean;
  notificationItemsLimit: number;
  userId: string;
};
export type LikesRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"Likes_viewer">;
  };
};
export type LikesRefetchQuery = {
  response: LikesRefetchQuery$data;
  variables: LikesRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "createdAfter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "eventsLimit"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fetchNewEvents"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fetchNotifications"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "notificationItemsLimit"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "createdAfter",
  "variableName": "createdAfter"
},
v2 = {
  "kind": "Variable",
  "name": "eventsLimit",
  "variableName": "eventsLimit"
},
v3 = {
  "kind": "Variable",
  "name": "notificationItemsLimit",
  "variableName": "notificationItemsLimit"
},
v4 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LikesRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Variable",
                "name": "fetchNewEvents",
                "variableName": "fetchNewEvents"
              },
              {
                "kind": "Variable",
                "name": "fetchNotifications",
                "variableName": "fetchNotifications"
              },
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "Likes_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LikesRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "fetchNewEvents",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "FavoriteNotifications",
                "kind": "LinkedField",
                "name": "favoriteNotifications",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "kind": "ScalarField",
                    "name": "hasNewEvents",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "fetchNotifications",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "favoriteNotificationsTiles",
                "args": (v4/*: any*/),
                "concreteType": "FavoriteNotifications",
                "kind": "LinkedField",
                "name": "favoriteNotifications",
                "plural": false,
                "selections": [
                  {
                    "alias": "tiles",
                    "args": [
                      (v1/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "Variable",
                        "name": "notificationsLimit",
                        "variableName": "eventsLimit"
                      }
                    ],
                    "concreteType": "FavoriteNotification",
                    "kind": "LinkedField",
                    "name": "notificationsList",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "eventType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastViewedDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "followingType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "page",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalResults",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Item",
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "pdpURL",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Seller",
                            "kind": "LinkedField",
                            "name": "seller",
                            "plural": false,
                            "selections": [
                              {
                                "alias": "isRewarded",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "listNames",
                                    "value": [
                                      "SELLER_REWARDS_1",
                                      "SELLER_REWARDS_2"
                                    ]
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "isAnchorListsMember",
                                "storageKey": "isAnchorListsMember(listNames:[\"SELLER_REWARDS_1\",\"SELLER_REWARDS_2\"])"
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "size",
                                "value": "thumb"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "firstPhotoWebPath",
                            "storageKey": "firstPhotoWebPath(size:\"thumb\")"
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeFirmType",
                    "kind": "LinkedField",
                    "name": "tradeFirm",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TradeFirmRewards",
                        "kind": "LinkedField",
                        "name": "rewards",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ParentProgram",
                            "kind": "LinkedField",
                            "name": "currentProgram",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LoyaltyProgram",
                                "kind": "LinkedField",
                                "name": "programInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "code",
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/494.0.0-2025-01-09T20:04:30.590Z/LikesRefetchQuery",
    "metadata": {},
    "name": "LikesRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ab2f3c4d7c41daccff0f4fa979704e09";

export default node;

import { sessionStorage, localStorage } from 'dibs-browser-storage';
import { ArrayElement } from 'dibs-ts-utils/exports/ArrayElement';
import { Mutable } from 'dibs-ts-utils/exports/Mutable';
import Cookies from 'js-cookie';
import SV from 'server-vars';
import { Environment } from 'react-relay';
import { setUserPriceBookNameCookie } from './setUserPriceBookNameCookie';

import { userGeoInfoQuery$data as GeoInfoQueryType } from './__generated__/userGeoInfoQuery.graphql';

type RegionalInfoType = ArrayElement<GeoInfoQueryType['viewer']['regionalInfo']>;
type ZipCodes = NonNullable<RegionalInfoType>['regionsByZipCode'];
type UserGeoInfo = RegionalInfoType & { timestamp: number };
export type RegionsByZipCode = {
    displayName: string;
    urlLabel: string;
};

export const USER_GEO_INFO = 'userGeoInfo';
// valid period is set to 30 days
const USER_ZIP_CODE_VALID_PERIOD = 2592000000;
// one day
const USER_ZIP_CODE_VALID_SHORT_PERIOD = 86400000;

const setUserGeoInfo = async ({
    userGeoInfo,
    environment,
    useLocalStorage,
}: {
    userGeoInfo?: RegionalInfoType;
    environment?: Environment;
    useLocalStorage?: boolean; // use localStorage only when user is doing manual updates
}): Promise<void> => {
    const timestamp = Date.now();

    const { countryCode, zipCode, incomeLevel, regionsByZipCode } = userGeoInfo || {};
    const { incomeBracket, incomePercentile } = incomeLevel || {};

    const storage = useLocalStorage ? localStorage : sessionStorage;
    storage.setItem(USER_GEO_INFO, {
        timestamp,
        countryCode,
        zipCode,
        incomeLevel: {
            incomeBracket,
            incomePercentile,
        },
        regionsByZipCode: regionsByZipCode as Mutable<ZipCodes>,
    });

    if (environment && countryCode) {
        await setUserPriceBookNameCookie(environment, {
            countryCode,
            cookieDomain: SV.get('cookieDomain'),
            zipCode,
        });
    }
};

const getUserGeoInfo = (): UserGeoInfo | null => {
    let userGeoInfo = localStorage.getItem(USER_GEO_INFO);
    if (userGeoInfo) {
        return userGeoInfo as UserGeoInfo;
    }
    userGeoInfo = sessionStorage.getItem(USER_GEO_INFO);
    if (userGeoInfo) {
        return userGeoInfo as UserGeoInfo;
    }
    return null;
};

const hasValidUserSessionGeoInfo = (): boolean => {
    const geoInfo = getUserGeoInfo();
    const { zipCode, countryCode, incomeLevel, timestamp } = geoInfo || {};

    // null is valid zipCode set with region selector or for private IP addresses
    if ((zipCode || zipCode === null) && countryCode && timestamp) {
        const timeDiff = Date.now() - timestamp;

        const validPeriod =
            zipCode === null ? USER_ZIP_CODE_VALID_SHORT_PERIOD : USER_ZIP_CODE_VALID_PERIOD;

        // Checking for users which has valid zipCode
        // But doesn't have incomeLevel
        // Will fetch incomeLevel with zipCode stored in localStorage as arg
        if (typeof incomeLevel === 'undefined' && zipCode) {
            return false;
        }

        if (!timeDiff || timeDiff <= validPeriod) {
            return true;
        }
    }
    return false;
};

const hasRegionsCookieValue = (): boolean => {
    return !!Cookies.get('regions');
};

const hasPriceBookNameCookieValue = (): boolean => {
    return !!Cookies.get('priceBookName');
};

const hasRegionsListCookieValue = (): boolean => !!Cookies.get('regionsList');

const getRegionsList = (): string[] | undefined => {
    const regionsList = Cookies.get('regionsList');

    if (regionsList) {
        return regionsList.split(',');
    }

    return undefined;
};

const getUserSessionCountryCode = (): string => {
    const userGeoInfo = getUserGeoInfo();
    return userGeoInfo?.countryCode || '';
};

const getUserZipCode = (): string => {
    const userGeoInfo = getUserGeoInfo();
    return userGeoInfo?.zipCode || '';
};

const getUserSessionRegions = (): RegionsByZipCode[] | undefined => {
    const userGeoInfo = getUserGeoInfo();
    return userGeoInfo?.regionsByZipCode?.map<RegionsByZipCode>(region => ({
        displayName: region?.displayName || '',
        urlLabel: region?.urlLabel || '',
    }));
};

const incomeLevelMap: Record<string, string> = {
    higher: 'tier 1',
    high: 'tier 2',
    mid: 'tier 3',
    low: 'tier 4',
};

const getIncomeLevelInfo = (regionalInfo: RegionalInfoType): string | undefined => {
    const geoInfo = getUserGeoInfo();
    const incomeLevel = geoInfo?.incomeLevel || regionalInfo?.incomeLevel;
    const { incomeBracket, incomePercentile } = incomeLevel || {};
    return incomeBracket && incomePercentile
        ? `${incomeLevelMap[incomeBracket.toLowerCase()]}| ${incomePercentile}`
        : undefined;
};

export {
    getUserZipCode,
    getIncomeLevelInfo,
    getUserSessionRegions,
    setUserGeoInfo,
    getUserGeoInfo,
    hasValidUserSessionGeoInfo,
    hasRegionsCookieValue,
    hasRegionsListCookieValue,
    getRegionsList,
    getUserSessionCountryCode,
    hasPriceBookNameCookieValue,
};

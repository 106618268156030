import { useState, useEffect } from 'react';
import { getAbTestV2, trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';

export const ACTIONABLE_REGISTRATION_COPY_AB_TEST_NAME = 'actionableRegistrationCopy';

export const useActionableRegistrationCopyClientVariant = (): boolean | null => {
    const [isActionableRegistrationCopyClient, setIsActionableRegistrationCopyVariant] = useState<
        boolean | null
    >(null);

    useEffect(() => {
        setIsActionableRegistrationCopyVariant(
            getAbTestV2(ACTIONABLE_REGISTRATION_COPY_AB_TEST_NAME)?.variant === 'variant'
        );
    }, []);

    return isActionableRegistrationCopyClient;
};

export const trackAactionableRegistrationCopyAbTestVariant = (): void => {
    trackAbTestV2Variant(ACTIONABLE_REGISTRATION_COPY_AB_TEST_NAME);
};

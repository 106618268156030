import { FC, ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { Environment, RelayEnvironmentProvider } from 'react-relay';

import { IntlProvider } from 'dibs-react-intl';
import serverVars from 'server-vars';

import { HeaderFooterServerVarsContextProvider } from '../components/HeaderFooterServerVarsContext/HeaderFooterServerVarsContext';
import { actionCreators } from '../actions';
import {
    isHideMwMarketingBannerTestVariant,
    trackHideMwMarketingBannerAbTestVariant,
} from '../utils/abTest/marketingBannerAbTestHelpers';

const getBuyerFooterClient = async ({
    store,
    relayEnv,
}: {
    store: Store;
    relayEnv: Environment;
}): Promise<{
    updateUserState: () => void;
}> => {
    const locale = serverVars.get('locale');
    const messages = serverVars.get('messages');
    const isMobile: boolean = serverVars.get('settings.isMobile');
    const buyerHost: string = serverVars.get('buyerHost');

    const Wrapper: FC<{ children?: ReactNode }> = ({ children }) => {
        return (
            <IntlProvider locale={locale} defaultLocale="en" messages={messages}>
                <RelayEnvironmentProvider environment={relayEnv}>
                    <HeaderFooterServerVarsContextProvider
                        isMobile={isMobile}
                        buyerHost={buyerHost}
                    >
                        <Provider store={store}>{children}</Provider>
                    </HeaderFooterServerVarsContextProvider>
                </RelayEnvironmentProvider>
            </IntlProvider>
        );
    };

    if (isMobile) {
        trackHideMwMarketingBannerAbTestVariant();
    }

    if (isHideMwMarketingBannerTestVariant()) {
        let nav;
        if (isMobile) {
            nav = await import(/* webpackChunkName: "mobileFooter" */ '../components/mobileFooter');
        } else {
            nav = await import(
                /* webpackChunkName: "responsiveFooter" */ '../components/responsiveFooter'
            );
        }
        const node = document.getElementById('js-footer');
        if (node) {
            await nav.render({ node, Wrapper });
        }
    } else if (isMobile) {
        const navPromise = await import(
            /* webpackChunkName: "mobileFooter" */ '../components/mobileFooter'
        );
        const node = document.getElementById('js-footer');

        const messageBannerPromise = import(
            /* webpackChunkName: "MobileFooterMessageBanner" */ '../components/MessageBanner/MobileFooterMessageBanner'
        );

        const [nav, { default: MobileFooterMessageBanner }] = await Promise.all([
            navPromise,
            messageBannerPromise,
        ]);

        if (node) {
            const div = document.createElement('div');
            div.className = 'footer-message-banner';
            node.parentNode?.insertBefore(div, node.nextSibling);
            const root = createRoot(div, { identifierPrefix: 'footer' });
            root.render(
                <Wrapper>
                    <MobileFooterMessageBanner environment={relayEnv} />
                </Wrapper>
            );
            await nav.render({ node, Wrapper });
        }
    } else {
        const nav = await import(
            /* webpackChunkName: "responsiveFooter" */ '../components/responsiveFooter'
        );
        const node = document.getElementById('js-footer');
        if (node) {
            await nav.render({ node, Wrapper });
        }
    }

    return {
        updateUserState: store.dispatch.bind(null, actionCreators.setUpdateUserState(true)),
    };
};

export { getBuyerFooterClient };

/**
 * @generated SignedSource<<c93677b08b319651c673e797b6a01695>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductSwiper_item$data = {
  readonly carouselPhotos: ReadonlyArray<{
    readonly masterOrZoomPath: string | null;
    readonly smallPath: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"SwiperItem_photo">;
  } | null> | null;
  readonly hasApprovedVideo?: boolean | null;
  readonly serviceId: string | null;
  readonly viewInRoomPhotos: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ViewInRoomImage_viewInRoomPhoto">;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"ViewInRoomImage_item">;
  readonly " $fragmentType": "ProductSwiper_item";
};
export type ProductSwiper_item$key = {
  readonly " $data"?: ProductSwiper_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductSwiper_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fetchVideo"
    },
    {
      "defaultValue": 3,
      "kind": "LocalArgument",
      "name": "photosLimit"
    },
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "viewInRoomLimit"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductSwiper_item",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ViewInRoomImage_item"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": "carouselPhotos",
      "args": [
        {
          "kind": "Variable",
          "name": "limit",
          "variableName": "photosLimit"
        }
      ],
      "concreteType": "ItemPhoto",
      "kind": "LinkedField",
      "name": "photos",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SwiperItem_photo"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "smallPath",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "masterOrZoomPath",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "fetchVideo",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasApprovedVideo",
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "limit",
          "variableName": "viewInRoomLimit"
        }
      ],
      "concreteType": "viewInRoomPhotos",
      "kind": "LinkedField",
      "name": "viewInRoomPhotos",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ViewInRoomImage_viewInRoomPhoto"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "c9d4c6a820ee60d429417b250073b13b";

export default node;

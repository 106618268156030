/* eslint-disable no-var */
//eslint-disable-next-line strict
'use strict';
var getUserAgent = function () {
    return window.navigator && window.navigator.userAgent;
};

/**
 * Detect if user is on mobile device
 * this is a jenky solution since it relies on the user agent
 * it should rely on feature detection instead
 */
module.exports = {
    Android: function (ua) {
        return /Android/i.test(ua || getUserAgent());
    },
    BlackBerry: function (ua) {
        return /BlackBerry/i.test(ua || getUserAgent());
    },
    BlackBerry10: function (ua) {
        return /BB10/i.test(ua || getUserAgent());
    },
    iPhone: function (ua) {
        return /iPhone/i.test(ua || getUserAgent());
    },
    iPad: function (ua) {
        // iPad Safari now mimicks desktop and additional check against maxTouchPoints is suggested.
        // It is not perfect solution, but best I could find.
        // maxTouchPoints > 2 is required, because iPhone 2-7 returns 2 maxTouchPoints.
        // https://stackoverflow.com/questions/57765958/how-to-detect-ipad-and-ipad-os-version-in-ios-13-and-up
        const userAgent = ua || getUserAgent();
        const isIpad = /iPad/i.test(userAgent);
        if (
            !isIpad &&
            /Macintosh/i.test(userAgent) &&
            navigator.maxTouchPoints &&
            navigator.maxTouchPoints > 2
        ) {
            return true;
        }
        return isIpad;
    },
    iPod: function (ua) {
        return /iPod/i.test(ua || getUserAgent());
    },
    iOS: function (ua) {
        return this.iPhone(ua) || this.iPad(ua) || this.iPod(ua);
    },
    Opera: function (ua) {
        return /Opera Mobi/i.test(ua || getUserAgent());
    },
    Windows: function (ua) {
        return /IEMobile/i.test(ua || getUserAgent());
    },
    iOSVersion: function (version, ua) {
        var r = new RegExp('(iPad|iPhone|iPod);.*CPU.*OS ' + version + '_\\d', 'i');
        return r.test(ua || getUserAgent());
    },
    any: function (ua) {
        var isMobile = false;
        var blacklist = ['hasTouch', 'isTouchDevice', 'any', 'iOSVersion'];
        var p;

        for (p in this) {
            if (this.hasOwnProperty(p) && blacklist.indexOf(p) === -1) {
                isMobile = this[p](ua || getUserAgent());
                if (isMobile) {
                    return isMobile;
                }
            }
        }
        return isMobile;
    },
    /**
     * @returns {boolean}
     */
    hasTouch: function () {
        // http://stackoverflow.com/a/4819886
        // reference  GFoley83 Sep 4 '13 at 6:20 comment
        //
        // added non-prefixed maxTouchPoints based on this post:
        // http://msdn.microsoft.com/en-us/library/ie/hh772144%28v=vs.85%29.aspx
        if (
            (window.navigator &&
                (!!window.navigator.msMaxTouchPoints || !!window.navigator.maxTouchPoints)) ||
            'ontouchstart' in window
        ) {
            return true;
        }

        // reference bolmaster2 UPDATE 2018 (Modernizr copy)
        // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
        const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        // Include optional chaining and fallback to fix cases where mocking fails in test
        return window.matchMedia(query)?.matches || false; // same as mq(query) in example
    },
};

//DETECT TOUCH CAPABILITIES

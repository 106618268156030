import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';

import { Badge } from 'dibs-elements/exports/Badge';

import { MeetsRecommendedPricingBadge_item$key } from './__generated__/MeetsRecommendedPricingBadge_item.graphql';

const MeetsRecommendedPricingBadge: FC<{
    item: MeetsRecommendedPricingBadge_item$key | null | undefined;
    wrapperClass?: string;
}> = ({ item: itemRef, wrapperClass }) => {
    const item = useFragment(
        graphql`
            fragment MeetsRecommendedPricingBadge_item on Item {
                meetsRecommendedPricing
            }
        `,
        itemRef
    );

    const meetsRecommendedPricing = item?.meetsRecommendedPricing;

    if (!meetsRecommendedPricing) {
        return null;
    }

    return (
        <div className={wrapperClass}>
            <Badge type="pill" size="small-heavy">
                Meets Pricing
            </Badge>
        </div>
    );
};

export default MeetsRecommendedPricingBadge;

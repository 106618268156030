import * as storage from 'dibs-browser-storage';
import { getUserId, cookieJarConstants } from 'dibs-cookie-jar';
const { MASQUERADE_BUYER } = cookieJarConstants;
import {
    KEY_PRE_REGISTRATION_PAGE_VISIT,
    TYPE_PRE_REGISTRATION_PAGE_VISIT,
    CAP_PRE_REGISTRATION_PAGE_VISIT,
} from './visitCountConstants';

function prepareTimeNr(number) {
    const num = Math.abs(Math.floor(number));
    return (num < 10 ? '0' : '') + num;
}

function getDateTime() {
    const now = new Date();
    const timezoneOffset = -now.getTimezoneOffset();
    const dif = timezoneOffset >= 0 ? '+' : '-';

    const date =
        now.getFullYear() +
        '-' +
        prepareTimeNr(now.getMonth() + 1) +
        '-' +
        prepareTimeNr(now.getDate()) +
        'T' +
        prepareTimeNr(now.getHours()) +
        ':' +
        prepareTimeNr(now.getMinutes()) +
        ':' +
        prepareTimeNr(now.getSeconds()) +
        '.' +
        now.getMilliseconds() +
        dif +
        prepareTimeNr(timezoneOffset / 60) +
        ':' +
        prepareTimeNr(timezoneOffset % 60);

    return {
        date,
        time: now.getTime(),
    };
}

function getSourceAttributionFromStorage() {
    return storage.localStorage.getItem(KEY_PRE_REGISTRATION_PAGE_VISIT) || [];
}

function prepareSourceAttributionToSave(element) {
    delete element.time;
    return element;
}

export function getSourceAttribution() {
    const sourceAttribution = getSourceAttributionFromStorage();
    return sourceAttribution.map(prepareSourceAttributionToSave);
}

export function clearSourceAttribution() {
    storage.localStorage.deleteItem(KEY_PRE_REGISTRATION_PAGE_VISIT);
}

export function trackPreRegistrationPageVisit(page) {
    if (getUserId(document.cookie, MASQUERADE_BUYER)) {
        return;
    }

    page = page || window.location.pathname;
    let sourceAttribution = getSourceAttributionFromStorage();
    const attributionElement = sourceAttribution.filter(
        ({ utmParams: { landingPage } }) => landingPage === page
    )[0];

    if (attributionElement) {
        attributionElement.utmParams.visitCount++;

        Object.assign(attributionElement, getDateTime());
    } else {
        const atribution = Object.assign(getDateTime(), {
            type: TYPE_PRE_REGISTRATION_PAGE_VISIT,
            utmParams: {
                landingPage: page,
                visitCount: 1,
            },
        });
        sourceAttribution.push(atribution);
    }

    sourceAttribution = sourceAttribution
        .sort((a, b) => {
            if (a.time < b.time) {
                return 1;
            } else if (a.time > b.time) {
                return -1;
            }
            return 0;
        })
        .slice(0, CAP_PRE_REGISTRATION_PAGE_VISIT);

    storage.localStorage.setItem(KEY_PRE_REGISTRATION_PAGE_VISIT, sourceAttribution);
}

import SV from 'server-vars';

import { trackPageview } from './pageView';
import { addUserAttributes } from './userAttributes';

type Options = {
    pageSubType?: string;
    itemId?: string;
    buyerId?: string;
};

export function trackSellerPageview(options?: Options): void {
    const { pageSubType, itemId, buyerId: buyerID } = options ?? {};
    const sellerData = SV.get('sellerData');
    const { sellerProfile, sellerPreferences, id: dealerID, vertical } = sellerData ?? {};
    const { email, company } = sellerProfile ?? {};
    const { currency } = sellerPreferences ?? {};

    addUserAttributes({
        userID: dealerID,
        userEmailAddress: email,
        defaultCurrency: currency,
    });

    trackPageview({
        additional: {
            pageType: SV.get('tracking.pageType'),
            loginStatus: sellerData ? 'logged in' : 'not logged in',
            dealerID, // must be dealerID with capital ID to match the field name in GTM
            dealerName: company,
            dealerVertical: vertical,
            pageSubType,
            itemId,
            buyerID,
        },
    });
}

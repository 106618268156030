import { graphql, fetchQuery_DEPRECATED as fetchQuery } from 'react-relay/legacy';
import { useEffect } from 'react';
import { useFragment } from 'react-relay';
import * as tracking from 'dibs-tracking';
import { getEverLoggedIn, getBuyerId, getBuyerToken, isMasquerading } from 'dibs-cookie-jar';
import { localStorage, sessionStorage } from 'dibs-browser-storage';
import { trackPreRegistrationPageVisit } from 'dibs-visit-tracking/exports/sourceAttribution';

import {
    getIncomeLevelInfo,
    hasValidUserSessionGeoInfo,
} from 'dibs-regional-info/exports/regionalInfoHelpers';
import { mobile } from 'dibs-client-check';
import { unmaskFragmentData } from '../helpers/relayUnmaskingHelper';
import { handleGuestId, getGuestId } from './guestIdStorage';
import {
    isTradeCampaign,
    isBuyerCampaign,
    getEmailTokenFromQuery,
} from '../helpers/queryParamsHelpers';
import { formatDate } from '../helpers/trackingHelpers';

import { USER_EMAIL_TOKEN } from './userTrackingHelpers/constants';
import { getEmailToken } from './userTrackingHelpers/getEmailToken';
import { getCurrentAccountType } from './userTrackingHelpers/getCurrentAccountType';

const STORAGE_TRANSACTIONS_STATUS = 'userTransactionsStatus';
const STORAGE_CHECKOUT_STATUS = 'userCheckoutStatus';
const STORAGE_TRADE_FIRM_ID = 'tradeFirmId';
const BUYER_STATUS_NONE = 'NONE';
const BUYER_STATUS_CONFIRMED_MULTIPLE = 'CONFIRMED_MULTIPLE';
const BUYER_STATUS_CONFIRMED_ONE = 'CONFIRMED_ONE';
const BUYER_STATUS_SUBMITTED = 'SUBMITTED';
const BUYER_STATUS_SUBMITTED_DIBS_CAMPAIGN = 'SUBMITTED_DIBS_CAMPAIGN';
const BUYER_STATUSES_MAP = {
    [BUYER_STATUS_CONFIRMED_MULTIPLE]: 'submitted|multiple confirmed',
    [BUYER_STATUS_CONFIRMED_ONE]: 'submitted|one confirmed',
    [BUYER_STATUS_SUBMITTED]: 'submitted|none confirmed',
    [BUYER_STATUS_SUBMITTED_DIBS_CAMPAIGN]: 'submitted|unknown confirmed',
    [BUYER_STATUS_NONE]: 'no orders',
};

export const CUSTOMER_TYPE_TRADE = 'Trade';
export const CUSTOMER_TYPE_VIP = 'VIP';
export const CUSTOMER_TYPE_CONSUMER = 'Consumer';
export const CUSTOMER_TYPE_UNKNOWN = 'Unknown';

/*
 * depending on transaction history, determine which value to add for the BUYER_STATUSES_MAP
 */
function getBuyerStatusFromTransactions(transactions) {
    const { submittedTransactions, confirmedTransactions } = transactions;
    let buyerStatus;

    if (confirmedTransactions && confirmedTransactions > 1) {
        buyerStatus = BUYER_STATUS_CONFIRMED_MULTIPLE;
    } else if (confirmedTransactions) {
        buyerStatus = BUYER_STATUS_CONFIRMED_ONE;
    } else if (submittedTransactions) {
        buyerStatus = BUYER_STATUS_SUBMITTED;
    } else {
        buyerStatus = BUYER_STATUS_NONE;
    }

    return { buyerStatus };
}

function getUserTransactionsFromLocalStorage() {
    return (
        sessionStorage.getItem(STORAGE_TRANSACTIONS_STATUS) ||
        localStorage.getItem(STORAGE_TRANSACTIONS_STATUS)
    );
}

function setUserTransactionStatusToLocalStorage(status) {
    sessionStorage.setItem(STORAGE_TRANSACTIONS_STATUS, { buyerStatus: status });
    localStorage.setItem(STORAGE_TRANSACTIONS_STATUS, { buyerStatus: status });
}

/*
 * fetches all transactions created by user using different filters
 * in order to track order history
 */
export function getTransactionsStatus({ transactions = {}, resetStorage = false }) {
    let transactionsStatus = getUserTransactionsFromLocalStorage();
    /**
     * Transactions status is always being taken from a session storage.
     * When user logs in or registers, this storage entry is set again.
     */
    if (!transactionsStatus || resetStorage) {
        transactionsStatus = getBuyerStatusFromTransactions(transactions);
        setUserTransactionStatusToLocalStorage(transactionsStatus.buyerStatus);
    }

    if (transactionsStatus) {
        transactionsStatus.buyerStatus = BUYER_STATUSES_MAP[transactionsStatus.buyerStatus];
    }

    return transactionsStatus;
}

/**
 * get buyer status based on dibs_campaign query param, f.ex. ?dibs_campaign=buyer
 * note: exported for testing
 */
export const getTransactionStatusFromQueryParams = () => {
    let buyerStatus;
    if (isBuyerCampaign()) {
        buyerStatus = BUYER_STATUSES_MAP[BUYER_STATUS_SUBMITTED_DIBS_CAMPAIGN];
    }
    if (buyerStatus) {
        setUserTransactionStatusToLocalStorage(BUYER_STATUS_SUBMITTED_DIBS_CAMPAIGN);
    }
    return { buyerStatus };
};

/**
 * gets stored transactions status for logged out users
 */
function getStoredTransactionsStatus() {
    const transactionsStatus = getUserTransactionsFromLocalStorage();
    if (transactionsStatus && transactionsStatus.buyerStatus) {
        transactionsStatus.buyerStatus = BUYER_STATUSES_MAP[transactionsStatus.buyerStatus];
        return transactionsStatus;
    } else if (!transactionsStatus) {
        return getTransactionStatusFromQueryParams();
    }
    return {
        buyerStatus: BUYER_STATUSES_MAP[BUYER_STATUS_NONE],
    };
}

/*
 * checkoutStatus logic is identical to buyer status, except it is
 * updated on different events and has nothing to do with session storage
 */
export function getCheckoutStatus({ transactions = {} }) {
    const storedCheckoutStatus = localStorage.getItem(STORAGE_CHECKOUT_STATUS);
    //  is user logged in? - only logged in user have 'transactions'
    if (typeof transactions?.confirmedTransactions === 'number') {
        const checkoutStatus =
            BUYER_STATUSES_MAP[getBuyerStatusFromTransactions(transactions).buyerStatus];
        if (storedCheckoutStatus !== checkoutStatus) {
            localStorage.setItem(STORAGE_CHECKOUT_STATUS, checkoutStatus);
        }
        return checkoutStatus;
    } else {
        const { buyerStatus: userCheckoutStatusFromQueryParams } =
            getTransactionStatusFromQueryParams();
        if (userCheckoutStatusFromQueryParams) {
            localStorage.setItem(
                STORAGE_CHECKOUT_STATUS,
                BUYER_STATUSES_MAP[BUYER_STATUS_SUBMITTED_DIBS_CAMPAIGN]
            );
            return BUYER_STATUSES_MAP[BUYER_STATUS_SUBMITTED_DIBS_CAMPAIGN];
        }
        return storedCheckoutStatus || BUYER_STATUSES_MAP[BUYER_STATUS_NONE];
    }
}

/*
 * create the userType tag according to
    - user permissions
    - dibs_campaign=true (forcedTradeUserType)
 * VIP|Trade|Consumer|Unknown
 */

export function getCustomerType(user, forcedTradeUserType) {
    if (isTradeCampaign() || forcedTradeUserType) {
        // isTradeCampaign: typically from paid media campaigns
        // forcedTradeUserType: typically from emailToken returns user.isVerifiedTrade as true
        return CUSTOMER_TYPE_TRADE;
    } else if (user.serviceId) {
        const isTrade = user.isVerifiedTrade ? CUSTOMER_TYPE_TRADE : '';
        const hasVipPermission = user.isVip ? CUSTOMER_TYPE_VIP : '';
        return (
            `${isTrade}${isTrade && hasVipPermission ? ' and ' : ''}${hasVipPermission}` ||
            CUSTOMER_TYPE_CONSUMER
        );
    } else if (user.everLoggedIn) {
        switch (user.everLoggedIn) {
            case 'trade':
                return CUSTOMER_TYPE_TRADE;
            case 'vip':
                return CUSTOMER_TYPE_VIP;
            default:
                return CUSTOMER_TYPE_CONSUMER;
        }
    } else {
        return CUSTOMER_TYPE_UNKNOWN;
    }
}

export function getUserIdFromEmailToken(emailToken) {
    if (emailToken) {
        return emailToken.split('_')[0];
    } else {
        return undefined;
    }
}

/*
 * store tradeFirmId for tracking trade user logout activities
 */
const setTradeFirmId = ({ tradeFirmId }) => {
    sessionStorage.setItem(STORAGE_TRADE_FIRM_ID, tradeFirmId);
    localStorage.setItem(STORAGE_TRADE_FIRM_ID, tradeFirmId);
};

/**
 * gets stored tradeFirmId for logged out users
 */
export const getStoredTradeFirmId = () => {
    return (
        sessionStorage.getItem(STORAGE_TRADE_FIRM_ID) || localStorage.getItem(STORAGE_TRADE_FIRM_ID)
    );
};

/* gets user buyerDisplayable active promotion code */

const getActivePromotion = user => {
    const activePromotion = user?.activePromotion || {};

    if (activePromotion.buyerDisplayable) {
        return activePromotion.campaignName;
    }
    return 'none';
};
/*
 * creates the user tracking data.
 */
function trackUserAttributes({
    transactions = {},
    user = {},
    forcedTradeUserType,
    regionalInfo,
    userWithEmailTokenOnly,
    isGdprApplicable,
}) {
    const userProfile = user?.profile;
    const tradeFirm = user?.tradeFirm;

    const emailToken = getEmailToken();
    const incomeRange = getIncomeLevelInfo(regionalInfo?.[0]);
    const userServiceId = user?.serviceId;
    const userID = userServiceId || getUserIdFromEmailToken(emailToken);
    const userEmail = userProfile?.email;
    const userEmailMd5 = userProfile?.emailMd5;
    const userEmailSha256 = userProfile?.emailSha256;
    const hasUser = userServiceId || userWithEmailTokenOnly;
    let buyerStatus = '';
    const activePromotion = getActivePromotion(user);
    if (hasUser) {
        const transactionStatus = getTransactionsStatus({
            transactions,
            resetStorage: userWithEmailTokenOnly,
        }); // reset local/session storage if user is coming with email token and preserve updated values
        buyerStatus = transactionStatus.buyerStatus;
    } else {
        const transactionStatus = getStoredTransactionsStatus();
        buyerStatus = transactionStatus.buyerStatus;
    }

    const tradeFirmId = tradeFirm?.serviceId;
    const storedTradeFirmId = getStoredTradeFirmId();
    const userTradeFirmId = tradeFirmId || storedTradeFirmId;
    const tradeFirmRewardStatus = tradeFirm?.rewards?.currentProgram?.programInfo?.code;

    //Check to see if the tradeFirmId has to be updated
    if (tradeFirmId && tradeFirmId !== storedTradeFirmId) {
        setTradeFirmId({ tradeFirmId });
    }

    const checkoutStatus = getCheckoutStatus({ transactions });
    tracking.updateGtmPageInfo({ checkoutStatus });

    const userAttributes = {
        loginStatus: user.serviceId ? 'Logged In' : 'Logged Out',
        registrationStatus: user.everLoggedIn ? 'Registered' : 'Not Registered',
        userID,
        guestID: isMasquerading(document.cookie) ? null : getGuestId(),
        userEmail: userEmailMd5 || undefined,
        userEmailAddress: userEmail || undefined,
        sha256UserEmail: userEmailSha256 || undefined,
        emailToken,
        userZip: userProfile?.zipCode,
        defaultCurrency: user?.preferences?.currency,
        registrationDate: formatDate(user.createdDate),
        currentAccountType: getCurrentAccountType({ user }),
        contactDealerStatus: user.totalMessagesToDealers
            ? 'dealer contacted'
            : 'no dealer contacted',
        buyerStatus,
        tradeStatus: user.verifiedTradeStatus,
        customerType: getCustomerType(user, forcedTradeUserType),
        // can tradeTier just be removed at this point?
        tradeTier: tradeFirmRewardStatus,
        tradeFirmId: userTradeFirmId,
        tradeRewardStatus: tradeFirmRewardStatus,
        profileStatus: tradeFirm?.profileStatus,
        incomeRange,
        activePromotion,
        isPadDevice: mobile.iPad() ? 'True' : 'False',
        isGdprApplicable: isGdprApplicable?.toString(),
    };

    tracking.addUserAttributes(userAttributes);
    return { ...userAttributes, checkoutStatus };
}

// used for relay in dbl/initializeTracking,
// dbl/Header, dbl/MobileNavWrapper
export const userFragment = graphql`
    fragment userTracking_user on User {
        createdDate
        isVip
        isVerifiedTrade
        verifiedTradeStatus
        authMetadata {
            emailOnly
            hasPassword
        }
        profile {
            email
            zipCode
            emailMd5
            emailSha256
        }
        preferences {
            currency
        }
        serviceId
        totalMessagesToDealers
        tradeFirm {
            serviceId
            profileStatus
            rewards {
                currentProgram {
                    programInfo {
                        code
                    }
                }
            }
        }
        activePromotion {
            campaignName
            buyerDisplayable
        }
    }
`;

function getUserFragmentData(user) {
    const {
        isVip,
        isVerifiedTrade,
        verifiedTradeStatus,
        profile,
        preferences,
        serviceId,
        totalMessagesToDealers,
        tradeFirm,
        createdDate,
        authMetadata,
        activePromotion,
    } = user;
    return {
        isVip,
        isVerifiedTrade,
        verifiedTradeStatus,
        profile,
        preferences,
        serviceId,
        totalMessagesToDealers,
        tradeFirm,
        createdDate,
        authMetadata,
        activePromotion,
    };
}

// used for relay in
// dbl/initializeTracking, dbl/MobileNavWrapper,
// dbl/Header, abc/CheckoutConfirmationContainer
const viewerFragment = graphql`
    fragment userTracking_viewer on Viewer {
        transactionsCounter(buyerId: $userId) @include(if: $hasUserId) {
            submittedTransactions: submitted
            confirmedTransactions: confirmed
        }
        gdprDisplayInfo @include(if: $fetchTrackingGdprInfo) {
            isGdprApplicable(countryCode: $userCountryCode)
        }
    }
`;

// used for relay in dbl/MobileNavWrapper, dbl/Header
export const regionalInfoFragment = graphql`
    fragment userTracking_regionalInfo on RegionalInfo {
        incomeLevel(zipCode: $zipCode) {
            incomeBracket
            incomePercentile
        }
    }
`;

const userTrackingQuery = graphql`
    query userTrackingQuery(
        $hasUserId: Boolean!
        $isLoggedIn: Boolean!
        $userId: String!
        $fetchTrackingGdprInfo: Boolean = false
        $userCountryCode: String = ""
    ) {
        viewer {
            ...userTracking_viewer @relay(mask: false)
            user(userId: $userId) @include(if: $isLoggedIn) {
                isVerifiedTrade
            }
        }
    }
`;

function getViewerFragmentData(viewer) {
    const transactions = viewer?.transactionsCounter || {};
    const user = viewer?.user;

    const { submittedTransactions, confirmedTransactions } = transactions;
    const isVerifiedTrade = user?.isVerifiedTrade;
    return {
        userIsVerifiedTrade: isVerifiedTrade,
        submittedTransactions,
        confirmedTransactions,
    };
}

export function unmaskViewerFragmentData({ relayContext, viewer }) {
    const viewerData =
        unmaskFragmentData({
            relayContext,
            relayData: viewer,
            fragmentPropName: 'viewer',
            fragment: viewerFragment,
        }) || {};
    return getViewerFragmentData(viewerData);
}

async function getUserInfoFromEmailToken({ queryEmailToken, environment }) {
    // always make this value a string so that the `userTrackingQuery` does
    // not throw `Expected non-nullable type String! not to be null`
    const tokens = getBuyerToken(document.cookie) || {};
    const isLoggedIn = !!tokens.userToken;
    const userId = getUserIdFromEmailToken(queryEmailToken) || '';
    const transactionsData = await fetchQuery(environment, userTrackingQuery, {
        hasUserId: !!userId,
        // user id info is used to look up if user is verified trade
        isLoggedIn,
        userId,
    });
    const viewerData = transactionsData?.viewer;
    return getViewerFragmentData(viewerData);
}

export function isTrackingDataReady({ viewer, user, regionalInfo }) {
    const transactions = getViewerFragmentData(viewer);
    const userId = getBuyerId(document.cookie);

    const transactionsReady = !isNaN(transactions.submittedTransactions);
    const transactionsNotRequired = !userId;
    // because activePromotion takes longest to load - we asume that
    // once that ready we can fire tracking
    const userReady = user?.activePromotion !== undefined;
    const userNotRequired = !userId;

    const hasValidGeoInfo = hasValidUserSessionGeoInfo() || regionalInfo;

    return (
        hasValidGeoInfo &&
        (transactionsReady || transactionsNotRequired) &&
        (userReady || userNotRequired)
    );
}
/*
 * should be called on initial page load for all entries.
 * determines user tracking data to send to dibs-tracking.
 */
export async function init({ viewer = {}, user, regionalInfo, environment }) {
    let transactions = getViewerFragmentData(viewer);
    const everLoggedIn = getEverLoggedIn(document.cookie);
    const userFragmentData = user ? getUserFragmentData(user) : undefined;

    let userWithEmailTokenOnly = false;
    let forcedTradeUserType = null;
    const queryEmailToken = getEmailTokenFromQuery();
    if (queryEmailToken) {
        localStorage.setItem(USER_EMAIL_TOKEN, queryEmailToken);
        /*
            fetch user's transactions from emailToken if user is not available
        */
        if (!user) {
            userWithEmailTokenOnly = true;
            const userInfo = await getUserInfoFromEmailToken({
                queryEmailToken,
                environment,
            });
            const { userIsVerifiedTrade, ...transactionsInfo } = userInfo;
            transactions = transactionsInfo;
            forcedTradeUserType = userIsVerifiedTrade;
        }
    }

    if (!getBuyerId(document.cookie)) {
        trackPreRegistrationPageVisit();
    }

    handleGuestId();
    const userAttributes = trackUserAttributes({
        user: { ...userFragmentData, everLoggedIn },
        forcedTradeUserType,
        userWithEmailTokenOnly,
        transactions,
        regionalInfo,
        isGdprApplicable: viewer?.gdprDisplayInfo?.isGdprApplicable,
    });
    return { userAttributes };
}

export const UserTrackingInit = ({
    viewer,
    user,
    environment,
    initialTrackingFired,
    setInitialTrackingFired,
}) => {
    const { regionalInfo } = viewer;
    const viewerData = useFragment(viewerFragment, viewer);
    const userData = useFragment(userFragment, user);

    useEffect(() => {
        if (
            !initialTrackingFired &&
            isTrackingDataReady({
                viewer: viewerData,
                user: userData,
                regionalInfo,
            })
        ) {
            init({ viewer: viewerData, user: userData, regionalInfo, environment });
            setInitialTrackingFired(true);
        }
    }, [
        viewerData,
        regionalInfo,
        userData,
        environment,
        initialTrackingFired,
        setInitialTrackingFired,
    ]);

    return null;
};

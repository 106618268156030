import { forwardRef, ForwardRefRenderFunction, ReactNode, FocusEventHandler } from 'react';
import styles from './Dropdown-style.scss';

type Props = {
    children: ReactNode;
    onBlur?: FocusEventHandler;
};
const DropdownBody: ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { children, onBlur },
    ref
) => (
    <div ref={ref} onBlur={onBlur} className={styles.body}>
        {children}
    </div>
);

export default forwardRef(DropdownBody);

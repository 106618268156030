/**
 * @generated SignedSource<<b9dca4050d7d8a79234e70cd419fe37c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecentlyViewedItemsCarousel_items$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedItemsHeart_items" | "useRecentHistoryList_items">;
  readonly " $fragmentType": "RecentlyViewedItemsCarousel_items";
}>;
export type RecentlyViewedItemsCarousel_items$key = ReadonlyArray<{
  readonly " $data"?: RecentlyViewedItemsCarousel_items$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedItemsCarousel_items">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RecentlyViewedItemsCarousel_items",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useRecentHistoryList_items"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecentlyViewedItemsHeart_items"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "a142391e47386b7cd5d8f907db0ad03b";

export default node;

import { FC } from 'react';
import classnames from 'classnames';
import { adjustFlexBasis } from 'dibs-scroll-carousel/exports/adjustFlexBasis';
import { getGapClassName } from 'dibs-scroll-carousel/exports/getGapClassName';
import { getScrollPaddingClassName } from 'dibs-scroll-carousel/exports/getScrollPaddingClassName';
import { GapSize } from 'dibs-scroll-carousel/exports/types';

// styles
import styles from './ItemTilesCarouselShimmer.scss';
import dibsCss from 'dibs-css';

export const ItemTilesCarouselShimmer: FC<{
    fullWidth?: boolean;
    height: number | string;
    itemsToShow: number;
    gapSize: GapSize;
    showArrows?: boolean;
}> = ({ fullWidth, height, itemsToShow, gapSize, showArrows = false }) => {
    const scrollPadding = fullWidth ? 'small' : 'none';
    const orientation = 'horizontal';

    return (
        <div
            className={classnames(
                {
                    'primary-viewport-width': fullWidth,
                    [styles.arrowSpacing]: showArrows,
                },
                dibsCss.overflowHidden
            )}
        >
            <div
                style={{ height }}
                className={classnames(
                    styles.wrapper,
                    dibsCss.flex,
                    dibsCss.overflowHidden,
                    styles[getGapClassName({ gapSize, orientation })],
                    styles[getScrollPaddingClassName({ scrollPadding, orientation })]
                )}
            >
                {[...Array(Math.ceil(itemsToShow))].map((_, index) => (
                    <div
                        key={index}
                        className={classnames(
                            styles.item,
                            dibsCss.shrink0,
                            styles[getGapClassName({ gapSize, orientation })]
                        )}
                        style={{
                            flexBasis: adjustFlexBasis({
                                scrollPadding,
                                gapSize,
                                itemsToShow,
                            }),
                        }}
                    >
                        <div className={classnames(styles.shimmer, dibsCss.wFull, dibsCss.hFull)} />
                    </div>
                ))}
            </div>
        </div>
    );
};

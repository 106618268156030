/**
 * @generated SignedSource<<7c16ef109ba031a10c58b592b5f7c849>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ViewInRoomImage_viewInRoomPhoto$data = {
  readonly centerPosition: {
    readonly left: string | null;
    readonly top: string | null;
  } | null;
  readonly comparatorWidth: {
    readonly inches: string | null;
    readonly pixel: string | null;
  } | null;
  readonly src: string | null;
  readonly " $fragmentType": "ViewInRoomImage_viewInRoomPhoto";
};
export type ViewInRoomImage_viewInRoomPhoto$key = {
  readonly " $data"?: ViewInRoomImage_viewInRoomPhoto$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewInRoomImage_viewInRoomPhoto">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewInRoomImage_viewInRoomPhoto",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "src",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "viewInRoomPhotosCenterPosition",
      "kind": "LinkedField",
      "name": "centerPosition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "top",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "left",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "viewInRoomPhotosComparatorWidth",
      "kind": "LinkedField",
      "name": "comparatorWidth",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pixel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inches",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "viewInRoomPhotos",
  "abstractKey": null
};

(node as any).hash = "855432bbe7bd8a0a3e35c5c4e823e392";

export default node;

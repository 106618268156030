import merge from 'deepmerge';
import serverVars from 'server-vars';

let gtmPageInfo = serverVars.get('tracking.initialDataLayerInfo') || {};

export function getGtmPageInfo(): ObjectType {
    return gtmPageInfo;
}

// should only be used for testing
// use `updateGtmPageInfo` to update the `gtmPageInfo` object
export function setGtmPageInfo(obj: ObjectType): void {
    gtmPageInfo = obj;
}
/**
 * add/update aditional page info vars
 */
export function updateGtmPageInfo(trackingObj: ObjectType): void {
    gtmPageInfo = merge(gtmPageInfo, trackingObj || {});
}

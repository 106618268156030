import { useEffect, useState } from 'react';

import { getAbTestV2 } from 'dibs-buyer-layout/exports/clientABTestV2';

export const AB_TEST_EDITORS_PICK = 'EditorsPicksHP_Nov2024';

export const isEditorsPickVariant = (): boolean =>
    getAbTestV2(AB_TEST_EDITORS_PICK)?.variant === 'variant';

export const useEditorsPicksVariant = (): boolean | null => {
    const [variant, setVariant] = useState<boolean | null>(null);

    useEffect(() => {
        setVariant(isEditorsPickVariant());
    }, []);

    return variant;
};

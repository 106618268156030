const AMOUNT_PRICE_TYPES = {
    SALE: 'WEEKLY_SALE',
    NET: 'NET',
    RETAIL: 'RETAIL',
    FINAL: 'FINAL',
};

const TEXT_PRICE_TYPES = {
    SOLD: 'SOLD',
    HOLD: 'HOLD',
    HOLD_CUSTOM: 'HOLD_CUSTOM',
    UNAVAILABLE: 'UNAVAILABLE',
    PUR: 'PUR',
};

export const TEXT_ONLY_PRICE_TYPES = [
    TEXT_PRICE_TYPES.SOLD,
    TEXT_PRICE_TYPES.UNAVAILABLE,
    TEXT_PRICE_TYPES.PUR,
];

export const TEXT_SIZE_SMALL = 'small';
export const TEXT_SIZE_MEDIUM = 'medium';
export const TEXT_SIZE_LARGE_HEAVY = 'largeHeavy';

export const PRICE_TYPES = {
    ...TEXT_PRICE_TYPES,
    ...AMOUNT_PRICE_TYPES,
};

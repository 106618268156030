const root = 'undefined' === typeof window ? global : window;

type StorageObj = Storage | null;
type StorageKeys = 'localStorage' | 'sessionStorage';
function isStorageSupported(rootArg: typeof root, key: StorageKeys): boolean {
    try {
        // FINDING-1393 - trying this first fixes private mode browsing in safari
        // https://github.com/marcuswestin/store.js/issues/42
        rootArg[key].setItem('dibsy', '');
        rootArg[key].removeItem('dibsy');
        return true;
    } catch (e) {
        return false;
    }
}

class BrowserStorage {
    #data: ObjectType = {};
    #storage: StorageObj = null;
    #isSupported = false;
    #type: StorageKeys | null = null;
    constructor(rootArg: typeof root, storageType: StorageKeys) {
        if (isStorageSupported(rootArg, storageType)) {
            this.#storage = rootArg[storageType];
            this.#isSupported = true;
            this.#type = storageType;
        }
    }
    // testing only
    get _data(): ObjectType {
        return this.#data;
    }
    set _data(obj: ObjectType) {
        if (obj && Object.keys(obj).length === 0 && obj.constructor === Object) {
            this.#data = obj;
        }
    }
    // testing only
    get _storage(): StorageObj {
        return this.#storage;
    }
    set _storage(obj: StorageObj) {
        if (!!obj && Object.keys(obj).length === 0 && obj.constructor === Object) {
            this.#storage = obj;
        }
    }
    /**
     * ************************
     * Only meant for testing *
     * ************************
     */
    public _isSupported(): boolean {
        return this.#isSupported;
    }
    /**
     * ************************
     * Only meant for testing *
     * ************************
     * Sets whether or not local/session storage is supported
     */
    public _setSupported(supported: boolean): void {
        this.#isSupported = supported;
    }
    public getItems(): StorageObj | string {
        if (!this.#isSupported) {
            return `${this.#type} is not supported`;
        }
        return this.#storage;
    }
    /**
     * Set storage value by the key
     */
    public setItem(key: string, value: JsonType): void {
        switch (typeof value) {
            case 'object':
                value = JSON.stringify(value);
                break;
            default:
                value += '';
        }

        if (!this.#isSupported) {
            this.#data[key] = value;
        } else {
            try {
                if (this.#storage !== null) {
                    this.#storage.setItem(key, value as string);
                }
            } catch (e) {
                // do nothing
            }
        }
    }
    /**
     * Get storage value by the key
     */
    public getItem(key?: string): JsonType {
        let value;

        if (!key) {
            return false;
        }
        if (!this.#isSupported && this.#data[key]) {
            value = this.#data[key];
        } else if (this.#storage) {
            value = this.#storage[key];
        }

        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    }
    /**
     * Delete storage by the key
     */
    public deleteItem(key: string): void {
        if (!this.#isSupported) {
            delete this.#data[key];
        } else {
            try {
                if (this.#storage !== null) {
                    this.#storage.removeItem(key);
                }
            } catch (e) {
                // do nothing
            }
        }
    }
}

export const localStorage = new BrowserStorage(root, 'localStorage');
export const sessionStorage = new BrowserStorage(root, 'sessionStorage');

export default BrowserStorage;

import { eventNameConstants, interactionTypeConstants } from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;

export const shareActivitiesDisplay = ({ trigger, timesDisplayed }) => {
    const action = `opt-in modal ${trigger}`;
    return {
        category: 'user favoriting update',
        action,
        label: timesDisplayed,
        eventName: EVENT_NAVIGATION,
        interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
        step_interaction_name: action,
        trigger: timesDisplayed,
    };
};

export const shareActivitiesUpdate = value => {
    const action = `share favoriting ${value}`;
    const label = 'favoriting dropdown';
    return {
        category: 'user favoriting update',
        action,
        label,
        eventName: EVENT_NAVIGATION,
        interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
        step_interaction_name: action,
        trigger: label,
    };
};

import { graphql } from 'react-relay/legacy';

export const userGeoInfoQuery = graphql`
    query userGeoInfoQuery($userId: String = "", $cookieDomain: String = "") {
        viewer {
            regionalInfo(userId: $userId, cookieDomain: $cookieDomain) {
                countryCode
                zipCode
                regionsByZipCode {
                    displayName
                    urlLabel
                }
                incomeLevel {
                    incomeBracket
                    incomePercentile
                }
            }
        }
    }
`;

import { Provider } from 'react-redux';
import { initializeDBL } from 'dibs-buyer-layout/src/entries/index';
import { IntlProvider } from 'dibs-react-intl';
import { useLazyLoadQuery, RelayEnvironmentProvider } from 'react-relay';

import { HpSharedLayout } from '../homepage/HpSharedLayout';
import { homepageRootQuery } from '../queries/homepageRoot';
import serverVars from 'server-vars';
import { hydrateRoot } from 'react-dom/client';
import { HomePageContextProvider } from '../homepage/HomePageContext';
import { getRelayEnvironment } from '../utils/relayModernEnvironment';
import { Error500Layout } from 'dibs-buyer-layout/exports/error500';
import { ErrorBoundary } from 'dibs-error-tools/ErrorBoundary';

const homepageUserGroup = serverVars.get('homepageUserGroup');

const isDesktop = serverVars.get('settings.isDesktop');

const ComponentWrapper = () => {
    const data = useLazyLoadQuery(homepageRootQuery, serverVars.get('relayVariables'), {
        fetchPolicy: 'store-only',
    });
    return <HpSharedLayout homepageUserGroup={homepageUserGroup} viewer={data.viewer} />;
};

initializeDBL().then(({ store }) => {
    const environment = getRelayEnvironment(serverVars.get('dbl.relayData'));

    hydrateRoot(
        document.getElementById('js-root'),
        <IntlProvider locale={serverVars.get('locale')} messages={serverVars.get('messages')}>
            <ErrorBoundary fallback={({ error }) => <Error500Layout error={error} />}>
                <RelayEnvironmentProvider environment={environment}>
                    <HomePageContextProvider
                        isBot={serverVars.get('isBot')}
                        isMobile={serverVars.get('settings.isMobile')}
                        isTablet={serverVars.get('settings.isTablet')}
                        isDesktop={isDesktop}
                        userType={homepageUserGroup}
                        // userType is only added as a query param for logged-in users
                        isLoggedInUser={!!serverVars.get('userType')}
                        isHPVideoAutoPlayVariant={serverVars.get('isHPVideoAutoPlayVariant')}
                        isRemoveRDIDTestVariant={serverVars.get('isRemoveRDIDTestVariant')}
                    >
                        <Provider store={store}>
                            <ComponentWrapper />
                        </Provider>
                    </HomePageContextProvider>
                </RelayEnvironmentProvider>
            </ErrorBoundary>
        </IntlProvider>
    );
});

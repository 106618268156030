/**
 * @generated SignedSource<<32ba26d1f6abfecc622e7474c8b45650>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AuctionLotStatus = "CANCELED" | "CLOSED" | "DELETED" | "DRAFT" | "OPEN" | "PAUSED" | "SCHEDULED" | "%future added value";
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
export type ImageVersionTypeName = "bgProcessed" | "datamatics" | "large" | "master" | "medium" | "oldMaster" | "original" | "placeholder" | "small" | "thumb" | "transparent" | "zoom" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CartNav_item$data = {
  readonly isAuctionEnabled: boolean | null;
  readonly isSold: boolean | null;
  readonly pdpURL: string | null;
  readonly photos: ReadonlyArray<{
    readonly versions: ReadonlyArray<{
      readonly type: {
        readonly name: ImageVersionTypeName | null;
      } | null;
      readonly webPath: string | null;
    } | null> | null;
  } | null> | null;
  readonly pricing: {
    readonly netPrice: {
      readonly amount: number | null;
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
      readonly currency: Currency | null;
    } | null;
    readonly retailPrice: {
      readonly amount: number | null;
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
      readonly currency: Currency | null;
    } | null;
    readonly salePrice: {
      readonly amount: number | null;
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
      readonly currency: Currency | null;
    } | null;
  } | null;
  readonly relevantAuctionLot: {
    readonly buyItNowPrice: {
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
    } | null;
    readonly status: AuctionLotStatus | null;
  } | null;
  readonly relevantAuctionSolution: {
    readonly bids: {
      readonly totalResults: number | null;
    } | null;
  } | null;
  readonly serviceId: string | null;
  readonly title: string | null;
  readonly " $fragmentType": "CartNav_item";
};
export type CartNav_item$key = {
  readonly " $data"?: CartNav_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"CartNav_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ConvertedAmountList",
  "kind": "LinkedField",
  "name": "convertedAmountList",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v0/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "userCountryCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartNav_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pdpURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemPhoto",
      "kind": "LinkedField",
      "name": "photos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemPhotoVersion",
          "kind": "LinkedField",
          "name": "versions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "webPath",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ImageVersionType",
              "kind": "LinkedField",
              "name": "type",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "userCountryCode",
          "variableName": "userCountryCode"
        }
      ],
      "concreteType": "ItemPricing",
      "kind": "LinkedField",
      "name": "pricing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "netPrice",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "salePrice",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "retailPrice",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAuctionEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuctionLot",
      "kind": "LinkedField",
      "name": "relevantAuctionLot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "buyItNowPrice",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuctionSolution",
      "kind": "LinkedField",
      "name": "relevantAuctionSolution",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuctionBidsConnection",
          "kind": "LinkedField",
          "name": "bids",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalResults",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "1102bb550709b6f3ea0eb758d47ae000";

export default node;

import { getGtmEventQueue } from './gtmEventQueue';
import { eventNameConstants } from './constants';

export const ABTEST_BUCKET_CUSTOM_EVENT = 'e_ab_bucket' as const;

export type AbTestBucketDecisions = {
    testName: string;
    testVariant: string;
};

export const trackAbTestBucketEvent = (buckets: AbTestBucketDecisions[], chunkSize = 10): void => {
    // chunk the buckets into {chunkSize} items each
    const abTestBuckets: string[][] = [];
    for (let i = 0; i < buckets.length; i += chunkSize) {
        abTestBuckets.push(
            buckets.slice(i, i + chunkSize).map(b => `${b.testName}:${b.testVariant}`)
        );
    }
    // push each chunk into individual dateLayer entries
    abTestBuckets.forEach(chunk => {
        getGtmEventQueue().push({
            event: ABTEST_BUCKET_CUSTOM_EVENT,
            eventName: eventNameConstants.EVENT_AB_TEST_BUCKET,
            interaction_type: chunk.join('|'),
        });
    });
};

import cart from './cartReducer';
import header from './headerReducer';
import { currencyReducer } from './currencyReducer';
import { appBannerReducer } from './appBannerReducer';
import { drawerReducer } from './drawerReducer';

export default {
    cart,
    currency: currencyReducer,
    appBanner: appBannerReducer,
    drawer: drawerReducer,
    header,
};

import { forwardRef, HTMLProps } from 'react';
import useHeadingManager from './useHeading';

type Props = Omit<HTMLProps<HTMLHeadingElement>, 'data-tn'> & { dataTn?: string };

export default forwardRef<HTMLHeadingElement, Props>(function Heading({ dataTn, ...props }, ref) {
    const Component = useHeadingManager();
    // eslint-disable-next-line ferrum/invalid-prop-format
    return <Component ref={ref} data-tn={dataTn} {...props} />;
});

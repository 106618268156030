import { FC, useEffect, useMemo, createContext, useContext, ReactNode } from 'react';
import { useQueryLoader, PreloadedQuery, graphql } from 'react-relay';
import { getBuyerId } from 'dibs-cookie-jar';

import {
    MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS,
    CAROUSEL_RECENTLY_VIEWED_ITEMS_PER_PAGE,
} from '../recentHistoryConstants';
import { getLocalRecentHistoryList } from '../getLocalRecentHistory';
import { LocalHistoryItemType } from '../recentHistoryTypes';

import {
    RecentlyViewedItemsContextQuery,
    RecentlyViewedItemsContextQuery$variables,
} from './__generated__/RecentlyViewedItemsContextQuery.graphql';

type Context = {
    queryReference?: PreloadedQuery<RecentlyViewedItemsContextQuery> | null;
    localStorageItems: LocalHistoryItemType[];
    userId: string;
    itemsPerPage: number;
};
const RecentlyViewedItemsContext = createContext<Context | null>(null);
export const useRecentlyViewedItemsContext = (): Context => {
    const context = useContext(RecentlyViewedItemsContext);
    if (!context) {
        throw new Error('RecentlyViewedItemsProvider was not found');
    }

    return context;
};

export const recentlyViewedItemsQuery = graphql`
    query RecentlyViewedItemsContextQuery(
        $userId: String!
        $excludeItemPks: [String] = []
        $count: Int = 0
        $fetchUserData: Boolean = false
        $selectedItemIds: [String] = []
        $loadPortfolioData: Boolean = false
        $userIds: [String] = []
        $itemIds: [String] = []
    ) {
        viewer {
            user(userId: $userId) @include(if: $fetchUserData) {
                ...RecentlyViewedItemsCarousel_user
                    @arguments(excludeItemPks: $excludeItemPks, count: $count)
            }
            ...RecentlyViewedItemsCarousel_viewer
                @arguments(
                    loadPortfolioData: $loadPortfolioData
                    selectedItemIds: $selectedItemIds
                    userIds: $userIds
                )
            itemRead(itemIds: $itemIds) {
                ...RecentlyViewedItemsCarousel_items
            }
        }
    }
`;

const getQueryArgs = ({
    localStorageItems,
    maxItems,
    excludedItemId,
    userId,
}: {
    localStorageItems: LocalHistoryItemType[];
    maxItems: number;
    excludedItemId: string | null;
    userId: string;
}): RecentlyViewedItemsContextQuery$variables => {
    const hasUserId = !!userId;

    const itemIds = localStorageItems.map(item => item.itemId);
    const remainingItemsCount = maxItems - itemIds.length;
    const fetchRemainingItems = remainingItemsCount > 0;

    return {
        userId,
        excludeItemPks: [...itemIds, excludedItemId],
        count: remainingItemsCount,
        //fetch user data only if maxItems is not reached and we have user id
        fetchUserData: hasUserId && fetchRemainingItems,

        //fetch favorites only if we have items in the local storage and we have user id
        loadPortfolioData: hasUserId && !!itemIds.length,
        selectedItemIds: itemIds,
        userIds: hasUserId ? [userId] : [],
        itemIds,
    };
};

export const RecentlyViewedItemsProvider: FC<{
    excludedItemId?: string | null;
    maxItems?: number;
    itemsPerPage?: number;
    children: ReactNode;
}> = ({
    excludedItemId = null,
    maxItems = MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS,
    itemsPerPage = CAROUSEL_RECENTLY_VIEWED_ITEMS_PER_PAGE,
    children,
}) => {
    const [queryReference, loadQuery] =
        useQueryLoader<RecentlyViewedItemsContextQuery>(recentlyViewedItemsQuery);

    const localStorageItems = useMemo(
        //excludedItemId excludes provided item from local storage
        () => getLocalRecentHistoryList(excludedItemId, maxItems).reverse(),
        [excludedItemId, maxItems]
    );

    const userId = typeof document !== 'undefined' ? getBuyerId(document.cookie) : '';
    const queryArgs = useMemo(
        () => getQueryArgs({ localStorageItems, maxItems, excludedItemId, userId }),
        [localStorageItems, maxItems, excludedItemId, userId]
    );

    useEffect(() => {
        loadQuery(queryArgs);
    }, [loadQuery, queryArgs]);

    const contextValue = useMemo(() => {
        return {
            queryReference,
            localStorageItems,
            userId: queryArgs.userId,
            itemsPerPage,
        };
    }, [queryReference, localStorageItems, queryArgs.userId, itemsPerPage]);

    return (
        <RecentlyViewedItemsContext.Provider value={contextValue}>
            {children}
        </RecentlyViewedItemsContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback = (arg0?: any) => any;
type Callbacks = Callback[];

const cbs: Callbacks = [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addLoginHandlers = (...args: any[]): void => {
    // const _args = Array.prototype.slice.call(args);
    cbs.push(
        ...args.reduce((acc, item) => {
            if (Array.isArray(item)) {
                acc.push(...item);
            } else {
                acc.push(item);
            }
            return acc;
        }, [])
    );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
export const fireLoginHandlers = (arg: any): void => {
    cbs.forEach(func => func(arg));
};

export const getLoginHandlers = (): Callback[] => {
    return [...cbs];
};

export const dumpHandlers = (): void => {
    cbs.length = 0;
};

const RESET_UPDATE_USER_STATE = 'resetUpdateUserState';
const FORCE_FAVORITE_REFETCH = 'forceFavoriteRefetch';
const SEND_CHAT_MESSAGE = 'sendChatMessage';
const SET_HAS_PAGE_REFETCHED = 'setHasPageFetched';

type Handlers = {
    resetUpdateUserState: Callback;
    forceFavoriteRefetch: Callbacks;
    sendChatMessage: Callback;
    setHasPageFetched: Callback;
};

const namedHandlers: Handlers = {
    [RESET_UPDATE_USER_STATE]: () => null,
    [FORCE_FAVORITE_REFETCH]: [],
    [SEND_CHAT_MESSAGE]: () => null,
    [SET_HAS_PAGE_REFETCHED]: () => null,
};

export const initSetHasPageRefetched = (fn: Callback): void => {
    namedHandlers[SET_HAS_PAGE_REFETCHED] = fn;
};

export const setHasPageRefetched = (): void => {
    if (typeof namedHandlers[SET_HAS_PAGE_REFETCHED] === 'function') {
        namedHandlers[SET_HAS_PAGE_REFETCHED]();
    }
};

export const initSendChatMessageOnPageRefetch = (fn: Callback): void => {
    namedHandlers[SEND_CHAT_MESSAGE] = fn;
};

export const sendChatMessageOnPageRefetch = (): void => {
    if (typeof namedHandlers[SEND_CHAT_MESSAGE] === 'function') {
        namedHandlers[SEND_CHAT_MESSAGE]();
    }
};

export const initResetUpdateUserState = (fn: Callback): void => {
    namedHandlers[RESET_UPDATE_USER_STATE] = fn;
};

export const resetUpdateUserState = (): void => {
    if (typeof namedHandlers[RESET_UPDATE_USER_STATE] === 'function') {
        namedHandlers[RESET_UPDATE_USER_STATE]();
    }
};

export const addForceFavoriteRefetchHandler = (fn: Callback): void => {
    namedHandlers[FORCE_FAVORITE_REFETCH] = [fn, ...namedHandlers[FORCE_FAVORITE_REFETCH]];
};

export const fireForceFavoriteRefetchHandlers = (): void => {
    namedHandlers[FORCE_FAVORITE_REFETCH].forEach(fn => {
        if (typeof fn === 'function') {
            fn();
        }
    });
};

/**
 * @generated SignedSource<<931e4d1172f8ed0017d897aa7a7d179e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedItemTilesCarouselModule_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedItemTilesCarousel_user">;
  readonly " $fragmentType": "HpSharedItemTilesCarouselModule_user";
};
export type HpSharedItemTilesCarouselModule_user$key = {
  readonly " $data"?: HpSharedItemTilesCarouselModule_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedItemTilesCarouselModule_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedItemTilesCarouselModule_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HpSharedItemTilesCarousel_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "980e298114450654dbc57e2c77beaec9";

export default node;

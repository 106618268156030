/**
 * @generated SignedSource<<fe896601f8adc5cd2832bc4a9b53f80d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userTracking_regionalInfo$data = {
  readonly incomeLevel: {
    readonly incomeBracket: string | null;
    readonly incomePercentile: string | null;
  } | null;
  readonly " $fragmentType": "userTracking_regionalInfo";
};
export type userTracking_regionalInfo$key = {
  readonly " $data"?: userTracking_regionalInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"userTracking_regionalInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "zipCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "userTracking_regionalInfo",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "zipCode",
          "variableName": "zipCode"
        }
      ],
      "concreteType": "IncomeLevel",
      "kind": "LinkedField",
      "name": "incomeLevel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "incomeBracket",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "incomePercentile",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RegionalInfo",
  "abstractKey": null
};

(node as any).hash = "f02bdb39ee092a2d4baa8aff15f762e3";

export default node;

import { ArrayElement } from 'dibs-ts-utils/exports/ArrayElement';

// constants
import { TEXT_ONLY_PRICE_TYPES } from './constants';

// types
import { PriceDisplay_item$data as ItemType } from './__generated__/PriceDisplay_item.graphql';

type DisplayPriceType = ArrayElement<ItemType['displayPrice']>;
type ConvertedAmountListType = NonNullable<DisplayPriceType>['convertedAmountList'];

export const getPriceFromAmountListByCurrency = (
    convertedAmountList: ConvertedAmountListType,
    currency: string
): number | undefined => {
    const priceAmount = (convertedAmountList || []).find(
        convertedAmount => convertedAmount && convertedAmount.currency === currency
    );

    return (priceAmount && priceAmount.amount) || undefined;
};

export const isValidTextOnlyPriceType = (type: string | null): boolean => {
    return type ? TEXT_ONLY_PRICE_TYPES.includes(type) : false;
};

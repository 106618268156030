import { trackEcommerce, eventNameConstants } from 'dibs-tracking';
import { getPromoCreative } from '../../helpers/getPromoCreative';

function trackFeaturedImpressions(items) {
    trackEcommerce({
        type: 'promotionImpression',
        eventName: eventNameConstants.EVENT_VIEW_PROMOTION,
        promotions: items.map((item, index) => {
            const url = item?.linkData?.path || '';
            const position = index + 1;
            return {
                id: 'five-block-module',
                name: 'Five Block Module',
                creative: getPromoCreative(url),
                position: position,
            };
        }),
    });
}

function trackFeaturedClick(item, index) {
    const url = item?.linkData?.path || '';
    const position = index + 1;
    trackEcommerce({
        type: 'promoClick',
        eventName: eventNameConstants.EVENT_SELECT_PROMOTION,
        promotions: [
            {
                id: 'five-block-module',
                name: 'Five Block Module',
                creative: getPromoCreative(url),
                position: position,
            },
        ],
    });
}

export { trackFeaturedImpressions, trackFeaturedClick };

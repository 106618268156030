import { FC } from 'react';
import { getSrcString, getSrcSetString } from '../utils/srcStringHelpers';

type Props = {
    src: string;
    srcSetWidths?: number[];
    ariaHidden?: boolean;
    ariaLabelledBy?: string;
    ariaDescribedBy?: string;
    className?: string;
    altText?: string;
    dataTn?: string;
    sizes?: string;
    // onLoad handler is passed down when ResponsiveImage is used within HpLazyImageWrapper/LazyImage
    onLoad?: () => void;
};

const ResponsiveImage: FC<Props> = ({
    altText,
    ariaHidden,
    ariaLabelledBy,
    ariaDescribedBy,
    className = '',
    src,
    srcSetWidths,
    dataTn,
    sizes,
    onLoad,
}) => {
    return (
        <img
            aria-hidden={ariaHidden === true || undefined}
            aria-labelledby={ariaLabelledBy}
            aria-describedby={ariaDescribedBy}
            // use alt text if not hidden and not labelledBy
            alt={!ariaHidden && !ariaLabelledBy && altText ? altText : ''}
            className={className}
            data-tn={dataTn}
            src={getSrcString({ url: src })}
            srcSet={
                srcSetWidths
                    ? getSrcSetString({
                          url: src,
                          widths: srcSetWidths,
                      })
                    : undefined
            }
            sizes={sizes}
            onLoad={onLoad}
        />
    );
};

export default ResponsiveImage;

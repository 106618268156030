import { GapSize, Orientation } from '../../types';

export const getGapClassName = ({
    gapSize,
    orientation,
}: {
    gapSize: GapSize;
    orientation: Orientation;
}): `${GapSize}${Capitalize<Orientation>}` =>
    `${gapSize}${(orientation[0].toUpperCase() + orientation.slice(1)) as Capitalize<Orientation>}`; // e.g. `xSmallHorizontal` or `xSmallVertical`

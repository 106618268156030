/**
 * @generated SignedSource<<7b04610d18b810cf096fd01f7983eb34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Notifications_viewer$data = {
  readonly conversations?: {
    readonly " $fragmentSpreads": FragmentRefs<"Conversations_conversations">;
  } | null;
  readonly " $fragmentType": "Notifications_viewer";
};
export type Notifications_viewer$key = {
  readonly " $data"?: Notifications_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Notifications_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "buyerId"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchNotifications"
    },
    {
      "defaultValue": 5,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Notifications_viewer",
  "selections": [
    {
      "condition": "fetchNotifications",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "conversations",
          "args": [
            {
              "kind": "Variable",
              "name": "buyerId",
              "variableName": "buyerId"
            },
            {
              "kind": "Literal",
              "name": "filter",
              "value": "showUnreadOnly"
            },
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "first"
            }
          ],
          "concreteType": "ConversationsSolrConnection",
          "kind": "LinkedField",
          "name": "conversationsSolr",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Conversations_conversations"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "f9be93fadc4431dad81e87e7b18cff2e";

export default node;

import serverVars from 'server-vars';
import gtmDataLayer from './gtmDataLayer';
import { setGtmPageInfo } from './gtmPageInfo';
import { getGtmEventQueue, setGtmEventQueue } from './gtmEventQueue';
import { setInitialPageviewFiredState, setInitialInfoFiredState } from './gtmStatus';

/**
 * For Google Tag Manager to work properly the order in which data is added to the dataLayer is important
 *  1. initial page data/user info
 *  2. initial page view event
 *  3. any ga events fired on the page
 * This is a private function which is called by either tracking.trackPageView or tracking.addUserAttributes and
 * controls this flow of how events are fired.
 * @private
 */
export function startTrackingEvents(): void {
    const gtmEventQueue = getGtmEventQueue();
    // need to check that we aren't already tracking events so that forEach call
    // doesn't accidentally put us in an infinite loop
    if (gtmEventQueue === gtmDataLayer) {
        return;
    }

    // add each of the events in the queue to the gtmDataLayer (in fifo order)
    gtmEventQueue.forEach(function (event) {
        gtmDataLayer.push(event);
    });

    // change reference
    setGtmEventQueue(gtmDataLayer);
}

// in rare cases, this method needs to be used, usually this is handled in ./userAttributes#addUserAttributes
// pageview needs these values to be set `true` before './pageView#trackPageview` can fire properly
export function allowTracking(): void {
    setInitialInfoFiredState(true);
    setInitialPageviewFiredState(true);
    startTrackingEvents();
}

export function resetGtmTrackingInSamePageView(): void {
    setInitialInfoFiredState(false);
    setGtmPageInfo(serverVars.get('tracking.initialDataLayerInfo') || {});
}

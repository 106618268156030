import { Environment } from 'react-relay';

import { getBuyerId } from 'dibs-cookie-jar';
import { isEmailOnly } from 'dibs-cookie-jar/exports/isEmailOnly';
import { includes } from 'dibs-ts-utils/exports/includes';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';

import { AuthOptionsType } from '../authModal/authentication/authTypes';
import { setUserEmailToStorage } from '../authModal/authentication/userStorageActions';
import {
    LOGIN,
    REGISTER,
    RESET_PASSWORD,
    REQUEST_PASSWORD_EMAIL,
    DEFAULT_FLOW,
    EMAIL_LINK_SENT_FLOW,
    verifyEmailFlows,
} from '../authModal/authentication/authFlowConstants';
import authModalLoader from './AuthModalLoader';
import {
    getPasswordToken,
    getUserEmailToken,
} from '../authModal/authentication/userStoragePasswordToken';
import { emailLoginLinkSendMutation } from '../authModal/authentication/requestPasswordEmail/emailLoginLinkSendMutation';
import { getEmailTokenFromQuery } from '../helpers/queryParamsHelpers';
import { getRedirectDestination } from '../helpers/getRedirectDestination';
import { verifyEmailHandler } from './verifyEmailHandler';

const authModals = [LOGIN, REGISTER, RESET_PASSWORD, REQUEST_PASSWORD_EMAIL];

const isValidAction = (action: string): boolean => {
    if (
        action === RESET_PASSWORD &&
        !getPasswordToken() &&
        !getUserEmailToken() &&
        !getEmailTokenFromQuery()
    ) {
        return false;
    }

    return !!action && includes(authModals, action);
};

/**
 * @param {object} environment Mock object mainly for testing
 */
export const triggerAuthModal = async (environment: Environment): Promise<void> => {
    const destination = getRedirectDestination() || undefined;
    const searchParams = new URLSearchParams(window.location.search);
    let action = searchParams.get('modal') || '';
    const gaAction = action.replace('_', ' ');
    const email = searchParams.get('email') || '';
    const emailLoginToken = searchParams.get('emailLoginToken') || '';
    action = action.replace(/_/g, '-');
    const flow = searchParams.get('flow') || DEFAULT_FLOW;
    const passwordToken = getPasswordToken();
    let authOptions: AuthOptionsType = {
        action: action,
        flow,
        email,
        ga: {
            label: gaAction + ' query param - address bar',
            value: window.scrollY ? 1 : 0,
        },
    };

    // If user comes with email token, store email in storage to display it in auth flows.
    if (email && getEmailTokenFromQuery()) {
        setUserEmailToStorage(email);
    }

    if (isValidAction(action)) {
        if (
            includes(verifyEmailFlows, flow) &&
            action === RESET_PASSWORD &&
            email &&
            passwordToken
        ) {
            verifyEmailHandler({
                environment,
                flow,
                email,
                destination,
                emailVerificationToken: passwordToken,
            });
        }
        // Need to make sure we have fresh user state
        // and don't get into infinite redirect loops
        else if (
            getBuyerId(document.cookie) &&
            destination &&
            !isEmailOnly(document.cookie) // don't want to redirect yet if you are email only user creating password or login in with email token
        ) {
            window.location.href = handleLocaleUrl(destination, GLOBAL_CLIENT_ONLY_LOCALE);
        } else if ((action === RESET_PASSWORD && getBuyerId(document.cookie)) || !emailLoginToken) {
            if (flow === EMAIL_LINK_SENT_FLOW && searchParams.get('emailLoginLink') === 'send') {
                const urlRedirect = destination
                    ? handleLocaleUrl(
                          `/?${new URLSearchParams({ ep: destination }).toString()}`,
                          GLOBAL_CLIENT_ONLY_LOCALE
                      )
                    : '';
                authOptions = {
                    ...authOptions,
                    additionalProps: {
                        urlRedirect,
                    },
                };

                await emailLoginLinkSendMutation(environment, {
                    email,
                    urlRedirect,
                });
            }

            const authResponse = await authModalLoader.show(authOptions);
            if (destination && !authResponse.err) {
                window.location.href = handleLocaleUrl(destination, GLOBAL_CLIENT_ONLY_LOCALE);
            }
        }
    }
};

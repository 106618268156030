/**
 * @generated SignedSource<<e4edf6bdea09ce229ab25ed3f2f7994f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedFeaturedModule_componentModule$data = {
  readonly cmsDisplayTitle: string | null;
  readonly items: ReadonlyArray<{
    readonly backgroundColor: string | null;
    readonly cmsId: number | null;
    readonly cta: string | null;
    readonly imageUrl: string | null;
    readonly linkData: {
      readonly path: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
    };
    readonly textColor: string | null;
    readonly title: string | null;
  } | null> | null;
  readonly primaryItem: {
    readonly backgroundColor: string | null;
    readonly cta: string | null;
    readonly imageHeight: string | null;
    readonly imageUrl: string | null;
    readonly imageWidth: string | null;
    readonly linkData: {
      readonly path: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
    };
    readonly mobileImageHeight?: string | null;
    readonly mobileImageUrl?: string | null;
    readonly mobileImageWidth?: string | null;
    readonly title: string | null;
  } | null;
  readonly " $fragmentType": "HpSharedFeaturedModule_componentModule";
};
export type HpSharedFeaturedModule_componentModule$key = {
  readonly " $data"?: HpSharedFeaturedModule_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedFeaturedModule_componentModule">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cta",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SeoLink_linkData"
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "backgroundColor",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isMobile"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedFeaturedModule_componentModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cmsDisplayTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeaturedItem",
      "kind": "LinkedField",
      "name": "primaryItem",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageHeight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageWidth",
          "storageKey": null
        },
        {
          "condition": "isMobile",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mobileImageHeight",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mobileImageWidth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mobileImageUrl",
              "storageKey": null
            }
          ]
        },
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeaturedItem",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cmsId",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "textColor",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FeaturedModule",
  "abstractKey": null
};
})();

(node as any).hash = "2150e82466621aa90a1fdb59fa9f7271";

export default node;

import { getGtmEventQueue } from './gtmEventQueue';

// see if an event has occurred yet on this page for a specific action value
export function hasAction(actionVal: string): boolean {
    const gtmEventQueue = getGtmEventQueue();
    return gtmEventQueue.some(trackingObj => trackingObj?.action === actionVal);
}

// see if an event has occurred yet on this page for a specific category value
export function hasCategory(categoryVal: string): boolean {
    const gtmEventQueue = getGtmEventQueue();
    return gtmEventQueue.some(trackingObj => trackingObj?.category === categoryVal);
}

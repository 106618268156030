import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import styles from './main.scss';

type Size = 'small' | 'medium';
type Weight = 'regular' | 'heavy';

export type BackgroundColor =
    | 'white'
    | 'buyer-tertiary'
    | 'moonstone'
    | 'dealer-tertiary'
    | 'yellow-200'
    | 'cyan-200'
    | 'green-200'
    | 'red-200'
    | 'alert-secondary';

type BadgeProps = {
    children: ReactNode;
    type?: 'neutral' | 'alert' | 'pill' | 'warning';
    size?: `${Size}-${Weight}`;
    interactive?: boolean;
    letterCase?: 'uppercase' | 'capitalize';
    backgroundColor?: BackgroundColor;
    dataTn?: string;
};
export const Badge: FC<BadgeProps> = props => {
    const { children, type, size, interactive, letterCase, dataTn, backgroundColor } = props;
    const isPill = type === 'pill';
    const isSmallSize = !size || size === 'small-heavy';

    const badgePillClasses = isPill
        ? {
              [styles.badgePill]: true,
              [styles.badgeMedium]: size === 'medium-regular' || size === 'medium-heavy',
              [styles.badgeInteractive]: !!interactive,
              [styles.badgeWhite]: backgroundColor === 'white',
              [styles.badgeBuyerTertiary]: backgroundColor === 'buyer-tertiary',
              [styles.badgeDealerTertiary]: backgroundColor === 'dealer-tertiary',
              [styles.badgeMoonstone]: backgroundColor === 'moonstone',
              [styles.badgeYellow200]: backgroundColor === 'yellow-200',
              [styles.badgeCyan200]: backgroundColor === 'cyan-200',
              [styles.badgeGreen200]: backgroundColor === 'green-200',
              [styles.badgeRed200]: backgroundColor === 'red-200',
              [styles.badgeAlertSecondary]: backgroundColor === 'alert-secondary',
          }
        : null;

    const badgeInnerPillClasses = isPill
        ? {
              [styles.badgeInnerPill]: true,
              [styles.badgeInnerSmallHeavy]: size === 'small-heavy',
              [styles.badgeInnerMediumRegular]: size === 'medium-regular',
              [styles.badgeInnerMediumHeavy]: size === 'medium-heavy',
              [styles.badgeInnerUppercase]: isSmallSize && letterCase === 'uppercase',
              [styles.badgeInnerCapitalize]: isSmallSize && letterCase === 'capitalize',
          }
        : null;

    const badgeClasses = classnames(
        styles.badge,
        {
            [styles.badgeNeutral]: type === 'neutral',
            [styles.badgeAlert]: type === 'alert',
            [styles.badgeWarning]: type === 'warning',
        },
        badgePillClasses
    );

    const badgeInnerClasses = classnames(styles.badgeInner, badgeInnerPillClasses);

    return (
        <div className={badgeClasses} data-tn={dataTn}>
            <div className={badgeInnerClasses}>{children}</div>
        </div>
    );
};

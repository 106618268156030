// Flow types automatically removed

import { useState, useEffect, useCallback } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import PropTypes from 'prop-types'; // Components

import HeartComponent from './HeartIconWrapper';
// Helpers
import { ADD, REMOVE } from './ModifyPortfolioItems';
import { mobile as mobileCheck } from 'dibs-client-check'; // Mutations

import ModifyPortfolioItems from './ModifyPortfolioItems'; // Types

function useForceFavoriteItem({
    forceFavoriteItem,
    portfolioId,
    userId,
    favoriteItem,
    setIsForceFavoriteItem,
}) {
    useEffect(() => {
        if (forceFavoriteItem) {
            setIsForceFavoriteItem(false);
            favoriteItem({
                portfolioId,
                userId,
            });
        }
    }, [forceFavoriteItem, favoriteItem, portfolioId, userId, setIsForceFavoriteItem]);
}
/**
 * @param {(Object|null)} item - Heart_item
 * @returns {[(number|null), (action: 'ADD' | 'REMOVE') => void]} - Tuple containining heartCount and updateHeartCount callback
 *
 * Since `Heart_item` is only passed for the purpose of retrieving the heart count,
 * rather than passing in a boolean prop, we rely on checking whether the item exists
 * at all.
 *
 * Note we can't just check `!heartCount` because if we're displaying heartCount,
 * 0 is still a valid value for it.
 */

export function Heart(props) {
    const {
        authModalShow,
        heartPortfolioItems,
        itemId,
        userId,
        viewer,
        item,
        relay,
        doRefetch,
        onFavorited,
        onUnFavorited,
        onError,
        isStrokeWidthHeavy,
        onHeartClick,
        forceFavoriteItem,
        onIsFilledChange,
        setIsForceFavoriteItem,
        wrapperComponent,
        hideTotalLikesCount,
    } = props;
    const isFavorited = !!heartPortfolioItems?.length;
    const [isAnimated, setIsAnimated] = useState(false);
    const [isFilled, setIsFilled] = useState(isFavorited);
    const [useHoverClass, setUseHoverClass] = useState(false);
    const [isLoginFlowInProgress, setIsLoginFlowInProgress] = useState(
        userId && !heartPortfolioItems
    );
    const [hasPendingNetworkRequest, setHasPendingNetworkRequest] = useState(false);
    const portfolioId = viewer?.userPortfolios?.edges?.[0]?.node?.serviceId;
    useEffect(() => {
        if (onIsFilledChange) {
            onIsFilledChange(isFilled);
        }
    }, [isFilled, onIsFilledChange]);
    useEffect(() => {
        // apply hoverClass if user device doesn't support touch
        const hasTouch = mobileCheck.hasTouch();

        if (!useHoverClass && !hasTouch) {
            setUseHoverClass(true);
        }
    }, [useHoverClass, setUseHoverClass]);
    useEffect(() => {
        if (userId && !heartPortfolioItems) {
            setHasPendingNetworkRequest(true);
        }
    }, [userId, heartPortfolioItems]);
    const favoriteItem = useCallback(
        args => {
            if (args.portfolioId && args.userId) {
                const action = !isFavorited || isLoginFlowInProgress ? ADD : REMOVE;
                setIsAnimated(true);
                setHasPendingNetworkRequest(true);
                setIsFilled(action === ADD);
                const portfolioItemIds = (heartPortfolioItems || [])
                    .map(heart => heart.portfolioItemId)
                    .filter(Boolean);
                ModifyPortfolioItems.commit(relay.environment, {
                    action,
                    itemsIds: [itemId],
                    portfolioItemsIds: action === REMOVE ? portfolioItemIds : null,
                    portfolioId: args.portfolioId,
                    userId: args.userId,
                    onCompleted: () => {
                        if (action === ADD && onFavorited) {
                            onFavorited();
                        } else if (action === REMOVE && onUnFavorited) {
                            onUnFavorited();
                        }

                        doRefetch(() => {
                            setHasPendingNetworkRequest(false);
                        });
                    },
                    onError: error => {
                        if (onError) {
                            onError(error);
                        }

                        setIsFilled(!isFilled);
                        setHasPendingNetworkRequest(false);
                    },
                });
            } else {
                setIsLoginFlowInProgress(true);
                authModalShow();
            }
        },
        [
            authModalShow,
            doRefetch,
            heartPortfolioItems,
            isFavorited,
            isFilled,
            isLoginFlowInProgress,
            itemId,
            onError,
            onFavorited,
            onUnFavorited,
            relay.environment,
        ]
    );
    const handleHeartClick = useCallback(
        e => {
            e.preventDefault();

            if (!hasPendingNetworkRequest) {
                if (onHeartClick) {
                    onHeartClick(favoriteItem);
                } else {
                    favoriteItem({
                        portfolioId,
                        userId,
                    });
                }
            }
        },
        [hasPendingNetworkRequest, onHeartClick, favoriteItem, portfolioId, userId]
    );

    // Do not use forceFavoriteItem unless all props fetched in this component's
    // relay container are fetched from an external higher-order component and can be
    // passed along to this component as props
    useForceFavoriteItem({
        forceFavoriteItem: forceFavoriteItem && !hasPendingNetworkRequest,
        portfolioId,
        userId,
        favoriteItem,
        setIsForceFavoriteItem,
    });
    useEffect(() => {
        if (isLoginFlowInProgress && !!userId && !!portfolioId) {
            favoriteItem({
                portfolioId,
                userId,
            });
            setIsLoginFlowInProgress(false);
        }
    }, [favoriteItem, isLoginFlowInProgress, portfolioId, userId]);
    useEffect(() => {
        if (!hasPendingNetworkRequest && isFavorited !== isFilled) {
            setIsFilled(isFavorited);
        }
    }, [hasPendingNetworkRequest, isFavorited, isFilled]);
    return (
        <HeartComponent
            animateHeartPulse={props.animateHeartPulse}
            className={props.className}
            buttonClass={props.buttonClass}
            isFavorited={isFavorited}
            isFilled={isAnimated || isFilled}
            isAnimated={isAnimated}
            isStrokeWidthHeavy={isStrokeWidthHeavy}
            useHoverClass={useHoverClass}
            onAnimationEnd={() => setIsAnimated(false)}
            onClick={handleHeartClick}
            type={props.type || 'outline'}
            itemId={itemId}
            theme={props.theme}
            heartCount={item?.portfolioItemDetails?.likes ?? null}
            wrapperComponent={wrapperComponent}
            hideTotalLikesCount={hideTotalLikesCount}
        />
    );
}
Heart.propTypes = {
    className: PropTypes.string,
    buttonClass: PropTypes.string,
    authModalShow: PropTypes.func.isRequired,
    isStrokeWidthHeavy: PropTypes.bool,
    heartPortfolioItems: PropTypes.array,
    forceFavoriteItem: PropTypes.bool,
    itemId: PropTypes.string.isRequired,
    doRefetch: PropTypes.func.isRequired,
    onError: PropTypes.func,
    onFavorited: PropTypes.func,
    onHeartClick: PropTypes.func,
    onUnFavorited: PropTypes.func,
    portfolioId: PropTypes.string,
    relay: PropTypes.object,
    wrapperComponent: PropTypes.oneOf(['div', 'Link']),
    hideTotalLikesCount: PropTypes.bool,
    type: PropTypes.oneOf(['outline', 'button']),
    userId: PropTypes.string,
    theme: PropTypes.oneOf(['dark', 'light']),
    onIsFilledChange: PropTypes.func,
    setIsForceFavoriteItem: PropTypes.func,
    item: PropTypes.object,
    viewer: PropTypes.object,
    animateHeartPulse: PropTypes.bool,
};
const HeartContainer = createFragmentContainer(Heart, {
    heartPortfolioItems: graphql`
        fragment Heart_heartPortfolioItems on PortfolioItemV2 @relay(plural: true) {
            portfolioId
            portfolioItemId
        }
    `,
    item: graphql`
        fragment Heart_item on Item {
            portfolioItemDetails {
                likes
            }
        }
    `,
    viewer: graphql`
        fragment Heart_viewer on Viewer
        @argumentDefinitions(
            loadPortfolioData: { type: "Boolean", defaultValue: false }
            userIds: { type: "[String]", defaultValue: [] }
        ) {
            userPortfolios: portfolios(first: 1, portfolioTypes: [HEART], userIds: $userIds)
                @include(if: $loadPortfolioData) {
                edges {
                    node {
                        serviceId
                    }
                }
            }
        }
    `,
});
HeartContainer.defaultProps = {
    item: null,
};
export default HeartContainer;

function getUrlWithoutParam(queryParams: URLSearchParams): string {
    const otherParams = queryParams.toString();
    const urlWithoutParam = `${window.location.pathname}${otherParams ? `?${otherParams}` : ''}`;

    return urlWithoutParam;
}

// This is temporary solution and can be deleted after
// we stop setting email token as cookie from query params.
export function getUrlWithoutEmailToken(queryParams: URLSearchParams): string {
    queryParams.delete('emailToken');
    const urlWithoutEmailToken = getUrlWithoutParam(queryParams);

    return urlWithoutEmailToken;
}

// When user logouts from page where user was logged in from EmailTokenLogin email
// remove emailLoginToken in order not to try logging user back again with same params
export function getUrlWithoutEmailLoginLinkParams(queryParams: URLSearchParams): string {
    queryParams.delete('emailLoginToken');
    const urlWithoutEmailLoginToken = getUrlWithoutParam(queryParams);

    return urlWithoutEmailLoginToken;
}

const DIBS_CAMPAIGN_KEY = 'dibs_campaign';
const BUYER = 'buyer';
const TRADE = 'trade';
const EMAIL_TOKEN = 'emailToken';

function isCampaign(campaignName: string): boolean {
    const searchParams = new URLSearchParams(window.location.search);
    return (searchParams.get(DIBS_CAMPAIGN_KEY) || '').includes(campaignName);
}

export function isBuyerCampaign(): boolean {
    // primarily used for buyer transaction status
    return isCampaign(BUYER);
}

export function isTradeCampaign(): boolean {
    // used primarily for paid marketing campaigns
    return isCampaign(TRADE);
}

export function getEmailTokenFromQuery(): string | null {
    return new URLSearchParams(window.location.search).get(EMAIL_TOKEN);
}

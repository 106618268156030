export const CUSTOMER_TYPE_TRADE = 'trade' as const;
export const CUSTOMER_TYPE_VIP = 'vip' as const;
export const CUSTOMER_TYPE_CONSUMER = 'consumer' as const;
export const USER_EMAIL = 'userEmail' as const;
export const USER_NAME = 'userName' as const;
export const USER_EMAIL_TOKEN = 'userEmailToken' as const;
export const GUEST_USER = 'GUEST_USER' as const;
export const PASSWORD_TOKEN = 'passwordToken' as const;
export const ON_SITE_REGISTRATION = 'onSiteRegistration' as const;
export const VISIT_COUNT = 'visitCount' as const;
export const PSEUDO_SESSION = 'pseudoSessionVisitCount' as const;

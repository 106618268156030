import { lazy, Suspense, useState, useRef, useEffect, FunctionComponent } from 'react';
import { createRefetchContainer, graphql, RelayRefetchProp } from 'react-relay/legacy';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import Dropdown, { alignConstants } from '../Dropdown/Dropdown';
import DropdownLoadingContent from '../Dropdown/DropdownLoadingContent';
import LikesIcon from './LikesIcon';

import {
    MAX_TILES,
    MAX_TILE_ITEMS,
    ITEM_EVENTS_SHOWN_PERIODE_MS,
} from '../FavNotifications/favNotificationsConstants';
import { itemEventsShownMutation } from '../FavNotifications/itemEventsShownMutation';

import styles from './Likes.scss';

import { Likes_viewer$data as ViewerType } from './__generated__/Likes_viewer.graphql';

const FavNotifications = lazy(
    () => import(/* webpackChunkName: "FavNotifications" */ '../FavNotifications/FavNotifications')
);

const VipCuratedFolderTooltip = lazy(
    () =>
        import(
            /* webpackChunkName: "VipCuratedFolderTooltip" */ '../VipCuratedFolderTooltip/VipCuratedFolderTooltip'
        )
);

const hasNewEvents: (viewer: ViewerType) => boolean = viewer =>
    !!viewer?.favoriteNotifications?.hasNewEvents;
const getCreatedAfterDate: () => string = () =>
    new Date(new Date().getTime() - ITEM_EVENTS_SHOWN_PERIODE_MS).toJSON();

type LikesLinkComponentPropsType = {
    fetchNewEvents: boolean;
    favoritesUrl: string;
    hasRootPendingRefetch: boolean;
    hasUserId: boolean;
    isClient: boolean;
    userId: string;
    viewer: ViewerType;
    relay: RelayRefetchProp;
};

export const LikesLinkComponent: FunctionComponent<LikesLinkComponentPropsType> = ({
    fetchNewEvents,
    favoritesUrl = '/favorites/items/',
    hasRootPendingRefetch,
    hasUserId,
    isClient,
    userId,
    viewer,
    relay,
}) => {
    const [fragmentVariables, setFragmentVariables] = useState({
        eventsLimit: MAX_TILES,
        fetchNotifications: false,
        createdAfter: getCreatedAfterDate(),
        notificationItemsLimit: MAX_TILE_ITEMS,
        fetchNewEvents,
        userId,
    });
    const [hasOpenedDropdown, setHasOpenedDropdown] = useState(false);
    const hasFetchedEvents = useRef(false);

    const { refetch, environment } = relay;

    const { user } = viewer || {};

    useEffect(() => {
        if (fetchNewEvents && !hasFetchedEvents.current) {
            hasFetchedEvents.current = true;
            refetch({ ...fragmentVariables, ...{ fetchNewEvents, userId } });
            setFragmentVariables({
                ...fragmentVariables,
                ...{ userId },
            });
        }
    }, [fetchNewEvents, userId, refetch, fragmentVariables]);

    const triggerNotificationsFetch: () => void = () => {
        if (hasUserId && !hasRootPendingRefetch && !fragmentVariables.fetchNotifications) {
            const updatedVars = {
                ...fragmentVariables,
                fetchNotifications: true,
                notificationItemsLimit: MAX_TILE_ITEMS,
            };
            setFragmentVariables(updatedVars);

            refetch(updatedVars, null, () => {
                if (hasNewEvents(viewer)) {
                    itemEventsShownMutation({
                        environment,
                        userId,
                    });
                }
            });
        }
    };

    const favoriteNotificationsTiles = viewer?.favoriteNotificationsTiles || null;
    const isVipCuratedFolderTooltipVisible = useSelector<
        { header: { isVipCuratedFolderTooltipVisible: boolean } },
        boolean
    >(({ header }) => header.isVipCuratedFolderTooltipVisible);

    return isClient ? (
        <div className={styles.likesWrapper}>
            <Dropdown
                align={alignConstants.ALIGN_RIGHT}
                onShow={() => {
                    setHasOpenedDropdown(true);
                }}
                hasDropdownHeader
                className={classnames({ [styles.noHover]: isVipCuratedFolderTooltipVisible })}
                bodyClassName={styles.dropdownBodyWrapper}
                dropdownClassName={styles.dropdown}
            >
                <LikesIcon
                    hasUnread={hasNewEvents(viewer)}
                    favoritesUrl={favoritesUrl}
                    onMouseEnter={triggerNotificationsFetch}
                />
                {hasOpenedDropdown ? (
                    <Suspense fallback={<DropdownLoadingContent />}>
                        <FavNotifications
                            user={user || null}
                            hasUserId={hasUserId}
                            hasNewEvents={hasNewEvents(viewer)}
                            favoriteNotificationsTiles={favoriteNotificationsTiles}
                            favoritesUrl={favoritesUrl}
                        />
                    </Suspense>
                ) : null}
            </Dropdown>
            {isVipCuratedFolderTooltipVisible ? (
                <Suspense fallback="">
                    <VipCuratedFolderTooltip />
                </Suspense>
            ) : null}
        </div>
    ) : (
        <LikesIcon favoritesUrl={favoritesUrl} />
    );
};

const LikesLink = createRefetchContainer(
    LikesLinkComponent,
    {
        viewer: graphql`
            fragment Likes_viewer on Viewer
            @argumentDefinitions(
                createdAfter: { type: "String", defaultValue: "" }
                eventsLimit: { type: "Int", defaultValue: 0 }
                fetchNewEvents: { type: "Boolean", defaultValue: false }
                fetchNotifications: { type: "Boolean", defaultValue: false }
                notificationItemsLimit: { type: "Int", defaultValue: 0 }
            ) {
                favoriteNotifications(userId: $userId) @include(if: $fetchNewEvents) {
                    id
                    hasNewEvents(eventsLimit: $eventsLimit, createdAfter: $createdAfter)
                }
                favoriteNotificationsTiles: favoriteNotifications(userId: $userId)
                    @include(if: $fetchNotifications) {
                    ...FavNotifications_favoriteNotificationsTiles
                        @arguments(
                            createdAfter: $createdAfter
                            eventsLimit: $eventsLimit
                            notificationItemsLimit: $notificationItemsLimit
                        )
                }
                user(userId: $userId) @include(if: $fetchNotifications) {
                    ...FavNotifications_user
                }
            }
        `,
    },
    graphql`
        query LikesRefetchQuery(
            $createdAfter: String!
            $eventsLimit: Int!
            $fetchNewEvents: Boolean!
            $fetchNotifications: Boolean!
            $notificationItemsLimit: Int!
            $userId: String!
        ) {
            viewer {
                ...Likes_viewer
                    @arguments(
                        createdAfter: $createdAfter
                        eventsLimit: $eventsLimit
                        fetchNewEvents: $fetchNewEvents
                        fetchNotifications: $fetchNotifications
                        notificationItemsLimit: $notificationItemsLimit
                    )
            }
        }
    `
);

export default LikesLink;

import { FC } from 'react';
import { Spinner } from 'dibs-elements/exports/Spinner';
import styles from './DropdownLoadingContent-style.scss';

const DropdownLoadingContent: FC = () => {
    return (
        <div className={styles.dropdownLoadingContent}>
            <Spinner dark />
        </div>
    );
};

export default DropdownLoadingContent;

import SV from 'server-vars';

export const LINK_TYPE_BUYER = 'BUYER';
export const LINK_TYPE_SELLER = 'SELLER';

type LinkType = typeof LINK_TYPE_BUYER | typeof LINK_TYPE_SELLER;

const SV_PATH_BUYER = 'supportHost';
const SV_PATH_SELLER = 'sellerSupportHost';

const SUPPORT_MAP = {
    [LINK_TYPE_BUYER]: SV_PATH_BUYER,
    [LINK_TYPE_SELLER]: SV_PATH_SELLER,
};

const CONSUL_MAP = {
    [LINK_TYPE_BUYER]: 'NODE_SUPPORT_CENTER_HOST',
    [LINK_TYPE_SELLER]: 'NODE_SELLER_SUPPORT_CENTER_HOST',
};

const FALLBACK_MAP = {
    [LINK_TYPE_BUYER]: 'https://support.1stdibs.com/hc',
    [LINK_TYPE_SELLER]: 'https://sellersupport.1stdibs.com/hc',
};

/**
 *
 * @param path Root-relative path to support document
 * @param linkType BUYER or SELLER, defaults to BUYER
 * @param host If server-side, use this host instead of referring to server-vars; ignores linkType
 * @returns Full URL to support document
 */
function getSupportLink(path: string, linkType: LinkType, host: string = ''): string {
    if (!host) {
        host = SV.get(SUPPORT_MAP[linkType]) || '';
    }

    // fallback 1 -- use consul config (if available on server)
    if (!host && typeof process !== 'undefined') {
        if (process?.env?.[CONSUL_MAP[linkType]]) {
            host = process?.env?.[CONSUL_MAP[linkType]] || '';
        }
    }

    // fallback 2 -- use hard-coded path
    if (!host) {
        host = FALLBACK_MAP[linkType];
    }

    let url = null;

    if (path.startsWith('/hc')) {
        url = new URL(path, host);
    } else {
        url = new URL(`/hc${path}`, host);
    }
    return url?.href || '';
}

export function _getBuyerSupportLink(path: string, host: string = ''): string {
    return getSupportLink(path, LINK_TYPE_BUYER, host);
}

export function _getSellerSupportLink(path: string, host: string = ''): string {
    return getSupportLink(path, LINK_TYPE_SELLER, host);
}

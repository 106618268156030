import { FC, useRef, useCallback, ComponentProps, useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { colors } from 'dibs-sassy/exports/colors';
import { spacingNumbers } from 'dibs-sassy/exports/spacing';
import { getSrcsetString } from 'dibs-image-utils/exports/srcSet';

import { hexToRgba } from '../helpers/hexToRgba';
import { useItemDisplayDimensions } from '../helpers/useItemDisplayDimensions';

import { ViewInRoomImage_item$key } from './__generated__/ViewInRoomImage_item.graphql';
import { ViewInRoomImage_user$key } from './__generated__/ViewInRoomImage_user.graphql';
import { ViewInRoomImage_viewInRoomPhoto$key } from './__generated__/ViewInRoomImage_viewInRoomPhoto.graphql';

type OnVisibilityChange = ComponentProps<typeof VisibilityTracker>['onVisibilityChange'];

const IMAGE_SIZE = 1200;

const loadImage = ({
    src,
    srcset,
    sizes,
}: {
    src: string;
    srcset?: string;
    sizes?: string;
}): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve(img);
        };
        img.onerror = () => {
            reject();
        };

        // Order matters here. Need to set sizes before srcset to avoid multiple calls of the image in safari
        if (sizes) {
            img.sizes = sizes;
        }
        if (srcset) {
            img.srcset = srcset;
        }

        img.src = src;
    });

const getSrcSetAttrs = ({
    src,
    srcSetSizes,
    srcSetWidths,
    srcSetQuality,
}: {
    src: string;
    srcSetSizes?: string;
    srcSetWidths?: number[];
    srcSetQuality?: number;
}): { srcset: string; sizes: string } | ObjectType => {
    if (!srcSetSizes) {
        return {};
    }

    return {
        srcset: getSrcsetString(src, srcSetWidths, { quality: srcSetQuality }),
        sizes: srcSetSizes,
    };
};

const userFragment = graphql`
    fragment ViewInRoomImage_user on User {
        ...useItemDisplayDimensions_user
    }
`;

const itemFragment = graphql`
    fragment ViewInRoomImage_item on Item {
        measurement {
            convertedMeasurements(unit: IN) {
                width
            }
        }
        ...useItemDisplayDimensions_item
    }
`;

const viewInRoomPhotoFragment = graphql`
    fragment ViewInRoomImage_viewInRoomPhoto on viewInRoomPhotos {
        src
        centerPosition {
            top
            left
        }
        comparatorWidth {
            pixel
            inches
        }
    }
`;

const DIMENSIONS_RECTANGLE_HEIGHT = 46;
const DIMENSIONS_VERTICAL_LINE_HEIGHT = 14;
const DIMENSIONS_TEXT_FONT_SIZE = 14;

export const ViewInRoomImage: FC<{
    viewInRoomPhoto: ViewInRoomImage_viewInRoomPhoto$key;
    item: ViewInRoomImage_item$key;
    user?: ViewInRoomImage_user$key | null | undefined;
    className: string;
    mainImagePaths: {
        imagePath?: string | null;
        masterOrZoomPath?: string | null;
    };
    useEagerImageLoad: boolean;
    showDimensionsOnHover?: boolean;

    srcSetSizes?: string;
    srcSetWidths?: Array<number>;
    srcSetQuality?: number;
    imageLoadVerticalOffset?: number;
    onContentLoaded?: () => void;
}> = ({
    className,
    mainImagePaths,
    useEagerImageLoad,
    //Relay prop `user` must be provided when using showDimensionsOnHover
    showDimensionsOnHover = false,
    srcSetSizes,
    srcSetWidths,
    srcSetQuality = 100,
    imageLoadVerticalOffset = 100,
    onContentLoaded = () => {},
    item: itemRef,
    user: userRef = null,
    viewInRoomPhoto: viewInRoomPhotoRef,
}) => {
    if (showDimensionsOnHover && typeof userRef === 'undefined') {
        throw new Error('Relay prop `user` must be provided when using showDimensionsOnHover!');
    }

    const isCanvasLoaded = useRef(false);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const user = useFragment(userFragment, userRef);
    const item = useFragment(itemFragment, itemRef);
    const viewInRoomPhoto = useFragment(viewInRoomPhotoFragment, viewInRoomPhotoRef);

    const { displayWidth } = useItemDisplayDimensions({ user, item });

    const { measurement } = item;
    const itemWidth = measurement?.convertedMeasurements?.width;

    const constructCanvas = useCallback(
        ({ drawDimensions }: { drawDimensions: boolean }): void => {
            const canvas = canvasRef.current;
            const ctx = canvas?.getContext('2d');
            const { src } = viewInRoomPhoto;
            const { imagePath, masterOrZoomPath } = mainImagePaths;
            const mainImgSrc = imagePath || masterOrZoomPath;

            if (!canvas || !ctx || !src || !mainImgSrc || !masterOrZoomPath || !itemWidth) {
                return;
            }

            (async () => {
                try {
                    const [roomImg, artworkImg] = await Promise.all([
                        loadImage({
                            src,
                            ...getSrcSetAttrs({ src, srcSetSizes, srcSetWidths, srcSetQuality }),
                        }),
                        loadImage({
                            src: mainImgSrc,
                            ...getSrcSetAttrs({
                                src: masterOrZoomPath,
                                srcSetSizes,
                                srcSetWidths,
                                srcSetQuality,
                            }),
                        }),
                    ]);

                    canvas.width = roomImg.width;
                    canvas.height = roomImg.height;

                    //clear canvas
                    ctx.clearRect(0, 0, canvas.width, canvas.height);

                    //draw room
                    ctx.drawImage(roomImg, 0, 0);

                    const proportion = IMAGE_SIZE / roomImg.width;
                    const { comparatorWidth, centerPosition } = viewInRoomPhoto;

                    const newWidth =
                        ((Number(comparatorWidth?.pixel) / Number(comparatorWidth?.inches)) *
                            Number(itemWidth)) /
                        proportion;
                    //recalculate height based on aspect ration
                    const newHeight = newWidth * (artworkImg.height / artworkImg.width);

                    const x = Number(centerPosition?.left) / proportion - newWidth / 2;
                    const y = Number(centerPosition?.top) / proportion - newHeight / 2;

                    //add shadow to artwork
                    ctx.save();
                    ctx.shadowOffsetX = 0;
                    ctx.shadowOffsetY = 4;
                    ctx.shadowBlur = 6;
                    ctx.shadowColor = hexToRgba(colors.sassyColorPitchBlack, 0.32);

                    //draw artwork
                    ctx.drawImage(artworkImg, x, y, newWidth, newHeight);

                    //restore shadow
                    ctx.restore();

                    if (drawDimensions && displayWidth) {
                        //get the scale ratio by which elements need to change their dimensions to reflect true size in pixels;
                        const scale = canvas.width / canvas.getBoundingClientRect().width;

                        const spacing = spacingNumbers.sassySpacingXSmall * scale;
                        const rectX = x - spacing;
                        const rectY = y + newHeight + spacing;
                        const rectWidth = newWidth + spacing * 2;
                        const rectHeight = DIMENSIONS_RECTANGLE_HEIGHT * scale;

                        ctx.fillStyle = hexToRgba(colors.sassyColorNoir, 0.2);
                        ctx.fillRect(rectX, rectY, rectWidth, rectHeight);

                        //move base point to rectangle
                        ctx.translate(rectX, rectY);

                        //line config
                        ctx.lineWidth = 1 * scale;
                        ctx.strokeStyle = colors.sassyColorWhite;
                        const lineHeight = DIMENSIONS_VERTICAL_LINE_HEIGHT * scale;

                        //draw left line
                        ctx.beginPath();
                        const leftLineX = spacing;
                        const leftLineY = spacing;
                        ctx.moveTo(leftLineX, leftLineY);
                        ctx.lineTo(leftLineX, leftLineY + lineHeight);
                        ctx.stroke();

                        //draw horizontal line
                        ctx.beginPath();
                        const horizontalLineX = spacing;
                        const horizontalLineY = spacing + lineHeight / 2;
                        ctx.moveTo(horizontalLineX, horizontalLineY);
                        ctx.lineTo(leftLineX + newWidth, horizontalLineY);
                        ctx.stroke();

                        //draw right line
                        ctx.beginPath();
                        const rightLineX = spacing + newWidth;
                        const rightLineY = spacing;
                        ctx.moveTo(rightLineX, rightLineY);
                        ctx.lineTo(rightLineX, rightLineY + lineHeight);
                        ctx.stroke();

                        //draw dimensions text
                        const fontSize = DIMENSIONS_TEXT_FONT_SIZE * scale;
                        ctx.font = `300 ${fontSize}px proxima-nova`;
                        ctx.fillStyle = colors.sassyColorWhite;
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'top';
                        const textX = spacing + newWidth / 2;
                        const textY = spacing + lineHeight;
                        ctx.fillText(displayWidth, textX, textY);
                    }

                    if (isCanvasLoaded.current) {
                        onContentLoaded();
                        isCanvasLoaded.current = true;
                    }
                } catch (e) {
                    //something wrong
                }
            })();
        },
        [
            viewInRoomPhoto,
            srcSetSizes,
            srcSetWidths,
            srcSetQuality,
            itemWidth,
            mainImagePaths,
            onContentLoaded,
            displayWidth,
        ]
    );

    useEffect(() => {
        if (useEagerImageLoad) {
            constructCanvas({ drawDimensions: false });
        }
    }, [useEagerImageLoad, constructCanvas]);

    const onVisibilityChange: OnVisibilityChange = useCallback(
        ({ isVisible, disconnect }) => {
            if (isVisible) {
                constructCanvas({ drawDimensions: false });
                disconnect();
            }
        },
        [constructCanvas]
    );

    const onMouseEnter = useCallback(() => {
        if (!showDimensionsOnHover) {
            return;
        }
        constructCanvas({ drawDimensions: true });
    }, [constructCanvas, showDimensionsOnHover]);

    const onMouseLeave = useCallback(() => {
        if (!showDimensionsOnHover) {
            return;
        }
        constructCanvas({ drawDimensions: false });
    }, [constructCanvas, showDimensionsOnHover]);

    return (
        <>
            <canvas
                ref={canvasRef}
                className={className}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
            {!useEagerImageLoad && (
                <VisibilityTracker
                    elementRef={canvasRef}
                    onVisibilityChange={onVisibilityChange}
                    observerOptions={{
                        rootMargin: `${imageLoadVerticalOffset}px 0px ${imageLoadVerticalOffset}px 0px`,
                    }}
                />
            )}
        </>
    );
};

import { useEffect, useState } from 'react';

import { getAbTestV2 } from 'dibs-buyer-layout/exports/clientABTestV2';

export const AB_TEST_DYNAMIC_COLLECTION = 'DynamicCollectionHP_Nov2024';

export const isDynamicCollectionVariant = (): boolean =>
    getAbTestV2(AB_TEST_DYNAMIC_COLLECTION)?.variant === 'variant';

export const useDynamicCollectionVariant = (): boolean | null => {
    const [variant, setVariant] = useState<boolean | null>(null);

    useEffect(() => {
        setVariant(isDynamicCollectionVariant());
    }, []);

    return variant;
};

/**
 * @generated SignedSource<<f790c3d5e4209d74ea1ba24eb2e1db9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MeetsRecommendedPricingBadge_item$data = {
  readonly meetsRecommendedPricing: boolean | null;
  readonly " $fragmentType": "MeetsRecommendedPricingBadge_item";
};
export type MeetsRecommendedPricingBadge_item$key = {
  readonly " $data"?: MeetsRecommendedPricingBadge_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"MeetsRecommendedPricingBadge_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MeetsRecommendedPricingBadge_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meetsRecommendedPricing",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "37f813c3c0e3f5e25b4b9ecdba474043";

export default node;

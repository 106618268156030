import { Environment, Store, RecordSource, Network } from 'relay-runtime';
import { getBuyerToken } from 'dibs-cookie-jar';
import serverVars from 'server-vars';
import { createFetcher } from 'dibs-relay-network-layer/client';

type GetRelayEnvironmentOptions = {
    getGraphQLNetworkContext?: () => string | null;
    additionalHeaders?: Record<string, string>;
};
let environment: Environment;

export default function getRelayEnvironment(
    records = {},
    { getGraphQLNetworkContext, additionalHeaders }: GetRelayEnvironmentOptions = {}
): Environment {
    if (!environment) {
        const network = Network.create(
            createFetcher({
                getGraphQLNetworkContext,
                additionalHeaders,
                userType: 'buyer',
                getQueries: () => ({
                    ...getBuyerToken(document.cookie),
                    locale: serverVars.get('locale'),
                }),
            })
        );
        const source = new RecordSource(records);
        const store = new Store(source);
        environment = new Environment({ network, store });
    }
    return environment;
}

import { useEffect, useRef, useCallback } from 'react';

export function useDebouncedCallback(cb = () => null, delay = 100) {
    const prevArgs = useRef([]);
    const timeoutHandler = useRef(null);
    const isComponentUnmounted = useRef(false);

    const cancelDebouncedCallback = useCallback(() => {
        clearTimeout(timeoutHandler.current);
        prevArgs.current = [];
        timeoutHandler.current = null;
    }, []);

    const debouncedCallback = useCallback(
        (...args) => {
            //keep track of most recently passed arguments
            prevArgs.current = args;
            //clear the existing timeout
            clearTimeout(timeoutHandler.current);

            //set timeout to clear stored timeout info and call supplied cb
            timeoutHandler.current = setTimeout(() => {
                cancelDebouncedCallback();

                //call cb if caller is still mounted
                if (!isComponentUnmounted.current) {
                    cb(...args);
                }
            }, delay);
        },
        [cb, delay, cancelDebouncedCallback]
    );

    const callPendingCallback = useCallback(() => {
        if (!timeoutHandler.current) {
            return;
        }

        cb.apply(null, prevArgs.current);
        cancelDebouncedCallback();
    }, [cancelDebouncedCallback, cb]);

    //update ref when caller unmounts
    useEffect(
        () => () => {
            isComponentUnmounted.current = true;
        },
        []
    );

    return [debouncedCallback, cancelDebouncedCallback, callPendingCallback];
}

import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

import { HpSharedModuleContainer } from '../HpSharedModuleContainer/HpSharedModuleContainer';
import HpSharedEditorialModuleItem from './HpSharedEditorialModuleItem';
import { trackEditorialImpressions, trackEditorialClick } from './helpers/EditorialTracking';
import { trackModuleLocation, trackModule } from '../helpers/moduleLocationTracking';
import { InViewport } from 'dibs-in-viewport/exports/InViewport';

import { HpSharedEditorialModule_componentModule$data } from './__generated__/HpSharedEditorialModule_componentModule.graphql';

type Props = {
    componentModule: HpSharedEditorialModule_componentModule$data;
    useLazyLoadImages: boolean;
    moduleIndex: number;
    totalModules: number;
};

const HpSharedEditorialModule: FC<Props> = ({
    componentModule,
    useLazyLoadImages,
    moduleIndex,
    totalModules,
}) => {
    const items = (componentModule?.items || []).filter(filterNulls);

    const handleInitialDisplay = ({ inViewport }: { inViewport: boolean }): void => {
        if (items && inViewport) {
            trackEditorialImpressions(items);
        }
    };

    if (!items) {
        return null;
    }
    return (
        <HpSharedModuleContainer addTopGap>
            <InViewport stopWhenInViewport onInViewportChange={handleInitialDisplay}>
                <div className="rowFlex" data-tn="editorial-wrapper">
                    {items.map((item, index) => (
                        <HpSharedEditorialModuleItem
                            key={item?.cmsId || index}
                            item={item}
                            index={index}
                            useLazyLoadImages={useLazyLoadImages}
                            onClick={() => {
                                trackEditorialClick(item, index);
                                trackModuleLocation({
                                    label: `content promo module #${index + 1}`,
                                    moduleIndex,
                                    totalModules,
                                });
                                trackModule(componentModule.cmsDisplayTitle);
                            }}
                        />
                    ))}
                </div>
            </InViewport>
        </HpSharedModuleContainer>
    );
};

export default createFragmentContainer(HpSharedEditorialModule, {
    componentModule: graphql`
        fragment HpSharedEditorialModule_componentModule on EditorialModule {
            cmsDisplayTitle
            items(userType: $userType) {
                cmsId
                linkData {
                    # path is used in EditorialTracking.js
                    path
                }
                ...HpSharedEditorialModuleItem_item
            }
        }
    `,
});

/**
 * @generated SignedSource<<29b1a809cbd2fa474a66bf23be5c68fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwiperItem_photo$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpers_getPhotoImgProps_photo">;
  readonly " $fragmentType": "SwiperItem_photo";
};
export type SwiperItem_photo$key = {
  readonly " $data"?: SwiperItem_photo$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwiperItem_photo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwiperItem_photo",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpers_getPhotoImgProps_photo",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeholder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "smallPath",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "masterOrZoomPath",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "ItemPhoto",
  "abstractKey": null
};

(node as any).hash = "748e941a970c0ba0f7b32b92b7b7ecb0";

export default node;

import { pick } from './utils';

const buyerCustomDimensionMap = {
    dimension8: 'onSale',
    dimension9: 'newListing',
    dimension11: 'dealerName',
    dimension12: 'dealerID',
    dimension13: 'dealerLocation',
    dimension20: 'itemPostingLocation',
    dimension37: 'purchaseStatus',
    dimension38: 'askingPrice',
    dimension76: 'priceType',
    dimension83: 'contemporaryTrackingString',
} as const;

type BuyerCustomDimensionKeys = keyof typeof buyerCustomDimensionMap;
type CustomDimensions = Partial<Record<BuyerCustomDimensionKeys, string | number | null>>;
type DataType = Record<string, string | number | null>;

/**
 * Maps the data values to the appropriate custom dimension indexes used by google analytics
 * (e.g. returns object { dimension1 : '1900s', dimension38 : '1000' } which ga will then map on their end
 * to 'timePeriod' and 'askingPrice' respectively).
 * @param   {object} data
 * @param   {array}  [dataKeys]         optional parameter to specify which specific keys in the
 *                                      data param should be tracked.
 * @returns {object} customDimensions
 */
export function getCustomDimensions(data: DataType, dataKeys?: string[]): CustomDimensions {
    const customDimensions: CustomDimensions = {};

    if (typeof data !== 'undefined') {
        // removes any key/values that are not wanted
        data = dataKeys ? pick(data, dataKeys) : data;

        // returns an object with the data object values associated with the correct dimension key
        const keys = Object.keys(buyerCustomDimensionMap) as BuyerCustomDimensionKeys[];
        keys.forEach(key => {
            const dimensionLabel = buyerCustomDimensionMap[key as BuyerCustomDimensionKeys];
            if (typeof data[dimensionLabel] !== 'undefined') {
                customDimensions[key] = data[dimensionLabel];
            }
        });
    }

    return customDimensions;
}

import { FC, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import classnames from 'classnames';
import { PageLayout } from 'dibs-elements/exports/PageLayout';
import { Link } from 'dibs-elements/exports/Link';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

import { Spinner } from 'dibs-elements/exports/Spinner';
import { Carousel } from 'dibs-carousel';

import { InViewport } from 'dibs-in-viewport/exports/InViewport';
import { HpSharedModuleContainer } from '../HpSharedModuleContainer/HpSharedModuleContainer';
import { HpSharedCategoryProducts } from './HpSharedCategoryProducts';
import {
    trackPersonalizationImpression,
    trackPersonalizationItemClick,
} from '../helpers/personalizationTracking';
import { trackModuleLocation, trackModule } from '../helpers/moduleLocationTracking';
import {
    TRACKING_VARIANT_BROWSE,
    DEFAULT_NEW_ARRIVALS_PER_PAGE,
    MOBILE_NEW_ARRIVALS_PER_PAGE,
} from '../constants';
import { HpSharedPersonalizedCategories_componentModule$data as ComponentModule } from './__generated__/HpSharedPersonalizedCategories_componentModule.graphql';
import { HpSharedPersonalizedCategories_personalization$data as Personalization } from './__generated__/HpSharedPersonalizedCategories_personalization.graphql';

import dibsCss from 'dibs-css';
import styles from './HpSharedPersonalizedCategories.scss';
import { SharedCarouselDot } from '../../sharedComponents/SharedCarouselDot/SharedCarouselDot';

const placeholderItems = [
    { node: { url: 'url1' } },
    { node: { url: 'url2' } },
    { node: { url: 'url3' } },
];

type Props = {
    isMobile: boolean;
    personalization: Personalization | null;
    componentModule: ComponentModule;
    moduleIndex: number;
    totalModules: number;
};

export const HpSharedPersonalizedCategoriesComponent: FC<Props> = ({
    isMobile,
    personalization,
    componentModule,
    moduleIndex,
    totalModules,
}) => {
    const [hasFiredImpressionsTracking, setHasFiredImpressionsTracking] = useState(false);

    const hasBorder = !isMobile;
    const fullBleed = isMobile;
    const itemsPerPage = isMobile ? MOBILE_NEW_ARRIVALS_PER_PAGE : DEFAULT_NEW_ARRIVALS_PER_PAGE;

    const personalizationItems = personalization?.edges?.filter(filterNulls) || [];
    const items = (personalizationItems && personalizationItems) || placeholderItems;
    const totalItems = items.length;

    const trackImpressions = ({ inViewport }: { inViewport: boolean }): void => {
        if (!hasFiredImpressionsTracking && inViewport) {
            setHasFiredImpressionsTracking(true);
            personalizationItems.map(({ node }, index) => {
                const categoryUrl = node?.linkData.path || '';
                const mainItem = node?.categoryMainItem;
                const categoryItems = node?.categoryItems || [];
                const variant = node?.variant || '';
                const recommendedItems = [mainItem, ...categoryItems];
                trackPersonalizationImpression({
                    recommendedItems,
                    categoryUrl,
                    startIndex: index,
                    variant,
                });
            });
        }
    };

    const title = componentModule?.title;

    const personalizedCategoriesEl = (
        <div
            className={classnames(
                dibsCss.bgBuyertertiary,
                dibsCss.ptLarge,
                dibsCss.pbMedium,
                dibsCss.pxXsmall
            )}
        >
            <div
                className={classnames(dibsCss.mbLarge, dibsCss.textCenter)}
                data-tn="personalized-categories-header"
            >
                <h2
                    className={classnames(
                        dibsCss.sassyFontHeaderXLarge,
                        dibsCss.m0,
                        dibsCss.mbXsmall
                    )}
                >
                    {title}
                </h2>
                <Link
                    className={classnames(dibsCss.sassyFontBody, dibsCss.textCenter)}
                    href="/favorites/discover/"
                    target="_blank"
                    onClick={() => trackModule(componentModule.cmsDisplayTitle)}
                >
                    <FormattedMessage
                        id="homepage.modules.personalizations.discoverCta"
                        defaultMessage="Discover More"
                    />
                </Link>
            </div>

            {!items.length ? (
                <Spinner
                    containerClass={classnames(dibsCss.my0, dibsCss.mxAuto, styles.spinContainer)}
                />
            ) : (
                <Carousel
                    classNames={{
                        dotsWrapper: styles.dotsWrapper,
                        list: styles.list,
                        item: styles.carouselItem,
                    }}
                    hideArrows
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    step={itemsPerPage}
                    showDots={totalItems > itemsPerPage}
                    renderItem={({ index }: { index: number }) => {
                        const node = items[index].node;
                        const {
                            categoryTitle,
                            categoryItems,
                            variant,
                            linkData,
                            categoryMainItem,
                        } = node || {};
                        const categoryUrl = linkData?.path || '';
                        const tripleIndex = index * 3;
                        const categoryIndex = tripleIndex + 1;
                        const trackMainItem = (): void => {
                            trackPersonalizationItemClick({
                                item: categoryMainItem,
                                variant: TRACKING_VARIANT_BROWSE,
                                categoryUrl,
                                index: categoryIndex,
                                moduleVariantType: variant,
                            });
                            trackModuleLocation({
                                label: `arrivals module - view all ${index + 1}`,
                                moduleIndex,
                                totalModules,
                            });
                            trackModule(componentModule.cmsDisplayTitle);
                        };
                        return (
                            <div
                                key={categoryUrl}
                                data-tn="personalized-category-container"
                                className={classnames(
                                    dibsCss.hFull,
                                    dibsCss.bgWhite,
                                    dibsCss.flex,
                                    dibsCss.justifyCenter,
                                    dibsCss.flexCol,
                                    dibsCss.my0,
                                    dibsCss.mxXsmall
                                )}
                            >
                                <HpSharedCategoryProducts
                                    title={categoryTitle || ''}
                                    products={categoryItems?.filter(filterNulls) || []}
                                    shopNowUrl={categoryUrl}
                                    variant={variant || ''}
                                    imageSize={hasBorder ? 'imageSmall' : 'imageMedium'}
                                    srcSetSizes={hasBorder ? '30vw' : '40vw'}
                                    categoryIndex={categoryIndex}
                                    onButtonClick={trackMainItem}
                                    componentModule={componentModule}
                                />
                            </div>
                        );
                    }}
                    renderDot={({ isCurrentDot }: { isCurrentDot?: boolean } = {}) => (
                        <SharedCarouselDot isActive={isCurrentDot} />
                    )}
                />
            )}
        </div>
    );

    return (
        <HpSharedModuleContainer addTopGap dataTn="personalized-categories-wrapper">
            <PageLayout
                setOffset={false}
                // fullBleed background-color on mobile
                setViewportBackground={fullBleed && 'sassyColorBuyerTertiary'}
            >
                {personalizationItems && personalizationItems.length ? (
                    <InViewport stopWhenInViewport onInViewportChange={trackImpressions}>
                        {personalizedCategoriesEl}
                    </InViewport>
                ) : (
                    personalizedCategoriesEl
                )}
            </PageLayout>
        </HpSharedModuleContainer>
    );
};

export const HpSharedPersonalizedCategories = createFragmentContainer(
    HpSharedPersonalizedCategoriesComponent,
    {
        personalization: graphql`
            fragment HpSharedPersonalizedCategories_personalization on PersonalizationModuleConnection {
                edges {
                    node {
                        linkData {
                            path
                        }
                        categoryTitle
                        variant
                        categoryMainItem {
                            serviceId
                            contemporaryTrackingString
                            seller {
                                serviceId
                            }
                            pricing {
                                retailPrice {
                                    amount
                                }
                            }
                        }
                        categoryItems {
                            localizedPdpUrl
                            serviceId
                            contemporaryTrackingString
                            seller {
                                serviceId
                            }
                            pricing {
                                retailPrice {
                                    amount
                                }
                            }
                            ...Tile_item @arguments(showPrice: false, showSeller: false)
                        }
                    }
                }
            }
        `,
        componentModule: graphql`
            fragment HpSharedPersonalizedCategories_componentModule on ArrivalsModule {
                title
                cmsDisplayTitle
                ...HpSharedCategoryProducts_componentModule
            }
        `,
    }
);

export default HpSharedPersonalizedCategories;

type ConvertedAmountList = readonly ({
    amount?: number | null;
    currency?: string | null;
} | null)[];

export function getAmountInCurrency(
    convertedAmountList?: ConvertedAmountList | null,
    currency?: string
): number {
    return convertedAmountList?.find(p => p?.currency === currency)?.amount || 0;
}

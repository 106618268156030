/**
 * @generated SignedSource<<e7e4d538fc8052c980a090c77729a377>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useAuctionPricing_item$data = {
  readonly relevantAuctionLot: {
    readonly buyItNowPrice: {
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
    } | null;
    readonly openingBid: {
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
    } | null;
    readonly reservePrice: {
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
    } | null;
  } | null;
  readonly relevantAuctionSolution: {
    readonly bids: {
      readonly totalResults: number | null;
    } | null;
    readonly hasReserve: boolean | null;
    readonly highestBidAmount: {
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
    } | null;
    readonly reserveMet: boolean | null;
  } | null;
  readonly " $fragmentType": "useAuctionPricing_item";
};
export type useAuctionPricing_item$key = {
  readonly " $data"?: useAuctionPricing_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAuctionPricing_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ConvertedAmountList",
    "kind": "LinkedField",
    "name": "convertedAmountList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAuctionPricing_item",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "AuctionSolution",
      "kind": "LinkedField",
      "name": "relevantAuctionSolution",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasReserve",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reserveMet",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AuctionBidsConnection",
          "kind": "LinkedField",
          "name": "bids",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalResults",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "highestBidAmount",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "AuctionLot",
      "kind": "LinkedField",
      "name": "relevantAuctionLot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "buyItNowPrice",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "openingBid",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "reservePrice",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "4e6cb72a89e7c704046f7081cf13d4ba";

export default node;

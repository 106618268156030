import { FC, ComponentProps, useCallback, useRef, Suspense } from 'react';
import { useFragment, graphql } from 'react-relay';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';

import {
    AB_TEST_DYNAMIC_COLLECTION,
    useDynamicCollectionVariant,
} from '../helpers/abTestDynamicCollection';

import { HpSharedMostSavedItemsCarousel } from '../HpSharedMostSavedItemsCarousel/HpSharedMostSavedItemsCarousel';
import { HpSharedExcellentValuesCarousel } from '../HpSharedExcellentValuesCarousel/HpSharedExcellentValuesCarousel';
import { HpSharedBestsellingDesignsCarousel } from '../HpSharedBestsellingDesignsCarousel/HpSharedBestsellingDesignsCarousel';
import { dibsLazyClient } from 'dibs-lazy/exports/dibsLazyClient';
const HpSharedLocationsModuleControl = dibsLazyClient(() =>
    import(
        /* webpackChunkName: "HpSharedLocationsModuleControl" */ './HpSharedLocationsModuleControl'
    ).then(m => ({
        default: m.HpSharedLocationsModuleControl,
    }))
);

import { HpSharedLocationsModule_viewer$key } from './__generated__/HpSharedLocationsModule_viewer.graphql';
import { HpSharedLocationsModule_user$key } from './__generated__/HpSharedLocationsModule_user.graphql';
import { HpSharedLocationsModule_componentModule$key } from './__generated__/HpSharedLocationsModule_componentModule.graphql';

const viewerFragment = graphql`
    fragment HpSharedLocationsModule_viewer on Viewer {
        ...HpSharedMostSavedItemsCarousel_viewer @include(if: $isDynamicCollectionVariant)
        ...HpSharedExcellentValuesCarousel_viewer @include(if: $isDynamicCollectionVariant)
        ...HpSharedBestsellingDesignsCarousel_viewer @include(if: $isDynamicCollectionVariant)
    }
`;

const userFragment = graphql`
    fragment HpSharedLocationsModule_user on User {
        ...HpSharedMostSavedItemsCarousel_user @include(if: $isDynamicCollectionVariant)
        ...HpSharedExcellentValuesCarousel_user @include(if: $isDynamicCollectionVariant)
        ...HpSharedBestsellingDesignsCarousel_user @include(if: $isDynamicCollectionVariant)
    }
`;

const componentModuleFragment = graphql`
    fragment HpSharedLocationsModule_componentModule on LocationsModule {
        ...HpSharedLocationsModuleControl_componentModule @skip(if: $isDynamicCollectionVariant)
    }
`;

export const HpSharedLocationsModule: FC<
    Omit<
        ComponentProps<typeof HpSharedLocationsModuleControl>,
        'viewer' | 'user' | 'componentModule'
    > & {
        viewer: HpSharedLocationsModule_viewer$key;
        user?: HpSharedLocationsModule_user$key | null;
        componentModule: HpSharedLocationsModule_componentModule$key;
        isClient: boolean;
    }
> = ({
    viewer: viewerRef,
    user: userRef,
    componentModule: componentModuleRef,
    isClient: afterRefetch,
    ...props
}) => {
    const { isMobile } = props;

    const viewer = useFragment(viewerFragment, viewerRef);
    const componentModule = useFragment(componentModuleFragment, componentModuleRef);
    const user = useFragment(userFragment, userRef);

    const isDynamicCollectionVariant = useDynamicCollectionVariant();

    const elementRef = useRef<HTMLDivElement>(null);
    const onVisibilityChange: ComponentProps<typeof VisibilityTracker>['onVisibilityChange'] =
        useCallback(({ isVisible }) => {
            if (isVisible) {
                trackAbTestV2Variant(AB_TEST_DYNAMIC_COLLECTION);
            }
        }, []);

    return (
        <>
            {afterRefetch && (
                <div ref={elementRef}>
                    <VisibilityTracker
                        elementRef={elementRef}
                        onVisibilityChange={onVisibilityChange}
                    />
                </div>
            )}
            {/* The isDynamicCollectionVariant !== null check prevents server-side rendering. This is necessary because
             * the variant and control have different shimmers. When cleaning up this test, remove
             * the check so the shimmer can render on the server side.
             */}

            {isDynamicCollectionVariant !== null &&
                (isDynamicCollectionVariant ? (
                    /**
                     * Since fragments are behind an client A/B test they won't be available until after the refetch.
                     * Because of this we need to pass null to the viewer and user props until the refetch is complete.
                     */
                    <>
                        <HpSharedMostSavedItemsCarousel
                            viewer={afterRefetch ? viewer : null}
                            user={afterRefetch ? user : null}
                            isMobile={isMobile}
                        />
                        <HpSharedExcellentValuesCarousel
                            viewer={afterRefetch ? viewer : null}
                            user={afterRefetch ? user : null}
                            isMobile={isMobile}
                        />
                        <HpSharedBestsellingDesignsCarousel
                            viewer={afterRefetch ? viewer : null}
                            user={afterRefetch ? user : null}
                            isMobile={isMobile}
                        />
                    </>
                ) : (
                    <Suspense fallback={null}>
                        <HpSharedLocationsModuleControl
                            {...props}
                            componentModule={componentModule}
                        />
                    </Suspense>
                ))}
        </>
    );
};

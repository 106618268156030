/**
 * @generated SignedSource<<dd5a64b5afd3998ae1b82813aeeb737f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwiperItem_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LazyThumbVideo_viewer">;
  readonly " $fragmentType": "SwiperItem_viewer";
};
export type SwiperItem_viewer$key = {
  readonly " $data"?: SwiperItem_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwiperItem_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwiperItem_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LazyThumbVideo_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "6f8e517da31075b28546d7a9ca03134a";

export default node;

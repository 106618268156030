import { FC, Fragment } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import { Maybe } from 'dibs-ts-utils/exports/Maybe';
import { includes } from 'dibs-ts-utils/exports/includes';

// Components
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import {
    QuickViewCategoryParagraph_item$data,
    QuickViewCategoryParagraph_item$key,
} from './__generated__/QuickViewCategoryParagraph_item.graphql';
import { QuickViewCategoryParagraph_itemSearch$key } from './__generated__/QuickViewCategoryParagraph_itemSearch.graphql';
import { PAGE_TYPE, BROWSE_TYPES } from 'dibs-constants/exports/pageTypes';
import { useAppliedFilterQuickView } from '../helpers/useValidFilterHelpers';
import { useValidFilter } from '../helpers/useValidFilter';

type QuickViewCategoryParagraphProps = {
    item: QuickViewCategoryParagraph_item$key;
    itemSearch: QuickViewCategoryParagraph_itemSearch$key | null | undefined;
    attributes: string;
    paragraphClassName: string;
    categoryClassName: string;
};

export type CategorySegmentsType = Maybe<
    NonNullable<QuickViewCategoryParagraph_item$data['quickViewDisplay']>['paragraphAttributeNames']
>;

export const whitelistedPageTypes = BROWSE_TYPES.filter(type => type !== PAGE_TYPE.BUY);

export const copy = {
    category: (
        <FormattedMessage id="dc.searchProductTile.category" defaultMessage="Category">
            {msg => <Fragment>{msg}</Fragment>}
        </FormattedMessage>
    ),
};

const itemFragment = graphql`
    fragment QuickViewCategoryParagraph_item on Item {
        quickViewDisplay {
            paragraphAttributeNames {
                period
                origin
                style
                periodPrefix
            }
        }
    }
`;

const itemSearchFragment = graphql`
    fragment QuickViewCategoryParagraph_itemSearch on ItemSearchQueryConnection {
        meta {
            header
        }
        pageType
        ...useValidFilter_itemSearch
    }
`;

export const QuickViewCategoryParagraph: FC<QuickViewCategoryParagraphProps> = ({
    item: itemRef,
    itemSearch: itemSearchRef,
    attributes,
    paragraphClassName,
    categoryClassName,
}) => {
    const item = useFragment(itemFragment, itemRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const validFilter = useValidFilter({
        itemSearch,
    });
    const pageHeading = itemSearch?.meta?.header;
    const pageType = itemSearch?.pageType;
    const categorySegments: CategorySegmentsType = item?.quickViewDisplay?.paragraphAttributeNames;

    let category = useAppliedFilterQuickView({
        pageType,
        appliedFilter: validFilter,
        pageHeading,
        categorySegments,
    });

    if (categorySegments && pageType && includes(whitelistedPageTypes, pageType)) {
        const filteredCategorySegments = Object.entries(categorySegments)
            // GROWTH-5337: remove segment if it matches pageType
            .filter(([key]) => key !== pageType)
            .map(([, value]) => value);

        category = [...filteredCategorySegments, pageHeading].join(' ');
    }

    if (!category && !attributes) {
        return null;
    }

    return (
        <HeadingLevel>
            {Heading => (
                <div data-tn="quick-view-category" className={paragraphClassName}>
                    {copy.category}
                    <Heading dataTn="quick-view-category-text" className={categoryClassName}>
                        {category || attributes}
                    </Heading>
                </div>
            )}
        </HeadingLevel>
    );
};

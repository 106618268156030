// Flow types automatically removed

import { graphql, commitMutation } from 'react-relay/legacy';
export const ADD = 'ADD';
export const REMOVE = 'REMOVE';
const mutation = graphql`
    mutation ModifyPortfolioItemsMutation($input: ModifyPortfolioItemsInput!) {
        modifyPortfolioItems(input: $input) {
            portfolio {
                id
            }
        }
    }
`;
export function commit(
    environment,
    { action, itemsIds, portfolioId, portfolioItemsIds, userId, onCompleted, onError }
) {
    let input = {
        action,
        portfolioId,
        userId,
    };

    if (action === REMOVE) {
        input = { ...input, portfolioItemsIds };
    } else if (action === ADD) {
        input = { ...input, itemsIds };
    }

    commitMutation(environment, {
        mutation,
        variables: {
            input,
        },
        onCompleted,
        onError,
    });
}
export default {
    commit,
};

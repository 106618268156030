/**
 * @generated SignedSource<<2e4cab773d87700a26acf67122440e00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsIcon_viewer$data = {
  readonly conversationsSummary?: {
    readonly lastUnreadMessageDate: string | null;
  } | null;
  readonly " $fragmentType": "NotificationsIcon_viewer";
};
export type NotificationsIcon_viewer$key = {
  readonly " $data"?: NotificationsIcon_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationsIcon_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationsIcon_viewer",
  "selections": [
    {
      "condition": "hasUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "userId"
            }
          ],
          "concreteType": "ConversationSummary",
          "kind": "LinkedField",
          "name": "conversationsSummary",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastUnreadMessageDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "99c34ba41de959793f615420f0897d09";

export default node;

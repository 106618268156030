import * as React from 'react';
import { FormattedMessage } from 'dibs-react-intl';

export const types = {
    SALE: 'WEEKLY_SALE',
    NET: 'NET',
    RETAIL: 'RETAIL',
    SOLD: 'SOLD',
    HOLD: 'HOLD',
    UNAVAILABLE: 'UNAVAILABLE',
    PUR: 'PUR',
    SOLD_PRICE: 'SOLD_PRICE',
};

const textPriceTypes = [types.SOLD, types.UNAVAILABLE, types.PUR, types.HOLD];

export const isValidTextPriceType = (type: string | null | undefined): boolean => {
    return !type ? false : !!textPriceTypes.includes(type);
};

export const textPriceValue = (textPriceType: string | null | undefined): React.ReactElement => (
    <FormattedMessage
        values={{ textPriceType }}
        id="dc.searchProductTile.textPriceValue"
        defaultMessage={`{textPriceType, select,
            SOLD {Sold}
            UNAVAILABLE {Unavailable}
            PUR {Price Upon Request}
            HOLD {On Hold}
            other {Sold}
        }`}
    />
);

export const textPercentageOff = (
    percentageOff: number | string | null | undefined
): React.ReactElement => (
    <FormattedMessage
        id="dc.searchProductTile.percentageOff"
        defaultMessage={`{percentageOff}% Off`}
        values={{ percentageOff }}
    />
);

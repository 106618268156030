import { useContext } from 'react';
import { Ctx } from './HeadingLevel';

export type Level = 2 | 3 | 4 | 5 | 6;
export type Heading = 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

function isLevel(x: number): x is Level {
    return x >= 2 && x <= 6;
}

function isHeading(x: string): x is Heading {
    return ['h2', 'h3', 'h4', 'h5', 'h6'].includes(x);
}

function useHeading(): Heading {
    const level = useContext(Ctx);
    let safeLevel: Level;

    if (isLevel(level)) {
        safeLevel = level;
    } else if (level > 6) {
        safeLevel = 6;
    } else {
        safeLevel = 2;
    }
    // useHeading should consume context w/o handling incrementing
    // the heading level to allow multiple sibling headings of the
    // same level
    const heading = `h${safeLevel}`;

    return isHeading(heading) ? heading : 'h2';
}
export default useHeading;

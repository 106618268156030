import { trackEcommerce, eventNameConstants } from 'dibs-tracking';

export type PromoTrackingObject = {
    id: string;
    name: string;
    creative: string;
    position: number;
};

export type PromoTrackingItem = {
    linkData?: {
        path?: string | null;
    } | null;
    imageUrl?: string | null;
};

export function buildPromoTrackingObject({
    id,
    name,
    item,
    index = 0,
}: {
    id: string;
    name: string;
    item: PromoTrackingItem | null;
    index?: number;
}): PromoTrackingObject {
    const url = item?.linkData?.path || item?.imageUrl || '';
    const position = index + 1;
    return {
        id,
        name,
        creative: url,
        position: position,
    };
}

export function trackPromoImpressions(promoTrackingObjects: PromoTrackingObject[]): void {
    trackEcommerce({
        type: 'promotionImpression',
        eventName: eventNameConstants.EVENT_VIEW_PROMOTION,
        promotions: promoTrackingObjects,
    });
}

export function trackPromoClick(promoTrackingObject: PromoTrackingObject): void {
    trackEcommerce({
        type: 'promoClick',
        eventName: eventNameConstants.EVENT_SELECT_PROMOTION,
        promotions: [promoTrackingObject],
    });
}

import { FC } from 'react';
import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';
import { FormattedMessage } from 'dibs-react-intl';
import classnames from 'classnames';
import styles from './swipeIndicator.scss';

type Props = {
    isVisible: boolean;
    containerAttributes?: { [key: string]: string | number | boolean | null | undefined };
};

export const SwipeIndicator: FC<Props> = ({ isVisible, containerAttributes = {} }) => {
    return (
        <div
            {...containerAttributes}
            className={classnames(styles.swipeIndicator, {
                [styles.isVisible]: isVisible,
            })}
        >
            <FormattedMessage defaultMessage="Swipe" id="productTile.swipeIndicatorText" />
            <ArrowRight className={styles.swipeIndicatorArrow} />
        </div>
    );
};

import { useRef, useEffect, useMemo, MutableRefObject } from 'react';
import createFocusTrap, { FocusTrap, Options } from './createFocusTrap';

// TODO FI-1462 create new FocusTrap package, write docs, write test, write storybook

export type Settings = {
    active?: boolean;
    paused?: boolean;
    options?: Options;
};
type UseFocusTrapType = (settings?: Settings) => MutableRefObject<HTMLElement | null>;

const defaultSettings = {
    active: true,
    paused: false,
    options: {},
};

export const useFocusTrap: UseFocusTrapType = (settings = defaultSettings) => {
    const { active = true, paused = false, options = {} } = settings;
    const focusTrap = useRef<FocusTrap | null>(null);
    const activeRef = useRef<boolean>(active);
    const pausedRef = useRef<boolean>(paused);
    const elementRef = useRef<HTMLElement>(null);

    // prevent shallow equality options check from reactivating effects
    const optionsMemo = useMemo(
        () => ({
            onActivate: options.onActivate,
            onDeactivate: options.onDeactivate,
            initialFocus: options.initialFocus,
            fallbackFocus: options.fallbackFocus,
            returnFocusOnDeactivate: options.returnFocusOnDeactivate,
            setReturnFocus: options.setReturnFocus,
            escapeDeactivates: options.escapeDeactivates,
            clickOutsideDeactivates: options.clickOutsideDeactivates,
            allowOutsideClick: options.allowOutsideClick,
            autoFocusFirstTabbable: options.autoFocusFirstTabbable,
        }),
        [
            options.onActivate,
            options.onDeactivate,
            options.initialFocus,
            options.fallbackFocus,
            options.returnFocusOnDeactivate,
            options.setReturnFocus,
            options.escapeDeactivates,
            options.clickOutsideDeactivates,
            options.allowOutsideClick,
            options.autoFocusFirstTabbable,
        ]
    );

    // effect for FocusTrap init
    useEffect(() => {
        if (elementRef.current && !focusTrap.current) {
            focusTrap.current = createFocusTrap(elementRef.current, optionsMemo);
            if (focusTrap.current) {
                if (activeRef.current) focusTrap.current.activate();
                focusTrap.current[pausedRef.current ? 'pause' : 'unpause']();
            }
        }
        return () => {
            if (focusTrap.current) {
                focusTrap.current.deactivate();
                focusTrap.current = null;
            }
        };
    }, [elementRef, optionsMemo]);

    // effect for FocusTrap activation/pausing changes
    useEffect(() => {
        if (focusTrap.current && elementRef.current) {
            if (activeRef.current !== active) {
                if (active) {
                    focusTrap.current.activate();
                } else {
                    const config = { returnFocus: optionsMemo.returnFocusOnDeactivate || false };
                    focusTrap.current.deactivate(config);
                }
            }
            if (pausedRef.current !== paused) {
                focusTrap.current[paused ? 'pause' : 'unpause']();
            }
        }
        activeRef.current = active;
        pausedRef.current = paused;
    }, [active, paused, optionsMemo, elementRef]);
    return elementRef;
};

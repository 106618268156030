/**
 * @generated SignedSource<<c1f68ac2c16c600d7f0ac3bc2ed260bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedCategoryProducts_componentModule$data = {
  readonly cmsDisplayTitle: string | null;
  readonly " $fragmentType": "HpSharedCategoryProducts_componentModule";
};
export type HpSharedCategoryProducts_componentModule$key = {
  readonly " $data"?: HpSharedCategoryProducts_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedCategoryProducts_componentModule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedCategoryProducts_componentModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cmsDisplayTitle",
      "storageKey": null
    }
  ],
  "type": "ArrivalsModule",
  "abstractKey": null
};

(node as any).hash = "8698392cc1b12b00a503c141c6d9a92c";

export default node;

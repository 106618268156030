/**
 * @generated SignedSource<<e989f6ce4bdd31fdc9ee24b63ad47d9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductSwiper_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SwiperItem_itemSearch">;
  readonly " $fragmentType": "ProductSwiper_itemSearch";
};
export type ProductSwiper_itemSearch$key = {
  readonly " $data"?: ProductSwiper_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductSwiper_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductSwiper_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwiperItem_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "772e44a0100df240ed63337274df5f8e";

export default node;

let gtmEventQueue: typeof window.dataLayer = [];

export function getGtmEventQueue(): typeof gtmEventQueue {
    return gtmEventQueue;
}

export function setGtmEventQueue(queue: ObjectType[]): void {
    if (Array.isArray(queue)) {
        gtmEventQueue = queue;
    }
}

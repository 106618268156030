import { trackEcommerce, eventNameConstants } from 'dibs-tracking';
import { getPromoCreative } from '../../homepage/helpers/getPromoCreative';

function getShopByPromoObj({ position, creative }) {
    return {
        id: 'eight-block-module',
        name: 'Eight Block Module',
        creative,
        position,
    };
}

function trackShopByItemsImpressions(items) {
    trackEcommerce({
        type: 'promotionImpression',
        eventName: eventNameConstants.EVENT_VIEW_PROMOTION,
        promotions: items.map((item, index) => {
            const url = item?.linkData?.path || '';
            const position = index + 1;
            return getShopByPromoObj({
                creative: getPromoCreative(url),
                position,
            });
        }),
    });
}

function trackShopByItemClick(item, index) {
    const url = item?.linkData?.path || '';
    const position = index + 1;
    const promoObj = getShopByPromoObj({
        creative: getPromoCreative(url),
        position,
    });

    trackEcommerce({
        type: 'promoClick',
        eventName: eventNameConstants.EVENT_SELECT_PROMOTION,
        promotions: [promoObj],
    });
}

function trackShopByLocationsImpressions(index, locationUrl = 'none', isPersonalized = false) {
    const variantType = isPersonalized ? 'personalized' : 'default';
    const position = index + 1;
    trackEcommerce({
        type: 'promotionImpression',
        eventName: eventNameConstants.EVENT_VIEW_PROMOTION,
        promotions: [
            {
                id: 'four-block-module',
                name: 'Four Block Module',
                creative: locationUrl,
                position,
                variant: `${variantType} location`,
            },
        ],
    });
}

function trackShopByLocationClick(item, index) {
    const url = item?.linkData?.path || '';
    const position = index + 1;
    trackEcommerce({
        type: 'promoClick',
        eventName: eventNameConstants.EVENT_SELECT_PROMOTION,
        promotions: [
            {
                id: 'four-block-module',
                name: 'Four Block Module',
                creative: getPromoCreative(url),
                position: position,
            },
        ],
    });
}

export {
    trackShopByItemsImpressions,
    trackShopByLocationsImpressions,
    trackShopByItemClick,
    trackShopByLocationClick,
};

/**
 * @generated SignedSource<<da4f3b40fbb5be20f429d06281463f18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemTile_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ItemTileBase_itemSearch">;
  readonly " $fragmentType": "ItemTile_itemSearch";
};
export type ItemTile_itemSearch$key = {
  readonly " $data"?: ItemTile_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemTile_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemTile_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemTileBase_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "a939e50b2f2f197eaeeddbb58c1209f8";

export default node;

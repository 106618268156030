import MultiTouchDetector from './MultiTouchDetector';
import { getPointAngle, getQuadrant } from './math';
const noop = () => {};

const quadrantDirectionMap = ['right', 'up', 'left', 'down'];

/**
 * Utility to detect if touch event is cancelable
 * @param {TouchEvent} event
 * return {boolean}
 */
const isCancelable = event => typeof event.cancelable !== 'boolean' || event.cancelable;

/**
 * Utility to detect if touch event is passive
 * @param {TouchEvent} event
 * return {boolean}
 */
const isPassive = event => typeof event.defaultPrevented !== 'boolean' || event.defaultPrevented;

/**
 * Utility to tell whether absolute value of some number greater than given threshold
 * @param {number} distance
 * @param {number} threshold
 * @returns {boolean}
 */
const isPastThreshold = (distance, threshold = 5) => Math.abs(distance) > threshold;

/**
 * Utility to detect the direction of the swipe.
 * @param {Array} - Array consisting of the touch coordinates [x, y]
 * @return {Object} - Enum class
 */
function swipeDirection(distance) {
    const quadrant = getQuadrant(getPointAngle(distance[0], distance[1]) + 45);
    return quadrantDirectionMap[quadrant];
}

/**
 * Utility class To handle swipe detection. Pass an element in and get notified on a swipe on that element. Instances
 * need to be destroyed when tracking is no longer needed.
 *
 * @param  {Object}  options.el Element to track.
 * @param  {Function}  [options.onP`artialSwipe] Callback to fire on initial panning. Allows you to get distance panned.
 * @param  {Function}  [options.onSwipeSuccess] Callback to fire on successful swipe. Callback is passed the swipe direction.
 * @param  {Function}  [options.onSwipeFailure] Callback to fire on failed swipe.
 */
class SwipeDetector {
    constructor({
        el,
        onPartialSwipe = noop,
        onSwipeSuccess = noop,
        onSwipeFailure = noop,
        onSwipeStart = noop,
        onSwipeEnd = noop,
    } = {}) {
        if (!el) {
            throw new Error('Must pass `el` to SwipeDetector');
        }

        Object.assign(this, {
            _distance: [null, null],
            _initialPosition: [null, null],
            _initialTime: null,
            _swiping: false,
            _lastDirection: null,
            _el: el,
            _onPartialSwipe: onPartialSwipe,
            _onSwipeSuccess: onSwipeSuccess,
            _onSwipeFailure: onSwipeFailure,
            _onSwipeStart: onSwipeStart.bind(this),
            _onSwipeEnd: onSwipeEnd.bind(this),
            _multiTouchDetector: new MultiTouchDetector({
                onSingleTouchStart: this._onSingleTouchStart.bind(this),
                onSingleTouchMove: this._onSingleTouchMove.bind(this),
                onSingleTouchEnd: this._onSingleTouchEnd.bind(this),
                el,
            }),
        });
    }

    _onSingleTouchStart(e) {
        // Get the initial position and time so we can do a difference on them later.
        this._initialPosition = [e.touches[0].clientX, e.touches[0].clientY];
        this._initialTime = Date.now();
        this._onSwipeStart();
    }

    _preventDefaultTouch(e) {
        if (!isPassive(e) && isCancelable(e)) {
            e.preventDefault();
        }
    }

    _onSingleTouchMove(e) {
        this._distance = [
            e.touches[0].clientX - this._initialPosition[0],
            e.touches[0].clientY - this._initialPosition[1],
        ];
        const direction = swipeDirection(this._distance);

        const [distX] = this._distance;
        const isMovementX = direction === 'left' || direction === 'right';
        const isMovementY = direction === 'up' || direction === 'down';

        /**
         * Save last X movement direction for cases when the user is still in swiping
         * mode but moves his finger in the Y direction
         */
        if (isMovementX) {
            this._lastDirection = direction;
        }

        /**
         * Don't cancel swiping action if the user moves his finger Y direction while swiping
         */
        if (isMovementX || (isMovementY && this._swiping)) {
            this._swiping = isPastThreshold(distX);
        }

        if (this._swiping) {
            // We don't wanna messup the scrolling experience so we only call preventDefault when swiping horizontally
            this._preventDefaultTouch(e);

            // Only call partialSwipe handler if user is actually swiping
            this._onPartialSwipe(this._distance, this._lastDirection);
        }
    }

    _onSingleTouchEnd() {
        if (this._shouldRegisterSwipe() && this._lastDirection) {
            // Determine swipe direction based on sign of distance.
            this._onSwipeSuccess(this._lastDirection);
        } else {
            this._onSwipeFailure();
        }

        this._onSwipeEnd();

        this._swiping = false;
        this._distance = [0, 0];
    }

    _shouldRegisterSwipe() {
        const totalTime = Date.now() - this._initialTime;
        let absoluteDistance;

        return this._distance.some(distance => {
            absoluteDistance = Math.abs(distance);

            // Register a swipe if:
            // slide duration is less than 250ms and if slide distance is greater than 20px
            // OR if slide distance is greater than half the width of the element.
            return (
                absoluteDistance > this._el.clientWidth / 2 ||
                (absoluteDistance > 20 && totalTime < 250)
            );
        });
    }

    destroy() {
        this._multiTouchDetector.destroy();
    }
}

export default SwipeDetector;

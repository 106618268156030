// general typescript utilities

export function omit<T extends Readonly<{ [key: string]: unknown }>, K extends string>(
    arg: T,
    keys: K[]
): Omit<T, K> {
    // actually write better filter!?
    const shallowClone = { ...arg };
    for (const key of keys) {
        delete shallowClone[key];
    }
    return shallowClone;
}

// use some secret typescript magic to transform unions to intersections then back to
// (different) unions
// https://stackoverflow.com/questions/50374908/transform-union-type-to-intersection-type
// and
// https://stackoverflow.com/questions/55904032/how-to-get-optional-property-from-union

type UnionToIntersection<U> = (U extends unknown ? (k: U) => void : never) extends (
    k: infer I
) => void
    ? I
    : never;

type ValueOfUnion<T, K> = T extends unknown ? (K extends keyof T ? T[K] : undefined) : never;

type UnionMapping<T> = {
    [K in keyof UnionToIntersection<T> | keyof T]: ValueOfUnion<T, K>;
};

export type UnionMerge<T> = Pick<UnionMapping<T>, keyof T> & Partial<UnionMapping<T>>;

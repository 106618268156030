import { createContext, useContext } from 'react';

type ScrollCarouselItemContextType = {
    isActive: boolean;
    setActive: () => void;
};

const ScrollCarouselItemContext = createContext<ScrollCarouselItemContextType | null>(null);

export const useScrollCarouselItem = (): ScrollCarouselItemContextType => {
    const context = useContext(ScrollCarouselItemContext);
    if (!context) {
        throw new Error('useScrollCarouselItem must be used within a ScrollCarouselItemProvider');
    }
    return context;
};

export const ScrollCarouselItemProvider = ScrollCarouselItemContext.Provider;

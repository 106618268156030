/**
 * @generated SignedSource<<5a9a2f5754b61f3924151ca0dfad91cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HpSharedLocationsModule_componentModule$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedLocationsModuleControl_componentModule">;
  readonly " $fragmentType": "HpSharedLocationsModule_componentModule";
};
export type HpSharedLocationsModule_componentModule$key = {
  readonly " $data"?: HpSharedLocationsModule_componentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"HpSharedLocationsModule_componentModule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isDynamicCollectionVariant"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HpSharedLocationsModule_componentModule",
  "selections": [
    {
      "condition": "isDynamicCollectionVariant",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HpSharedLocationsModuleControl_componentModule"
        }
      ]
    }
  ],
  "type": "LocationsModule",
  "abstractKey": null
};

(node as any).hash = "98794bb8950925c993426df40a698c3c";

export default node;
